import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { closeGlobalModal } from '../../store/reducers/globalModalReducer'
import { agentGroupsActions } from '../../store/actions/agentGroupsActions'
import styled from '@emotion/styled'
import {
  StyledCloseButtonModal,
  StyledDialog,
  StyledDialogActions,
  StyledDialogBody,
  StyledDialogContent,
  StyledDialogContentText,
  StyledDialogHeader,
  StyledDialogTitle,
} from './styledModalComponent'
import CancelIcon from '@mui/icons-material/Cancel'
import { Button } from '@mui/material'
import { mobileUserActions } from '../../store/actions/mobileUserActions'

const StyledDialogModalTitle = styled(StyledDialogTitle)({
  width: '300px',
})

type SetExpirationDateModalProps = {
  isOpenDialog: boolean
  // onCloseModalClick: ActionCreatorWithoutPayload<'globalModal/closeGlobalModal'>
}

const SetExpirationDateModal = ({ isOpenDialog }: SetExpirationDateModalProps) => {
  const dispatch = useAppDispatch()
  const { actionData: userId } = useAppSelector(state => state.globalModal)

  const closeModal = useCallback(() => {
    dispatch(closeGlobalModal())
  }, [dispatch])

  const onConfirm = useCallback(() => {
    userId && dispatch(mobileUserActions.setUserExpirationDate(userId))

    closeModal()
  }, [closeModal, dispatch, userId])

  return (
    <StyledDialog open={isOpenDialog} onClose={closeModal}>
      <StyledDialogHeader>
        <StyledDialogModalTitle>Setting Expiration Date</StyledDialogModalTitle>
        <StyledCloseButtonModal onClick={closeModal}>
          Close
          <CancelIcon />
        </StyledCloseButtonModal>
      </StyledDialogHeader>
      <StyledDialogBody>
        <StyledDialogContent>
          <StyledDialogContentText>
            Are you sure you want to set an expiration date for the user's verification status?
          </StyledDialogContentText>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button size="large" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="large" variant="contained" onClick={onConfirm}>
            Confirm
          </Button>
        </StyledDialogActions>
      </StyledDialogBody>
    </StyledDialog>
  )
}

export default SetExpirationDateModal
