import { useCallback } from 'react'
import {
  StyledCloseButtonModal,
  StyledDialog,
  StyledDialogActions,
  StyledDialogBody,
  StyledDialogContent,
  StyledDialogContentText,
  StyledDialogHeader,
  StyledDialogTitle,
} from '../../../../../components/modal/styledModalComponent'
import { useAppDispatch, useAppSelector } from '../../../../../store'
import { closeGlobalModal } from '../../../../../store/reducers/globalModalReducer'
import CancelIcon from '@mui/icons-material/Cancel'
import { Button, styled } from '@mui/material'
import ChangingBlockingRecordStatusForm from './ChangingBlockingRecordStatusForm'
import { ChangeBlockingRecordStatusForm } from '../../../../../types/mobileUserTypes'
import { mobileUserActions } from '../../../../../store/actions/mobileUserActions'

const StyledDialogModalTitle = styled(StyledDialogTitle)({
  width: '100%',
})

type BlockingRecordConfirmationModalProps = {
  isOpenDialog: boolean
}

const BlockingRecordConfirmationModal = ({
  isOpenDialog,
}: BlockingRecordConfirmationModalProps) => {
  const dispatch = useAppDispatch()

  const { id: entityId } = useAppSelector(state => state.globalModal)

  const closeModal = useCallback(() => {
    dispatch(closeGlobalModal())
  }, [dispatch])

  const onConfirm = useCallback(
    (body: ChangeBlockingRecordStatusForm) => {
      entityId && dispatch(mobileUserActions.confirmBlockingRecord({ entityId, body }))
      closeModal()
    },
    [closeModal, dispatch, entityId]
  )
  return (
    <StyledDialog open={isOpenDialog} onClose={closeModal}>
      <StyledDialogHeader>
        <StyledDialogModalTitle>Blocking Record Confirmation</StyledDialogModalTitle>
        <StyledCloseButtonModal onClick={closeModal}>
          Close
          <CancelIcon />
        </StyledCloseButtonModal>
      </StyledDialogHeader>
      <StyledDialogBody>
        <StyledDialogContent>
          <StyledDialogContentText>
            Please, type the reason of status changing into this field.
          </StyledDialogContentText>
          <ChangingBlockingRecordStatusForm onMainSubmit={onConfirm} />
        </StyledDialogContent>
        <StyledDialogActions>
          <Button size="large" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            form="changing-blocking-record-status-from"
            type="submit"
            size="large"
            variant="contained"
          >
            Confirm
          </Button>
        </StyledDialogActions>
      </StyledDialogBody>
    </StyledDialog>
  )
}

export default BlockingRecordConfirmationModal
