import { TableConfig } from '../../../types/tableTyles'
import {
  WpCardPaymentAmountItem,
  WpCardPaymentStatusItem,
  WPKuvaLocalItem,
  WpKwlItem,
} from '../../../types/wpTypes'
import { getDateWithTime } from '../../dateFormating'
import {
  getCardPaymentDetailedStatus,
  getCardPaymentEventStatus,
  getCardPaymentStatus,
} from '../../statusUtils/wpStatusUtils'

const wpKuvaLocalTableConfig = (): TableConfig<WPKuvaLocalItem> => {
  return {
    columns: [
      {
        id: 'createdDate',
        header: 'Created Date(UTC)',
        contentColumn: item => getDateWithTime(item.createdDate),
        // width: '30%',
        width: {
          minWidth: '200px',
          width: '40%',
        },
      },
      {
        id: 'status',
        header: 'Status',
        contentColumn: item => getCardPaymentStatus(item.status),
        // width: '20%',
        width: {
          minWidth: '130px',
          width: '20%',
        },
      },
      {
        id: 'amount',
        header: 'Amount',
        contentColumn: item => item.amount,
        // width: '10%',
        width: {
          minWidth: '130px',
          width: '20%',
        },
      },
      {
        id: 'currency',
        header: 'Currency',
        contentColumn: item => item.currency,
        // width: '10%',
        width: {
          minWidth: '100px',
          width: '20%',
        },
      },
    ],
  }
}

const wpKuvaWhiteLabelTableConfig = (): TableConfig<WpKwlItem> => {
  return {
    columns: [
      {
        id: 'instanceId',
        header: 'Instance Id',
        contentColumn: item => item.instance.id,
        width: {
          minWidth: '150px',
          width: '15%',
        },
      },
      {
        id: 'createdDate',
        header: 'Created Date(UTC)',
        contentColumn: item => getDateWithTime(item.createdDate),
        // width: '30%',
        width: {
          minWidth: '200px',
          width: '25%',
        },
      },
      {
        id: 'status',
        header: 'Status',
        contentColumn: item => getCardPaymentStatus(item.status),
        // width: '20%',
        width: {
          minWidth: '130px',
          width: '20%',
        },
      },
      {
        id: 'amount',
        header: 'Amount',
        contentColumn: item => item.amount,
        // width: '10%',
        width: {
          minWidth: '130px',
          width: '20%',
        },
      },
      {
        id: 'currency',
        header: 'Currency',
        contentColumn: item => item.currency,
        // width: '10%',
        width: {
          minWidth: '100px',
          width: '20%',
        },
      },
    ],
  }
}

const wpCardPaymentAmountTable = (): TableConfig<WpCardPaymentAmountItem> => {
  return {
    columns: [
      {
        id: 'id',
        header: 'Id',
        contentColumn: item => item.id,
        // width: '20%',
        width: {
          minWidth: '100px',
          width: '20%',
        },
      },
      {
        id: 'createdDate',
        header: 'Created Date(UTC)',
        contentColumn: item => getDateWithTime(item.createdDate),
        // width: '30%',
        width: {
          minWidth: '200px',
          width: '40%',
        },
      },

      {
        id: 'amount',
        header: 'Amount',
        contentColumn: item => item.amount,
        // width: '20%',
        width: {
          minWidth: '130px',
          width: '20%',
        },
      },
      {
        id: 'currency',
        header: 'Currency',
        contentColumn: item => item.currency,
        // width: '20%',
        width: {
          minWidth: '100px',
          width: '20%',
        },
      },
    ],
  }
}

const getCardPaymentDetailStatus = (details: Record<string, string> | null) => {
  let currentDetails = ''
  if (details === null) {
    currentDetails = '-'
  } else {
    for (let key in details) {
      const modifiedDetails = details[key].replaceAll(',', ', ')
      currentDetails += `${key}: ${modifiedDetails}, `
    }
  }

  return currentDetails
}

const wpCardPaymentStatusConfig = (): TableConfig<WpCardPaymentStatusItem> => {
  return {
    columns: [
      {
        id: 'createdDate',
        header: 'Created Date(UTC)',
        contentColumn: item => getDateWithTime(item.createdDate),
        // width: '30%',
        width: {
          minWidth: '200px',
          width: '30%',
        },
      },

      {
        id: 'event',
        header: 'Event',
        contentColumn: item => getCardPaymentEventStatus(item.event),
        // width: '20%',
        width: {
          minWidth: '200px',
          width: '20%',
        },
      },
      {
        id: 'status',
        header: 'Status',
        contentColumn: item => getCardPaymentDetailedStatus(item.status),
        // width: '20%',
        width: {
          minWidth: '200px',
          width: '20%',
        },
      },
      {
        id: 'details',
        header: 'Details',
        contentColumn: item => getCardPaymentDetailStatus(item.details),
        // width: '20%',
        width: {
          minWidth: '300px',
          width: '30%',
        },
      },
    ],
  }
}

const wpTableConfigs = {
  wpKuvaLocalTableConfig,
  wpCardPaymentAmountTable,
  wpCardPaymentStatusConfig,
  wpKuvaWhiteLabelTableConfig,
}

export default wpTableConfigs
