import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { Button, Container } from '@mui/material'
import LazyLoadingTable from '../../../components/CustomTable/LazyLoadingTable'
import { worldPayActions } from '../../../store/actions/wpActions'
import wpTableConfigs from '../../../utils/config/tableConfig/wp'
import KlCardPaymentFilter from './KlCardPaymentFilter'
import {
  KlCardPaymentFilterForm,
  KlCardPaymentFilters,
  WPKuvaLocalItem,
} from '../../../types/wpTypes'
import { removeKlCardPayments } from '../../../store/reducers/wpReducer'

import { DatePicker } from 'antd'
import moment, { MomentInput } from 'moment'
import getQueryString from '../../../helpers/getQueryString'
import concatenateObjProps from '../../../helpers/concatenateObjProps'
import { useNavigate } from 'react-router-dom'
import { wpForKlPath } from '../../../utils/appPaths'

const { RangePicker } = DatePicker

const WPKuvaLocalPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { items: tableData, hasNextPage } = useAppSelector(
    state => state.worldPay.klCardPaymentList
  )
  const { isMobileView } = useAppSelector(state => state.display)
  const { isLoading, error } = useAppSelector(state => state.worldPay)

  const pageSize = 15
  const offset = 0

  const [isShowFilter, setIsShowFilter] = useState(false)
  const [filterList, setFilterList] = useState<KlCardPaymentFilters>({
    userQuery: '',
    orderReference: '',
    statuses: [],
    dates: { startDate: '', endDate: '' },
    amounts: { min: '', max: '' },
    reference: '',
    paymentType: [],
  })

  useEffect(() => {
    const currentParams = getQueryString(concatenateObjProps({ offset, pageSize }))
    dispatch(worldPayActions.getKLCardPayments({ queryParams: currentParams, offset }))

    return () => {
      dispatch(removeKlCardPayments())
    }
  }, [dispatch])
  
  const tableConfig = useMemo(() => {
    return wpTableConfigs.wpKuvaLocalTableConfig()
  }, [])

  const onOpenFilter = useCallback(() => {
    setIsShowFilter(true)
  }, [])

  const getCardPaymentWithFilter = useCallback(
    (data: KlCardPaymentFilterForm) => {
      const updatedFilterList = { ...filterList, ...data }
      const currentParams = getQueryString(
        concatenateObjProps({ ...updatedFilterList, offset, pageSize })
      )

      dispatch(worldPayActions.getKLCardPayments({ queryParams: currentParams, offset }))
    },
    [dispatch, filterList]
  )

  const onLoadMore = async () => {
    const currentOffset = offset + tableData.length
    const currentParams = getQueryString(
      concatenateObjProps({ ...filterList, offset: currentOffset, pageSize })
    )

    await dispatch(
      worldPayActions.getKLCardPayments({ queryParams: currentParams, offset: currentOffset })
    )
  }

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  })

  const onChangeDate = async (val: [MomentInput, MomentInput] | null) => {
    let queryString = { ...filterList, offset, pageSize }
    let startDate: string, endDate: string

    if (val) {
      startDate = moment(val[0]).format('MM-DD-YYYY') + 'Z'
      endDate = moment(val[1]).format('MM-DD-YYYY') + 'Z'

      queryString = {
        ...queryString,
        dates: { startDate, endDate },
      }
    } else {
      queryString.dates.startDate = queryString.dates.endDate = ''

      startDate = endDate = ''
    }

    const currentParams = getQueryString(concatenateObjProps(queryString))

    await dispatch(worldPayActions.getKLCardPayments({ queryParams: currentParams, offset }))

    setFilterList(prevState => ({
      ...prevState,
      dates: { startDate, endDate },
    }))
  }

  const clearFilters = useCallback(async () => {
    let queryString = { offset, pageSize, dates: { ...filterList.dates } }

    const currentParams = getQueryString(concatenateObjProps(queryString))

    await dispatch(worldPayActions.getKLCardPayments({ queryParams: currentParams, offset }))

    setFilterList(prevState => ({
      ...prevState,
      userQuery: '',
      orderReference: '',
      statuses: [],
      amounts: { min: '', max: '' },
      reference: '',
      paymentType: [],
    }))
  }, [dispatch, filterList.dates])

  const onClickRow = useCallback(
    (id: string | number) => {
      navigate(`${wpForKlPath}/${id}`)
    },
    [navigate]
  )

  return (
    <Container className="pt-25 pb-10 h-100-percent overflow-hidden" maxWidth="xl">
      <h3 className="mb-25">Kuva Local Card Payment</h3>
      <div className={`flex j-content-space-between ${isMobileView ? 'flex-dir-column' : ''}`}>
        <RangePicker className="mb-20" onChange={onChangeDate} />
        <div className="mb-20 align-self-end">
          <Button className="w-130 mr-15" variant="outlined" size="small" onClick={onOpenFilter}>
            Show Filters
          </Button>

          <Button
            className="w-130"
            variant="outlined"
            size="small"
            onClick={clearFilters}
            // disabled={isDisabled}
          >
            Clear Filters
          </Button>
        </div>
      </div>

      <KlCardPaymentFilter
        isShowFilter={isShowFilter}
        setIsShowFilter={setIsShowFilter}
        filterState={filterList}
        setFilterList={setFilterList}
        getCardPaymentWithFilter={getCardPaymentWithFilter}
      />
      <LazyLoadingTable<WPKuvaLocalItem>
        tableData={tableData}
        tableConfig={tableConfig}
        sentryRef={sentryRef}
        rootRef={rootRef}
        onClick={onClickRow}
        minTableWidth="360px"
        loading={isLoading}
        hasNextPage={hasNextPage}
        maxHeight='calc(100vh - 350px)'
      />
    </Container>
  )
}

export default WPKuvaLocalPage
