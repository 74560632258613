import { useCallback, useEffect, useMemo } from 'react'
import { Button, styled } from '@mui/material'
import {
  StyledCloseButtonModal,
  StyledDialog,
  StyledDialogActions,
  StyledDialogBody,
  StyledDialogContent,
  StyledDialogHeader,
  StyledDialogTitle,
} from '../../../../../components/modal/styledModalComponent'
import { useAppDispatch, useAppSelector } from '../../../../../store'
import { closeGlobalModal } from '../../../../../store/reducers/globalModalReducer'

import CancelIcon from '@mui/icons-material/Cancel'
import { remittanceActions } from '../../../../../store/actions/kwlActions'
// import InstanceCurrencyForm from './InstanceCurrencyForm'
import {
  CreateOrUpdateInstanceCurrencyRequestBody,
  InstancePaymentOptionType,
  PaymentMethod,
  PaymentOptionByCountry,
  PaymentOptionByCountryFormType,
} from '../../../../../types/remittanceTypes'
import { removeAfterClosingInstanceCurrencyModal } from '../../../../../store/reducers/kwlReducers'
import { getCurrencyTypeOptions } from '../../../../../utils/remittanceUtils'
import PaymentOptionByCountryForm from './PaymentOptionByCountryForm'
import omit from 'lodash/omit'

const StyledDialogModalTitle = styled(StyledDialogTitle)({
  width: '100%',
  paddingLeft: 0,
})

const StyledPaymentOptionByCountryModal = styled(StyledDialog)({
  '.MuiDialog-paper': {
    width: '600px',
  },
})

type PaymentOptionByCountryModalProps = {
  isOpenDialog: boolean
  variant: 'add-payment-option-by-country' | 'edit-payment-option-by-country'
}

const PaymentOptionByCountryModal = ({
  isOpenDialog,
  variant,
}: PaymentOptionByCountryModalProps) => {
  const dispatch = useAppDispatch()
  const actionData: PaymentOptionByCountry = useAppSelector(state => state.globalModal.actionData)
  const { id, additionalId } = useAppSelector(state => state.globalModal)
  const {
    currencyListByCountry,
    paymentProviderList,
    paymentMethodList,
    selectedCashInCountry,
    selectedCashOutCountry,
  } = useAppSelector(state => state.kwl)

  useEffect(() => {
    const arg = {
      instanceId: String(id),
      countryId: additionalId || actionData?.country?.id || 0,
      type: actionData?.paymentProviderMethod?.paymentOptionType,
    }
    dispatch(remittanceActions.getCurrencyListByCountry(arg))
    dispatch(remittanceActions.getPaymentProviderListV2())
    dispatch(remittanceActions.getPaymentMethodList())
  }, [
    actionData?.country?.id,
    actionData?.paymentProviderMethod?.paymentOptionType,
    additionalId,
    dispatch,
    id,
  ])

  const closeModal = useCallback(() => {
    dispatch(closeGlobalModal())
    dispatch(removeAfterClosingInstanceCurrencyModal())
  }, [dispatch])

  const onConfirm = useCallback(
    (dataForm: PaymentOptionByCountryFormType) => {
      if (variant === 'add-payment-option-by-country') {
        const arg = {
          instanceId: String(dataForm.instanceId),
          countryId: dataForm.countryId,
          type: dataForm.type,
          body: omit(dataForm, 'type'),
        }

        dispatch(remittanceActions.addPaymentOptionByCountry(arg))
      }
      if (variant === 'edit-payment-option-by-country') {
        const arg = {
          paymentOptionId: String(actionData.id),
          instanceId: String(dataForm.instanceId),
          countryId: dataForm.countryId,
          type: dataForm.type,
          body: {
            title: dataForm.title,
            longTitle: dataForm.longTitle,
            description: dataForm.description,
            order: dataForm.order,
            fee: {
              percentValue: dataForm.fee.percentValue,
              fixedValue: dataForm.fee.fixedValue,
            },
          },
        }

        dispatch(remittanceActions.updatePaymentOptionByCountry(arg))
      }

      closeModal()
    },
    [actionData.id, closeModal, dispatch, variant]
  )
  const titleText = useMemo(() => {
    if (variant === 'add-payment-option-by-country') {
      return 'Adding Payment Option By Country'
    }
    if (variant === 'edit-payment-option-by-country') {
      return 'Editing Payment Option By Country'
    }
  }, [variant])

  const currencyOptions = useMemo(
    () =>
      currencyListByCountry.map(currency => ({
        label: currency.displayName,
        value: currency.id,
      })),
    [currencyListByCountry]
  )

  const countryOptions = useMemo(() => {
    if (actionData?.paymentProviderMethod?.paymentOptionType === InstancePaymentOptionType.CashIn) {
      return [
        {
          label: selectedCashInCountry?.name || '',
          value: selectedCashInCountry?.id || '',
        },
      ]
    } else {
      return [
        {
          label: selectedCashOutCountry?.name || '',
          value: selectedCashOutCountry?.id || '',
        },
      ]
    }
  }, [
    actionData?.paymentProviderMethod?.paymentOptionType,
    selectedCashInCountry?.id,
    selectedCashInCountry?.name,
    selectedCashOutCountry?.id,
    selectedCashOutCountry?.name,
  ])

  const currencyTypeOptions = useMemo(
    () => [
      {
        label: InstancePaymentOptionType[actionData?.paymentProviderMethod?.paymentOptionType],
        value: String(actionData?.paymentProviderMethod?.paymentOptionType),
      },
    ],
    [actionData?.paymentProviderMethod?.paymentOptionType]
  )

  const paymentProviderOptions = useMemo(
    () =>
      paymentProviderList.map(paymentProvider => ({
        label: paymentProvider.name,
        value: paymentProvider.id,
      })),
    [paymentProviderList]
  )

  const paymentMethodOptions = useMemo(
    () =>
      paymentMethodList.map(paymentMethod => ({
        label: PaymentMethod[paymentMethod.paymentMethod],
        value: paymentMethod.paymentMethod,
        paymentProviderId: paymentMethod.paymentProvider.id,
      })),

    [paymentMethodList]
  )

  return (
    <>
      {currencyOptions.length > 0 &&
        countryOptions.length > 0 &&
        currencyTypeOptions.length > 0 &&
        paymentProviderOptions.length > 0 &&
        paymentMethodOptions.length > 0 && (
          <StyledPaymentOptionByCountryModal open={isOpenDialog} onClose={closeModal}>
            <StyledDialogHeader>
              <StyledDialogModalTitle>{titleText}</StyledDialogModalTitle>
              <StyledCloseButtonModal onClick={closeModal}>
                Close
                <CancelIcon />
              </StyledCloseButtonModal>
            </StyledDialogHeader>
            <StyledDialogBody>
              <StyledDialogContent>
                <PaymentOptionByCountryForm
                  currencyOptions={currencyOptions}
                  countryOptions={countryOptions}
                  currencyTypeOptions={currencyTypeOptions}
                  paymentProviderOptions={paymentProviderOptions}
                  paymentMethodOptions={paymentMethodOptions}
                  instanceId={id}
                  countryId={additionalId}
                  onConfirm={onConfirm}
                  variant={variant}
                  data={actionData}
                />
              </StyledDialogContent>
              <StyledDialogActions>
                <Button size="large" variant="outlined" onClick={closeModal}>
                  Cancel
                </Button>
                <Button
                  form="instance-currency-form"
                  type="submit"
                  size="large"
                  variant="contained"
                >
                  Save
                </Button>
              </StyledDialogActions>
            </StyledDialogBody>
          </StyledPaymentOptionByCountryModal>
        )}

      {/* )} */}
    </>
  )
}

export default PaymentOptionByCountryModal
