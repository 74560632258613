import { Grid } from '@mui/material'
import React from 'react'
import SettingUserExpirationForm from './SettingUserExpirationForm'

const CommonTab = () => {
  return (
    <Grid
      container
      sx={{
        margin: 0,
        padding: 0,
        width: '100%',
      }}
    >
      <Grid sx={{ padding: '10px 10px 10px 0' }} item xs={12} md={6}>
        <SettingUserExpirationForm />
      </Grid>
    </Grid>
  )
}

export default CommonTab
