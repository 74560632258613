import { memo } from 'react'
import { BlockingRecord } from '../../../../../types/mobileUserTypes'
import { getDateWithTime } from '../../../../../utils/dateFormating'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export type CollapsingBlockingRecordRowProps = {
  itemRow: BlockingRecord
}

const StyledCollapsingBlockingRecordRow = styled(Grid)({
  margin: 0,
  width: '100%',
  '.MuiGrid-item': {
    padding: '16px 0 16px 16px',
    '.MuiBox-root': {
      display: 'flex',
      '.MuiTypography-root': {
        fontSize: '14px',
        '&:first-of-type': { fontWeight: 'bold', width: '20%', minWidth: '190px' },
        '&:last-child': {
          width: '80%',
        },
      },
    },
  },
})

const CollapsingBlockingRecordRow = ({ itemRow }: CollapsingBlockingRecordRowProps) => {
  return (
    <StyledCollapsingBlockingRecordRow container spacing={2}>
      <Grid item xs={12} md={6}>
        {itemRow.blockedBy && (
          <Box>
            <Typography>Blocked By:</Typography>
            <Typography fontSize={14}>{itemRow.blockedBy}</Typography>
          </Box>
        )}
        {itemRow.blockingReason && (
          <Box>
            <Typography>Blocking Reason:</Typography>
            <Typography fontSize={14}>{itemRow.blockingReason}</Typography>
          </Box>
        )}
        {itemRow.confirmationDate && (
          <Box>
            <Typography>Confirmation Date (UTC):</Typography>
            <Typography>{getDateWithTime(itemRow.confirmationDate)}</Typography>
          </Box>
        )}
        {itemRow.confirmBy && (
          <Box>
            <Typography>Confirmed By:</Typography>
            <Typography>{itemRow.confirmBy}</Typography>
          </Box>
        )}
        {itemRow.confirmationReason && (
          <Box>
            <Typography>Confirmation Reason:</Typography>
            <Typography>{itemRow.confirmationReason}</Typography>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {itemRow.resolvedDate && (
          <Box>
            <Typography>Resolved Date (UTC):</Typography>
            <Typography>{getDateWithTime(itemRow.resolvedDate)}</Typography>
          </Box>
        )}
        {itemRow.resolvedBy && (
          <Box>
            <Typography>Resolved By:</Typography>
            <Typography>{itemRow.resolvedBy}</Typography>
          </Box>
        )}
        {itemRow.resolvedReason && (
          <Box>
            <Typography>Resolved Reason:</Typography>
            <Typography>{itemRow.resolvedReason}</Typography>
          </Box>
        )}
      </Grid>
    </StyledCollapsingBlockingRecordRow>
  )
}

export default memo(CollapsingBlockingRecordRow) as typeof CollapsingBlockingRecordRow
