import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AgentGroupKeys, GetAgentGroupListResponse } from '../../types/agentGroupTypes'
import { agentGroupsActions } from '../actions/agentGroupsActions'
import { CustomErrorResponse } from '../../types/apiTypes'

type AgentGroupsState = {
  isLoading: boolean
  error: string
  agentGroupsList: GetAgentGroupListResponse
  agentGroupKeys: AgentGroupKeys | null
}

const initialState: AgentGroupsState = {
  isLoading: false,
  error: '',
  agentGroupKeys: null,
  agentGroupsList: {
    hasNextPage: false,
    items: [],
  },
}

export const agentGroupReducer = createSlice({
  name: 'agentGroups',
  initialState,
  reducers: {
    removeAgentGroupsList: state => {
      state.agentGroupsList.hasNextPage = false
      state.agentGroupsList.items = []
    },
    removeAgentGroupKeys: state => {
      state.agentGroupKeys = null
    },
  },
  extraReducers: builder => {
    builder.addCase(agentGroupsActions.getAgentGroupKeys.fulfilled, (state, action) => {
      state.agentGroupKeys = action.payload
    })
    builder
      .addCase(agentGroupsActions.generateAgentGroupApiKey.fulfilled, (state, action) => {
        state.agentGroupKeys = action.payload
      })
      .addCase(agentGroupsActions.getAgentGroupList.pending, (state, action) => {
        state.isLoading = true
        state.error = ''
        state.agentGroupsList.items =
          action.meta.arg.offset === 0 ? [] : state.agentGroupsList.items
      })
      .addCase(agentGroupsActions.getAgentGroupList.fulfilled, (state, action) => {
        state.isLoading = false
        state.agentGroupsList.hasNextPage = action.payload.hasNextPage
        state.agentGroupsList.items = [...state.agentGroupsList.items, ...action.payload.items]
      })
      .addCase(agentGroupsActions.getAgentGroupList.rejected, (state, action) => {
        state.isLoading = false

        const error = action.payload as CustomErrorResponse
        state.error = error.message
      })
  },
})

export const { removeAgentGroupsList, removeAgentGroupKeys } = agentGroupReducer.actions

export default agentGroupReducer.reducer
