import { Box, Button, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import TextFieldForm from '../../../../components/reusable/form/TextFieldForm'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { mobileUserActions } from '../../../../store/actions/mobileUserActions'
import { RiskLevelMarker } from '../../../../types/commonTypes'

const riskScoreMarkerColor = {
  [RiskLevelMarker.Green]: { bg: '#c8e6c9', textColor: '#64dd17' },
  [RiskLevelMarker.Yellow]: { bg: '#ffd600', textColor: '#ffd600' },
  [RiskLevelMarker.Red]: { bg: 'red', textColor: 'red' },
  [RiskLevelMarker.Black]: { bg: '#bdbdbd', textColor: '#000000' },
}

type ManualRiskScoreForm = {
  kwlRiskScore: number
}
type RiscScoresTabProps = {
  userId: string | undefined
}

const RiscScoresTab = ({ userId }: RiscScoresTabProps) => {
  const dispatch = useAppDispatch()

  const { mobileUserDetails, isLoading } = useAppSelector(state => state.mobileUser)

  const [isKwlTransactionRiskScoreIgnored, setIsKwlTransactionRiskScoreIgnored] =
    useState<boolean>(false)

  useEffect(() => {
    if (userId) {
      dispatch(mobileUserActions.getMobileUserDetails(userId))
    }
  }, [dispatch, userId])

  const { handleSubmit, setValue, control, watch } = useForm<ManualRiskScoreForm>({})

  useEffect(() => {
    if (mobileUserDetails && !isLoading) {
      setValue('kwlRiskScore', mobileUserDetails.kwlRiskScore)
      setIsKwlTransactionRiskScoreIgnored(mobileUserDetails.isKwlTransactionRiskScoreIgnored)
    }
  }, [isLoading, mobileUserDetails, setValue])

  const onSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked

      userId && dispatch(mobileUserActions.updateIgnoreRemittanceTxRiskForUser(userId))
      setIsKwlTransactionRiskScoreIgnored(checked)
    },
    [dispatch, userId]
  )

  const onSubmit = (data: ManualRiskScoreForm) => {
    const arg = { userId: userId || '0', riskScore: data.kwlRiskScore }

    userId && dispatch(mobileUserActions.updateRemittanceUserRisk(arg))
  }
  return (
    <>
      {!isLoading ? (
        <FormGroup sx={{ padding: '16px', width: '350px' }}>
          <Box pt={1} width={300} display="flex">
            <form
              style={{ marginRight: '20px' }}
              id="change-early-access-password"
              onSubmit={handleSubmit(onSubmit)}
            >
              <TextFieldForm<ManualRiskScoreForm>
                name="kwlRiskScore"
                control={control}
                label="Manual Risk-Score"
                type="tel"
                inputMode="numeric"
                validMask={/[^0-9]/}
              />
            </form>
            <Button
              sx={{ height: '40px' }}
              form="change-early-access-password"
              size="small"
              variant="contained"
              type="submit"
              disabled={!watch('kwlRiskScore')}
            >
              Update
            </Button>
          </Box>
          <FormControlLabel
            sx={{ marginBottom: '16px' }}
            control={
              <Switch
                checked={isKwlTransactionRiskScoreIgnored}
                onChange={onSwitchChange}
              />
            }
            label="Ignore Transaction Risk-Score"
          />
          <Box display="flex" gap={1}>
            <Typography fontWeight="bold">Transaction Risk-Score:</Typography>
            <Typography
              mb={1}
              sx={{
                color: `${
                  mobileUserDetails?.kwlTransactionRiskLevelMarker
                    ? riskScoreMarkerColor[mobileUserDetails?.kwlTransactionRiskLevelMarker]
                        .textColor
                    : '#000000'
                }`,
              }}
            >
              {mobileUserDetails?.kwlTransactionRiskScore}
            </Typography>
          </Box>
          <Box display="flex" gap={1}>
            <Typography fontWeight="bold">Final Risk-Score:</Typography>
            <Typography
              mb={1}
              sx={{
                color: `${
                  mobileUserDetails?.kwlFinalRiskLevelMarker
                    ? riskScoreMarkerColor[mobileUserDetails?.kwlFinalRiskLevelMarker].textColor
                    : '#000000'
                }`,
              }}
            >
              {mobileUserDetails?.kwlFinalRiskScore}
            </Typography>
          </Box>
        </FormGroup>
      ) : null}
    </>
  )
}

export default RiscScoresTab
