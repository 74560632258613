import { Button, Container, Drawer } from '@mui/material'
import { RemittanceTopUpFilterForm } from '../../../types/topUpTypes'
import { riskLevelMarkerList, trimObjectFields } from '../../../utils/commonUtils'
import { useForm } from 'react-hook-form'
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import TextFieldForm from '../../../components/reusable/form/TextFieldForm'
import SelectFieldForm from '../../../components/reusable/form/SelectFieldForm'

type RemittanceTopUpFilterProps = {
  isShowFilter: boolean
  filterState: RemittanceTopUpFilterForm
  setIsShowFilter: Dispatch<SetStateAction<boolean>>
  getRemittanceTopUpsWithFilter: (data: RemittanceTopUpFilterForm) => void
  // setFilterList: Dispatch<SetStateAction<KwlCardPaymentFilters>>
  // getCardPaymentWithFilter: (data: KwlCardPaymentFilterForm) => void
}

const RemittanceTopUpFilter = ({
  isShowFilter,
  setIsShowFilter,
  getRemittanceTopUpsWithFilter,
  filterState,
}: RemittanceTopUpFilterProps) => {
  const onCloseFilter = useCallback(() => {
    setIsShowFilter(false)
  }, [setIsShowFilter])

  const {
    handleSubmit,
    // formState: { errors, isValid },
    setValue,
    control,
  } = useForm<RemittanceTopUpFilterForm>({})

  useEffect(() => {
    setValue('reference', filterState.reference)
    setValue('riskLevelMarker', filterState.riskLevelMarker)
  }, [filterState.reference, filterState.riskLevelMarker, setValue])

  const onSubmit = (data: RemittanceTopUpFilterForm) => {
    const trimmedData = trimObjectFields(data)
    getRemittanceTopUpsWithFilter(trimmedData)

    // setFilterList(prevState => ({ ...prevState, ...trimmedData }))
    // getCardPaymentWithFilter(trimmedData)
  }
  return (
    <Drawer anchor="right" open={isShowFilter} onClose={onCloseFilter}>
      <Container className="mt-20 mb-20">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextFieldForm<RemittanceTopUpFilterForm>
            name="reference"
            control={control}
            label="Search by Reference..."
            type="text"
            inputMode="text"
            validMask={/[^a-zA-Z0-9-]/}
          />
          <SelectFieldForm<RemittanceTopUpFilterForm>
            name="riskLevelMarker"
            control={control}
            label="Risk Level Marker"
            options={riskLevelMarkerList}
            multiple={false}
          />
          <div className="flex j-content-center mt-20">
            <Button size="small" variant="contained" type="submit">
              Apply
            </Button>
          </div>
        </form>
      </Container>
    </Drawer>
  )
}

export default RemittanceTopUpFilter
