import { AmlInspectionStatus } from '../../types/mobileUserTypes'

export const getAmlInspectionStatus = (statusCode: AmlInspectionStatus) => {
  const statuses = {
    [AmlInspectionStatus.Unknown]: 'Unknown',
    [AmlInspectionStatus.Initiated]: 'Initiated',
    [AmlInspectionStatus.Pending]: 'Pending',
    [AmlInspectionStatus.Verified]: 'Verified',
    [AmlInspectionStatus.Rejected]: 'Rejected',
    [AmlInspectionStatus.RejectedWithRetry]: 'Rejected With Retry',
  }

  return statusCode !== undefined ? statuses[statusCode] : ''
}
