import { styled } from '@mui/material/styles'
import CancelIcon from '@mui/icons-material/Cancel'
import {
  StyledCloseButtonModal,
  StyledDialog,
  StyledDialogActions,
  StyledDialogBody,
  StyledDialogContent,
  StyledDialogContentText,
  StyledDialogHeader,
  StyledDialogTitle,
} from './styledModalComponent'
import { useAppDispatch, useAppSelector } from '../../store'
// import {CustomButton} from '../ui/CustomButton'
import { closeGlobalModal } from '../../store/reducers/globalModalReducer'
import Button from '@mui/material/Button'
import { useCallback } from 'react'
import { agentGroupsActions } from '../../store/actions/agentGroupsActions'

const StyledDialogModalTitle = styled(StyledDialogTitle)({
  width: '300px',
})

type GenerateDialogModalProps = {
  isOpenDialog: boolean
  // onCloseModalClick: ActionCreatorWithoutPayload<'globalModal/closeGlobalModal'>
}

const GenerateDialogModal = ({ isOpenDialog }: GenerateDialogModalProps) => {
  const dispatch = useAppDispatch()
  const actionData = useAppSelector(state => state.globalModal.actionData)

  const closeModal = useCallback(() => {
    dispatch(closeGlobalModal())
  }, [dispatch])

  const onConfirm = useCallback(() => {
    dispatch(agentGroupsActions.generateAgentGroupApiKey(actionData))
    closeModal()
  }, [actionData, closeModal, dispatch])

  return (
    <StyledDialog open={isOpenDialog} onClose={closeModal}>
      <StyledDialogHeader>
        <StyledDialogModalTitle>Generate Api Key</StyledDialogModalTitle>
        <StyledCloseButtonModal onClick={closeModal}>
          Close
          <CancelIcon />
        </StyledCloseButtonModal>
      </StyledDialogHeader>
      <StyledDialogBody>
        <StyledDialogContent>
          <StyledDialogContentText>
            After the new key is generated, the current API key will become invalid! Are you sure
            you want to generate a new key?
          </StyledDialogContentText>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button size="large" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="large" variant="contained" onClick={onConfirm}>
            Generate
          </Button>
        </StyledDialogActions>
      </StyledDialogBody>
    </StyledDialog>
  )
}

export default GenerateDialogModal
