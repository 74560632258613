import { useCallback, useMemo } from 'react'

import GenerateDialogModal from './GenerateDialogModal'
import { useAppDispatch, useAppSelector } from '../../store'
import DocDisplayModal from './DocDisplayModal'
import SetExpirationDateModal from './SetExpirationDateModal'
import TopUpCancellingDialogModal from './TopUpCancellingDialogModal'
import TopUpRefundingDialogModal from './TopUpRefundingDialogModal'
import BlockingRecordConfirmationModal from '../../pages/MobileUsers/MobileUserDetails/blockingRecords/modal/BlockingRecordConfirmationModal'
import BlockingRecordResolvingModal from '../../pages/MobileUsers/MobileUserDetails/blockingRecords/modal/BlockingRecordResolvingModal'
import BlockingRecordCreationModal from '../../pages/MobileUsers/MobileUserDetails/blockingRecords/modal/BlockingRecordCreationModal'
import InstanceCurrencyModal from '../../pages/KuvaWhiteLabel/Sites/CurrencyAndFeesTab/modal/InstanceCurrencyModal'
import DialogModal from './DialogModal'
import { remittanceActions } from '../../store/actions/kwlActions'
import PaymentOptionByCountryModal from '../../pages/KuvaWhiteLabel/Sites/CurrencyTab/modal/PaymentOptionByCountryModal'
import { PaymentOptionByCountry } from '../../types/remittanceTypes'
import CommonCurrencyModal from '../../pages/KuvaWhiteLabel/RemittanceSettings/currencies/modal/CommonCurrencyModal'

const CustomModal = () => {
  const dispatch = useAppDispatch()
  const {
    isModalOpen: isOpenDialog,
    variant,
    id,
    additionalId,
    actionData,
  } = useAppSelector(state => state.globalModal)

  const onDialogConfirm = useCallback(() => {
    if (variant === 'activate-instance-currency') {
      const requestArg = { instanceId: id, currencyId: additionalId || 0 }

      additionalId && dispatch(remittanceActions.activateInstanceCurrency(requestArg))
    }
    if (variant === 'deactivate-instance-currency') {
      const requestArg = { instanceId: id, currencyId: additionalId || 0 }

      additionalId && dispatch(remittanceActions.deactivateInstanceCurrency(requestArg))
    }

    if (variant === 'assign-agent-group-to-remittance-instance') {
      const requestArg = { instanceId: id, agentGroupId: additionalId || 0 }

      dispatch(remittanceActions.assignAgentGroup(requestArg))
    }

    if (variant === 'unassign-agent-group-to-remittance-instance') {
      const requestArg = { instanceId: id, agentGroupId: additionalId || 0 }

      dispatch(remittanceActions.unassignAgentGroup(requestArg))
    }

    if (variant === 'delete-payment-option-by-country') {
      const data = actionData as PaymentOptionByCountry
      const arg = {
        instanceId: String(id),
        paymentOptionId: String(data.id),
        countryId: data.country.id,
        type: data.paymentProviderMethod.paymentOptionType,
      }

      dispatch(remittanceActions.deletePaymentOptionByCountry(arg))
    }

    if (variant === 'delete-common-currency') {
      dispatch(remittanceActions.removeCommonCurrency(id))
    }
  }, [actionData, additionalId, dispatch, id, variant])

  const currentModal = useMemo(() => {
    if (variant === 'generate-dialog-modal') {
      return <GenerateDialogModal isOpenDialog={isOpenDialog} />
    }
    if (variant === 'doc-display-modal') {
      return <DocDisplayModal isOpenDialog={isOpenDialog} />
    }
    if (variant === 'set-exp-date-modal') {
      return <SetExpirationDateModal isOpenDialog={isOpenDialog} />
    }
    if (variant === 'cancel-top-up') {
      return <TopUpCancellingDialogModal isOpenDialog={isOpenDialog} />
    }
    if (variant === 'refund-remittance-top-up') {
      return <TopUpRefundingDialogModal isOpenDialog={isOpenDialog} />
    }
    if (variant === 'confirm-blocking-record') {
      return <BlockingRecordConfirmationModal isOpenDialog={isOpenDialog} />
    }
    if (variant === 'resolve-blocking-record') {
      return <BlockingRecordResolvingModal isOpenDialog={isOpenDialog} />
    }
    if (variant === 'create-blocking-record') {
      return <BlockingRecordCreationModal isOpenDialog={isOpenDialog} />
    }
    if (variant === 'add-instance-currency' || variant === 'edit-instance-currency') {
      return <InstanceCurrencyModal isOpenDialog={isOpenDialog} variant={variant} />
    }
    if (
      variant === 'add-payment-option-by-country' ||
      variant === 'edit-payment-option-by-country'
    ) {
      return <PaymentOptionByCountryModal isOpenDialog={isOpenDialog} variant={variant} />
    }
    if (variant === 'activate-instance-currency') {
      return (
        <DialogModal
          isOpenDialog={isOpenDialog}
          headerText="Currency Activation"
          mainText="Are you sure you want to activate this currency?"
          confirmBtnText="Activate"
          confirmBtnColor="#2e7d32"
          onParentConfirm={onDialogConfirm}
        />
      )
    }

    if (variant === 'deactivate-instance-currency') {
      return (
        <DialogModal
          isOpenDialog={isOpenDialog}
          headerText="Currency Deactivation"
          mainText="Are you sure you want to deactivate this currency?"
          confirmBtnText="Deactivate"
          confirmBtnColor="#d32f2f"
          onParentConfirm={onDialogConfirm}
        />
      )
    }

    if (variant === 'assign-agent-group-to-remittance-instance') {
      return (
        <DialogModal
          isOpenDialog={isOpenDialog}
          headerText="Assigning Agent Group"
          mainText="Are you sure you want to assign this agent group?"
          confirmBtnText="Assign"
          confirmBtnColor="#67BCF0"
          onParentConfirm={onDialogConfirm}
        />
      )
    }

    if (variant === 'unassign-agent-group-to-remittance-instance') {
      return (
        <DialogModal
          isOpenDialog={isOpenDialog}
          headerText="Unassigning Agent Group"
          mainText="Are you sure you want to unassign this agent group?"
          confirmBtnText="Unassign"
          confirmBtnColor="#FD8111"
          onParentConfirm={onDialogConfirm}
        />
      )
    }
    if (variant === 'delete-payment-option-by-country') {
      return (
        <DialogModal
          isOpenDialog={isOpenDialog}
          headerText="Delete Payment Option"
          mainText="Are you sure you want to delete this payment option?"
          confirmBtnText="Delete"
          confirmBtnColor="#d32f2f"
          onParentConfirm={onDialogConfirm}
        />
      )
    }

    if (variant === 'add-common-currency' || variant === 'edit-common-currency') {
      return <CommonCurrencyModal isOpenDialog={isOpenDialog} variant={variant} />
    }

    if (variant === 'delete-common-currency') {
      return (
        <DialogModal
          isOpenDialog={isOpenDialog}
          headerText="Delete Currency"
          mainText="Are you sure you want to delete this currency?"
          confirmBtnText="Delete"
          confirmBtnColor="#d32f2f"
          onParentConfirm={onDialogConfirm}
        />
      )
    }
  }, [isOpenDialog, onDialogConfirm, variant])

  return <>{currentModal}</>
}

export default CustomModal
