import { createSlice } from '@reduxjs/toolkit'

import {
  BlockingRecord,
  GetKlProfileDetailsResponse,
  GetKwlProfileDetailsResponse,
  GetMobileUserDetailsResponse,
  GetMobileUserListResponse,
  GetUserBlockingRecordsResponse,
  InspectionDoc,
  MobileUserType,
  UserAmlInspection,
  UserVisibleAmlInspection,
} from '../../types/mobileUserTypes'
import { mobileUserActions } from '../actions/mobileUserActions'

type MobileUsersState = {
  isLoading: boolean
  isLoaded: boolean
  error: string
  mobileUserList: GetMobileUserListResponse & { hasNextPage: boolean }
  mobileUserDetails: GetMobileUserDetailsResponse | null
  klProfileDetails: GetKlProfileDetailsResponse | null
  kwlProfileDetails: GetKwlProfileDetailsResponse | null
  userInspectionList: Array<UserAmlInspection>
  userVisibleInspectionList: Array<UserVisibleAmlInspection>
  userDocList: Array<InspectionDoc>
  userIdForCurrentDocList?: number | string
  currentDocImgList: Array<string>
  userBlockingRecords: GetUserBlockingRecordsResponse
  currentUserBlockingRecords: Array<BlockingRecord>

  isUserVisibleInspectionListLoading: boolean
  isUserInspectionListLoading: boolean
  isUserDocListLoading: boolean

  isUserVisibleInspectionListLoaded: boolean
  isUserInspectionListLoaded: boolean
  isUserDocListLoaded: boolean

  userBlockingRecordsLoadState: {
    isLoading: boolean
    isLoaded: boolean
  }

  getBlockingRecordsByUserIdLoadState: {
    isLoading: boolean
    isLoaded: boolean
  }
}

const initialState: MobileUsersState = {
  isLoading: false,
  isLoaded: false,
  error: '',
  mobileUserList: {
    items: [],
    hasNextPage: false,
    count: 0,
  },
  mobileUserDetails: null,
  klProfileDetails: null,
  kwlProfileDetails: null,
  userInspectionList: [],
  userVisibleInspectionList: [],
  userDocList: [],
  currentDocImgList: [],
  userBlockingRecords: {
    hasNextPage: false,
    items: [],
  },
  userBlockingRecordsLoadState: {
    isLoading: false,
    isLoaded: false,
  },

  isUserVisibleInspectionListLoading: false,
  isUserInspectionListLoading: false,
  isUserDocListLoading: false,

  isUserVisibleInspectionListLoaded: false,
  isUserInspectionListLoaded: false,
  isUserDocListLoaded: false,

  currentUserBlockingRecords: [],
  getBlockingRecordsByUserIdLoadState: {
    isLoading: false,
    isLoaded: false,
  },
}

const mobileUser = createSlice({
  name: 'mobileUser',
  initialState,
  reducers: {
    removeMobileUserList: state => {
      state.mobileUserList.hasNextPage = false
      state.mobileUserList.items = []
      state.mobileUserList.count = 0
    },
    removeMobileUserDetails: state => {
      state.klProfileDetails = null
      state.kwlProfileDetails = null
      state.mobileUserDetails = null
    },
    removeUserAmlInspectionData: state => {
      state.userInspectionList = []
      state.userVisibleInspectionList = []
      state.userDocList = []
      state.userIdForCurrentDocList = undefined
      state.isUserVisibleInspectionListLoaded = false
      state.isUserInspectionListLoaded = false
      state.isUserDocListLoaded = false
    },
    removeCurrentDocImgList: state => {
      state.currentDocImgList = []
    },
    removeAgentGroupsList: state => {
      state.userBlockingRecords.hasNextPage = false
      state.userBlockingRecords.items = []
    },
    removeUserBlockingRecords: state => {
      state.userBlockingRecords.hasNextPage = false
      state.userBlockingRecords.items = []
    },
    removeCurrentUserBlockingRecords: state => {
      state.currentUserBlockingRecords = []
    },
  },
  extraReducers: builder => {
    builder
      .addCase(mobileUserActions.fetchMobileUserList.pending, (state, action) => {
        state.isLoading = true
        state.error = ''
        state.mobileUserList.items = action.meta.arg.offset === 0 ? [] : state.mobileUserList.items
      })
      .addCase(mobileUserActions.fetchMobileUserList.fulfilled, (state, action) => {
        state.isLoading = false
        state.isLoaded = true
        state.mobileUserList.hasNextPage =
          action.payload.count - (action.meta.arg.offset + action.meta.arg.pageSize) > 0
        state.mobileUserList.items = [...state.mobileUserList.items, ...action.payload.items]
      })
      .addCase(mobileUserActions.getMobileUserDetails.pending, state => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(mobileUserActions.getMobileUserDetails.fulfilled, (state, action) => {
        state.isLoading = false
        state.mobileUserDetails = action.payload
      })
      .addCase(mobileUserActions.getMobileUserDetails.rejected, state => {
        state.isLoading = false
      })
      .addCase(mobileUserActions.getKlProfileDetails.pending, state => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(mobileUserActions.getKlProfileDetails.fulfilled, (state, action) => {
        state.isLoading = false
        state.klProfileDetails = action.payload
      })
      .addCase(mobileUserActions.getKlProfileDetails.rejected, state => {
        state.isLoading = false
      })
      .addCase(mobileUserActions.getKwlProfileDetails.pending, state => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(mobileUserActions.getKwlProfileDetails.fulfilled, (state, action) => {
        state.isLoading = false
        state.kwlProfileDetails = action.payload
      })
      .addCase(mobileUserActions.getKwlProfileDetails.rejected, state => {
        state.isLoading = false
      })
      .addCase(mobileUserActions.getAllUserInspections.pending, state => {
        state.error = ''
        state.isUserInspectionListLoading = true
        state.isUserInspectionListLoaded = false
      })
      .addCase(mobileUserActions.getAllUserInspections.fulfilled, (state, action) => {
        state.userInspectionList = action.payload
        state.isUserInspectionListLoading = false
        state.isUserInspectionListLoaded = true
      })
      .addCase(mobileUserActions.getVisibleUserInspections.pending, state => {
        state.error = ''
        state.isUserVisibleInspectionListLoading = true
        state.isUserVisibleInspectionListLoaded = false
      })
      .addCase(mobileUserActions.getVisibleUserInspections.fulfilled, (state, action) => {
        state.userVisibleInspectionList = action.payload
        state.isUserVisibleInspectionListLoading = false
        state.isUserVisibleInspectionListLoaded = true
      })
      .addCase(mobileUserActions.getUserDocs.pending, state => {
        state.error = ''
        state.isUserDocListLoading = true
        state.isUserDocListLoaded = false
      })
      .addCase(mobileUserActions.getUserDocs.fulfilled, (state, action) => {
        state.userDocList = action.payload
        state.userIdForCurrentDocList = action.meta.arg
        state.isUserDocListLoading = false
        state.isUserDocListLoaded = true
      })
      .addCase(mobileUserActions.getUserDocs.rejected, (state, action) => {
        state.isUserDocListLoading = false
        state.isUserDocListLoaded = true
      })
      .addCase(mobileUserActions.getUserDocImage.pending, state => {
        state.error = ''
      })
      .addCase(mobileUserActions.getUserDocImage.fulfilled, (state, action) => {
        state.currentDocImgList.push(action.payload)
      })
      .addCase(mobileUserActions.getUserBlockingRecords.pending, (state, action) => {
        state.userBlockingRecordsLoadState.isLoading = true
        state.error = ''
        state.userBlockingRecords.items =
          action.meta.arg.offset === 0 ? [] : state.userBlockingRecords.items
      })
      .addCase(mobileUserActions.getUserBlockingRecords.fulfilled, (state, action) => {
        state.userBlockingRecordsLoadState.isLoading = false
        state.userBlockingRecords.hasNextPage = action.payload.hasNextPage
        state.userBlockingRecords.items = [
          ...state.userBlockingRecords.items,
          ...action.payload.items,
        ]
      })
      .addCase(mobileUserActions.getUserBlockingRecords.rejected, (state, action) => {
        state.userBlockingRecordsLoadState.isLoading = false
        state.error = action.payload as string
      })
      .addCase(mobileUserActions.getBlockingRecordsByUserId.pending, state => {
        state.error = ''
        state.getBlockingRecordsByUserIdLoadState.isLoading = true
      })
      .addCase(mobileUserActions.getBlockingRecordsByUserId.fulfilled, (state, action) => {
        state.currentUserBlockingRecords = action.payload
        state.getBlockingRecordsByUserIdLoadState.isLoading = false
      })
      .addCase(mobileUserActions.getBlockingRecordsByUserId.rejected, (state, action) => {
        state.getBlockingRecordsByUserIdLoadState.isLoading = false
      })
      .addCase(mobileUserActions.confirmBlockingRecord.fulfilled, (state, action) => {
        state.currentUserBlockingRecords = state.currentUserBlockingRecords.map(blockingRecord =>
          blockingRecord.id === action.payload.id ? action.payload : blockingRecord
        )
      })
      .addCase(mobileUserActions.resolveBlockingRecord.fulfilled, (state, action) => {
        state.currentUserBlockingRecords = state.currentUserBlockingRecords.map(blockingRecord =>
          blockingRecord.id === action.payload.id ? action.payload : blockingRecord
        )
      })
      .addCase(mobileUserActions.createBlockingRecord.fulfilled, (state, action) => {
        state.currentUserBlockingRecords.unshift(action.payload)
      })
  },
})

export const {
  removeMobileUserList,
  removeMobileUserDetails,
  removeCurrentDocImgList,
  removeUserAmlInspectionData,
  removeUserBlockingRecords,
  removeCurrentUserBlockingRecords,
} = mobileUser.actions

export default mobileUser.reducer
