import { styled } from '@mui/material'
import { StyledRowCell } from './TableRow'
import { TableConfig } from '../../../../../types/tableTyles'

type TableHeaderProps<T> = {
  tableConfig: TableConfig<T>
}

const StyledTableHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: '55px',
  backgroundColor: 'white',
  // borderBottom: '1px solid rgba(224, 224, 224, 1)',
  minWidth: 'fit-content',
  width: '100%',

  position: 'sticky',
  top: '0',
  zIndex: 10,
  borderBottom: '1px solid rgba(224, 224, 224, 1)',

  '.table-header-item': {
    // height: '49px',
    // lineHeight: '24px',
    fontWeight: '700',
    fontSize: '16px',
  },
}))

const TableHeader = <T,>({ tableConfig }: TableHeaderProps<T>) => {
  return (
    <StyledTableHeader className="table-header">
      {tableConfig.columns.map(column => (
        <StyledRowCell className="table-header-item" key={column.id} width={column.width}>
          {column.header}
        </StyledRowCell>
      ))}
    </StyledTableHeader>
  )
}

export default TableHeader
