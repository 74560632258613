import * as yup from 'yup'

const paymentProviderFeeForm = yup.object({
  fixedValue: yup.string().typeError('No valid').required('No valid'),
  percentValue: yup.string().typeError('No valid').required('No valid'),
})

const settingUserExpirationForm = yup.object({
  userRiskLevelMarker: yup
    .string()
    .typeError('No Valid')
    .trim()
    .required('Required Field'),
  months: yup
    .string()
    .typeError('No Valid')
    .trim()
    .required('Required Field'),
})

const commonCurrencyForm = yup.object({
  id: yup
    .string()
    .typeError('No Valid')
    .trim()
    .required('Required Field'),
  sign: yup
    .string()
    .typeError('No Valid')
    .trim()
    .required('Required Field'),
  displayName: yup
    .string()
    .typeError('No Valid')
    .trim()
    .required('Required Field'),
})

export const remittanceYupSchemes = { paymentProviderFeeForm, settingUserExpirationForm, commonCurrencyForm }
