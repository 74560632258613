import { useState, useEffect, useContext, MouseEvent, useCallback, useRef } from 'react'
import { MenuRounded, MenuOpenRounded } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'
import { MainContext } from '../../App'
import getOpenedCollapse from './getOpenedCollapse'
import MappedSidebar from './MappedSidebar'
import { collapseItems } from '../../utils/constants'
import useScrollBar from '../../hooks/useScrollBar'

export default ({ isMobileView }: { isMobileView?: boolean }) => {
  const [open, setOpen] = useState<{ [key: string]: boolean }>({
    [collapseItems.WORLD_PAY]: false,
    [collapseItems.KWL]: false,
    [collapseItems.TOP_UP]: false,
    [collapseItems.KUVA_LOCAL]: false,
    [collapseItems.CURRENCY_CLOUD]: false,
    [collapseItems.MASTER_NODE]: false,
    [collapseItems.OUTBOUND_TRANSFERS]: false,
    [collapseItems.CASHOUT]: false,
    [collapseItems.PAYMENTS]: false,
    [collapseItems.TRANSACTIONS]: false,
    [collapseItems.MOBILE_TRANSFERS]: false,
  })
  const [menuOpen, setMenuOpen] = useState(true)

  const { userPermissionList } = useContext(MainContext)

  const { pathname } = useLocation()

  useEffect(() => {
    if (userPermissionList.length) {
      setOpen(prevState => ({ ...prevState, [getOpenedCollapse(pathname)]: true }))
    }
  }, [pathname, userPermissionList.length])

  const onClick = (e: MouseEvent, val: string) => {
    const target = e.target as HTMLLIElement

    //remove this after changing sideBarConfig
    const isLink = target.className === 'item-link'
    // if (!isLink) setOpen({ ...open, [val]: !open[val] })
    if (!menuOpen) {
      setMenuOpen(true)
    }
    setOpen(prevState => ({ ...prevState, [val]: !prevState[val] }))
  }

  const toggleSideMenu = useCallback(
    (sideMenuState: boolean) => {
      if (sideMenuState) {
        const nestedMenuObj = { ...open }
        for (let key in nestedMenuObj) {
          nestedMenuObj[key] = false
        }
        setMenuOpen(false)
        setOpen(nestedMenuObj)
      } else {
        setMenuOpen(true)
        setOpen(prevState => ({ ...prevState, [getOpenedCollapse(pathname)]: true }))
      }
    },
    [open, pathname]
  )

  const sideBarRef = useRef<HTMLDivElement | null>(null)

  useScrollBar(sideBarRef)

  return (
    <>
      {!isMobileView && (
        <div className="switch-menu-section">
          <div className="menu-icon" onClick={() => toggleSideMenu(menuOpen)}>
            {!menuOpen ? <MenuRounded /> : <MenuOpenRounded />}
          </div>
        </div>
      )}
      <div className={`sidebar ${!menuOpen ? 'menu-closed' : ''}`} ref={sideBarRef}>
        <MappedSidebar userPermissionList={userPermissionList} onClick={onClick} open={open} />
      </div>
    </>
  )
}
