import { Box, Chip, styled } from '@mui/material'
import { useCallback } from 'react'

export type FilterTag = {
  nameState: string
  nameField: string
  valueField: string
}

const StyledCustomChip = styled(Chip)({
  marginRight: '10px',
  marginBottom: '10px',
  '.MuiChip-label': {
    display: 'flex',
    'p:first-of-type': {
      fontWeight: 'bold',
    },
  },
})

type CustomChipProps = {
  item: FilterTag
  onDeleteTag: (deletedFilterItem: FilterTag) => void
}

const CustomChip = ({ item, onDeleteTag }: CustomChipProps) => {
  const onDelete = useCallback(() => {
    onDeleteTag(item)
  }, [item, onDeleteTag])

  return (
    <StyledCustomChip
      label={
        <>
          <p>{item.nameField}</p>&nbsp;
          <p>{item.valueField}</p>
        </>
      }
      onDelete={onDelete}
    />
  )
}

const StyledTagsForFilter = styled(Box)({
  // marginBottom: '20px',
})

type TagsForFilterProps = {
  data: Array<FilterTag>
  onDeleteTag: (deletedFilterItem: FilterTag) => void
}

const TagsForFilter = ({ data, onDeleteTag }: TagsForFilterProps) => {
  return (
    <StyledTagsForFilter>
      {data.map(item => (
        <CustomChip
          item={item}
          key={`${item.valueField}${item.nameState}`}
          onDeleteTag={onDeleteTag}
        />
      ))}
    </StyledTagsForFilter>
  )
}

export default TagsForFilter
