import { FormEventHandler } from "react"
import { Container, TextField, Button, Drawer, MenuItem, IconButton } from "@mui/material"
import { HighlightOffRounded } from "@mui/icons-material"

import excludeSymbolsFromNumber from '../../../helpers/excludeSymbolsFromNumber'
import { coinTypes, transactionTypes, transferStatuses } from "../../../utils/constants"

type FilterPropsType = {
  query: {
    fromAddress: string,
    toAddress: string,
    bwsId: string,
    originTransactionId: string,
    usdAmounts: { min: number | string, max: number | string },
    coinAmounts: { min: number | string, max: number | string },
    rates: { min: number | string, max: number | string },
    types: [],
    statuses: [],
    walletTypes: [],
  },
  onChange: (value: string | [], name: string, parentProp?: string) => {},
  onSubmit: FormEventHandler,
  isDisabled: boolean,
  isShow: boolean,
  setShow: (isShow: boolean) => {},
  onSelect: (value: any) => void
}

export default ({ query, onChange, onSubmit, isDisabled, isShow, setShow, onSelect }: FilterPropsType) => {
  const {
    fromAddress, toAddress, bwsId, originTransactionId, usdAmounts, coinAmounts, rates, types, statuses, walletTypes,
  } = query

  return (
    <Drawer anchor="right" open={isShow} onClose={() => setShow(false)}>
      <Container className="mt-20 mb-20">
        <form onSubmit={onSubmit}>
          <TextField
            className="w-100-percent mb-20"
            label='Search by From Address...'
            name='fromAddress'
            size="small"
            value={fromAddress}
            onChange={({ target: { value, name } }) => onChange(value, name)}
          />
          <TextField
            className="w-100-percent mb-20"
            label='Search by To Address...'
            name='toAddress'
            size="small"
            value={toAddress}
            onChange={({ target: { value, name } }) => onChange(value, name)}
          />
          <TextField
            className="w-100-percent mb-20"
            label='Search by Bws Id...'
            name='bwsId'
            size="small"
            value={bwsId}
            onChange={({ target: { value, name } }) => onChange(value, name)}
          />
          <TextField
            className="w-100-percent mb-20"
            label='Search by Origin Transaction Id...'
            name='originTransactionId'
            size="small"
            value={originTransactionId}
            onChange={({ target: { value, name } }) => onChange(value, name)}
          />
          <div className="flex j-content-space-between">
            <TextField
              className="w-45-percent mb-20"
              label='Search USD Amounts min...'
              type='number'
              name='min'
              size="small"
              value={usdAmounts.min}
              onChange={({ target: { value, name } }) => onChange(value, name, 'usdAmounts')}
              onKeyDown={excludeSymbolsFromNumber}
            />
            <TextField
              className="w-45-percent mb-20"
              label='Search USD Amounts max...'
              type='number'
              name='max'
              size="small"
              value={usdAmounts.max}
              onChange={({ target: { value, name } }) => onChange(value, name, 'usdAmounts')}
              onKeyDown={excludeSymbolsFromNumber}
            />
          </div>
          <div className="flex j-content-space-between">
            <TextField
              className="w-45-percent mb-20"
              label='Search Coin Amounts min...'
              type='number'
              name='min'
              size="small"
              value={coinAmounts.min}
              onChange={({ target: { value, name } }) => onChange(value, name, 'coinAmounts')}
              onKeyDown={excludeSymbolsFromNumber}
            />
            <TextField
              className="w-45-percent mb-20"
              label='Search Coin Amounts max...'
              type='number'
              name='max'
              size="small"
              value={coinAmounts.max}
              onChange={({ target: { value, name } }) => onChange(value, name, 'coinAmounts')}
              onKeyDown={excludeSymbolsFromNumber}
            />
          </div>
          <div className="flex j-content-space-between">
            <TextField
              className="w-45-percent mb-20"
              label='Search Rates min...'
              type='number'
              name='min'
              size="small"
              value={rates.min}
              onChange={({ target: { value, name } }) => onChange(value, name, 'rates')}
              onKeyDown={excludeSymbolsFromNumber}
            />
            <TextField
              className="w-45-percent mb-20"
              label='Search Rates max...'
              type='number'
              name='max'
              size="small"
              value={rates.max}
              onChange={({ target: { value, name } }) => onChange(value, name, 'rates')}
              onKeyDown={excludeSymbolsFromNumber}
            />
          </div>
          <TextField
            select
            className="w-100-percent mb-20"
            label='Transaction Types'
            name='types'
            size="small"
            SelectProps={{
              multiple: true,
              value: types,
              onChange: onSelect,
              endAdornment: types.length ?
                (<IconButton onClick={() => onChange([], 'types')}><HighlightOffRounded /></IconButton>) : null
            }}
          >
            {
              Object.keys(transactionTypes).map(key => (
                <MenuItem key={key} value={key}>{transactionTypes[key]}</MenuItem>
              ))
            }
          </TextField>
          <TextField
            select
            className="w-100-percent mb-20"
            label='Transfer Status'
            name='statuses'
            size="small"
            SelectProps={{
              multiple: true,
              value: statuses,
              onChange: onSelect,
              endAdornment: statuses.length ?
                (<IconButton onClick={() => onChange([], 'statuses')}><HighlightOffRounded /></IconButton>) : null
            }}
          >
            {
              Object.keys(transferStatuses).map(key => (
                <MenuItem key={key} value={key}>{transferStatuses[key].value}</MenuItem>
              ))
            }
          </TextField>
          <TextField
            select
            className="w-100-percent mb-20"
            label='Wallet Types'
            name='walletTypes'
            size="small"
            SelectProps={{
              multiple: true,
              value: walletTypes,
              onChange: onSelect,
              endAdornment: walletTypes.length ?
                (<IconButton onClick={() => onChange([], 'walletTypes')}><HighlightOffRounded /></IconButton>) : null
            }}
          >
            {
              Object.keys(coinTypes).map(key => (
                <MenuItem key={key} value={key}>{coinTypes[+key]}</MenuItem>
              ))
            }
          </TextField>
          <div className="flex j-content-center mt-20">
            <Button size="small" variant="contained" type="submit" disabled={isDisabled}>Apply</Button>
          </div>
        </form>
      </Container>
    </Drawer>
  )
}
