export default (params: {
  [key: string]:
    | string
    | number
    | boolean
    | []
    | number[]
    | string[]
    | { [key: string]: string | number | number[] | string[] | [] }
}) => {
  let concatenatedStr: string = ''

  for (const [key, val] of Object.entries(params)) {
    if (Array.isArray(val)) {
      // eslint-disable-next-line no-loop-func
      val.forEach(item => {
        concatenatedStr += `&${key}=${item}`
      })
    }

    if (val === '' || val === undefined || Array.isArray(val)) delete params[key]
  }

  const res = Object.keys(params).reduce((previous: string, current: string) => {
    return `${previous}=${params[previous]}&${current}=${params[current]}`.replace('=undefined', '')
  })

  return concatenatedStr ? res + concatenatedStr : res
}
