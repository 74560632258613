import { createAsyncThunk, createAction } from '@reduxjs/toolkit'

import apiRequestService from '../../services/apiRequestService'
import setApiHeaders from '../../helpers/setApiHeaders'
import {
  TOKENS_FETCHING,
  AUTHENTICATOR_FETCHING,
  AUTHENTICATOR_CONFIRM,
  AUTHENTICATOR_DISABLE,
  RESET_ERROR,
  REFRESH_TOKEN,
  CONFIRM_TWO_FA,
} from './actionTypes'

import {
  apiAuthenticatorConfirmPath,
  apiAuthenticatorDisablePath,
  apiAuthenticatorPath,
  apiLoginPath,
  apiTwoFaConfirmPath,
  getApiRefreshTokensPath,
} from '../../utils/apiPaths'
import apiRequestServiceWithRefresh from '../../services/apiRequestServiceWithRefresh'
import { logout } from '../reducers/authReducer'


type ErrType = {
  response: { data: { message: string; code: number } }
}

export const resetError = createAction(RESET_ERROR)

export const fetchTokens = createAsyncThunk(
  TOKENS_FETCHING,
  async (data: { email: string; password: string } | null, { rejectWithValue }) => {
    try {
      if (!data) {
        const { accessToken, refreshToken, twoFactorEnabled } =
          window.localStorage.getItem('tokens') &&
          JSON.parse(window.localStorage.getItem('tokens')!)

        setApiHeaders(accessToken)

        if (accessToken)
          return { tokens: { accessToken, refreshToken }, twoFactorEnabled, isAuth: true }
      } else {
        const result = await apiRequestService('post', apiLoginPath, { ...data, deviceId: false })

        if (result) {
          const { accessToken, refreshToken, twoFactorEnabled } = result

          if (!twoFactorEnabled) {
            window.localStorage.setItem(
              'tokens',
              JSON.stringify({ accessToken, refreshToken, twoFactorEnabled })
            )
          }

          setApiHeaders(accessToken)

          return {
            tokens: { accessToken, refreshToken },
            twoFactorEnabled,
            isAuth: twoFactorEnabled ? false : true,
          }
        }
      }
    } catch (error) {
      const err = error as ErrType

      return rejectWithValue(err.response.data.message)
    }
  }
)

export const refreshTokens = createAsyncThunk(
  REFRESH_TOKEN,
  async (token: string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestService('post', getApiRefreshTokensPath(token))

      if (result) {
        const { accessToken, refreshToken } = result

        window.localStorage.setItem('tokens', JSON.stringify({ accessToken, refreshToken }))

        setApiHeaders(accessToken)

        return { accessToken, refreshToken }
      }
    } catch (error) {
      const err = error as ErrType

      if (err.response.data.code === 401) {
        dispatch(logout())
      }

      return rejectWithValue(err.response.data.message)
    }
  }
)

export const fetchAuthenticator = createAsyncThunk(
  AUTHENTICATOR_FETCHING,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(dispatch, 'get', apiAuthenticatorPath)

      if (result) return result
    } catch (error) {
      const err = error as ErrType

      return rejectWithValue(err.response.data.message)
    }
  }
)

export const confirmAuthenticator = createAsyncThunk(
  AUTHENTICATOR_CONFIRM,
  async (data: { code: string }, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'post',
        apiAuthenticatorConfirmPath,
        data
      )

      if (result) return result
    } catch (error) {
      const err = error as ErrType

      return rejectWithValue(err.response.data.message)
    }
  }
)

export const disableAuthenticator = createAsyncThunk(
  AUTHENTICATOR_DISABLE,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'post',
        apiAuthenticatorDisablePath
      )

      if (result) return result
    } catch (error) {
      const err = error as ErrType

      return rejectWithValue(err.response.data.message)
    }
  }
)

export const confirmTwoFa = createAsyncThunk(
  CONFIRM_TWO_FA,
  async (code: string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(dispatch, 'post', apiTwoFaConfirmPath, {
        code,
      })

      if (result) {
        const { accessToken, refreshToken, twoFactorEnabled } = result

        window.localStorage.setItem(
          'tokens',
          JSON.stringify({ accessToken, refreshToken, twoFactorEnabled })
        )

        setApiHeaders(accessToken)

        return { tokens: { accessToken, refreshToken }, twoFactorEnabled, isAuth: true }
      }
    } catch (error) {
      const err = error as ErrType

      return rejectWithValue(err.response.data.message)
    }
  }
)
