import { FormEvent, useState } from "react"
import { Button, TextField } from "@mui/material"

import { useAppDispatch, useAppSelector } from "../../store"
import { confirmTwoFa } from "../../store/actions/authActions"
import excludeSymbolsFromNumber from "../../helpers/excludeSymbolsFromNumber"

const TwoFaVerification = () => {
  const [code, setCode] = useState('')

  const dispatch = useAppDispatch()
  const { error } = useAppSelector(state => state.auth)

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()

    dispatch(confirmTwoFa(code))
  }

  return (
    <div className="flex align-items-center flex-dir-column j-content-center h-100-vh">
      <div>
        <h2>Two Factor Authentication:</h2>
        <form className="flex j-content-center flex-dir-column align-items-center" onSubmit={onSubmit}>
          <TextField
            autoFocus
            className="mt-30 code-two-fa"
            type='number'
            label='Verify code'
            value={code}
            onChange={({ target: { value } }) => value.length < 7 ? setCode(value) : false}
            onKeyDown={excludeSymbolsFromNumber}
          />
          {error && <span className="err-mess mt-5 mb-10">{error}</span>}
          <Button className="w-50 mt-20" variant='contained' type="submit" disabled={code.length < 6}>Send</Button>
        </form>
      </div>
    </div>
  )
}

export default TwoFaVerification
