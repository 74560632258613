import { MouseEvent } from 'react'
import { NavLink } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Collapse from '@mui/material/Collapse'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { List, ListItemButton, ListItemText } from '@mui/material'

const StyledNestedMenu = styled(List)({
  minWidth: '200px',
  padding: 0,
  a: {
    display: 'block',
    color: 'inherit',

    // marginRight: '20px',
    '&.active': {
      background: '#29EDFC',
      borderTopRightRadius: '30px',
      borderBottomRightRadius: '30px',
    },
    '.MuiTouchRipple-root': {
      borderTopRightRadius: '30px',
      borderBottomRightRadius: '30px',
    },
    '.MuiListItemButton-root': {
      paddingLeft: '30px',
      borderTopRightRadius: '30px',
      borderBottomRightRadius: '30px',
    },
  },
  '.MuiList-padding': {
    // padding: '0 25px 0 0'
    paddingRight: '25px',
  },
})

type NestedMenuProps = {
  open: boolean
  onClick: (e: MouseEvent, val: string) => void
  config: {
    titleGroup: string
    nestedList: {
      label: string
      path: string
    }[]
  }
  name: string
}

const NestedMenu = ({ open, onClick, config, name }: NestedMenuProps) => {
  return (
    <StyledNestedMenu>
      <ListItemButton onClick={e => onClick(e, name)}>
        <ListItemText primary={config.titleGroup} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse component="div" in={open} className="collapse" unmountOnExit>
        <List>
          {config.nestedList.map(item => (
            <NavLink to={item.path} key={item.label}>
              <ListItemButton>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </NavLink>
          ))}
        </List>
      </Collapse>
    </StyledNestedMenu>
  )
}

export default NestedMenu
