import { styled } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

const StyledCustomLoader = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 100,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: '100%',
  backgroundColor: 'rgba(252, 252, 252, 0.8)',
  '&.absolute': {
    position: 'absolute',
    borderRadius: '10px',
  },
})

const CustomLoader = () => {
  return (
    <StyledCustomLoader className="absolute">
      <CircularProgress size={70} color="primary" />
    </StyledCustomLoader>
  )
}

export default CustomLoader
