import { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { remittanceActions } from '../../../../store/actions/kwlActions'
import remittanceTableConfig from '../../../../utils/config/tableConfig/remittance'
import CurrencyAndFeesTable from './CurrencyAndFeesTable/CurrencyAndFeesTable'
import { CurrencyDetails } from '../../../../types/remittanceTypes'
import { Box, Button, styled } from '@mui/material'
import { openGlobalModal } from '../../../../store/reducers/globalModalReducer'
import { removeInstanceCurrencyList } from '../../../../store/reducers/kwlReducers'

const StyledCurrencyAndFeesTab = styled(Box)({
  padding: '8px 16px',
  '.add-currency-btn': {
    marginBottom: '20px',
  },
})

type CurrencyAndFeesTabProps = {
  instanceId: string | undefined
}

const CurrencyAndFeesTab = ({ instanceId }: CurrencyAndFeesTabProps) => {
  const dispatch = useAppDispatch()

  const { instanceCurrencyList, availableInstanceCurrencyList } = useAppSelector(state => state.kwl)
  const { getInstanceCurrencyListLoadState } =
    useAppSelector(state => state.kwl)

  const isLoading = useMemo(
    () =>
      getInstanceCurrencyListLoadState.isLoading,
    [
      getInstanceCurrencyListLoadState.isLoading,
    ]
  )

  const tableConfig = useMemo(() => {
    return remittanceTableConfig.instanceCurrencyTableConfig(instanceId)
  }, [instanceId])

  useEffect(() => {
    if (instanceId) {
      dispatch(remittanceActions.getInstanceCurrencyList(instanceId))
      dispatch(remittanceActions.getAvailableInstanceCurrencyList(instanceId))
    }

    return () => {
      dispatch(removeInstanceCurrencyList())
    }
  }, [dispatch, instanceId])

  const addNewCurrency = useCallback(() => {
    dispatch(openGlobalModal({ variant: 'add-instance-currency', id: instanceId }))
  }, [dispatch, instanceId])

  return !isLoading ? (
    <StyledCurrencyAndFeesTab>
      <Button
        size="medium"
        variant="contained"
        onClick={addNewCurrency}
        className="add-currency-btn"
        disabled={availableInstanceCurrencyList.length === 0}
      >
        Add New Currency
      </Button>
      {instanceCurrencyList.length > 0 && (
        <CurrencyAndFeesTable<CurrencyDetails>
          tableConfig={tableConfig}
          tableData={instanceCurrencyList}
        />
      )}
    </StyledCurrencyAndFeesTab>
  ) : null
}

export default CurrencyAndFeesTab
