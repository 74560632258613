export enum CardPaymentStatus {
  Initiated = 0,
  PaymentInProgress = 10,
  Authenticated = 20,
  Challenge = 40,
  Verified = 60,
  Authorizing = 70,
  Authorized = 80,
  Settled = 100,
  Canceled = 150,
  Aborted = 160,
  FullyRefunded = 170,
  PartiallyRefunded = 180,
  Failed = 200,
  FraudWarningReceived = 300,
}

export enum WorldPayPaymentType {
  SavedCard = 0,
  CardSession = 1,
  GooglePay = 2,
  ApplePay = 3,
  None = 100,
}

export enum CardPaymentService {
  Stripe = 0,
  WorldPay = 1,
  Thunes = 2,
}

export enum CreditCardPaymentEvent {
  Initiation = 0,
  DeviceDataInitialization = 1,
  Authentication = 2,
  Verification = 3,
  Authorization = 4,
  Settlement = 5,
  PartialSettlement = 6,
  Cancel = 7,
  FullyRefund = 8,
  PartialRefund = 9,
  Abort = 10,
  FraudAssessment = 11,
  PaymentLinkCreated = 12,
  NewDataFromThirdPartyService = 13,
  ThunesWebhook = 14,
}
export enum CreditCardPaymentDetailedStatus {
  Started = 0,
  Pending = 1,
  Succeeded = 2,
  SucceededWithIssue = 3,
  Failed = 4,
}

export type WPKuvaLocalItem = {
  extId: string
  createdDate: string
  status: CardPaymentStatus
  amount: number
  currency: string
}

export type GetWPKuvaLocalListResponse = {
  hasNextPage: boolean
  items: WPKuvaLocalItem[]
}

export type WpKwlItem = WPKuvaLocalItem & {
  creditCardNumber: string
  instance: {
    id: number
    hostUrl: string
  }
}
export type GetWPKwlListResponse = {
  hasNextPage: boolean
  items: WpKwlItem[]
}

export type KwlCardPaymentFilterForm = {
  userQuery: string
  topUpReference: string
  statuses: Array<CardPaymentStatus>
  reference: string
  paymentType: Array<WorldPayPaymentType>
  amounts: { min: string; max: string }
}

export type KwlCardPaymentFilters = KwlCardPaymentFilterForm & {
  dates: {
    startDate: string
    endDate: string
  }
}

export type GetWpKwlCardPaymentDetailResponse = {
  remittanceCardPaymentId?: string | number
  topUp: {
    id: string
    extId: string
  }
  instance: {
    id: number
    name: string
    hostUrl: string
  }
  refundIsAvailable: boolean
  createdDate: string
  updatedDate: string
  status: CardPaymentStatus
  paymentType: WorldPayPaymentType
  paymentService: CardPaymentService
  user: {
    id: string | number
    extId: string
    firstName: string
    lastName: string
    phoneNumber: string
    email: string
  }
  amounts: WpCardPaymentAmountItem[]
  statuses: WpCardPaymentStatusItem[]
}

export type KlCardPaymentFilterForm = {
  userQuery: string
  orderReference: string
  statuses: []
  reference: string
  paymentType: []
  amounts: { min: string; max: string }
}

export type KlCardPaymentFilters = KlCardPaymentFilterForm & {
  dates: {
    startDate: string
    endDate: string
  }
}

export type GetWPKuvaLocalListRequest = {
  queryParams: string
  offset: number
}

export type GetWpKwlListRequest = {
  queryParams: string
  offset: number
}

export type WpCardPaymentAmountItem = {
  id: string
  amount: number | string
  currency: string
  createdDate: string
  creditCardNumber: string
}

export type WpCardPaymentStatusItem = {
  id: string
  createdDate: string
  event: CreditCardPaymentEvent
  details: Record<string, string> | null
  status: CreditCardPaymentDetailedStatus
}

export type GetWPKuvaLocalCardPaymentDetailResponse = {
  order: {
    id: string
    reference: string
  }
  createdDate: string
  updatedDate: string
  status: CardPaymentStatus
  paymentType: WorldPayPaymentType
  paymentService: CardPaymentService
  user: {
    id: string | number
    extId: string
    firstName: string
    lastName: string
    phoneNumber: string
    email: string
  }
  amounts: WpCardPaymentAmountItem[]
  statuses: WpCardPaymentStatusItem[]
}
