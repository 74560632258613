import { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import Drawer from '@mui/material/Drawer'
import Container from '@mui/material/Container'
import TextFieldForm from '../../../components/reusable/form/TextFieldForm'
import { useForm } from 'react-hook-form'
import { Button, Grid } from '@mui/material'
import { KlCardPaymentFilterForm, KlCardPaymentFilters } from '../../../types/wpTypes'
import { trimObjectFields } from '../../../utils/commonUtils'
import SelectFieldForm from '../../../components/reusable/form/SelectFieldForm'
import {
  cardPaymentStatusList,
  worldPayPaymentType,
} from '../../../utils/statusUtils/wpStatusUtils'

type KlCardPaymentFilterProps = {
  isShowFilter: boolean
  filterState: KlCardPaymentFilters
  setIsShowFilter: Dispatch<SetStateAction<boolean>>
  setFilterList: Dispatch<SetStateAction<KlCardPaymentFilters>>
  getCardPaymentWithFilter: (data: KlCardPaymentFilterForm) => void
}

const KlCardPaymentFilter = ({
  isShowFilter,
  setIsShowFilter,
  setFilterList,
  getCardPaymentWithFilter,
  filterState,
}: KlCardPaymentFilterProps) => {
  const {
    handleSubmit,
    // formState: { errors, isValid },
    setValue,
    control,
  } = useForm<KlCardPaymentFilterForm>({})

  useEffect(() => {
    setValue('userQuery', filterState.userQuery)
    setValue('orderReference', filterState.orderReference)
    setValue('statuses', filterState.statuses)
    setValue('reference', filterState.reference)
    setValue('paymentType', filterState.paymentType)
    setValue('amounts.min', filterState.amounts.min)
    setValue('amounts.max', filterState.amounts.max)
  }, [
    filterState.amounts.max,
    filterState.amounts.min,
    filterState.orderReference,
    filterState.paymentType,
    filterState.reference,
    filterState.statuses,
    filterState.userQuery,
    setValue,
  ])

  const onCloseFilter = useCallback(() => {
    setIsShowFilter(false)
  }, [setIsShowFilter])

  const onSubmit = (data: KlCardPaymentFilterForm) => {
    const trimmedData = trimObjectFields(data)

    setFilterList(prevState => ({ ...prevState, ...trimmedData }))
    getCardPaymentWithFilter(trimmedData)
  }

  return (
    <Drawer anchor="right" open={isShowFilter} onClose={onCloseFilter}>
      <Container className="mt-20 mb-20">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextFieldForm<KlCardPaymentFilterForm>
            name="userQuery"
            control={control}
            label="Search User name, phone, email "
            type="text"
            inputMode="text"
            validMask={/[^a-zA-Z0-9.\s-()]/}
          />
          <TextFieldForm<KlCardPaymentFilterForm>
            name="orderReference"
            control={control}
            label="Search Order Reference"
            type="text"
            inputMode="text"
            validMask={/[^a-zA-Z0-9-]/}
          />
          <SelectFieldForm<KlCardPaymentFilterForm>
            name="statuses"
            control={control}
            label="Select Card Payment Statuses"
            options={cardPaymentStatusList}
          />
          <TextFieldForm<KlCardPaymentFilterForm>
            name="reference"
            control={control}
            label="Search Payment Reference"
            type="text"
            inputMode="text"
            validMask={/[^a-zA-Z0-9-]/}
          />
          <SelectFieldForm<KlCardPaymentFilterForm>
            name="paymentType"
            control={control}
            label="Select Card Payment Type"
            options={worldPayPaymentType}
          />
          <Grid container spacing={{ md: 2, xs: 0 }}>
            <Grid item md={6} xs={12}>
              <TextFieldForm<KlCardPaymentFilterForm>
                name="amounts.min"
                control={control}
                label="Set Amount Min"
                type="text"
                inputMode="text"
                validMask={/[^0-9]/}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextFieldForm<KlCardPaymentFilterForm>
                name="amounts.max"
                control={control}
                label="Set Amount Max"
                type="text"
                inputMode="text"
                validMask={/[^0-9]/}
              />
            </Grid>
          </Grid>
          <div className="flex j-content-center mt-20">
            <Button size="small" variant="contained" type="submit">
              Apply
            </Button>
          </div>
        </form>
      </Container>
    </Drawer>
  )
}

export default KlCardPaymentFilter
