import { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../store'
import { topUpActions } from '../../../../../store/actions/topUpActions'
import { Button, Grid, Typography } from '@mui/material'
import {
  AddFundsType,
  ConversionStatus,
  ReasonForTransfer,
  SourceOfFunds,
  TopUpPaymentProvider,
  TopUpStatus,
} from '../../../../../types/topUpTypes'
import { getDateWithTime } from '../../../../../utils/dateFormating'
import { openGlobalModal } from '../../../../../store/reducers/globalModalReducer'
import { topUpTableConfigs } from '../../../../../utils/config/tableConfig/topUp'
import CustomTable from '../../../../../components/CustomTable/CustomTable'
import { WpKwlItem } from '../../../../../types/wpTypes'
import { PaymentMethod } from '../../../../../types/remittanceTypes'

export const getReasonForTransfer = (reason: ReasonForTransfer | undefined) => {
  const reasonList = {
    [ReasonForTransfer.Other]: 'Other',
    [ReasonForTransfer.Personal]: 'Personal/Family',
    [ReasonForTransfer.Gift]: 'Gift',
    [ReasonForTransfer.Schooling]: 'Schooling',
    [ReasonForTransfer.ExpenseReimbursement]: 'Expense Reimbursement',
    [ReasonForTransfer.InvoicePayment]: 'Invoice Payment',
    [ReasonForTransfer.MedicalCosts]: 'Medical Costs',
    [ReasonForTransfer.Transportation]: 'Transportation',
  }

  return reason || reason === ReasonForTransfer.Other ? reasonList[reason] : ''
}

export const getSourceOfFunds = (source: SourceOfFunds | undefined) => {
  const sourceList = {
    [SourceOfFunds.Other]: 'Other',
    [SourceOfFunds.Salary]: 'Salary',
    [SourceOfFunds.Savings]: 'Savings',
    [SourceOfFunds.Investments]: 'Investments',
    [SourceOfFunds.Gift]: 'Gift',
    [SourceOfFunds.Loan]: 'Loan',
    [SourceOfFunds.Winnings]: 'Winnings',
    [SourceOfFunds.PropertySale]: 'PropertySale',
    [SourceOfFunds.BusinessOwnership]: 'BusinessOwnership',
  }

  return source || source === SourceOfFunds.Other ? sourceList[source] : ''
}

const domain = process.env.REACT_APP_BACK_OFFICE_DOMAIN

type RemittanceTopUpDetailsTabProps = {
  topUpId: string | undefined
}

const RemittanceTopUpDetailsTab = ({ topUpId }: RemittanceTopUpDetailsTabProps) => {
  const dispatch = useAppDispatch()

  const { remittanceTopUpDetailsLoadState, remittanceTopUpDetails, remittanceCardPaymentTxList } =
    useAppSelector(state => state.topUp)

  const tableConfig = useMemo(() => {
    return topUpTableConfigs.remittanceCardPaymentTxTableConfig()
  }, [])

  useEffect(() => {
    topUpId && dispatch(topUpActions.getRemittanceTopUpDetails(topUpId))

    if (topUpId && remittanceTopUpDetails?.paymentProvider === TopUpPaymentProvider.WorldPay) {
      dispatch(topUpActions.getRemittanceCardPaymentTxList(topUpId))
    }
  }, [dispatch, remittanceTopUpDetails?.paymentProvider, topUpId])

  const onCancelTopUp = useCallback(() => {
    dispatch(openGlobalModal({ variant: 'cancel-top-up', actionData: topUpId }))
  }, [dispatch, topUpId])

  return (
    <>
      {!remittanceTopUpDetailsLoadState.isLoading ? (
        <Grid
          container
          spacing={2}
          sx={{ margin: 0, width: '100%', height: 'calc(100dvh - 200px)', overflowX: 'auto' }}
        >
          <Grid item xs={12} md={4}>
            <Typography fontWeight="bold">Type:</Typography>
            <Typography mb={1}>
              {remittanceTopUpDetails?.requestType &&
                AddFundsType[remittanceTopUpDetails?.requestType]}
            </Typography>

            <>
              <Typography fontWeight="bold">Instance Name:</Typography>
              <Typography mb={1}>{remittanceTopUpDetails?.instanceName}</Typography>
            </>

            {remittanceTopUpDetails?.cashInPaymentProviderName && (
              <>
                <Typography fontWeight="bold">Cash In Payment Provider:</Typography>
                <Typography mb={1}>{remittanceTopUpDetails?.cashInPaymentProviderName}</Typography>
              </>
            )}
            {remittanceTopUpDetails?.cashInMethod && (
              <>
                <Typography fontWeight="bold">Cash In Payment Method:</Typography>
                <Typography mb={1}>
                  {PaymentMethod[remittanceTopUpDetails?.cashInMethod]}
                </Typography>
              </>
            )}
            {remittanceTopUpDetails?.withdrawPaymentProviderName && (
              <>
                <Typography fontWeight="bold">Withdrawal Payment Provider:</Typography>
                <Typography mb={1}>
                  {remittanceTopUpDetails?.withdrawPaymentProviderName}
                </Typography>
              </>
            )}
            {remittanceTopUpDetails?.withdrawMethod && (
              <>
                <Typography fontWeight="bold">Withdrawal Payment Method:</Typography>
                <Typography mb={1}>
                  {PaymentMethod[remittanceTopUpDetails?.withdrawMethod]}
                </Typography>
              </>
            )}

            <Typography fontWeight="bold">Status:</Typography>
            <Typography mb={1}>
              {remittanceTopUpDetails?.status && TopUpStatus[remittanceTopUpDetails?.status]}
            </Typography>

            {remittanceTopUpDetails?.requestType === AddFundsType.KuvaWhiteLabel &&
              remittanceTopUpDetails?.status !== TopUpStatus.Cancelled &&
              remittanceTopUpDetails?.status !== TopUpStatus.Completed && (
                <Button
                  sx={{ width: '160px', marginBottom: '8px' }}
                  size="small"
                  variant="contained"
                  onClick={onCancelTopUp}
                >
                  Cancel Top Up
                </Button>
              )}

            <Typography fontWeight="bold">Reference:</Typography>
            <Typography mb={1}>{remittanceTopUpDetails?.reference}</Typography>

            <Typography fontWeight="bold">Digital Reference:</Typography>
            <Typography mb={1}>{remittanceTopUpDetails?.digitalReference}</Typography>

            {remittanceTopUpDetails?.status === TopUpStatus.Completed && (
              <>
                {remittanceTopUpDetails?.branchId && (
                  <>
                    <Typography fontWeight="bold">Branch Id:</Typography>
                    <Typography mb={1}>{remittanceTopUpDetails?.branchId}</Typography>
                  </>
                )}
                {remittanceTopUpDetails?.tellerId && (
                  <>
                    <Typography fontWeight="bold">Teller Id:</Typography>
                    <Typography mb={1}>{remittanceTopUpDetails?.tellerId}</Typography>
                  </>
                )}
              </>
            )}

            <Typography fontWeight="bold">Actual amount:</Typography>
            <Typography mb={1}>
              {remittanceTopUpDetails?.from.totalFromAmount.toFixed(2)}{' '}
              {remittanceTopUpDetails?.from.currency}
            </Typography>

            <Typography fontWeight="bold">From:</Typography>
            <Typography mb={1}>
              {remittanceTopUpDetails?.from.amount.toFixed(2)}{' '}
              {remittanceTopUpDetails?.from.currency}
            </Typography>

            <Typography fontWeight="bold">To:</Typography>
            <Typography mb={1}>
              {remittanceTopUpDetails?.to.amount.toFixed(2)} {remittanceTopUpDetails?.to.currency}
            </Typography>

            <Typography fontWeight="bold">Rate:</Typography>
            <Typography mb={1}>{remittanceTopUpDetails?.rate.toFixed(4)}</Typography>

            <Typography fontWeight="bold">Total Fee:</Typography>
            <Typography mb={1}>
              {remittanceTopUpDetails?.totalFee.toFixed(2)} {remittanceTopUpDetails?.from.currency}
            </Typography>

            <Typography fontWeight="bold">Reason For Transfer:</Typography>
            <Typography mb={1}>
              {getReasonForTransfer(remittanceTopUpDetails?.transactionDetails.reasonTransfer)}
            </Typography>

            {remittanceTopUpDetails?.transactionDetails.reasonTransfer ===
              ReasonForTransfer.Other && (
              <>
                <Typography fontWeight="bold">Reason For Transfer Details:</Typography>
                <Typography mb={1}>
                  {remittanceTopUpDetails?.transactionDetails!.reasonTransferDetails}
                </Typography>
              </>
            )}

            <Typography fontWeight="bold">Source Of Funds:</Typography>
            <Typography mb={1}>
              {getSourceOfFunds(remittanceTopUpDetails?.transactionDetails.sourceOfFunds)}
            </Typography>

            {remittanceTopUpDetails?.transactionDetails.sourceOfFunds === SourceOfFunds.Other && (
              <>
                <Typography fontWeight="bold">Source Of Funds Details:</Typography>
                <Typography mb={1}>
                  {remittanceTopUpDetails?.transactionDetails!.sourceOfFundsDetails}
                </Typography>
              </>
            )}

            <Typography fontWeight="bold">Created Date (UTC):</Typography>
            <Typography mb={1}>{getDateWithTime(remittanceTopUpDetails?.createdDate)}</Typography>

            <Typography fontWeight="bold">Expiration Date (UTC):</Typography>
            <Typography mb={1}>
              {getDateWithTime(remittanceTopUpDetails?.expirationDate)}
            </Typography>

            <Typography fontWeight="bold">Conversion Settlement Date (UTC):</Typography>
            <Typography mb={1}>
              {getDateWithTime(remittanceTopUpDetails?.conversion.settlementDate)}
            </Typography>

            <Typography fontWeight="bold">Conversion Short Reference:</Typography>
            <Typography mb={1}>{remittanceTopUpDetails?.conversion.shortReference}</Typography>

            <Typography fontWeight="bold">Conversion status:</Typography>
            <Typography mb={1}>
              {remittanceTopUpDetails?.conversion.status &&
                ConversionStatus[remittanceTopUpDetails?.conversion.status]}
            </Typography>

            <Typography fontSize={18} fontWeight="bold" mt={1.5}>
              Fees:
            </Typography>
            <Typography fontWeight="bold">Kuva Fee:</Typography>
            <Typography mb={1}>
              {remittanceTopUpDetails?.kuvaFee.toFixed(2)} {remittanceTopUpDetails?.from.currency}
            </Typography>
            <Typography fontWeight="bold">Provider Fee:</Typography>
            <Typography mb={1}>
              {remittanceTopUpDetails?.totalCashInProviderFeeSrcCurrAmount.toFixed(2)}{' '}
              {remittanceTopUpDetails?.from.currency}
            </Typography>
            <Typography fontWeight="bold">Withdrawal Fee (Source):</Typography>
            <Typography mb={1}>
              {remittanceTopUpDetails?.totalCashOutProviderFeeSrcCurrencyAmount.toFixed(2)}{' '}
              {remittanceTopUpDetails?.from.currency}
            </Typography>
            <Typography fontWeight="bold">Withdrawal Fee (Destination):</Typography>
            <Typography mb={1}>
              {remittanceTopUpDetails?.totalCashOutProviderFeeDestCurrencyAmount.toFixed(2)}{' '}
              {remittanceTopUpDetails?.to.currency}
            </Typography>

            <Typography fontWeight="bold">Agent Reward Fee:</Typography>
            <Typography mb={1}>
              {remittanceTopUpDetails?.totalRewardFeeSrcCurrAmount.toFixed(2)}{' '}
              {remittanceTopUpDetails?.from.currency}
            </Typography>

            <Typography fontWeight="bold">Agent Total Fee (Source):</Typography>
            <Typography mb={1}>
              {remittanceTopUpDetails?.kdAgentFeeSrcCurrencyAmount.toFixed(2)}{' '}
              {remittanceTopUpDetails?.from.currency}
            </Typography>

            <Typography fontWeight="bold">Agent Total Fee (Destination):</Typography>
            <Typography mb={1}>
              {remittanceTopUpDetails?.kdAgentFeeDestCurrencyAmount.toFixed(2)}{' '}
              {remittanceTopUpDetails?.to.currency}
            </Typography>

            <Typography fontWeight="bold">User:</Typography>
            <Typography>
              {remittanceTopUpDetails?.localUser.firstName +
                ' ' +
                remittanceTopUpDetails?.localUser.lastName}
            </Typography>

            <Typography>{remittanceTopUpDetails?.localUser.phoneNumber}</Typography>
            <Typography mb={1}>{remittanceTopUpDetails?.localUser.email || ''}</Typography>

            <Button
              sx={{ width: '160px', marginBottom: '8px' }}
              size="small"
              variant="contained"
              href={`${domain}/MobileUsers/${remittanceTopUpDetails?.localUser.extId}`}
              target="_blank"
            >
              User Details
            </Button>
          </Grid>

          <Grid item xs={12} md={8}>
            {remittanceTopUpDetails?.paymentProvider === TopUpPaymentProvider.WorldPay &&
              remittanceCardPaymentTxList.length > 0 && (
                <>
                  <h3>Card Payment Transactions</h3>
                  <CustomTable<WpKwlItem>
                    tableConfig={tableConfig}
                    tableData={remittanceCardPaymentTxList}
                  />
                </>
              )}
          </Grid>
        </Grid>
      ) : null}
    </>
  )
}

export default RemittanceTopUpDetailsTab
