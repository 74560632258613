import { Fragment } from 'react'
import { Route, Navigate, Routes } from 'react-router-dom'

import { getMapedPermissionList } from '../../services/permissionsService'
import { PermissionDataType } from '../../types/permissionTypes'

import * as path from '../../utils/appPaths'

import Loader from '../Loader'

import PageNotFound from '../../pages/PageNotFound'
import Profile from '../../pages/Profile'

import KuvaWhiteLabel from '../../pages/KuvaWhiteLabel'
import KWLSites from '../../pages/KuvaWhiteLabel/Sites'
import KWLUsers from '../../pages/KuvaWhiteLabel/Users'
import MobileUsers from '../../pages/MobileUsers'
import AgentsProfiles from '../../pages/AgentsProfiles'
import AgentGroupsPage from '../../pages/AgentGroups'
import TopUp from '../../pages/TopUp'
import Usdk from '../../pages/TopUp/Usdk'
import KuvaCoin from '../../pages/TopUp/KuvaCoin'
import Cashrail from '../../pages/TopUp/Cashrail'
import TopUpKuvaWhiteLabel from '../../pages/TopUp/KuvaWhiteLabel'
import BugReport from '../../pages/BugReport'
import Contact from '../../pages/Contact'
import KuvaLocal from '../../pages/KuvaLocal'
import Settings from '../../pages/KuvaLocal/Settings'
import Orders from '../../pages/KuvaLocal/Orders'
import DispatchOrders from '../../pages/KuvaLocal/DispatchOrders'
import Users from '../../pages/KuvaLocal/Users'
import KuvaContact from '../../pages/KuvaLocal/Contact'
import Feedback from '../../pages/KuvaLocal/Feedback'
import Stores from '../../pages/KuvaLocal/Stores'
import Countries from '../../pages/KuvaLocal/Countries'
import PaymentRefunds from '../../pages/KuvaLocal/PaymentRefunds'
import Submissions from '../../pages/KuvaLocal/Submissions'
import Promocode from '../../pages/KuvaLocal/Promocode'
import CharityCompanies from '../../pages/KuvaLocal/CharityCompanies'
import Report from '../../pages/KuvaLocal/Report'
import CreditVendor from '../../pages/KuvaLocal/CreditVendor'
import MobileHomePage from '../../pages/KuvaLocal/MobileHomePage'
import Facebook from '../../pages/KuvaLocal/Facebook'
import CreditWalletTreasure from '../../pages/CreditWalletTreasure'
import CurrencyCloud from '../../pages/CurrencyCloud'
import KLRates from '../../pages/CurrencyCloud/KLRates'
import OTRates from '../../pages/CurrencyCloud/OTRates'
import TopUpRates from '../../pages/CurrencyCloud/TopUpRates'
import AllRates from '../../pages/CurrencyCloud/AllRates'
import Feedbacks from '../../pages/Feedbacks'
import MasterNode from '../../pages/MasterNode'
import AllMasterNodes from '../../pages/MasterNode/AllMasterNodes'
import Rewards from '../../pages/MasterNode/Rewards'
import OutboundTransfers from '../../pages/OutboundTransfers'
import Transfers from '../../pages/OutboundTransfers/Transfers'
import OutboundTransfersCountries from '../../pages/OutboundTransfers/Countries'
import Fees from '../../pages/OutboundTransfers/Fees'
import RiskScores from '../../pages/OutboundTransfers/RiskScores'
import CashOut from '../../pages/CashOut'
import PendingRequests from '../../pages/CashOut/PendingRequests'
import CompletedRequests from '../../pages/CashOut/CompletedRequests'
import CompletedKWLRequests from '../../pages/CashOut/CompletedKWLRequests'
import PendingKWLRequests from '../../pages/CashOut/PendingKWLRequests'
import PendingMulticashRequests from '../../pages/CashOut/PendingMuticashRequests'
import CompletedMulticashRequests from '../../pages/CashOut/CompletedMultiCashRequests'
import PushNotifications from '../../pages/PushNotifications'
import ReferralLink from '../../pages/ReferralLink'
import TreasuryTransfers from '../../pages/Payments/TreasuryTransfers'
import Payments from '../../pages/Payments'
import Payins from '../../pages/Payments/Payins'
import Payouts from '../../pages/Payments/Payouts'
import UpholdTransactions from '../../pages/Payments/UpholdTransactions'
import Transactions from '../../pages/Transactions'
import AllTransactions from '../../pages/Transactions/AllTransactions'
import DuplicateTransactions from '../../pages/Transactions/DuplicateTransactions'
import IncorrectRateTransactions from '../../pages/Transactions/IncorrectRateTransactions'
import UnpaidTransactions from '../../pages/Transactions/UnpaidTransactions'
import MobileTransfers from '../../pages/MobileTransfers'
import MobileTransferTable from '../../pages/MobileTransfers/MobileTransferTable'
import MobileOrderTable from '../../pages/MobileTransfers/MobileOrderTable'
import Edit from '../../pages/KuvaWhiteLabel/Countries/Edit'
import WorldPay from '../../pages/WorldPay'
import WPKuvaWhiteLabelPage from '../../pages/WorldPay/WPKuvaWhiteLabel'
import WPKuvaLocalPage from '../../pages/WorldPay/WPKuvaLocal'
import SiteEditing from '../../pages/KuvaWhiteLabel/Sites/SiteEditing'

import WpKwlCardPaymentDetail from '../../pages/WorldPay/WPKuvaWhiteLabel/WpKwlCardPaymentDetail'
import WpKlCardPaymentDetail from '../../pages/WorldPay/WPKuvaLocal/WpKlCardPaymentDetail'
import AgentGroupDetailPage from '../../pages/AgentGroups/AgentGroupDetailPage'
import MobileUserDetailsPage from '../../pages/MobileUsers/MobileUserDetails'
import TransactionDetailsPage from '../../pages/Transactions/TransactionDetails'
import RemittanceTopUpDetailsPage from '../../pages/TopUp/KuvaWhiteLabel/RemittanceTopUpDetailsPage'
import UserBlockingRecords from '../../pages/UserBlockingRecords'
import RemittanceSettings from '../../pages/KuvaWhiteLabel/RemittanceSettings/RemittanceSettings'

export default ({ userPermissionList }: { userPermissionList: PermissionDataType[] }) => {
  const permissions = getMapedPermissionList(userPermissionList)
  let routesList: JSX.Element[] = []

  userPermissionList.forEach((item, _, thisArr) => {
    if (item.key === permissions.SuperUser) {
      routesList = [
        ...routesList,
        <Fragment key={permissions.SuperUser}>
          <Route path="/" element={<Navigate replace to={path.kwlPath} />} />
          <Route path={path.kwlPath} element={<Navigate replace to={path.kwlInstancesPath} />} />
          <Route path={path.kwlPath} element={<KuvaWhiteLabel />}>
            <Route path={path.kwlInstancesPath} element={<KWLSites />} />
            <Route path={path.kwlUsersPath} element={<KWLUsers />} />
            <Route path={path.kwlEditCountriesPath} element={<Edit />} />
            <Route path={path.kwlInstancePath} element={<SiteEditing />} />
            <Route path={path.remittanceSettingsPath} element={<RemittanceSettings />} />
          </Route>
          <Route path={path.wpPath} element={<Navigate replace to={path.wpForKwlPath} />} />
          <Route path={path.wpPath} element={<WorldPay />}>
            <Route path={path.wpForKwlPath} element={<WPKuvaWhiteLabelPage />} />
            <Route path={path.wpForKwlPaymentDetail} element={<WpKwlCardPaymentDetail />} />
            <Route path={path.wpForKlPath} element={<WPKuvaLocalPage />} />
            <Route path={path.wpForKlPaymentDetail} element={<WpKlCardPaymentDetail />} />
          </Route>
          <Route path={path.agentGroupsPath}>
            <Route path={path.agentGroupsPath} element={<AgentGroupsPage />} />
            <Route path={path.agentGroupDetailPath} element={<AgentGroupDetailPage />} />
          </Route>
          <Route path={path.mobileUsersPath} element={<MobileUsers />} />
          <Route path={path.mobileUserDetailsPath} element={<MobileUserDetailsPage />} />
          <Route path={path.transactionsPath} element={<Transactions />}>
            <Route path={path.transactionsAllPath} element={<AllTransactions />} />
            <Route path={path.transactionDetailsPath} element={<TransactionDetailsPage />} />
            <Route path={path.transactionsDuplicatePath} element={<DuplicateTransactions />} />
            <Route
              path={path.transactionsIncorectRatePath}
              element={<IncorrectRateTransactions />}
            />
            <Route path={path.transactionsUnpaidPath} element={<UnpaidTransactions />} />
          </Route>
          <Route path={path.topUpPath} element={<Navigate replace to={path.topUpUsdkPath} />} />
          <Route path={path.topUpPath} element={<TopUp />}>
            <Route path={path.topUpUsdkPath} element={<Usdk />} />
            <Route path={path.topUpKuvaCoinPath} element={<KuvaCoin />} />
            <Route path={path.topUpCashrailPath} element={<Cashrail />} />
            <Route path={path.topUpKuvaWhiteLabelPath} element={<TopUpKuvaWhiteLabel />} />
            <Route
              path={path.topUpRemittanceDetailsPath}
              element={<RemittanceTopUpDetailsPage />}
            />
          </Route>
          <Route path={path.userBlockingRecordsPath} element={<UserBlockingRecords />} />
        </Fragment>,
      ]
    } else if (item.key === permissions.Users_Access) {
      routesList = [
        ...routesList,
        <Fragment key={permissions.Users_Access}>
          <Route path="/" element={<Navigate replace to={path.mobileUsersPath} />} />
          <Route path={path.mobileUsersPath} element={<MobileUsers />} />
        </Fragment>,
      ]
    } else if (item.key === permissions.Agents_Access) {
      routesList = [
        ...routesList,
        <Fragment key={permissions.Agents_Access}>
          <Route path="/" element={<Navigate replace to={path.agentsProfilesPath} />} />
          <Route path={path.agentsProfilesPath} element={<AgentsProfiles />} />
          {/* <Route path={path.agentGroupsPath} element={<AgentGroups />} /> */}
        </Fragment>,
      ]
    }
    // else if (item.key === permissions.Top_Up_Access) {
    //   routesList = [
    //     ...routesList,
    //     <Fragment key={permissions.Top_Up_Access}>
    //       <Route path="/" element={<Navigate replace to={path.topUpPath} />} />
    //       <Route path={path.topUpPath} element={<Navigate replace to={path.topUpUsdkPath} />} />
    //       <Route path={path.topUpPath} element={<TopUp />}>
    //         <Route path={path.topUpUsdkPath} element={<Usdk />} />
    //         <Route path={path.topUpKuvaCoinPath} element={<KuvaCoin />} />
    //         <Route path={path.topUpCashrailPath} element={<Cashrail />} />
    //         <Route path={path.topUpKuvaWhiteLabelPath} element={<TopUpKuvaWhiteLabel />} />
    //       </Route>
    //     </Fragment>,
    //   ]
    // }
    else if (item.key === permissions.Bug_Reports_Access) {
      routesList = [
        ...routesList,
        <Fragment key={permissions.Bug_Reports_Access}>
          <Route path="/" element={<Navigate replace to={path.bugReportPath} />} />
          <Route path={path.bugReportPath} element={<BugReport />} />
        </Fragment>,
      ]
    } else if (item.key === permissions.Contacts_Access) {
      routesList = [
        ...routesList,
        <Fragment key={permissions.Contacts_Access}>
          <Route path="/" element={<Navigate replace to={path.contactPath} />} />
          <Route path={path.contactPath} element={<Contact />} />
        </Fragment>,
      ]
    } else if (item.key === permissions.KuvaLocalAdmin) {
      routesList = [
        ...routesList,
        <Fragment key={permissions.KuvaLocalAdmin}>
          <Route path="/" element={<Navigate replace to={path.kuvaLocalPath} />} />
          <Route
            path={path.kuvaLocalPath}
            element={<Navigate replace to={path.kuvaLocalSettingsPath} />}
          />
          <Route path={path.kuvaLocalPath} element={<KuvaLocal />}>
            <Route path={path.kuvaLocalSettingsPath} element={<Settings />} />
            <Route path={path.kuvaLocalOrdersPath} element={<Orders />} />
            <Route path={path.kuvaLocalCourierDispatchOrderPath} element={<DispatchOrders />} />
            <Route path={path.kuvaLocalUsersPath} element={<Users />} />
            <Route path={path.kuvaLocalContactPath} element={<KuvaContact />} />
            <Route path={path.kuvaLocalFeedbackPath} element={<Feedback />} />
            <Route path={path.kuvaLocalStoresPath} element={<Stores />} />
            <Route path={path.kuvaLocalCountriesPath} element={<Countries />} />
            <Route path={path.kuvaLocalRefundsPath} element={<PaymentRefunds />} />
            <Route path={path.kuvaLocalSubmissionsPath} element={<Submissions />} />
            {thisArr.find(perm => perm.key === permissions.Promo_Code_Manager) && (
              <Route path={path.kuvaLocalPromocodePath} element={<Promocode />} />
            )}
            {thisArr.find(perm => perm.key === permissions.Charity_Manager) && (
              <Route path={path.kuvaLocalCompaniesPath} element={<CharityCompanies />} />
            )}
            <Route path={path.kuvaLocalReportPath} element={<Report />} />
            {thisArr.find(perm => perm.key === permissions.Credit_Vendor) && (
              <Route path={path.kuvaLocalCreditVendorPath} element={<CreditVendor />} />
            )}
            <Route path={path.kuvaLocalMobileHomePageWidgetsPath} element={<MobileHomePage />} />
            <Route path={path.kuvaLocalFacebookPath} element={<Facebook />} />
          </Route>
        </Fragment>,
      ]
    } else if (item.key === permissions.Credit_Wallet_Reserve_Treasury_Reconciliation) {
      routesList = [
        ...routesList,
        <Fragment key={permissions.Credit_Wallet_Reserve_Treasury_Reconciliation}>
          <Route path="/" element={<Navigate replace to={path.creditWalletTreasurePath} />} />
          <Route path={path.creditWalletTreasurePath} element={<CreditWalletTreasure />} />
        </Fragment>,
      ]
    } else if (item.key === permissions.Currency_Cloud_Rate_Access) {
      routesList = [
        ...routesList,
        <Fragment key={permissions.Currency_Cloud_Rate_Access}>
          <Route path="/" element={<Navigate replace to={path.currencyCloudPath} />} />
          <Route
            path={path.currencyCloudPath}
            element={<Navigate replace to={path.currencyCloudKLRatesPath} />}
          />
          <Route path={path.currencyCloudPath} element={<CurrencyCloud />}>
            <Route path={path.currencyCloudKLRatesPath} element={<KLRates />} />
            <Route path={path.currencyCloudOTRatesPath} element={<OTRates />} />
            <Route path={path.currencyCloudTopUpRatesPath} element={<TopUpRates />} />
            <Route path={path.currencyCloudAllRatesPath} element={<AllRates />} />
          </Route>
        </Fragment>,
      ]
    } else if (item.key === permissions.Feedbacks_Access) {
      routesList = [
        ...routesList,
        <Fragment key={permissions.Feedbacks_Access}>
          <Route path="/" element={<Navigate replace to={path.feedbacksPath} />} />
          <Route path={path.feedbacksPath} element={<Feedbacks />} />
        </Fragment>,
      ]
    } else if (item.key === permissions.Masternodes_Access) {
      routesList = [
        ...routesList,
        <Fragment key={permissions.Masternodes_Access}>
          <Route path="/" element={<Navigate replace to={path.masterNodePath} />} />
          <Route
            path={path.masterNodePath}
            element={<Navigate replace to={path.masterNodeAllMasterNodesPath} />}
          />
          <Route path={path.masterNodePath} element={<MasterNode />}>
            <Route path={path.masterNodeAllMasterNodesPath} element={<AllMasterNodes />} />
            <Route path={path.masterNodeRewardsPath} element={<Rewards />} />
          </Route>
        </Fragment>,
      ]
    } else if (item.key === permissions.Outbound_Transfers_Access) {
      routesList = [
        ...routesList,
        <Fragment key={permissions.Outbound_Transfers_Access}>
          <Fragment>
            <Route path="/" element={<Navigate replace to={path.outboundTransfersPath} />} />
            <Route
              path={path.outboundTransfersPath}
              element={<Navigate replace to={path.outboundTransfersTransfersPath} />}
            />
            <Route path={path.outboundTransfersPath} element={<OutboundTransfers />}>
              <Route path={path.outboundTransfersTransfersPath} element={<Transfers />} />
              <Route
                path={path.outboundTransfersCountriesPath}
                element={<OutboundTransfersCountries />}
              />
              <Route path={path.outboundTransfersFeesPath} element={<Fees />} />
              <Route path={path.outboundTransfersRiskScoresPath} element={<RiskScores />} />
            </Route>
          </Fragment>
          <Fragment>
            <Route path="/" element={<Navigate replace to={path.cashOutPath} />} />
            <Route
              path={path.cashOutPath}
              element={<Navigate replace to={path.cashOutPendingRequestsPath} />}
            />
            <Route path={path.cashOutPath} element={<CashOut />}>
              <Route path={path.cashOutPendingRequestsPath} element={<PendingRequests />} />
              <Route path={path.cashOutCompletedRequestsPath} element={<CompletedRequests />} />
              <Route path={path.cashOutPendingKWLRequestsPath} element={<PendingKWLRequests />} />
              <Route
                path={path.cashOutCompletedKWLRequestsPath}
                element={<CompletedKWLRequests />}
              />
              <Route
                path={path.cashOutPendingMulticashRequestsPath}
                element={<PendingMulticashRequests />}
              />
              <Route
                path={path.cashOutCompletedMulticashRequestsPath}
                element={<CompletedMulticashRequests />}
              />
            </Route>
          </Fragment>
        </Fragment>,
      ]
    } else if (item.key === permissions.Push_Notifications_Access) {
      routesList = [
        ...routesList,
        <Fragment key={permissions.Push_Notifications_Access}>
          <Route path="/" element={<Navigate replace to={path.pushNotificationsPath} />} />
          <Route path={path.pushNotificationsPath} element={<PushNotifications />} />
        </Fragment>,
      ]
    } else if (item.key === permissions.Referral_Links_Access) {
      routesList = [
        ...routesList,
        <Fragment key={permissions.Referral_Links_Access}>
          <Route path="/" element={<Navigate replace to={path.referralLinkPath} />} />
          <Route path={path.referralLinkPath} element={<ReferralLink />} />
        </Fragment>,
      ]
    } else if (item.key === permissions.Payments_Access) {
      routesList = [
        ...routesList,
        <Fragment key={permissions.Payments_Access}>
          <Fragment>
            <Route path="/" element={<Navigate replace to={path.paymentsPath} />} />
            <Route
              path={path.paymentsPath}
              element={<Navigate replace to={path.paymentsTreasuryTransfersPath} />}
            />
            <Route path={path.paymentsPath} element={<Payments />}>
              <Route path={path.paymentsTreasuryTransfersPath} element={<TreasuryTransfers />} />
              <Route path={path.paymentsPayinsPath} element={<Payins />} />
              <Route path={path.paymentsPayoutsPath} element={<Payouts />} />
              <Route path={path.paymentsUpholdTransactionsPath} element={<UpholdTransactions />} />
            </Route>
          </Fragment>
          {/* <Fragment>
            <Route path="/" element={<Navigate replace to={path.transactionsPath} />} />
            <Route
              path={path.transactionsPath}
              element={<Navigate replace to={path.transactionsAllPath} />}
            />
            <Route path={path.transactionsPath} element={<Transactions />}>
              <Route path={path.transactionsAllPath} element={<AllTransactions />} />
              {(thisArr.find(perm => perm.key === permissions.SuperUser) ||
                thisArr.find(perm => perm.key === permissions.Duplicate_Transactions_Access)) && (
                <Route path={path.transactionsDuplicatePath} element={<DuplicateTransactions />} />
              )}
              {thisArr.find(perm => perm.key === permissions.SuperUser) && (
                <Route
                  path={path.transactionsIncorectRatePath}
                  element={<IncorrectRateTransactions />}
                />
              )}
              {thisArr.find(perm => perm.key === permissions.SuperUser) && (
                <Route path={path.transactionsUnpaidPath} element={<UnpaidTransactions />} />
              )}
            </Route>
          </Fragment> */}
          <Fragment>
            <Route path="/" element={<Navigate replace to={path.mobileTransfersPath} />} />
            <Route
              path={path.mobileTransfersPath}
              element={<Navigate replace to={path.mobileTransfersTransferTablePath} />}
            />
            <Route path={path.mobileTransfersPath} element={<MobileTransfers />}>
              <Route
                path={path.mobileTransfersTransferTablePath}
                element={<MobileTransferTable />}
              />
              <Route path={path.mobileTransfersOrderTablePath} element={<MobileOrderTable />} />
            </Route>
          </Fragment>
        </Fragment>,
      ]
    }
  })

  return (
    <Routes>
      {!!userPermissionList.length ? (
        <>
          {routesList}
          <Route path={path.profilePath} element={<Profile />} />
          <Route path={path.pageNotFoundPath} element={<PageNotFound />} />
          <Route path="*" element={<Navigate replace to={path.pageNotFoundPath} />} />
        </>
      ) : (
        <Route path="*" element={<Loader />} />
      )}
    </Routes>
  )
}
