import { RiskLevelMarker } from './commonTypes'
import { EntityType } from './generalTypes'

export enum MobileUserStatus {
  Active = 20,
  Blocked = 40,
  Deleted = 50,
  Guest = 130,
  Idle = 140,
}

export type MobileUserDataType = {
  id: string
  registrationDate: string
  firstName: string
  lastName: string
  email: string
  walletAddressUsd: string
  status: number
  phoneNumber: string
  canInvite: boolean
  roles: string[]
  kwlRiskScore: number
  kwlRiskLevelMarker: number
  riskScore: number
  riskLevelMarker: number
}

export type MobileUserType = EntityType<MobileUserDataType> & { error: string }

export type GetMobileUserListRequest = {
  queryParams: string
  offset: number
  pageSize: number
}

export type GetMobileUserListResponse = {
  count: number
  items: MobileUserDataType[]
}

export type GetMobileUserDetailsResponse = {
  firstName: string
  lastName: string
  isEmailConfirmed: boolean
  fullName: string
  phoneNumber: string
  email: string
  status: MobileUserStatus
  kwlRiskScore: number,
  kwlRiskLevelMarker: RiskLevelMarker,
  kwlTransactionRiskScore: number,
  kwlTransactionRiskLevelMarker: RiskLevelMarker,
  kwlFinalRiskScore: number,
  kwlFinalRiskLevelMarker: RiskLevelMarker,
  isKwlTransactionRiskScoreIgnored: false
}

export type GetUserBlockingRecordsRequest = {
  queryParams: string
  offset: number
}

export type UserBlockingRecord = {
  userId: string
  firstName: string
  lastName: string
  phoneNumber: string
  lastBlockingDate: string
  unconfirmedCount: number
  confirmedCount: number
  resolvedCount: number
}

export type GetUserBlockingRecordsResponse = {
  hasNextPage: false
  items: Array<UserBlockingRecord>
}

export enum BlockingType {
  WorldPayCreditCardPayment,
  StripeCreditCardPayment,
  Manual,
}

export enum BlockingRecordStatus {
  Unconfirmed,
  Confirmed,
  Resolved,
}

export type ChangeBlockingRecordStatusForm = {
  reason: string
}

export type ChangeBlockingRecordStatusRequest = {
  entityId: number | string
  body: ChangeBlockingRecordStatusForm
}

export type CreateBlockingRecordRequest = {
  reason: string
  userId: string | number
  projectTypes: number
}

export type CreateBlockingRecordForm = {
  reason: string
  kuvaCashProject: boolean
  kuvaLocalProject: boolean
  kuvaWhiteLabelProject: boolean
  checkboxSection?: string
}

export enum ProjectType {
  KuvaCash = 1,
  KuvaLocal = 2,
  KuvaWhiteLabel = 4,
}

export type BlockingRecord = {
  id: number
  blockingType: BlockingType
  status: BlockingRecordStatus
  blockingReason: string
  blockingDate: string
  blockedById: string
  // mobileUserId: string
  confirmById: string
  confirmationReason: string
  confirmationDate: string
  details: string
  resolvedReason: string
  resolvedById: string
  resolvedDate: string
  projectTypes: number
  blockedBy: string
  confirmBy: string
  resolvedBy: string
}

export type CustomerKlProfile = {
  nopCommerceCustomerId: number
  countryCode: string
  isDeleted: boolean
}

export type GetKlProfileDetailsResponse = {
  status: MobileUserStatus
  previousShopLocation: string
  createdDate: string
  customerProfiles: Array<CustomerKlProfile>
}

export type GetKwlProfileDetailsResponse = {
  status: MobileUserStatus
  countryId: number
  city: string
  street: string
  postcode: string
  isSubscribedToNewsAndUpdates: boolean
}

export type GetUserDocImageRequest = {
  userId: string | number
  imageId: string | number
}

export enum AmlInspectionStatus {
  Unknown = 0,
  Initiated = 10,
  Pending = 15,
  Verified = 20,
  Rejected = 30,
  RejectedWithRetry = 40,
}

export enum AmlInspectionState {
  Started = 0,
  Active = 10,
  Cancelled = 20,
  Expired = 21,
}

export type UserAmlInspection = {
  id: number
  createdDate: string
  status: AmlInspectionStatus
  state: AmlInspectionState
  expirationDate: string
  flow: {
    name: string
    displayName: string
    weight: number
  }
}

export type UserVisibleAmlInspection = {
  status: AmlInspectionStatus
  expirationDate: string
  level: {
    name: string
    displayName: string
    weight: number
  }
}

export type InspectionDoc = {
  id: number
  docType: string
  imagesReviewResults: Array<{
    imageId: number
    reviewAnswer: string
  }>
  reviewAnswer: string
}

export type GetUserDocsResponse = {
  inspectionId: string
  results: Array<InspectionDoc>
}

export type UpdateRemittanceUserRiskRequestArg = {
  userId: string
  riskScore: string | number
}
