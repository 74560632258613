import { AlertColor } from '@mui/material/Alert'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type NotificationState = {
  open: boolean
  type: AlertColor
  message: string
  errorCode?: string
}

const initialState: NotificationState = {
  open: false,
  type: 'info',
  message: '',
}

export const notificationReducer = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<NotificationState>) => {
      state.open = action.payload.open
      state.type = action.payload.type
      state.message = action.payload.message
      state.errorCode = action.payload.errorCode
    },
    clearNotification: state => {
      state.open = false
    },
  },
})

export const { addNotification, clearNotification } = notificationReducer.actions

export default notificationReducer.reducer
