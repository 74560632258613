import React, { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { remittanceActions } from '../../../../store/actions/kwlActions'
import { Box, Button, styled } from '@mui/material'
import CurrenciesTable from './CurrenciesTable/CurrenciesTable'
import { Currency } from '../../../../types/remittanceTypes'
import remittanceTableConfig from '../../../../utils/config/tableConfig/remittance'
import { openGlobalModal } from '../../../../store/reducers/globalModalReducer'

const StyledCurrenciesTab = styled(Box)({
  padding: '8px 16px',
  '.add-currency-btn': {
    marginBottom: '20px',
  },
})

const CurrenciesTab = () => {
  const dispatch = useAppDispatch()

  const { commonCurrencyList, getCommonCurrencyListLoadState } = useAppSelector(state => state.kwl)

  const tableConfig = useMemo(() => {
    return remittanceTableConfig.currencyTableConfig()
  }, [])

  useEffect(() => {
    dispatch(remittanceActions.getCommonCurrencyList())
  }, [dispatch])

  const addNewCurrency = useCallback(() => {
    dispatch(
      openGlobalModal({
        variant: 'add-common-currency',
      })
    )
  }, [dispatch])

  if (!getCommonCurrencyListLoadState.isLoaded) {
    return null
  }

  return (
    <StyledCurrenciesTab>
      <Button
        size="medium"
        variant="contained"
        onClick={addNewCurrency}
        className="add-currency-btn"
      >
        Add New Currency
      </Button>

      <CurrenciesTable<Currency> tableConfig={tableConfig} tableData={commonCurrencyList} />
    </StyledCurrenciesTab>
  )
}

export default CurrenciesTab
