import { Button, styled } from '@mui/material'
import React, { useCallback } from 'react'
import { Currency } from '../../../../../types/remittanceTypes'
import { useAppDispatch } from '../../../../../store'
import { openGlobalModal } from '../../../../../store/reducers/globalModalReducer'

const StyledCommonCurrencyControlButtonCell = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  gridGap: '15px',
})

type CommonCurrencyControlButtonCellProps = {
  currencyDetails: Currency
}

const CommonCurrencyControlButtonCell = ({
  currencyDetails,
}: CommonCurrencyControlButtonCellProps) => {
  const dispatch = useAppDispatch()

  const onEdit = useCallback(() => {
    dispatch(
      openGlobalModal({
        variant: 'edit-common-currency',
        actionData: currencyDetails,
      })
    )
  }, [currencyDetails, dispatch])

  const onDelete = useCallback(() => {
    dispatch(
      openGlobalModal({
        variant: 'delete-common-currency',
        id: currencyDetails.id,
      })
    )
  }, [currencyDetails.id, dispatch])

  return (
    <StyledCommonCurrencyControlButtonCell>
      <Button size="small" variant="contained" onClick={onEdit}>
        Edit
      </Button>
      <Button size="small" variant="contained" color="error" onClick={onDelete}>
        Delete
      </Button>
    </StyledCommonCurrencyControlButtonCell>
  )
}

export default CommonCurrencyControlButtonCell
