import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../../../store'
import { Container, Tab, Tabs } from '@mui/material'
import RemittanceTopUpDetailsTab from './RemittanceTopUpDetailsTabs/RemittanceTopUpDetailsTab'

const RemittanceTopUpDetailsPage = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()

  const [tabValue, setTabValue] = useState<'details'>('details')

  // useEffect(() => {
  //   return () => {
  //     // dispatch(removeMobileUserDetails())
  //   }
  // }, [dispatch])

  const onChangeTab = useCallback((event: SyntheticEvent, newTabValue: 'details') => {
    setTabValue(newTabValue)
  }, [])
  return (
    <Container
      className="pt-25 pb-10"
      maxWidth="xl"
      sx={{ height: 'calc(100dvh - 90px)', overflowY: 'auto' }}
    >
      <Tabs value={tabValue} onChange={onChangeTab} sx={{ marginBottom: '16px' }}>
        <Tab value="details" label="Top Up Details" />
      </Tabs>
      {tabValue === 'details' && <RemittanceTopUpDetailsTab topUpId={id} />}
      {/* {tabValue === 'details' && <ProfileDetailsTab userId={id} />}
      {tabValue === 'aml' && <AmlInspectionTab userId={id} />} */}
    </Container>
  )
}

export default RemittanceTopUpDetailsPage
