import { useCallback, useEffect, useMemo, useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Switch from '@mui/material/Switch'
import {
  FlowState,
  FlowType,
  EarlyAccessForm,
  PostFlowStateRequest,
} from '../../../types/remittanceTypes'
import { useAppDispatch, useAppSelector } from '../../../store'
import { remittanceActions } from '../../../store/actions/kwlActions'
import { useForm } from 'react-hook-form'
import TextFieldForm from '../../../components/reusable/form/TextFieldForm'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import debounce from 'lodash/debounce'

type FlowListState = {
  [FlowType.MaintenanceMode]: FlowState
  [FlowType.EarlyAccess]: FlowState
  [FlowType.CBZSanction]: FlowState
}

type EditingFlowsProps = {
  instanceId: string | undefined
}

const EditingFlows = ({ instanceId }: EditingFlowsProps) => {
  const dispatch = useAppDispatch()

  const { flowList: instanceFlowList } = useAppSelector(state => state.kwl)

  const [flowList, setFlowList] = useState<FlowListState>({
    [FlowType.MaintenanceMode]: FlowState.Off,
    [FlowType.EarlyAccess]: FlowState.Off,
    [FlowType.CBZSanction]: FlowState.Off,
  })

  const { handleSubmit, setValue, control } = useForm<EarlyAccessForm>({})

  const setLocalState = useCallback(() => {
    const currentFlowList = {}

    for (const item of instanceFlowList) {
      currentFlowList[item.type] = item.state
    }

    setFlowList(prevState => ({ ...prevState, ...currentFlowList }))
  }, [instanceFlowList])

  const setPasswordFieldValue = useCallback(() => {
    const details = instanceFlowList.find(
      flowItem => flowItem.type === FlowType.EarlyAccess
    )?.details

    setValue('password', details ? details.password : '')
  }, [instanceFlowList, setValue])

  useEffect(() => {
    if (instanceFlowList.length > 0) {
      setLocalState()
      setPasswordFieldValue()
    }
  }, [instanceFlowList.length, setLocalState, setPasswordFieldValue])

  useEffect(() => {
    if (instanceId) {
      dispatch(remittanceActions.getFlowList(instanceId))
    }
  }, [dispatch, instanceId])

  const updateFlowState = useCallback(
    (arg: PostFlowStateRequest) => {
      dispatch(remittanceActions.postFlowState(arg))
    },
    [dispatch]
  )

  const switchDebounceFn = useMemo(() => debounce(updateFlowState, 300), [updateFlowState])

  const onSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked ? FlowState.On : FlowState.Off
      const arg = {
        id: instanceId,
        flowType: Number(event.target.name),
        flowState: checked,
      }

      switchDebounceFn(arg)
    },
    [instanceId, switchDebounceFn]
  )

  const onSubmit = (data: EarlyAccessForm) => {
    const arg = { id: instanceId, body: data }

    dispatch(remittanceActions.postEarlyAccessPassword(arg))
  }

  return (
    <>
      {instanceFlowList.length > 0 ? (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(flowList[FlowType.MaintenanceMode])}
                onChange={onSwitchChange}
                name={String(FlowType.MaintenanceMode)}
              />
            }
            label="Maintenance mode"
          />
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(flowList[FlowType.EarlyAccess])}
                onChange={onSwitchChange}
                name={String(FlowType.EarlyAccess)}
              />
            }
            label="Early Access"
          />
          {Boolean(flowList[FlowType.EarlyAccess]) && (
            <Box pt={1} width={300} display="flex">
              <form
                style={{ marginRight: '20px' }}
                id="change-early-access-password"
                onSubmit={handleSubmit(onSubmit)}
              >
                <TextFieldForm<EarlyAccessForm>
                  name="password"
                  control={control}
                  label="Password"
                  type="text"
                  inputMode="text"
                  validMask={/[^a-zA-Z0-9]/}
                />
              </form>
              <Button
                sx={{ height: '40px' }}
                form="change-early-access-password"
                size="small"
                variant="contained"
                type="submit"
              >
                Change
              </Button>
            </Box>
          )}
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(flowList[FlowType.CBZSanction])}
                onChange={onSwitchChange}
                name={String(FlowType.CBZSanction)}
              />
            }
            label="CBZ Sanction Screening"
          />
        </FormGroup>
      ) : null}
    </>
  )
}

export default EditingFlows
