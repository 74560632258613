import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  FETCH_TOP_UP_CASHRAIL_LIST,
  FETCH_TOP_UP_KWL_LIST,
  FETCH_TOP_UP_USDK_LIST,
} from './actionTypes'

import apiRequestServiceWithRefresh from '../../services/apiRequestServiceWithRefresh'
import {
  apiCancelRemittanceTopUp,
  apiGetTopUpRemittanceDetailsPath,
  apiRefundRemittanceTopUp,
  apiTopUpCashrailPath,
  apiTopUpKWLPath,
  apiTopUpUsdkPath,
  getWpKwlCardPaymentListPath,
} from '../../utils/apiPaths'
import { TopUpCashrailDataType, TopUpKWLDataType, TopUpUsdkDataType } from '../../types/topUpTypes'
import { RequestErrType, ResultListType } from '../../types/generalTypes'
import { AlertColor } from '@mui/material'
import { addNotification } from '../reducers/notificationReducer'
import { WorldPayState } from '../reducers/wpReducer'
import { worldPayActions } from './wpActions'
import { AxiosError } from 'axios'
import { CustomErrorResponse } from '../../types/apiTypes'

export const fetchTopUpUsdkList = createAsyncThunk<
  ResultListType<TopUpUsdkDataType>,
  string,
  { rejectValue: RequestErrType }
>(FETCH_TOP_UP_USDK_LIST, async (queryParams: string, { rejectWithValue, dispatch }) => {
  try {
    const result = await apiRequestServiceWithRefresh(
      dispatch,
      'get',
      `${apiTopUpUsdkPath}?${queryParams}`
    )

    if (result) return result
  } catch (err) {
    return rejectWithValue(err as RequestErrType)
  }
})

export const fetchTopUpCashrailList = createAsyncThunk<
  ResultListType<TopUpCashrailDataType>,
  string,
  { rejectValue: RequestErrType }
>(FETCH_TOP_UP_CASHRAIL_LIST, async (queryParams: string, { rejectWithValue, dispatch }) => {
  try {
    const result = await apiRequestServiceWithRefresh(
      dispatch,
      'get',
      `${apiTopUpCashrailPath}?${queryParams}`
    )

    if (result) return result
  } catch (err) {
    return rejectWithValue(err as RequestErrType)
  }
})

export const fetchTopUpKWLList = createAsyncThunk<
  ResultListType<TopUpKWLDataType>,
  string,
  { rejectValue: RequestErrType }
>(FETCH_TOP_UP_KWL_LIST, async (queryParams: string, { rejectWithValue, dispatch }) => {
  try {
    const result = await apiRequestServiceWithRefresh(
      dispatch,
      'get',
      `${apiTopUpKWLPath}?${queryParams}`
    )

    if (result) return result
  } catch (err) {
    return rejectWithValue(err as RequestErrType)
  }
})

const getRemittanceTopUpDetails = createAsyncThunk(
  'topUp/getRemittanceTopUpDetails',
  async (remittanceId: string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        apiGetTopUpRemittanceDetailsPath(remittanceId)
      )

      return result
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const cancelRemittanceTopUp = createAsyncThunk(
  'topUp/cancelKwlTopUp',
  async (topUpId: string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'post',
        apiCancelRemittanceTopUp(topUpId)
      )

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'The top up was cancelled successfully',
      }

      dispatch(addNotification(notificationData))

      return result
    } catch (err) {
      // if (errorData.code === 400) {
      //   const notificationData = {
      //     open: true,
      //     type: 'error' as AlertColor,
      //     message: errorData.message,
      //     errorCode: errorData.errorCode,
      //   }

      //   dispatch(addNotification(notificationData))
      // }

      return rejectWithValue('error')
    }
  }
)

const refundRemittanceTopUp = createAsyncThunk(
  'topUp/refundRemittanceTopUp',
  async (topUpId: string, { rejectWithValue, dispatch, getState }) => {
    const { worldPay } = getState() as { worldPay: WorldPayState }
    const remittanceCardPaymentId = worldPay.kwlCardPaymentDetail?.remittanceCardPaymentId

    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'post',
        apiRefundRemittanceTopUp(topUpId)
      )

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'The top up was refunded successfully',
      }

      dispatch(addNotification(notificationData))

      remittanceCardPaymentId &&
        dispatch(worldPayActions.getKwlCardPaymentDetail(remittanceCardPaymentId))

      return result
    } catch (err) {
      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse

      if (errorData.code === 400) {
        const notificationData = {
          open: true,
          type: 'error' as AlertColor,
          message: errorData.message,
          errorCode: errorData.errorCode,
        }

        dispatch(addNotification(notificationData))
      }

      return rejectWithValue('error')
    }
  }
)

const getRemittanceCardPaymentTxList = createAsyncThunk(
  'topUp/getCardPaymentTxList',
  async (topUpId: string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        `${getWpKwlCardPaymentListPath}?topUpExtId=${topUpId}`
      )
      return result
    } catch (err) {
      return rejectWithValue('error')
    }
  }
)

export const topUpActions = {
  getRemittanceTopUpDetails,
  cancelRemittanceTopUp,
  refundRemittanceTopUp,
  getRemittanceCardPaymentTxList,
}
