import { createAsyncThunk } from '@reduxjs/toolkit'
import apiRequestServiceWithRefresh from '../../services/apiRequestServiceWithRefresh'
import { apiGenerateAgentGroupApiKey, apiGetAgentGroupKey, apiAgentGroupsPath } from '../../utils/apiPaths'
import { RequestErrType } from '../../types/generalTypes'
import { GenerateAgentGroupApiKeyRequest, GetAgentGroupListRequest } from '../../types/agentGroupTypes'
import { closeGlobalModal } from '../reducers/globalModalReducer'
import { CustomErrorResponse } from '../../types/apiTypes'
import { AxiosError } from 'axios'
import { AlertColor } from '@mui/material'
import { addNotification } from '../reducers/notificationReducer'

const getAgentGroupKeys = createAsyncThunk(
  'agentGroups/getKeys',
  async (agentGroupId: number | string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        apiGetAgentGroupKey(agentGroupId)
      )

      return result
    } catch (err) {
      return rejectWithValue(err as RequestErrType)
    }
  }
)

const generateAgentGroupApiKey = createAsyncThunk(
  'agentGroup/generateAgentGroupApiKey',
  async (arg: GenerateAgentGroupApiKeyRequest, { rejectWithValue, dispatch }) => {
    const { agentGroupId, body } = arg

    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'post',
        apiGenerateAgentGroupApiKey(agentGroupId),
        body
      )
      dispatch(closeGlobalModal())

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'The Api Key was generated successfully',

      }

      dispatch(addNotification(notificationData))

      return result
    } catch (err) {

      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse
      if (errorData.code === 400) {
        const notificationData = {
          open: true,
          type: 'error' as AlertColor,
          message: errorData.message,
          errorCode: errorData.errorCode,
        }
  
        dispatch(addNotification(notificationData))
      }
      
      
      
      return rejectWithValue(errorData as CustomErrorResponse)
    }
  }
)

const getAgentGroupList = createAsyncThunk(
  'agentGroup/getAgentGroupList',
  async (arg: GetAgentGroupListRequest, { rejectWithValue, dispatch }) => {
    const { queryParams } = arg

    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        `${apiAgentGroupsPath}?${queryParams}`,
      )

      return result
    } catch (err) {

      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse
      // if (errorData.code === 400) {
      //   const notificationData = {
      //     open: true,
      //     type: 'error' as AlertColor,
      //     message: errorData.message,
      //     errorCode: errorData.errorCode,
      //   }
  
      //   dispatch(addNotification(notificationData))
      // }
      
      
      
      return rejectWithValue(errorData as CustomErrorResponse)
    }
  }
)



export const agentGroupsActions = {
  getAgentGroupKeys,
  generateAgentGroupApiKey,
  getAgentGroupList,
}
