import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../store'
import { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { agentGroupsActions } from '../../store/actions/agentGroupsActions'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Container from '@mui/material/Container'
import AgentGroupApiKeysTab from './AgentGroupApiKeysTab'

const AgentGroupDetailPage = () => {
  const { id } = useParams()

  const [tabValue, setTabValue] = useState<'api-keys'>('api-keys')

  const onChangeTab = useCallback((event: SyntheticEvent, newTabValue: 'api-keys') => {
    setTabValue(newTabValue)
  }, [])

  return (
    <Container className="pt-25 pb-10 h-100-percent" maxWidth="xl">
      <Tabs
        value={tabValue}
        onChange={onChangeTab}
        sx={{ marginBottom: '16px' }}
      >
        <Tab value="api-keys" label="API keys" />
        {/* <Tab value="beneficiary-settings" label="Beneficiary Settings" /> */}
        {/* <Tab label="Item Three" /> */}
      </Tabs>
      {tabValue === 'api-keys' && <AgentGroupApiKeysTab agentGroupId={id} />}
    </Container>
  )
}

export default AgentGroupDetailPage
