import * as yup from 'yup'

const changeBlockingRecordStatus = yup.object({
  reason: yup
    .string()
    .typeError('Please, enter reason.')
    .trim()
    .min(3, `Please, enter reason.`)
    .required('Please, enter reason.'),
})

const createBlockingRecordStatus = yup
  .object({
    reason: yup
      .string()
      .typeError('Please, enter reason.')
      .trim()
      .min(3, `Please, enter reason.`)
      .required('Please, enter reason.'),
    kuvaCashProject: yup.boolean(),
    kuvaLocalProject: yup.boolean(),
    kuvaWhiteLabelProject: yup.boolean(),
  })
  .label('test')
  .test('OneOfAll', 'Please, select projects', fields => {
    if (fields.kuvaCashProject || fields.kuvaLocalProject || fields.kuvaWhiteLabelProject) {
      return true
    }
    return new yup.ValidationError('Please, select projects', null, 'checkboxSection')
  })

export const blockingRecordYupSchemes = {
  changeBlockingRecordStatus,
  createBlockingRecordStatus,
}
