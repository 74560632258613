import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { closeGlobalModal } from '../../store/reducers/globalModalReducer'
import styled from '@emotion/styled'
import {
  StyledCloseButtonModal,
  StyledDialog,
  StyledDialogActions,
  StyledDialogBody,
  StyledDialogContent,
  StyledDialogContentText,
  StyledDialogHeader,
  StyledDialogTitle,
} from './styledModalComponent'
import CancelIcon from '@mui/icons-material/Cancel'
import { Button } from '@mui/material'
import { topUpActions } from '../../store/actions/topUpActions'

const StyledDialogModalTitle = styled(StyledDialogTitle)({
  width: '300px',
})

type TopUpCancellingDialogModalProps = {
  isOpenDialog: boolean
}

const TopUpCancellingDialogModal = ({ isOpenDialog }: TopUpCancellingDialogModalProps) => {
  const dispatch = useAppDispatch()
  const { actionData: remittanceId } = useAppSelector(state => state.globalModal)

  const closeModal = useCallback(() => {
    dispatch(closeGlobalModal())
  }, [dispatch])

  const onConfirm = useCallback(() => {
    remittanceId && dispatch(topUpActions.cancelRemittanceTopUp(remittanceId))

    closeModal()
  }, [closeModal, dispatch, remittanceId])

  return (
    <StyledDialog open={isOpenDialog} onClose={closeModal}>
      <StyledDialogHeader>
        <StyledDialogModalTitle>Top Up Cancelling</StyledDialogModalTitle>
        <StyledCloseButtonModal onClick={closeModal}>
          Close
          <CancelIcon />
        </StyledCloseButtonModal>
      </StyledDialogHeader>
      <StyledDialogBody>
        <StyledDialogContent>
          <StyledDialogContentText>
            Are you sure you want to cancel this top-up?
          </StyledDialogContentText>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button size="large" variant="outlined" onClick={closeModal}>
            Close
          </Button>
          <Button size="large" variant="contained" onClick={onConfirm}>
            Yes, Cancel
          </Button>
        </StyledDialogActions>
      </StyledDialogBody>
    </StyledDialog>
  )
}

export default TopUpCancellingDialogModal
