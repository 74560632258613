import { SyntheticEvent, useCallback } from 'react'
import { useAppDispatch } from '../../store'
import { GlobalModalVariant } from '../../types/globalModalTypes'
import { Button } from '@mui/material'
import { openGlobalModal } from '../../store/reducers/globalModalReducer'

type ModalActivationButtonProps = {
  textBtn: string
  variant: GlobalModalVariant
  id: string | number
}

const ModalActivationButton = ({ variant, id, textBtn }: ModalActivationButtonProps) => {
  const dispatch = useAppDispatch()

  const activateModal = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation()

      dispatch(openGlobalModal({ variant, id }))
    },
    [dispatch, id, variant]
  )

  return (
    <Button variant="contained" size="small" onClick={activateModal}>
      {textBtn}
    </Button>
  )
}

export default ModalActivationButton
