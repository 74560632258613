import { Box, Button, Paper, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { riskLevelMarkerList, trimObjectFields } from '../../../../utils/commonUtils'
import TextFieldForm from '../../../../components/reusable/form/TextFieldForm'
import SelectFieldForm from '../../../../components/reusable/form/SelectFieldForm'
import { useAppDispatch } from '../../../../store'
import { remittanceActions } from '../../../../store/actions/kwlActions'
import { yupResolver } from '@hookform/resolvers/yup'
import { remittanceYupSchemes } from '../../../../utils/yupScheme/remittanceYupSchemes'
import isEmpty from 'lodash/isEmpty'

type SettingUserExpirationFormType = {
  userRiskLevelMarker: number
  months: number
}

export const StyledSettingUserExpirationForm = styled(Paper)({
  width: 'calc(50% - 5px)',
  padding: '10px',
  backgroundColor: '#ffffff',
  '.MuiBox-root': {
    display: 'flex',
    gridGap: '8px',
    marginBottom: '15px',
    p: {
      fontSize: '18px',
    },
  },
})

const SettingUserExpirationForm = () => {
  const dispatch = useAppDispatch()
  const [isDisabledButton, setIsDisabledButton] = useState(false)

  const { handleSubmit, formState, setValue, control } = useForm<SettingUserExpirationFormType>({
    resolver: yupResolver(remittanceYupSchemes.settingUserExpirationForm),
  })

  useEffect(() => {
    if (isEmpty(formState.errors)) {
      setIsDisabledButton(false)
    } else {
      setIsDisabledButton(true)
    }
  }, [formState, setIsDisabledButton])

  const onSubmit = (data: SettingUserExpirationFormType) => {
    const trimmedData = trimObjectFields(data)

    const body = {
      userRiskLevelMarker: Number(trimmedData.userRiskLevelMarker),
      months: Number(trimmedData.months),
    }

    dispatch(remittanceActions.setExpirationEntryForUsersByRiskLevel(body))
  }

  return (
    <StyledSettingUserExpirationForm>
      <Box>
        <Typography fontWeight="bold">Expire Verification older then</Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextFieldForm<SettingUserExpirationFormType>
          name="months"
          control={control}
          label="Months"
          type="tel"
          inputMode="numeric"
          maxLength={2}
          validMask={/[^0-9]/}
        />
        <SelectFieldForm<SettingUserExpirationFormType>
          name="userRiskLevelMarker"
          control={control}
          label="Risk Level Marker"
          options={riskLevelMarkerList}
          multiple={false}
        />
        <div className="flex j-content-end">
          <Button size="small" variant="contained" type="submit" disabled={isDisabledButton}>
            Update
          </Button>
        </div>
      </form>
    </StyledSettingUserExpirationForm>
  )
}

export default SettingUserExpirationForm
