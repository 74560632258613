import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { CommonCurrencyFormType, Currency } from '../../../../../types/remittanceTypes'
import { Stack, styled } from '@mui/material'
import TextFieldForm from '../../../../../components/reusable/form/TextFieldForm'
import { yupResolver } from '@hookform/resolvers/yup'
import { remittanceYupSchemes } from '../../../../../utils/yupScheme/remittanceYupSchemes'
import { trimObjectFields } from '../../../../../utils/commonUtils'

const StyledCommonCurrencyForm = styled('form')({
  padding: '12px 0',

  '.MuiGrid-item': {
    display: 'flex',
    '&.fixed-value-block': {
      padding: '8px 16px 0 16px',
      '.MuiTextField-root': {},
    },
    '&.percent-value-block': {
      padding: '8px 0 0 32px',
    },
    '.MuiCheckbox-root': {
      marginLeft: '12px',
      paddingTop: 0,
    },

    '.MuiFormControl-root': {
      width: 'initial',
      '.MuiInput-input': {
        padding: '1px 0 0 5px',
      },
    },
    '.MuiFormControlLabel-root': {
      alignItems: 'start',
    },
  },
})

type CommonCurrencyFormProps = {
  data: Currency
  onConfirm: (dataForm: CommonCurrencyFormType) => void
  variant: 'add-common-currency' | 'edit-common-currency'
}

const CommonCurrencyForm = ({ data, onConfirm, variant }: CommonCurrencyFormProps) => {
  const { handleSubmit, setValue, control } = useForm<CommonCurrencyFormType>({
    resolver: yupResolver(remittanceYupSchemes.commonCurrencyForm),
  })

  const setFormValue = useCallback(() => {
    setValue('id', data.id)
    setValue('sign', data.sign)
    setValue('displayName', data.displayName)
  }, [data?.displayName, data?.id, data?.sign, setValue])

  useEffect(() => {
    if (variant === 'edit-common-currency' && data) {
      setFormValue()
    }
  }, [data, setFormValue, variant])

  const onSubmit = (data: CommonCurrencyFormType) => {
    const trimmedData = trimObjectFields(data)
    const upperCaseSign = trimmedData.sign.toUpperCase()
    const upperCaseId = trimmedData.id.toUpperCase()
    const modifiedData = {
      ...trimmedData,
      id: upperCaseId,
      sign: upperCaseSign,
    }

    onConfirm(modifiedData)
  }

  return (
    <StyledCommonCurrencyForm id="instance-currency-form" onSubmit={handleSubmit(onSubmit)}>
      <Stack justifyContent="space-between">
        <TextFieldForm<CommonCurrencyFormType>
          name="id"
          control={control}
          label="Id"
          type="text"
          inputMode="text"
          variant="standard"
          autoComplete="off"
          validMask={/[^a-zA-Z]/}
          maxLength={3}
          disabled={variant === 'edit-common-currency'}
        />
        <TextFieldForm<CommonCurrencyFormType>
          name="sign"
          control={control}
          label="Sign"
          type="text"
          inputMode="text"
          variant="standard"
          autoComplete="off"
          maxLength={5}
        />
        <TextFieldForm<CommonCurrencyFormType>
          name="displayName"
          control={control}
          label="Display Name"
          type="text"
          inputMode="text"
          variant="standard"
          autoComplete="off"
          validMask={/[^a-zA-Z ]/}
          maxLength={32}
        />
      </Stack>
    </StyledCommonCurrencyForm>
  )
}

export default CommonCurrencyForm
