import { styled } from '@mui/material/styles'
import { memo, useCallback, useState } from 'react'
import { TableConfig } from '../../../../../types/tableTyles'
import { Collapse } from '@mui/material'

type StyledRowCellProps = {
  width: {
    minWidth: string
    width: string
  }
}

export const StyledRowCell = styled('div', {
  shouldForwardProp: prop => prop !== 'width',
})<StyledRowCellProps>(({ width }) => ({
  width: width.width,
  minWidth: width.minWidth,
  padding: '0 15px',
  textAlign: 'center',
}))

type StyledTableRowWithCollapsingProps = {
  rowIndex: number
}

export const StyledTableRowWithCollapsing = styled('div')<StyledTableRowWithCollapsingProps>(
  ({ theme, rowIndex }) => ({
    '.table-row': {
      display: 'flex',
      alignItems: 'center',
      minHeight: '55px',
      lineHeight: '28px',
      minWidth: 'fit-content',
      width: '100%',

      backgroundColor: rowIndex % 2 ? 'white' : 'whitesmoke',
      borderBottom: rowIndex % 2 ? 'none' : '1px solid rgba(224, 224, 224, 1)',
      borderTop: rowIndex % 2 ? 'none' : '1px solid rgba(224, 224, 224, 1)',

      '&:hover': {
        backgroundColor: '#B8B8B8',
        cursor: 'pointer',
      },

      '#email, #phoneNumber, #walletAddressUsd, #title': {
        wordBreak: 'break-all',
      },
      '#collapsing-icon svg': {
        verticalAlign: 'middle',
      }
    },
  })
)

type TableRowWithCollapsingProps<T> = {
  rowData: T
  rowIndex: number
  tableConfig: TableConfig<T>
  CollapsibleComponent: (props: { itemRow: T }) => JSX.Element
}

const TableRowWithCollapsing = <T extends { extId?: string | number; id?: string | number }>({
  rowData,
  tableConfig,
  rowIndex,
  CollapsibleComponent,
}: TableRowWithCollapsingProps<T>) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleCollapsingRow = useCallback(() => {
    setIsOpen(prevState => !prevState)
  }, [])
  return (
    <StyledTableRowWithCollapsing rowIndex={rowIndex}>
      <div className="table-row" onClick={toggleCollapsingRow}>
        {tableConfig.columns.map(column => (
          <StyledRowCell id={column.id} key={column.id} className="table-item" width={column.width}>
            {column.contentColumn(rowData) === null
              ? column.contentColumnWithChangingIcon && column.contentColumnWithChangingIcon(isOpen)
              : column.contentColumn(rowData)}
          </StyledRowCell>
        ))}
      </div>
      <Collapse
        in={isOpen}
        timeout="auto"
        unmountOnExit
        sx={{ width: '100%', minWidth: '1175px', backgroundColor: '#b0bec5' }}
      >
        <CollapsibleComponent itemRow={rowData} />
      </Collapse>
    </StyledTableRowWithCollapsing>
  )
}

export default memo(TableRowWithCollapsing) as typeof TableRowWithCollapsing
