import { useCallback, useEffect, useMemo, useState } from 'react'
import Container from '@mui/material/Container'
import LazyLoadingTable from '../../../components/CustomTable/LazyLoadingTable'
import Typography from '@mui/material/Typography'
import { useAppDispatch, useAppSelector } from '../../../store'
import { worldPayActions } from '../../../store/actions/wpActions'
import { DatePicker } from 'antd'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import {
  CardPaymentStatus,
  KwlCardPaymentFilterForm,
  KwlCardPaymentFilters,
  WorldPayPaymentType,
  WpKwlItem,
} from '../../../types/wpTypes'
import getQueryString from '../../../helpers/getQueryString'
import concatenateObjProps from '../../../helpers/concatenateObjProps'
import { removeKwlCardPayments } from '../../../store/reducers/wpReducer'
import wpTableConfigs from '../../../utils/config/tableConfig/wp'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { wpForKwlPath } from '../../../utils/appPaths'
import moment, { MomentInput } from 'moment'
import KwlCardPaymentFilter from './KwlCardPaymentFilter'
import TagsForFilter, { FilterTag } from '../../../components/reusable/tagsForFilter/TagsForFilter'
import { Chip } from '@mui/material'
import { forEach, isArray, isEmpty, isObject, omit } from 'lodash'

const kwlCardPaymentFilterTagConfig = {
  userQuery: 'User Data:',
  topUpReference: 'Top Up Ref:',
  statuses: 'Status:',
  'amounts.min': 'Min Amount:',
  'amounts.max': 'Max Amount:',
  reference: 'Payment Ref:',
  paymentType: 'Status:',
}

const { RangePicker } = DatePicker

const WPKuvaWhiteLabelPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { isMobileView } = useAppSelector(state => state.display)
  const { items: tableData, hasNextPage } = useAppSelector(
    state => state.worldPay.kwlCardPaymentList
  )
  const { isLoading, error } = useAppSelector(state => state.worldPay)

  const pageSize = 15
  const offset = 0

  const [isShowFilter, setIsShowFilter] = useState(false)
  const [filterList, setFilterList] = useState<KwlCardPaymentFilters>({
    userQuery: '',
    topUpReference: '',
    statuses: [],
    dates: { startDate: '', endDate: '' },
    amounts: { min: '', max: '' },
    reference: '',
    paymentType: [],
  })

  useEffect(() => {
    const currentParams = getQueryString(concatenateObjProps({ offset, pageSize }))
    dispatch(worldPayActions.getKwlCardPayments({ queryParams: currentParams, offset }))

    return () => {
      dispatch(removeKwlCardPayments())
    }
  }, [dispatch])

  const tableConfig = useMemo(() => {
    return wpTableConfigs.wpKuvaWhiteLabelTableConfig()
  }, [])

  const onLoadMore = async () => {
    const currentOffset = offset + tableData.length
    const currentParams = getQueryString(
      concatenateObjProps({ ...filterList, offset: currentOffset, pageSize })
    )

    await dispatch(
      worldPayActions.getKwlCardPayments({ queryParams: currentParams, offset: currentOffset })
    )
  }

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  })

  const getCardPaymentWithFilter = useCallback(
    (data: KwlCardPaymentFilterForm) => {
      const updatedFilterList = { ...filterList, ...data }
      const currentParams = getQueryString(
        concatenateObjProps({ ...updatedFilterList, offset, pageSize })
      )

      dispatch(worldPayActions.getKwlCardPayments({ queryParams: currentParams, offset }))
    },
    [dispatch, filterList]
  )

  const clearFilters = useCallback(async () => {
    let queryString = { offset, pageSize, dates: { ...filterList.dates } }

    const currentParams = getQueryString(concatenateObjProps(queryString))

    await dispatch(worldPayActions.getKwlCardPayments({ queryParams: currentParams, offset }))

    setFilterList(prevState => ({
      ...prevState,
      userQuery: '',
      topUpReference: '',
      statuses: [],
      amounts: { min: '', max: '' },
      reference: '',
      paymentType: [],
    }))
  }, [dispatch, filterList.dates])

  const onChangeDate = async (val: [MomentInput, MomentInput] | null) => {
    let queryString = { ...filterList, offset, pageSize }
    let startDate: string, endDate: string

    if (val) {
      startDate = moment(val[0]).format('MM-DD-YYYY') + 'Z'
      endDate = moment(val[1]).format('MM-DD-YYYY') + 'Z'

      queryString = {
        ...queryString,
        dates: { startDate, endDate },
      }
    } else {
      queryString.dates.startDate = queryString.dates.endDate = ''

      startDate = endDate = ''
    }

    const currentParams = getQueryString(concatenateObjProps(queryString))

    await dispatch(worldPayActions.getKwlCardPayments({ queryParams: currentParams, offset }))

    setFilterList(prevState => ({
      ...prevState,
      dates: { startDate, endDate },
    }))
  }

  const onClickRow = useCallback(
    (id: string | number) => {
      navigate(`${wpForKwlPath}/${id}`)
    },
    [navigate]
  )

  const onOpenFilter = useCallback(() => {
    setIsShowFilter(true)
  }, [])

  const onDeleteTag = useCallback(
    async (deletedFilterItem: FilterTag) => {
      let modifiedFilterList = { ...filterList }

      if (deletedFilterItem.nameState === 'statuses') {
        const updatedStatuses = filterList.statuses.filter(
          status => status !== CardPaymentStatus[deletedFilterItem.valueField]
        )

        modifiedFilterList = { ...modifiedFilterList, statuses: updatedStatuses }
      }

      if (deletedFilterItem.nameState === 'paymentType') {
        const updatedPaymentTypes = filterList.paymentType.filter(
          paymentType => paymentType !== WorldPayPaymentType[deletedFilterItem.valueField]
        )

        modifiedFilterList = { ...modifiedFilterList, paymentType: updatedPaymentTypes }
      }

      if (deletedFilterItem.nameState === 'amounts.min') {
        modifiedFilterList.amounts.min = ''
      }

      if (deletedFilterItem.nameState === 'amounts.max') {
        modifiedFilterList.amounts.max = ''
      }

      if (
        deletedFilterItem.nameState !== 'amounts.max' &&
        deletedFilterItem.nameState !== 'amounts.min' &&
        deletedFilterItem.nameState !== 'paymentType' &&
        deletedFilterItem.nameState !== 'statuses'
      ) {
        modifiedFilterList[deletedFilterItem.nameState] = ''
      }

      const queryString = { ...modifiedFilterList, offset, pageSize }
      const currentParams = getQueryString(concatenateObjProps(queryString))

      await dispatch(worldPayActions.getKwlCardPayments({ queryParams: currentParams, offset }))

      setFilterList(prevState => ({ ...prevState, ...modifiedFilterList }))
    },
    [dispatch, filterList]
  )

  const modifiedFilterList = useMemo(() => {
    let newList = []

    for (const prop in filterList) {
      if (!filterList[prop] || isEmpty(filterList[prop]) || prop === 'dates') {
        continue
      }

      if (!isArray(filterList[prop]) && isObject(filterList[prop])) {
        for (const nestedProp in filterList[prop]) {
          filterList[prop][nestedProp] &&
            newList.push({
              nameState: `${prop}.${nestedProp}`,
              nameField: kwlCardPaymentFilterTagConfig[`${prop}.${nestedProp}`],
              valueField: filterList[prop][nestedProp],
            })
        }
      }
      if (typeof filterList[prop] === 'string') {
        newList.push({
          nameState: prop,
          nameField: kwlCardPaymentFilterTagConfig[prop],
          valueField: filterList[prop],
        })
      }

      if (isArray(filterList[prop]) && prop === 'statuses') {
        filterList[prop].forEach(status =>
          newList.push({
            nameState: prop,
            nameField: kwlCardPaymentFilterTagConfig[prop],
            valueField: CardPaymentStatus[status],
          })
        )
      }

      if (isArray(filterList[prop]) && prop === 'paymentType') {
        filterList[prop].forEach(paymentType =>
          newList.push({
            nameState: prop,
            nameField: kwlCardPaymentFilterTagConfig[prop],
            valueField: WorldPayPaymentType[paymentType],
          })
        )
      }
    }
    return newList
  }, [filterList])

  return (
    <Container className="pt-25 pb-10 h-100-percent overflow-hidden" maxWidth="xl">
      <h3 className="mb-25">Kuva White Label Card Payment</h3>
      <div className={`flex j-content-space-between ${isMobileView ? 'flex-dir-column' : ''}`}>
        <RangePicker className="mb-20" onChange={onChangeDate} />
        <div className="mb-20 align-self-end">
          <Button className="w-130 mr-15" variant="outlined" size="small" onClick={onOpenFilter}>
            Show Filters
          </Button>

          <Button
            className="w-130"
            variant="outlined"
            size="small"
            onClick={clearFilters}
            // disabled={isDisabled}
          >
            Clear Filters
          </Button>
        </div>
      </div>
      <TagsForFilter data={modifiedFilterList} onDeleteTag={onDeleteTag} />
      <KwlCardPaymentFilter
        isShowFilter={isShowFilter}
        setIsShowFilter={setIsShowFilter}
        filterState={filterList}
        setFilterList={setFilterList}
        getCardPaymentWithFilter={getCardPaymentWithFilter}
      />
      <LazyLoadingTable<WpKwlItem>
        tableData={tableData}
        tableConfig={tableConfig}
        sentryRef={sentryRef}
        rootRef={rootRef}
        onClick={onClickRow}
        minTableWidth="360px"
        loading={isLoading}
        hasNextPage={hasNextPage}
        maxHeight="calc(100vh - 350px)"
      />
    </Container>
  )
}

export default WPKuvaWhiteLabelPage
