import { ChangeEvent, useCallback } from 'react'
import { useController, FieldValues, UseControllerProps } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'

export const StyledTextField = styled(TextField)({
  width: '100%',
  marginBottom: '20px',
})

type TextFieldFormProps<T extends FieldValues> = UseControllerProps<T> & {
  label: string
  variant?: 'outlined' | 'standard' | 'filled' | undefined
  type: 'text' | 'tel'
  inputMode: 'text' | 'numeric'
  validMask?: RegExp
  disabled?: boolean
  multiline?: boolean
  maxLength?: number
  rows?: number
  autoComplete?: 'on' | 'off'
}

const TextFieldForm = <T extends FieldValues>({
  name,
  control,
  label,
  type,
  inputMode,
  validMask,
  disabled,
  maxLength = 32,
  multiline = false,
  variant = 'outlined',
  rows,
  autoComplete = 'on',
}: TextFieldFormProps<T>) => {
  const {
    field: { onChange, value },
    fieldState: { error: inputError },
  } = useController({
    name,
    control,
  })

  const validateValue = useCallback(
    (value: string) => {
      return validMask ? value.replace(validMask, '') : value
    },
    [validMask]
  )

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    const valueAfterValidating = validateValue(value)

    onChange(valueAfterValidating)
  }

  return (
    <StyledTextField
      color="info"
      variant={variant}
      autoComplete={autoComplete}
      type={type}
      inputMode={inputMode}
      size="small"
      inputProps={{
        maxLength: maxLength,
      }}
      label={label}
      id="test"
      value={typeof value === 'undefined' ? '' : value}
      onChange={onInputChange}
      multiline={multiline}
      {...(inputError && { error: true, helperText: inputError.message })}
      // {...(true && { error: true, helperText: 'TEST' })}
      {...(rows && { rows })}
      {...(disabled && { disabled })}
    />
  )
}

export default TextFieldForm
