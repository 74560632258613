import { Button, styled } from '@mui/material'
import React, { useCallback } from 'react'
import { AgentGroup } from '../../../../../types/remittanceTypes'
import { useAppDispatch } from '../../../../../store'
import { openGlobalModal } from '../../../../../store/reducers/globalModalReducer'

type StyledAgentGroupsControlBtnProps = {
  bgColor: string
}

const StyledAgentGroupsControlBtn = styled(Button, {
  shouldForwardProp: prop => prop !== 'bgColor',
})<StyledAgentGroupsControlBtnProps>(({ bgColor }) => ({
  backgroundColor: bgColor,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: bgColor,
    filter: 'brightness(80%)',
  },
}))
type AgentGroupAssignBtnProps = {
  agentGroupDetails: AgentGroup
  instanceId?: string
}

export const AgentGroupAssignBtn = ({
  agentGroupDetails,
  instanceId,
}: AgentGroupAssignBtnProps) => {
  const dispatch = useAppDispatch()

  const onAssignBtn = useCallback(() => {
    instanceId &&
      dispatch(
        openGlobalModal({
          variant: 'assign-agent-group-to-remittance-instance',
          id: instanceId,
          additionalId: agentGroupDetails.id,
        })
      )
  }, [agentGroupDetails.id, dispatch, instanceId])

  return (
    <StyledAgentGroupsControlBtn
      size="small"
      variant="contained"
      onClick={onAssignBtn}
      bgColor="#67BCF0"
    >
      Assign
    </StyledAgentGroupsControlBtn>
  )
}

type AgentGroupUnassignBtnProps = {
  agentGroupDetails: AgentGroup
  instanceId?: string
}

export const AgentGroupUnassignBtn = ({
  agentGroupDetails,
  instanceId,
}: AgentGroupUnassignBtnProps) => {
  const dispatch = useAppDispatch()

  const onUnassignBtn = useCallback(() => {
    instanceId &&
      instanceId &&
      dispatch(
        openGlobalModal({
          variant: 'unassign-agent-group-to-remittance-instance',
          id: instanceId,
          additionalId: agentGroupDetails.id,
        })
      )
  }, [agentGroupDetails.id, dispatch, instanceId])

  return (
    <StyledAgentGroupsControlBtn
      size="small"
      variant="contained"
      onClick={onUnassignBtn}
      bgColor="#FD8111"
    >
      Unassign
    </StyledAgentGroupsControlBtn>
  )
}
