import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Container from '@mui/material/Container'
import { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { styled } from '@mui/material'
import RiskScoresTab from './RiskScores/RiskScoresTab'
import CommonTab from './common/CommonTab'
import CurrenciesTab from './currencies/CurrenciesTab'

type PageTabs = 'risk-scores' | 'common' | 'currencies'

const StyledTabs = styled(Tabs)({
  padding: '0 10px',
  '.MuiTabs-scrollButtons': {
    width: '25px',
    '&.Mui-disabled': {
      opacity: 0.3,
    },
  },
})

const RemittanceSettings = () => {
  const { id } = useParams()

  const [tabValue, setTabValue] = useState<PageTabs>('common')
  const [queryParams, setQueryParams] = useSearchParams()

  useEffect(() => {
    if (queryParams.get('activeTab') === 'risk-scores') {
      setTabValue('risk-scores')
      return
    }

    if (queryParams.get('activeTab') === 'currencies') {
      setTabValue('currencies')
      return
    }

    setTabValue('common')
    setQueryParams({ activeTab: 'common' })
  }, [queryParams, setQueryParams])

  const onChangeTab = useCallback(
    (event: SyntheticEvent, newTabValue: PageTabs) => {
      setTabValue(newTabValue)
      setQueryParams({ activeTab: newTabValue })
    },
    [setQueryParams]
  )
  return (
    <Container
      className="pt-25 pb-10"
      maxWidth="xl"
      sx={{ height: 'calc(100dvh - 90px)', overflowY: 'auto' }}
    >
      <StyledTabs
        value={tabValue}
        onChange={onChangeTab}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        sx={{ marginBottom: '16px' }}
      >
        <Tab value="common" label="Common" />
        <Tab value="risk-scores" label="Risk Scores" />
        <Tab value="currencies" label="Currencies" />
      </StyledTabs>
      {tabValue === 'risk-scores' && (
        <RiskScoresTab
        // instanceId={id}
        />
      )}
      {tabValue === 'common' && <CommonTab />}
      {tabValue === 'currencies' && <CurrenciesTab />}
    </Container>
  )
}

export default RemittanceSettings
