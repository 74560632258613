import { collapseItems } from '../../utils/constants'

import * as path from '../../utils/appPaths'

export default (url: string) => {
  switch (url) {
    case path.kwlPath:
    case path.kwlUsersPath:
    case path.kwlInstancesPath:
    case path.kwlCountriesPath:
      return collapseItems.KWL

    case path.topUpPath:
    case path.topUpCashrailPath:
    case path.topUpKuvaCoinPath:
    case path.topUpKuvaWhiteLabelPath:
    case path.topUpUsdkPath:
      return collapseItems.TOP_UP

    case path.kuvaLocalPath:
    case path.kuvaLocalOrdersPath:
    case path.kuvaLocalSettingsPath:
    case path.kuvaLocalCouriersPath:
    case path.kuvaLocalCourierDispatchOrderPath:
    case path.kuvaLocalUsersPath:
    case path.kuvaLocalContactPath:
    case path.kuvaLocalFeedbackPath:
    case path.kuvaLocalStoresPath:
    case path.kuvaLocalCountriesPath:
    case path.kuvaLocalRefundsPath:
    case path.kuvaLocalSubmissionsPath:
    case path.kuvaLocalPromocodePath:
    case path.kuvaLocalCompaniesPath:
    case path.kuvaLocalReportPath:
    case path.kuvaLocalCreditVendorPath:
    case path.kuvaLocalMobileHomePageWidgetsPath:
    case path.kuvaLocalFacebookPath:
      return collapseItems.KUVA_LOCAL

    case path.currencyCloudPath:
    case path.currencyCloudKLRatesPath:
    case path.currencyCloudOTRatesPath:
    case path.currencyCloudTopUpRatesPath:
    case path.currencyCloudAllRatesPath:
      return collapseItems.CURRENCY_CLOUD

    case path.masterNodePath:
    case path.masterNodeAllMasterNodesPath:
    case path.masterNodeRewardsPath:
      return collapseItems.MASTER_NODE

    case path.outboundTransfersPath:
    case path.outboundTransfersTransfersPath:
    case path.outboundTransfersCountriesPath:
    case path.outboundTransfersFeesPath:
    case path.outboundTransfersRiskScoresPath:
      return collapseItems.OUTBOUND_TRANSFERS

    case path.cashOutPath:
    case path.cashOutPendingRequestsPath:
    case path.cashOutCompletedRequestsPath:
    case path.cashOutPendingKWLRequestsPath:
    case path.cashOutCompletedKWLRequestsPath:
    case path.cashOutPendingMulticashRequestsPath:
    case path.cashOutCompletedMulticashRequestsPath:
      return collapseItems.CASHOUT

    case path.paymentsPath:
    case path.paymentsTreasuryTransfersPath:
    case path.paymentsPayinsPath:
    case path.paymentsPayoutsPath:
    case path.paymentsUpholdTransactionsPath:
      return collapseItems.PAYMENTS

    case path.transactionsPath:
    case path.transactionsAllPath:
    case path.transactionsDuplicatePath:
    case path.transactionsIncorectRatePath:
    case path.transactionsUnpaidPath:
      return collapseItems.TRANSACTIONS

    case path.mobileTransfersPath:
    case path.mobileTransfersTransferTablePath:
    case path.mobileTransfersOrderTablePath:
      return collapseItems.MOBILE_TRANSFERS

    default:
      return ''
  }
}
