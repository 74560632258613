import { useState, useEffect, createContext } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'

import Router from './components/Router'
import Loader from './components/Loader'
import Layout from './components/Layout'

import { useAppSelector, useAppDispatch } from './store/index'
import { history } from './helpers/history'
import getDisplayData from './helpers/getDisplayData'

import { setDisplayData } from './store/actions/displayActions'
import { fetchTokens } from './store/actions/authActions'
import { fetchUser } from './store/actions/userActions'
import { fetchUserPermissiionList } from './store/actions/permissionActions'
import { setStartLoader, setStopLoader } from './store/actions/loaderActions'
import { UserDataType } from './types/userTypes'
import { PermissionDataType } from './types/permissionTypes'
import { loginPath, twoFaVerificationPath } from './utils/appPaths'
import CustomModal from './components/modal/CustomModal'
import { Notification } from './components/Notification'

import 'overlayscrollbars/overlayscrollbars.css'

export const MainContext = createContext({
  user: { email: '' } as UserDataType,
  userPermissionList: [] as PermissionDataType[],
  isMobileView: false,
})

const App = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [path, setPath] = useState<string>('')

  const dispatch = useAppDispatch()
  const state = useAppSelector(state => state)

  const {
    display: { isMobileView },
    auth: {
      tokens,
      tokensRefreshStart,
      tokensRefreshFinished,
      twoFactorEnabled,
      isAuth,
      confirmTwoFaFinished,
    },
    user: { data },
    permission: { userPermissionList },
    loader: { isShowLoader },
  } = state

  const { accessToken } = tokens

  const storageAccess =
    window.localStorage.getItem('tokens') &&
    JSON.parse(window.localStorage.getItem('tokens')!).accessToken

  useEffect(() => {
    getDisplayData(data => dispatch(setDisplayData(data)))

    history.listen((props: { location: { pathname: string } }) => {
      setPath(props.location.pathname)
    })
  }, [])

  useEffect(() => {
    if (isMobileView && open) setOpen(!open)
  }, [path])

  useEffect(() => {
    ;(async () => {
      if (storageAccess && !tokensRefreshStart && !tokensRefreshFinished) {
        dispatch(setStartLoader())

        if (!accessToken) {
          await dispatch(fetchTokens(null))
        }

        await dispatch(fetchUser())
        await dispatch(fetchUserPermissiionList())

        setTimeout(() => dispatch(setStopLoader()), 500)
      }
    })()
  }, [storageAccess])

  useEffect(() => {
    ;(async () => {
      if (twoFactorEnabled && !storageAccess) history.push(twoFaVerificationPath)

      if (
        // entry to app after login/two-fa
        history.location.pathname === loginPath ||
        (history.location.pathname === twoFaVerificationPath && confirmTwoFaFinished)
      )
        history.push('/')
    })()
  }, [accessToken])

  return (
    <HistoryRouter history={history}>
      <CssBaseline />
      {!isShowLoader && (
        <MainContext.Provider value={{ user: data, userPermissionList, isMobileView }}>
          {isAuth && (
            <Layout path={path}>
              <Router isAuth={isAuth} />
            </Layout>
          )}

          {!isAuth && !storageAccess && (
            <Router isAuth={!!isAuth} isTwoFa={twoFactorEnabled} isAccess={!!accessToken} />
          )}
        </MainContext.Provider>
      )}
      {isShowLoader && <Loader />}
      <CustomModal />
      <Notification />
    </HistoryRouter>
  )
}

export default App
