import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { closeGlobalModal } from '../../store/reducers/globalModalReducer'
import styled from '@emotion/styled'
import {
  StyledCloseButtonModal,
  StyledDialog,
  StyledDialogActions,
  StyledDialogBody,
  StyledDialogContent,
  StyledDialogContentText,
  StyledDialogHeader,
  StyledDialogTitle,
} from './styledModalComponent'
import CancelIcon from '@mui/icons-material/Cancel'
import { Button } from '@mui/material'
import { topUpActions } from '../../store/actions/topUpActions'

const StyledDialogModalTitle = styled(StyledDialogTitle)({
  width: '300px',
})

type TopUpRefundingDialogModalProps = {
  isOpenDialog: boolean
}

const TopUpRefundingDialogModal = ({ isOpenDialog }: TopUpRefundingDialogModalProps) => {
  const dispatch = useAppDispatch()
  const { actionData } = useAppSelector(state => state.globalModal)

  const closeModal = useCallback(() => {
    dispatch(closeGlobalModal())
  }, [dispatch])

  const onConfirm = useCallback(() => {
    actionData.remittanceId && dispatch(topUpActions.refundRemittanceTopUp(actionData.remittanceId))

    closeModal()
  }, [actionData.remittanceId, closeModal, dispatch])

  return (
    <StyledDialog open={isOpenDialog} onClose={closeModal}>
      <StyledDialogHeader>
        <StyledDialogModalTitle>Top Up Refunding</StyledDialogModalTitle>
        <StyledCloseButtonModal onClick={closeModal}>
          Close
          <CancelIcon />
        </StyledCloseButtonModal>
      </StyledDialogHeader>
      <StyledDialogBody>
        <StyledDialogContent>
          <StyledDialogContentText>
            Are you sure you want to refund this top-up? This action can not be undone!
          </StyledDialogContentText>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button size="large" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="large" variant="contained" onClick={onConfirm}>
            Confirm
          </Button>
        </StyledDialogActions>
      </StyledDialogBody>
    </StyledDialog>
  )
}

export default TopUpRefundingDialogModal
