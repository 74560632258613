import { styled } from '@mui/material/styles'

import { memo, useCallback } from 'react'
import { TableConfig } from '../../../../../types/tableTyles'

type StyledRowCellProps = {
  width: {
    minWidth: string
    width: string
  }
}

export const StyledRowCell = styled('div', {
  shouldForwardProp: prop => prop !== 'width',
})<StyledRowCellProps>(({ width }) => ({
  width: width.width,
  // wordBreak: 'break-all',
  minWidth: width.minWidth,
  padding: '0 15px',
  textAlign: 'center',
}))

type StyledTableRowProps = {
  rowIndex: number
  isExistOnClick: boolean
}

export const StyledTableRow = styled('div')<StyledTableRowProps>(
  ({ theme, rowIndex, isExistOnClick }) => ({
    display: 'flex',
    alignItems: 'center',
    minHeight: '55px',
    lineHeight: '28px',
    minWidth: 'fit-content',
    width: '100%',

    backgroundColor: 'white',
    borderBottom: rowIndex % 2 ? 'none' : '1px solid rgba(224, 224, 224, 1)',
    borderTop: rowIndex % 2 ? 'none' : '1px solid rgba(224, 224, 224, 1)',

    '&:first-of-type': {
      borderTop: 'none',
    },

    '&:hover': isExistOnClick && {
      backgroundColor: '#B8B8B8',
      cursor: 'pointer',
    },

    '#email, #phoneNumber, #walletAddressUsd, #title, #user-id': {
      wordBreak: 'break-all',
    },
  })
)

type TableRowProps<T> = {
  rowData: T
  rowIndex: number
  tableConfig: TableConfig<T>
  // sentryRef?: LegacyRef<HTMLDivElement>
  onClick?: (id: string | number) => void
  isExistOnClick: boolean
}

const TableRow = <T extends { extId?: string | number; id?: string | number }>({
  rowData,
  tableConfig,
  rowIndex,
  // sentryRef,
  onClick,
  isExistOnClick,
}: TableRowProps<T>) => {
  const onClickRow = useCallback(() => {
    if (rowData.extId) {
      onClick && onClick(rowData.extId)
      return
    }
    if (rowData.id) {
      onClick && onClick(rowData.id)
      return
    }
  }, [onClick, rowData.extId, rowData.id])

  return (
    <StyledTableRow
      className="table-row"
      rowIndex={rowIndex}
      isExistOnClick={isExistOnClick}
      {...(onClick && { onClick: onClickRow })}
    >
      {tableConfig.columns.map(column => (
        <StyledRowCell
          // ref={sentryRef}
          id={column.id}
          key={column.id}
          className="table-item"
          width={column.width}
        >
          {column.contentColumn(rowData)}
        </StyledRowCell>
      ))}
    </StyledTableRow>
  )
}

export default memo(TableRow) as typeof TableRow
