import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangeBlockingRecordStatusForm } from '../../../../../types/mobileUserTypes'
import TextFieldForm from '../../../../../components/reusable/form/TextFieldForm'
import { styled } from '@mui/material'
import { trimObjectFields } from '../../../../../utils/commonUtils'
import { blockingRecordYupSchemes } from '../../../../../utils/yupScheme/blockingRecordYupSchemes'

const StyledChangingBlockingRecordStatusForm = styled('form')({
  padding: '0 25px',
})

type ChangingBlockingRecordStatusFormProps = {
  onMainSubmit: (data: ChangeBlockingRecordStatusForm) => void
}

const ChangingBlockingRecordStatusForm = ({
  onMainSubmit,
}: ChangingBlockingRecordStatusFormProps) => {
  const { handleSubmit, control } = useForm<ChangeBlockingRecordStatusForm>({
    resolver: yupResolver(blockingRecordYupSchemes.changeBlockingRecordStatus),
  })

  const onSubmit = (data: ChangeBlockingRecordStatusForm) => {
    const trimmedData = trimObjectFields(data)

    onMainSubmit(trimmedData)
  }

  return (
    <StyledChangingBlockingRecordStatusForm
      id="changing-blocking-record-status-from"
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextFieldForm<ChangeBlockingRecordStatusForm>
        name="reason"
        control={control}
        label="Reason"
        type="text"
        inputMode="text"
        validMask={/[^a-zA-Z0-9-\s,.`'"+]/}
        multiline
        rows={3}
        maxLength={500}
      />
    </StyledChangingBlockingRecordStatusForm>
  )
}

export default ChangingBlockingRecordStatusForm
