import { Button, styled } from '@mui/material'
import React, { useCallback } from 'react'
import { CurrencyDetails } from '../../../../../types/remittanceTypes'
import { useAppDispatch } from '../../../../../store'
import { openGlobalModal } from '../../../../../store/reducers/globalModalReducer'

const StyledCurrencyControlButtonCell = styled('div')({
  display: 'flex',
  gridGap: '5px',
})

type CurrencyControlButtonCellProps = {
  currencyDetails: CurrencyDetails
  instanceId?: string
}

const CurrencyControlButtonCell = ({
  currencyDetails,
  instanceId,
}: CurrencyControlButtonCellProps) => {
  const dispatch = useAppDispatch()

  const onEditBtn = useCallback(() => {
    instanceId &&
      dispatch(
        openGlobalModal({
          variant: 'edit-instance-currency',
          id: instanceId,
          additionalId: currencyDetails.id,
        })
      )
  }, [currencyDetails.id, dispatch, instanceId])

  const onDeactivateBtn = useCallback(() => {
    dispatch(
      openGlobalModal({
        variant: 'deactivate-instance-currency',
        id: instanceId,
        additionalId: currencyDetails.id,
      })
    )
  }, [currencyDetails.id, dispatch, instanceId])

  const onActivateBtn = useCallback(() => {
    dispatch(
      openGlobalModal({
        variant: 'activate-instance-currency',
        id: instanceId,
        additionalId: currencyDetails.id,
      })
    )
  }, [currencyDetails.id, dispatch, instanceId])

  return (
    <StyledCurrencyControlButtonCell>
      <Button size="small" variant="contained" onClick={onEditBtn}>
        Edit
      </Button>
      {currencyDetails.state ? (
        <Button size="small" variant="contained" color="error" onClick={onDeactivateBtn}>
          Deactivate
        </Button>
      ) : (
        <Button size="small" variant="contained" color="success" onClick={onActivateBtn}>
          Activate
        </Button>
      )}
    </StyledCurrencyControlButtonCell>
  )
}

export default CurrencyControlButtonCell
