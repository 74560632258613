import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { mobileUserActions } from '../../../../store/actions/mobileUserActions'
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material'
import CustomTable from '../../../../components/CustomTable/CustomTable'
import { InspectionDoc, UserAmlInspection } from '../../../../types/mobileUserTypes'
import { mobileUserTableConfigs } from '../../../../utils/config/tableConfig/mobileUsers'
import VisibleAmlInspectionBox from './VisibleAmlInspectionBox'
import { removeUserAmlInspectionData } from '../../../../store/reducers/mobileUserReducer'
import { openGlobalModal } from '../../../../store/reducers/globalModalReducer'

type AmlInspectionTabProps = {
  userId: string | undefined
}

const AmlInspectionTab = ({ userId }: AmlInspectionTabProps) => {
  const dispatch = useAppDispatch()

  const {
    userInspectionList,
    userDocList,
    userVisibleInspectionList,
    isUserVisibleInspectionListLoading,
    isUserInspectionListLoading,
    isUserDocListLoading,
  } = useAppSelector(state => state.mobileUser)

  const { isUserVisibleInspectionListLoaded, isUserInspectionListLoaded, isUserDocListLoaded } =
    useAppSelector(state => state.mobileUser)

  const [isInitialLoaded, setIsInitialLoaded] = useState(false)

  useEffect(() => {
    isUserVisibleInspectionListLoaded &&
      isUserInspectionListLoaded &&
      isUserDocListLoaded &&
      !isInitialLoaded &&
      setIsInitialLoaded(true)
  }, [
    isInitialLoaded,
    isUserDocListLoaded,
    isUserInspectionListLoaded,
    isUserVisibleInspectionListLoaded,
  ])

  const userAmlInspectionsTableConfig = useMemo(() => {
    return mobileUserTableConfigs.userAmlInspectionsTableConfig()
  }, [])

  const inspectionDocListTableConfig = useMemo(() => {
    return mobileUserTableConfigs.amlInspectionDocTableConfig()
  }, [])

  useEffect(() => {
    if (userId) {
      dispatch(mobileUserActions.getAllUserInspections(userId))
      dispatch(mobileUserActions.getVisibleUserInspections(userId))
      dispatch(mobileUserActions.getUserDocs(userId))
    }

    return () => {
      dispatch(removeUserAmlInspectionData())
    }
  }, [dispatch, userId])

  const setExpirationDate = useCallback(() => {
    dispatch(openGlobalModal({ variant: 'set-exp-date-modal', actionData: userId }))
  }, [dispatch, userId])

  const isDisabledSetExpDateBtn = useMemo(
    () =>
      userVisibleInspectionList.length > 0 &&
      userVisibleInspectionList.length < 2 &&
      userVisibleInspectionList[0].level.weight === 0,
    [userVisibleInspectionList]
  )

  return isInitialLoaded ? (
    <>
      <Grid
        container
        sx={{
          margin: 0,
          padding: 0,
          width: '100%',
        }}
      >
        <Grid sx={{ padding: '10px 10px 10px 0' }} item xs={7}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gridGap: '10px',
            }}
          >
            {userVisibleInspectionList.length > 0 &&
              userVisibleInspectionList.map((userVisibleInspection, index) => (
                <VisibleAmlInspectionBox
                  userVisibleInspection={userVisibleInspection}
                  key={index}
                />
              ))}
          </Box>
        </Grid>
        <Grid sx={{ paddingLeft: 1, paddingTop: 0 }} item xs={5}>
          {userDocList.length > 0 && (
            <CustomTable<InspectionDoc>
              tableConfig={inspectionDocListTableConfig}
              tableData={userDocList.length > 0 ? userDocList : []}
            />
          )}
        </Grid>
      </Grid>
      {userVisibleInspectionList.length > 0 && (
        <Button
          size="medium"
          variant="contained"
          onClick={setExpirationDate}
          sx={{ mt: 1, mb: 1 }}
          disabled={isDisabledSetExpDateBtn}
        >
          Set Expiration Date
        </Button>
      )}
      {userInspectionList.length > 0 && (
        <CustomTable<UserAmlInspection>
          tableConfig={userAmlInspectionsTableConfig}
          tableData={userInspectionList.length > 0 ? userInspectionList : []}
        />
      )}
    </>
  ) : null
}

export default AmlInspectionTab
