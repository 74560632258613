import axios from 'axios'

type MethodType = 'post' | 'put' | 'get' | 'delete'

export default async (
  method: MethodType,
  url: string,
  payload: {} = {},
  queryParams?: Record<string, string | number>
) => {
  const extendedPayload = queryParams ? { ...payload, ...{ params: queryParams } } : payload
  const res = await axios[method](url, extendedPayload)

  return res.data
}
