import { Button, IconButton } from '@mui/material'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { mobileUserActions } from '../../../../store/actions/mobileUserActions'
import { openGlobalModal } from '../../../../store/reducers/globalModalReducer'
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined'
import { removeCurrentDocImgList } from '../../../../store/reducers/mobileUserReducer'

type GettingDocsButtonProps = {
  inspectionId: string | number
}

export const GettingDocsButton = ({ inspectionId }: GettingDocsButtonProps) => {
  const dispatch = useAppDispatch()

  const onClick = useCallback(() => {
    dispatch(mobileUserActions.getUserDocs(inspectionId))
  }, [dispatch, inspectionId])

  return (
    <Button variant="contained" size="small" onClick={onClick}>
      Get Documents
    </Button>
  )
}

type GettingImgDocButtonProps = {
  docImgList: Array<{
    imageId: number
    reviewAnswer: string
  }>
}

export const GettingImgDocButton = ({ docImgList }: GettingImgDocButtonProps) => {
  const dispatch = useAppDispatch()

  const { userIdForCurrentDocList } = useAppSelector(state => state.mobileUser)

  const [src, setScr] = useState('')

  const onClick = useCallback(async () => {
    const requests = docImgList.map(docImgItem =>
      dispatch(
        mobileUserActions.getUserDocImage({
          userId: userIdForCurrentDocList || 0,
          imageId: docImgItem.imageId,
        })
      )
    )

    Promise.all(
      requests

    ).then(() => {

      dispatch(openGlobalModal({ variant: 'doc-display-modal' }))
    })

  }, [dispatch, docImgList, userIdForCurrentDocList])

  return (
    <>
      {/* <Button variant="contained" size="small" onClick={onClick}> */}
      <IconButton size="large" color="primary" onClick={onClick}>
        <DocumentScannerOutlinedIcon />
      </IconButton>

      {/* </Button> */}
      {/* <img src={src} alt="sdfs" /> */}
    </>
  )
}

// export memo(GettingDocsButton)
