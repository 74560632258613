import { Box, Button, Paper, Typography, styled } from '@mui/material'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { trimObjectFields } from '../../../../utils/commonUtils'
import TextFieldForm from '../../../../components/reusable/form/TextFieldForm'

type StyledSettingRiskScoreByLevelBoxProps = {
  bgColor: string
}

export const StyledSettingRiskScoreByLevelBox = styled(Paper, {
  shouldForwardProp: prop => prop !== 'bgColor',
})<StyledSettingRiskScoreByLevelBoxProps>(({ bgColor }) => ({
  width: 'calc(50% - 5px)',
  padding: '10px',
  backgroundColor: bgColor,
  '.MuiBox-root': {
    display: 'flex',
    // justifyContent: 'space-between',
    gridGap: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
    marginBottom: '15px',
    p: {
      // flex: 1,
      fontSize: '18px',

      '&:last-child': {
        textAlign: 'right',
      },
    },
  },
}))

export type SettingRiskScoreByLevelFormType = {
  dailyBorder: number
  weeklyBorder: number
  amountLimit: number
  score: number
}

type SettingRiskScoreByLevelBoxProps = {
  levelItem: { level: string; name: string }
}

const SettingRiskScoreByLevelBox = ({ levelItem }: SettingRiskScoreByLevelBoxProps) => {
  const bgColor = useMemo(() => {
    if (levelItem.level === 'green') {
      return '#c8e6c9'
    }

    if (levelItem.level === 'yellow') {
      return '#ffd600'
    }

    if (levelItem.level === 'red') {
      return '#ffab91'
    }

    if (levelItem.level === 'black') {
      return '#bdbdbd'
    }

    return '#ffffff'
  }, [levelItem.level])

  const onSubmit = (data: SettingRiskScoreByLevelFormType) => {
    const trimmedData = trimObjectFields(data)


    // setFilterList(prevState => ({ ...prevState, ...trimmedData }))
    // getCardPaymentWithFilter(trimmedData)
  }

  const {
    handleSubmit,
    // formState: { errors, isValid },
    setValue,
    control,
  } = useForm<SettingRiskScoreByLevelFormType>({})

  return (
    <StyledSettingRiskScoreByLevelBox elevation={3} bgColor={bgColor}>
      {/* <Box>
    <Typography fontWeight="bold">Id:</Typography>
    <Typography>545345</Typography>
  </Box> */}
      {/* <Box>
    <Typography fontWeight="bold">Created Date:</Typography>
    <Typography>2023-32-32 10:54:56</Typography>
  </Box> */}

      <Box>
        <Typography fontWeight="bold">Level:</Typography>
        <Typography>{levelItem.name}</Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextFieldForm<SettingRiskScoreByLevelFormType>
          name="dailyBorder"
          control={control}
          label="Daily Border"
          type="tel"
          inputMode="numeric"
          maxLength={9}
          validMask={/[^0-9]/}
        />
        <TextFieldForm<SettingRiskScoreByLevelFormType>
          name="weeklyBorder"
          control={control}
          label="Weekly Border"
          type="tel"
          inputMode="numeric"
          maxLength={9}
          validMask={/[^0-9]/}
        />
        <TextFieldForm<SettingRiskScoreByLevelFormType>
          name="amountLimit"
          control={control}
          label="Amount Limit"
          type="tel"
          inputMode="numeric"
          maxLength={9}
          validMask={/[^0-9]/}
        />
        <TextFieldForm<SettingRiskScoreByLevelFormType>
          name="score"
          control={control}
          label="Score"
          type="tel"
          inputMode="numeric"
          maxLength={4}
          validMask={/[^0-9]/}
        />
        <div className="flex j-content-end">
          <Button size="small" variant="contained" type="submit">
            Update
          </Button>
        </div>
      </form>

      {/* <Box>
    <Typography fontWeight="bold">Flow Display Name:</Typography>
    <Typography>{userVisibleInspection.level.displayName} </Typography>
  </Box>

  <Box>
    <Typography fontWeight="bold">Flow Weight:</Typography>
    <Typography>{userVisibleInspection.level.weight}</Typography>
  </Box>

  <Box>
    <Typography fontWeight="bold">Status:</Typography>
    <Typography>{getAmlInspectionStatus(userVisibleInspection.status)}</Typography>
  </Box>

  <Box>
    <Typography fontWeight="bold">Expiration Date:</Typography>
    <Typography>{getDateWithTime(userVisibleInspection.expirationDate)}</Typography>
  </Box> */}
    </StyledSettingRiskScoreByLevelBox>
  )
}

export default SettingRiskScoreByLevelBox
