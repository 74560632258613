import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  GetWPKuvaLocalCardPaymentDetailResponse,
  GetWPKuvaLocalListResponse,
  GetWpKwlCardPaymentDetailResponse,
  GetWPKwlListResponse,
} from '../../types/wpTypes'
import { worldPayActions } from '../actions/wpActions'

export type WorldPayState = {
  isLoading: boolean
  isLoaded: boolean
  error: string
  klCardPaymentList: GetWPKuvaLocalListResponse
  klCardPaymentDetail: GetWPKuvaLocalCardPaymentDetailResponse | null
  kwlCardPaymentList: GetWPKwlListResponse
  kwlCardPaymentDetail: GetWpKwlCardPaymentDetailResponse | null
}

const initialState: WorldPayState = {
  isLoading: false,
  isLoaded: false,
  error: '',
  klCardPaymentList: {
    hasNextPage: false,
    items: [],
  },
  klCardPaymentDetail: null,
  kwlCardPaymentList: {
    hasNextPage: false,
    items: [],
  },
  kwlCardPaymentDetail: null,
}

export const worldPayReducer = createSlice({
  name: 'worldPay',
  initialState,
  reducers: {
    removeKlCardPayments: state => {
      state.klCardPaymentList.hasNextPage = false
      state.klCardPaymentList.items = []
    },
    removeKwlCardPayments: state => {
      state.kwlCardPaymentList.hasNextPage = false
      state.kwlCardPaymentList.items = []
    },

    // getCurrentCustomer: (state, action) => {
    // 	state.customer = state.customers.find(customer => customer.id === action.payload)
    // },
  },
  extraReducers: builder => {
    builder
      .addCase(worldPayActions.getKwlCardPayments.pending, (state, action) => {
        state.isLoading = true
        state.error = ''
        state.kwlCardPaymentList.items =
          action.meta.arg.offset === 0 ? [] : state.kwlCardPaymentList.items
      })
      .addCase(worldPayActions.getKwlCardPayments.fulfilled, (state, action) => {
        state.isLoading = false
        state.isLoaded = true
        state.kwlCardPaymentList.hasNextPage = action.payload.hasNextPage
        state.kwlCardPaymentList.items = [
          ...state.kwlCardPaymentList.items,
          ...action.payload.items,
        ]
      })
      .addCase(worldPayActions.getKLCardPayments.pending, (state, action) => {
        state.isLoading = true
        state.error = ''
        state.klCardPaymentList.items =
          action.meta.arg.offset === 0 ? [] : state.klCardPaymentList.items
      })
      .addCase(
        worldPayActions.getKLCardPayments.fulfilled,
        (state, action: PayloadAction<GetWPKuvaLocalListResponse>) => {
          state.isLoading = false
          state.isLoaded = true
          state.klCardPaymentList.hasNextPage = action.payload.hasNextPage
          state.klCardPaymentList.items = [
            ...state.klCardPaymentList.items,
            ...action.payload.items,
          ]
        }
      )
      .addCase(worldPayActions.getKwlCardPaymentDetail.pending, state => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(worldPayActions.getKwlCardPaymentDetail.fulfilled, (state, action) => {
        state.isLoading = false
        state.isLoaded = true
        state.kwlCardPaymentDetail = action.payload
        state.kwlCardPaymentDetail!.remittanceCardPaymentId = action.meta.arg
      })
      .addCase(worldPayActions.getKlCardPaymentDetail.pending, state => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(worldPayActions.getKlCardPaymentDetail.fulfilled, (state, action) => {
        state.isLoading = false
        state.isLoaded = true
        state.klCardPaymentDetail = action.payload
      })
  },
})

export const { removeKlCardPayments, removeKwlCardPayments } = worldPayReducer.actions

export default worldPayReducer.reducer
