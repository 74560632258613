import {
  CurrencyType,
  InstancePaymentOptionType,
  PaymentMethod,
  PaymentProviderForForm,
  PaymentProviderFormType,
  PaymentProviderMethod,
  PaymentProviderWithFeeForInstanceDetails,
} from '../types/remittanceTypes'

export const getPaymentProvidersForRequestBody = (
  paymentProvidersFromForm: PaymentProviderFormType,
) => {
  const modifiedPaymentProvidersFromForm = []

  for (const key in paymentProvidersFromForm) {
    modifiedPaymentProvidersFromForm.push(paymentProvidersFromForm[key])
  }

  const modifiedPaymentProviders = modifiedPaymentProvidersFromForm.reduce((acc, item) => {
    const existingPaymentProvider = acc.findIndex(
      accItem => accItem.id === item.paymentProviderId
    )
    let newArrElement = { id: item.paymentProviderId, paymentMethods: item.checked ? [item.paymentMethod] : [], fee: item.fee }

    if (existingPaymentProvider === -1) {
      acc.push(newArrElement)
    } else {
      const modifiedNewArrElement = {
        ...acc[existingPaymentProvider],
        paymentMethods: [
          ...acc[existingPaymentProvider].paymentMethods,
          ...newArrElement.paymentMethods]
      }
      acc[existingPaymentProvider] = modifiedNewArrElement
    }

    return acc
  }, [] as Array<PaymentProviderWithFeeForInstanceDetails>)

  return modifiedPaymentProviders
}

export const getCashInPaymentProvidersForForm = (
  paymentProviderData: Array<PaymentProviderMethod>,
) => {
  const modifiedPaymentProviderMethods = paymentProviderData
    .filter(paymentProviderMethod =>
      paymentProviderMethod.paymentOptionType !== InstancePaymentOptionType.CashOut &&
      !(paymentProviderMethod.paymentMethod === PaymentMethod.GooglePay ||
        paymentProviderMethod.paymentMethod === PaymentMethod.ApplePay))
    .map(paymentProviderMethod => ({
      paymentProviderId: paymentProviderMethod.paymentProvider.id,
      paymentProviderName: paymentProviderMethod.paymentProvider.name,
      paymentMethods: [
        {
          type: paymentProviderMethod.paymentMethod,
          checked: false,
          name: PaymentMethod[paymentProviderMethod.paymentMethod],
        },
      ],
    }))

  const paymentProviders = modifiedPaymentProviderMethods.reduce((acc, item) => {
    const existingPaymentProviderIndex = acc.findIndex(
      accItem => accItem.paymentProviderName === item.paymentProviderName
    )
    if (existingPaymentProviderIndex === -1) {

      acc.push(item)
    } else {
      const paymentProvider = {
        ...acc[existingPaymentProviderIndex],
        paymentMethods: [
          ...acc[existingPaymentProviderIndex].paymentMethods,
          ...item.paymentMethods,
        ],
      }
      acc[existingPaymentProviderIndex] = paymentProvider
    }

    return acc
  }, [] as Array<PaymentProviderForForm>)

  return paymentProviders
}

export const getCashOutPaymentProvidersForForm = (
  paymentProviderData: Array<PaymentProviderMethod>,
) => {
  const modifiedPaymentProviderMethods = paymentProviderData
    .filter(paymentProviderMethod => paymentProviderMethod.paymentOptionType !== InstancePaymentOptionType.CashIn)
    .map(paymentProviderMethod => ({
      paymentProviderId: paymentProviderMethod.paymentProvider.id,
      paymentProviderName: paymentProviderMethod.paymentProvider.name,
      paymentMethods: [
        {
          type: paymentProviderMethod.paymentMethod,
          checked: false,
          name: PaymentMethod[paymentProviderMethod.paymentMethod],
        },
      ],
    }))

  const paymentProviders = modifiedPaymentProviderMethods.reduce((acc, item) => {
    const existingPaymentProviderIndex = acc.findIndex(
      accItem => accItem.paymentProviderName === item.paymentProviderName
    )
    if (existingPaymentProviderIndex === -1) {
      acc.push(item)
    } else {
      const paymentProvider = {
        ...acc[existingPaymentProviderIndex],
        paymentMethods: [
          ...acc[existingPaymentProviderIndex].paymentMethods,
          ...item.paymentMethods,
        ],
      }
      acc[existingPaymentProviderIndex] = paymentProvider
    }

    return acc
  }, [] as Array<PaymentProviderForForm>)

  return paymentProviders
}

export const getCurrencyTypeOptions = () => [
  {
    label: "Cash In",
    value: String(CurrencyType.CashIn),
  }, {
    label: "Cash Out",
    value: CurrencyType.CashOut,
  }, {
    label: "Both",
    value: CurrencyType.Both,
  }
]

