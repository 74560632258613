import { useContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { MainContext } from '../../App'
import MappedRouting from './MappedRouting'

import { loginPath, twoFaVerificationPath } from '../../utils/appPaths'

import Login from '../../pages/Login'
import TwoFaVerification from '../../pages/TwoFaVerification'

type RouterProps = {
  isAuth: boolean,
  isAccess?: boolean,
  isTwoFa?: boolean,
}

export default ({ isAuth, isAccess = false, isTwoFa = false }: RouterProps) => {

  const { userPermissionList } = useContext(MainContext)

  return (
    <>
      {
        isAuth ?
          <MappedRouting userPermissionList={userPermissionList} />
          :
          <Routes>
            {!isAccess && !isTwoFa && <Route path={loginPath} element={<Login />} />}
            {isTwoFa && <Route path={twoFaVerificationPath} element={<TwoFaVerification />} />}
            {<Route path='*' element={<Navigate replace to={loginPath} />} />}
          </Routes>
      }
    </>
  )
}
