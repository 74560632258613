import { createAsyncThunk } from '@reduxjs/toolkit'

import { FETCH_MOBILE_USER_LIST } from './actionTypes'

import { ResultListType, RequestErrType } from '../../types/generalTypes'
import {
  ChangeBlockingRecordStatusRequest,
  CreateBlockingRecordRequest,
  GetMobileUserListRequest,
  GetUserBlockingRecordsRequest,
  GetUserDocImageRequest,
  GetUserDocsResponse,
  MobileUserDataType,
  UpdateRemittanceUserRiskRequestArg,
} from '../../types/mobileUserTypes'
import {
  apiConfirmBlockingRecord,
  apiGetAllUserInspections,
  apiGetBlockingRecordsByUserId,
  apiGetKlProfileDetailsPath,
  apiGetKwlProfileDetailsPath,
  apiGetMobileUserDetailsPath,
  apiGetUserDocImage,
  apiGetUserDocs,
  apiGetVisibleUserInspections,
  apiMobileUsersPath,
  apiResolveBlockingRecords,
  apiSetUserExpirationDate,
  apiUserBlockingRecords,
  updateIgnoreRemittanceTxRiskForUserApiPath,
  updateRemittanceUserRiskApiPath,
} from '../../utils/apiPaths'
import apiRequestServiceWithRefresh from '../../services/apiRequestServiceWithRefresh'
import { AlertColor } from '@mui/material'
import { addNotification } from '../reducers/notificationReducer'

const fetchMobileUserList = createAsyncThunk(
  FETCH_MOBILE_USER_LIST,
  async (arg: GetMobileUserListRequest, { rejectWithValue, dispatch }) => {
    const { queryParams } = arg

    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        `${apiMobileUsersPath}?${queryParams}`
      )

      if (result) return result
    } catch (err) {
      return rejectWithValue(err as RequestErrType)
    }
  }
)

const updateRemittanceUserRisk = createAsyncThunk(
  'mobileUser/updateRemittanceUserRisk',
  async (arg: UpdateRemittanceUserRiskRequestArg, { rejectWithValue, dispatch }) => {
    const { userId, riskScore } = arg
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'put',
        updateRemittanceUserRiskApiPath(userId, riskScore)
      )

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'The user risk was updated successfully',
      }

      dispatch(addNotification(notificationData))

      return result
    } catch (err) {
      return rejectWithValue('error')
    }
  }
)

const updateIgnoreRemittanceTxRiskForUser = createAsyncThunk(
  'mobileUser/updateIgnoreRemittanceTxRiskForUser',
  async (userId: string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'put',
        updateIgnoreRemittanceTxRiskForUserApiPath(userId)
      )

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'The ignore remittance transaction risk was updated successfully',
      }

      dispatch(addNotification(notificationData))

      return result
    } catch (err) {
      return rejectWithValue('error')
    }
  }
)

const getMobileUserDetails = createAsyncThunk(
  'mobileUser/getMobileUserDetails',
  async (userId: number | string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        apiGetMobileUserDetailsPath(userId)
      )

      return result
    } catch (err) {
      return rejectWithValue('error')
    }
  }
)

const getKlProfileDetails = createAsyncThunk(
  'mobileUser/getKlProfileDetails',
  async (userId: number | string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        apiGetKlProfileDetailsPath(userId)
      )

      return result
    } catch (err) {
      return rejectWithValue('error')
    }
  }
)

const getKwlProfileDetails = createAsyncThunk(
  'mobileUser/getKwlProfileDetails',
  async (userId: number | string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        apiGetKwlProfileDetailsPath(userId)
      )

      return result
    } catch (err) {
      return rejectWithValue('error')
    }
  }
)

const getAllUserInspections = createAsyncThunk(
  'mobileUser/getAllUserInspections',
  async (userId: number | string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        apiGetAllUserInspections(userId)
      )

      return result
    } catch (err) {
      return rejectWithValue('error')
    }
  }
)

const getVisibleUserInspections = createAsyncThunk(
  'mobileUser/getVisibleUserInspections',
  async (userId: number | string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        apiGetVisibleUserInspections(userId)
      )

      return result
    } catch (err) {
      return rejectWithValue('error')
    }
  }
)

const getUserDocs = createAsyncThunk(
  'mobileUser/getUserDocs',
  async (userId: number | string, { rejectWithValue, dispatch }) => {
    try {
      const result: GetUserDocsResponse = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        apiGetUserDocs(userId)
      )

      const modifiedInspectionDocList = result.results.map((inspectionDoc, index) => {
        return { ...inspectionDoc, id: ++index }
      })

      return modifiedInspectionDocList
    } catch (err) {
      return rejectWithValue('error')
    }
  }
)

const getUserDocImage = createAsyncThunk(
  'mobileUser/getUserDocImage',
  async (arg: GetUserDocImageRequest, { rejectWithValue, dispatch }) => {
    const { userId, imageId } = arg
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        apiGetUserDocImage(userId, imageId),
        { responseType: 'blob' }
      )

      return URL.createObjectURL(result)
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const setUserExpirationDate = createAsyncThunk(
  'mobileUser/setUserExpirationDate',
  async (userId: number | string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'put',
        apiSetUserExpirationDate(userId)
      )

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'The expiration date has been successfully set',
      }

      dispatch(addNotification(notificationData))

      dispatch(mobileUserActions.getVisibleUserInspections(userId))
      dispatch(mobileUserActions.getAllUserInspections(userId))

      return result
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

const getUserBlockingRecords = createAsyncThunk(
  'mobileUser/getUserBlockingRecords',
  async (arg: GetUserBlockingRecordsRequest, { rejectWithValue, dispatch }) => {
    const { queryParams } = arg

    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        `${apiUserBlockingRecords}?${queryParams}`
      )

      return result
    } catch (err) {
      return rejectWithValue('Error')
    }
  }
)

const getBlockingRecordsByUserId = createAsyncThunk(
  'mobileUser/getBlockingRecordsByUserId',
  async (userId: string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        apiGetBlockingRecordsByUserId(userId)
      )

      return result
    } catch (err) {
      return rejectWithValue('error')
    }
  }
)

const confirmBlockingRecord = createAsyncThunk(
  'mobileUser/confirmBlockingRecord',
  async (arg: ChangeBlockingRecordStatusRequest, { rejectWithValue, dispatch }) => {
    const { entityId, body } = arg

    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'put',
        apiConfirmBlockingRecord(entityId),
        body
      )

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'The blocking record was changed to "Confirmed" status successfully',
      }

      dispatch(addNotification(notificationData))

      return result
    } catch (err) {
      return rejectWithValue('error')
    }
  }
)

const resolveBlockingRecord = createAsyncThunk(
  'mobileUser/resolveBlockingRecord',
  async (arg: ChangeBlockingRecordStatusRequest, { rejectWithValue, dispatch }) => {
    const { entityId, body } = arg

    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'put',
        apiResolveBlockingRecords(entityId),
        body
      )

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'The blocking record was changed to "Resolved" status successfully',
      }

      dispatch(addNotification(notificationData))

      return result
    } catch (err) {
      return rejectWithValue('error')
    }
  }
)

const createBlockingRecord = createAsyncThunk(
  'mobileUser/createBlockingRecord',
  async (arg: CreateBlockingRecordRequest, { rejectWithValue, dispatch }) => {

    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'post',
        apiUserBlockingRecords,
        arg
      )

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'The blocking record was created successfully',
      }

      dispatch(addNotification(notificationData))

      return result
    } catch (err) {
      return rejectWithValue('error')
    }
  }
)

export const mobileUserActions = {
  updateRemittanceUserRisk,
  updateIgnoreRemittanceTxRiskForUser,
  fetchMobileUserList,
  getMobileUserDetails,
  getKlProfileDetails,
  getKwlProfileDetails,
  getAllUserInspections,
  getVisibleUserInspections,
  getUserDocs,
  getUserDocImage,
  setUserExpirationDate,
  getUserBlockingRecords,
  getBlockingRecordsByUserId,
  confirmBlockingRecord,
  resolveBlockingRecord,
  createBlockingRecord,
}
