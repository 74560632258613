import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  CreateOrUpdateInstanceCurrencyRequestBody,
  CurrencyDetailForForm,
  CurrencyType,
  InstanceCurrencyFormType,
  PaymentProviderMethod,
} from '../../../../../types/remittanceTypes'
import SelectFieldForm from '../../../../../components/reusable/form/SelectFieldForm'
import { Checkbox, FormControlLabel, Grid, Stack, Typography, styled } from '@mui/material'
import TextFieldForm from '../../../../../components/reusable/form/TextFieldForm'
import {
  getCashInPaymentProvidersForForm,
  getCashOutPaymentProvidersForForm,
  getCurrencyTypeOptions,
  getPaymentProvidersForRequestBody,
} from '../../../../../utils/remittanceUtils'

const StyledInstanceCurrencyForm = styled('form')({
  padding: '12px 0',

  '.MuiFormControl-root': {
    width: '50%',
  },
  '.MuiGrid-item': {
    display: 'flex',
    '&.fixed-value-block': {
      padding: '8px 16px 0 16px',
      '.MuiTextField-root': {},
    },
    '&.percent-value-block': {
      padding: '8px 0 0 32px',
    },
    '.MuiCheckbox-root': {
      marginLeft: '12px',
      paddingTop: 0,
    },

    '.MuiFormControl-root': {
      width: 'initial',
      '.MuiInput-input': {
        padding: '1px 0 0 5px',
      },
    },
    '.MuiFormControlLabel-root': {
      alignItems: 'start',
    },
  },
})

type InstanceCurrencyFormProps = {
  modifiedAllCurrencyList: Array<{ label: string; value: string | number }>
  instanceCurrencyDetails: CurrencyDetailForForm | null
  paymentProviderMethods: Array<PaymentProviderMethod>
  onConfirm: (body: CreateOrUpdateInstanceCurrencyRequestBody) => void
  variant: 'edit-instance-currency' | 'add-instance-currency'
}

const InstanceCurrencyForm = ({
  modifiedAllCurrencyList,
  instanceCurrencyDetails,
  paymentProviderMethods,
  onConfirm,
  variant,
}: InstanceCurrencyFormProps) => {
  const cashInPaymentProvidersForForm = useMemo(
    () => getCashInPaymentProvidersForForm(paymentProviderMethods),
    [paymentProviderMethods]
  )
  const cashOutPaymentProvidersForForm = useMemo(
    () => getCashOutPaymentProvidersForForm(paymentProviderMethods),
    [paymentProviderMethods]
  )

  const { handleSubmit, setValue, control, watch } = useForm<InstanceCurrencyFormType>({})

  const currentCurrencyCode = watch('selectedCurrency')
  const watchedCurrencyType = watch('selectedCurrencyType')

  const currencyType = useMemo(() => {
    if (instanceCurrencyDetails?.type === CurrencyType.CashIn) {
      return String(CurrencyType.CashIn)
    }
    if (!instanceCurrencyDetails?.type) {
      return CurrencyType.Both
    }

    return instanceCurrencyDetails?.type
  }, [instanceCurrencyDetails?.type])

  const setFormValue = useCallback(() => {
    setValue(
      'selectedCurrency',
      instanceCurrencyDetails?.currency.id || modifiedAllCurrencyList[0].value
    )
    setValue('selectedCurrencyType', currencyType)
    setValue('cashInFee.fixedValue', instanceCurrencyDetails?.cashInFee?.fixedValue || 0)
    setValue('cashInFee.percentValue', instanceCurrencyDetails?.cashInFee?.percentValue || 0)

    setValue('kuvaCashInFee.fixedValue', instanceCurrencyDetails?.kuvaCashInFee?.fixedValue || 0)
    setValue(
      'kuvaCashInFee.percentValue',
      instanceCurrencyDetails?.kuvaCashInFee?.percentValue || 0
    )

    if (instanceCurrencyDetails) {
      instanceCurrencyDetails?.paymentProviders.forEach(cashInPaymentProvider => {
        const providerName = cashInPaymentProvidersForForm.find(
          paymentProviderItem => cashInPaymentProvider.id === paymentProviderItem.paymentProviderId
        )?.paymentProviderName
        const paymentMethod = cashInPaymentProvidersForForm.find(
          paymentProviderItem => cashInPaymentProvider.id === paymentProviderItem.paymentProviderId
        )?.paymentMethods[0].type as number

        if (cashInPaymentProvider.paymentMethods.length) {
          setValue(`cashInPaymentOptions.${providerName}.checked`, true)
        }

        setValue(`cashInPaymentOptions.${providerName}.paymentMethod`, paymentMethod)

        setValue(`cashInPaymentOptions.${providerName}.paymentProviderId`, cashInPaymentProvider.id)
        setValue(
          `cashInPaymentOptions.${providerName}.fee.fixedValue`,
          cashInPaymentProvider.fee?.fixedValue || 0
        )
        setValue(
          `cashInPaymentOptions.${providerName}.fee.percentValue`,
          cashInPaymentProvider.fee?.percentValue || 0
        )
      })
      instanceCurrencyDetails?.cashOutProviders.forEach(cashOutPaymentProvider => {
        const cashOutProviderName = cashOutPaymentProvidersForForm.find(
          paymentProviderItem => cashOutPaymentProvider.id === paymentProviderItem.paymentProviderId
        )?.paymentProviderName
        const paymentMethod = cashOutPaymentProvidersForForm.find(
          paymentProviderItem => cashOutPaymentProvider.id === paymentProviderItem.paymentProviderId
        )?.paymentMethods[0].type as number

        if (cashOutPaymentProvider.paymentMethods.length) {
          setValue(`cashOutPaymentOptions.${cashOutProviderName}.checked`, true)
        }
        setValue(`cashOutPaymentOptions.${cashOutProviderName}.paymentMethod`, paymentMethod)

        setValue(
          `cashOutPaymentOptions.${cashOutProviderName}.paymentProviderId`,
          cashOutPaymentProvider.id
        )
        setValue(
          `cashOutPaymentOptions.${cashOutProviderName}.fee.fixedValue`,
          cashOutPaymentProvider.fee?.fixedValue || 0
        )
        setValue(
          `cashOutPaymentOptions.${cashOutProviderName}.fee.percentValue`,
          cashOutPaymentProvider.fee?.percentValue || 0
        )
      })
    } else {
      cashInPaymentProvidersForForm.forEach(paymentProvider => {
        setValue(
          `cashInPaymentOptions.${paymentProvider.paymentProviderName}.paymentMethod`,
          paymentProvider.paymentMethods[0].type
        )
        setValue(
          `cashInPaymentOptions.${paymentProvider.paymentProviderName}.paymentProviderId`,
          paymentProvider.paymentProviderId
        )
        setValue(`cashInPaymentOptions.${paymentProvider.paymentProviderName}.fee.fixedValue`, 0)
        setValue(`cashInPaymentOptions.${paymentProvider.paymentProviderName}.fee.percentValue`, 0)
      })

      cashOutPaymentProvidersForForm.forEach(paymentProvider => {
        setValue(
          `cashOutPaymentOptions.${paymentProvider.paymentProviderName}.paymentMethod`,
          paymentProvider.paymentMethods[0].type
        )
        setValue(
          `cashOutPaymentOptions.${paymentProvider.paymentProviderName}.paymentProviderId`,
          paymentProvider.paymentProviderId
        )
        setValue(`cashOutPaymentOptions.${paymentProvider.paymentProviderName}.fee.fixedValue`, 0)
        setValue(`cashOutPaymentOptions.${paymentProvider.paymentProviderName}.fee.percentValue`, 0)
      })
    }
  }, [
    cashInPaymentProvidersForForm,
    cashOutPaymentProvidersForForm,
    currencyType,
    instanceCurrencyDetails,
    modifiedAllCurrencyList,
    setValue,
  ])

  useEffect(() => {
    setFormValue()
  }, [setFormValue])

  const onSubmit = (data: InstanceCurrencyFormType) => {
    const paymentProviders = getPaymentProvidersForRequestBody(data.cashInPaymentOptions)
    const cashOutProviders = getPaymentProvidersForRequestBody(data.cashOutPaymentOptions)

    const body = {
      type: Number(data.selectedCurrencyType),
      paymentProviders,
      cashOutProviders,
      cashInFee: {
        percentValue: data.cashInFee.percentValue || 0,
        fixedValue: data.cashInFee.fixedValue || 0,
      },
      kuvaCashInFee: {
        percentValue: data.kuvaCashInFee.percentValue || 0,
        fixedValue: data.kuvaCashInFee.fixedValue || 0,
      },
      currency: {
        id: data.selectedCurrency,
      },
    }

    onConfirm(body)
  }

  const onChangeCheckBox = useCallback(
    (checked: boolean, name: string) => {
      if (name === 'cashInPaymentOptions.CurrencyCloud.checked' && checked) {
        setValue(`cashInPaymentOptions.ClearJunction.checked`, false)
      }
      if (name === 'cashInPaymentOptions.ClearJunction.checked' && checked) {
        setValue(`cashInPaymentOptions.CurrencyCloud.checked`, false)
      }
    },
    [setValue]
  )

  return (
    <StyledInstanceCurrencyForm id="instance-currency-form" onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <SelectFieldForm<InstanceCurrencyFormType>
          name="selectedCurrencyType"
          control={control}
          label="Select Currency Type"
          multiple={false}
          options={getCurrencyTypeOptions()}
        />
        <SelectFieldForm<InstanceCurrencyFormType>
          name="selectedCurrency"
          control={control}
          label="Select Currency"
          multiple={false}
          options={modifiedAllCurrencyList}
          disabled={variant === 'edit-instance-currency'}
        />
      </Stack>

      {(watchedCurrencyType === String(CurrencyType.CashIn) ||
        watchedCurrencyType === CurrencyType.Both) && (
        <>
          <Typography fontSize="20px" mb={1.5} fontWeight="bold">
            Fee Settings
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography fontSize="16px">Cash-in Fee:</Typography>
            </Grid>
            <Grid item xs={4} sx={{ paddingLeft: 2 }} className="fixed-value-block">
              <Typography fontSize="16px">{currentCurrencyCode}</Typography>
              <TextFieldForm<InstanceCurrencyFormType>
                name="cashInFee.fixedValue"
                control={control}
                label=""
                type="tel"
                inputMode="numeric"
                variant="standard"
                autoComplete="off"
                validMask={/[^0-9.]/}
                maxLength={5}
              />
              <Typography fontSize="16px">(Fixed)</Typography>
            </Grid>
            <Grid item xs={4} className="percent-value-block">
              <Typography fontSize="16px">%</Typography>
              <TextFieldForm<InstanceCurrencyFormType>
                name="cashInFee.percentValue"
                control={control}
                label=""
                type="tel"
                inputMode="numeric"
                variant="standard"
                autoComplete="off"
                validMask={/[^0-9.]/}
                maxLength={5}
              />
              <Typography fontSize="16px">(Percent)</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography fontSize="16px" mb={1}>
                Cash-in Kuva Fee:
              </Typography>
            </Grid>
            <Grid item xs={4} className="fixed-value-block">
              <Typography fontSize="16px">{currentCurrencyCode}</Typography>
              <TextFieldForm<InstanceCurrencyFormType>
                name="kuvaCashInFee.fixedValue"
                control={control}
                label=""
                type="tel"
                inputMode="numeric"
                variant="standard"
                autoComplete="off"
                validMask={/[^0-9.]/}
                maxLength={5}
              />
              <Typography fontSize="16px">(Fixed)</Typography>
            </Grid>
            <Grid item xs={4} className="percent-value-block">
              <Typography fontSize="16px">%</Typography>
              <TextFieldForm<InstanceCurrencyFormType>
                name="kuvaCashInFee.percentValue"
                control={control}
                label=""
                type="tel"
                inputMode="numeric"
                variant="standard"
                autoComplete="off"
                validMask={/[^0-9.]/}
                maxLength={5}
              />
              <Typography fontSize="16px">(Percent)</Typography>
            </Grid>
          </Grid>
        </>
      )}
      {(watchedCurrencyType === String(CurrencyType.CashIn) ||
        watchedCurrencyType === CurrencyType.Both) && (
        <>
          <Typography fontSize="20px" mb={1.5} fontWeight="bold">
            Payment Variants
          </Typography>
          <Grid container spacing={1}>
            {cashInPaymentProvidersForForm.map((paymentProvider, indexPaymentProvider) => (
              <React.Fragment key={paymentProvider.paymentProviderId}>
                <Grid item xs={4}>
                  <Controller
                    name={`cashInPaymentOptions.${paymentProvider.paymentProviderName}.checked`}
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <FormControlLabel
                        sx={{ width: '100%' }}
                        control={
                          <Checkbox
                            onChange={event => {
                              onChange(event.target.checked)
                              onChangeCheckBox(event.target.checked, name)
                            }}
                            disableRipple
                            checked={typeof value === 'undefined' ? false : value}
                          />
                        }
                        label={paymentProvider.paymentProviderName}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} className="fixed-value-block">
                  <Typography fontSize="16px">{currentCurrencyCode}</Typography>
                  <TextFieldForm<InstanceCurrencyFormType>
                    name={`cashInPaymentOptions.${paymentProvider.paymentProviderName}.fee.fixedValue`}
                    control={control}
                    label=""
                    type="tel"
                    inputMode="numeric"
                    variant="standard"
                    autoComplete="off"
                    validMask={/[^0-9.]/}
                    maxLength={5}
                  />
                  <Typography fontSize="16px">(Fixed)</Typography>
                </Grid>
                <Grid item xs={4} className="percent-value-block">
                  <Typography fontSize="16px">%</Typography>
                  <TextFieldForm<InstanceCurrencyFormType>
                    name={`cashInPaymentOptions.${paymentProvider.paymentProviderName}.fee.percentValue`}
                    control={control}
                    label=""
                    type="tel"
                    inputMode="numeric"
                    variant="standard"
                    autoComplete="off"
                    validMask={/[^0-9.]/}
                    maxLength={5}
                  />
                  <Typography fontSize="16px">(Percent)</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </>
      )}
      {(watchedCurrencyType === CurrencyType.CashOut ||
        watchedCurrencyType === CurrencyType.Both) && (
        <>
          <Typography fontSize="20px" mb={1.5} fontWeight="bold">
            Payout options
          </Typography>
          <Grid container spacing={1}>
            {cashOutPaymentProvidersForForm.map((paymentProvider, indexPaymentProvider) => (
              <React.Fragment key={paymentProvider.paymentProviderId}>
                <Grid item xs={4}>
                  <Controller
                    name={`cashOutPaymentOptions.${paymentProvider.paymentProviderName}.checked`}
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <FormControlLabel
                        sx={{ width: '100%' }}
                        control={
                          <Checkbox
                            onChange={event => {
                              onChange(event.target.checked)
                              onChangeCheckBox(event.target.checked, name)
                            }}
                            disableRipple
                            checked={typeof value === 'undefined' ? false : value}
                          />
                        }
                        label={paymentProvider.paymentProviderName}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} className="fixed-value-block">
                  <Typography fontSize="16px">{currentCurrencyCode}</Typography>
                  <TextFieldForm<InstanceCurrencyFormType>
                    name={`cashOutPaymentOptions.${paymentProvider.paymentProviderName}.fee.fixedValue`}
                    control={control}
                    label=""
                    type="tel"
                    inputMode="numeric"
                    variant="standard"
                    autoComplete="off"
                    validMask={/[^0-9.]/}
                    maxLength={5}
                  />
                  <Typography fontSize="16px">(Fixed)</Typography>
                </Grid>
                <Grid item xs={4} className="percent-value-block">
                  <Typography fontSize="16px">%</Typography>
                  <TextFieldForm<InstanceCurrencyFormType>
                    name={`cashOutPaymentOptions.${paymentProvider.paymentProviderName}.fee.percentValue`}
                    control={control}
                    label=""
                    type="tel"
                    inputMode="numeric"
                    variant="standard"
                    autoComplete="off"
                    validMask={/[^0-9.]/}
                    maxLength={5}
                  />
                  <Typography fontSize="16px">(Percent)</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </>
      )}
    </StyledInstanceCurrencyForm>
  )
}

export default InstanceCurrencyForm
