import { useEffect, useState, FormEvent, useCallback, useMemo } from 'react'
import { Button, Container, TextField, styled } from '@mui/material'

import Loader from '../../../components/Loader'
import Table from '../../../components/Table'

import { useAppDispatch, useAppSelector } from '../../../store'
import { fetchTopUpKWLList } from '../../../store/actions/topUpActions'
import getQueryString from '../../../helpers/getQueryString'
import { riskScoreMarkers, topUpStatuses } from '../../../utils/constants'
import { Link } from 'react-router-dom'
import RemittanceTopUpFilter from './RemittanceTopUpFilter'
import { RemittanceTopUpFilterForm } from '../../../types/topUpTypes'
import concatenateObjProps from '../../../helpers/concatenateObjProps'
import TagsForFilter, { FilterTag } from '../../../components/reusable/tagsForFilter/TagsForFilter'
import { isArray } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import { RiskLevelMarker } from '../../../types/commonTypes'

const filterTagConfig = {
  reference: 'Reference:',
  riskLevelMarker: 'Risk Level:',
}

const domain = process.env.REACT_APP_BACK_OFFICE_DOMAIN

const StyledInstanceNameCell = styled('span')({
  display: 'block',
  maxWidth: '120px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const StyledExternalLink = styled('a')({
  textDecoration: 'underline',
})

type PaginationType = { offset: number; pageSize: number }

const KWL = () => {
  const [isShowFilter, setIsShowFilter] = useState(false)
  const [filterList, setFilterList] = useState<RemittanceTopUpFilterForm>({
    reference: '',
    riskLevelMarker: '',
  })
  const [isResetPagination, setResetPagination] = useState(false)
  const [pagination, setPagination] = useState<PaginationType>({ offset: 0, pageSize: 10 })

  const dispatch = useAppDispatch()
  const {
    list: { items, count },
    listFetchFinished,
  } = useAppSelector(state => state.topUp.kwl)

  useEffect(() => {
    dispatch(fetchTopUpKWLList(getQueryString({ offset: 0, pageSize: 10 })))
  }, [dispatch])

  useEffect(() => {
    setResetPagination(false)
  }, [isResetPagination])

  // const changeSearch = (value: string) => {
  //   setFilterList({ reference: value })
  //   setResetPagination(true)
  // }

  // const clickSearch = async (e: FormEvent) => {
  //   e.preventDefault()

  //   // await fetchData({ ...pagination, offset: 0 }, true)

  //   setResetPagination(true)
  // }

  const onOpenFilter = useCallback(() => {
    setIsShowFilter(true)
  }, [])

  const getRemittanceTopUpsWithFilter = useCallback(
    (data: RemittanceTopUpFilterForm) => {
      const updatedFilterList = { ...filterList, ...data }
      const clearedPagination = { offset: 0, pageSize: 10 }
      const currentParams = getQueryString(
        concatenateObjProps({ ...updatedFilterList, ...clearedPagination })
      )

      dispatch(fetchTopUpKWLList(currentParams))

      setPagination(clearedPagination)
      setResetPagination(true)
      setFilterList(prevState => ({
        ...prevState,
        ...data,
      }))
    },
    [dispatch, filterList]
  )

  const clearFilters = useCallback(async () => {
    let queryString = { ...pagination, offset: 0 }

    const currentParams = getQueryString(concatenateObjProps(queryString))

    await dispatch(fetchTopUpKWLList(currentParams))

    setPagination(queryString)

    setFilterList(prevState => ({
      ...prevState,
      reference: '',
    }))
  }, [dispatch, pagination])

  const fetchData = useCallback(
    async (params: PaginationType = pagination) => {
      const currentParams = getQueryString(concatenateObjProps({ ...filterList, ...params }))

      await dispatch(fetchTopUpKWLList(currentParams))

      setPagination({ ...params } as PaginationType)
      setResetPagination(false)
    },
    [dispatch, filterList, pagination]
  )

  const headData = [
    'Id',
    'Created',
    'Risk Score',
    'Reference',
    'Status',
    'Instance Name',
    'From',
    'To',
    'Details',
  ]
  const dataKeys = [
    'id',
    'createdDate',
    'riskScore',
    'reference',
    'status',
    'instanceName',
    'from',
    'to',
    'detailsBtn',
  ]
  const mappedData = items.map(item => ({
    ...item,
    from: `${item.sourceAmount} ${item.sourceCurrency}`,
    to: `${item.destinationAmount} ${item.destinationCurrency}`,
    status: (
      <span className={`color-${topUpStatuses[item.status].color}`}>
        {topUpStatuses[item.status].value}
      </span>
    ),
    riskScore: (
      <span className={`color-${riskScoreMarkers[item.riskLevelMarker]}`}>{item.riskScore}</span>
    ),
    instanceName: (
      <StyledInstanceNameCell title={item?.instanceName}>
        {item?.instanceName}
      </StyledInstanceNameCell>
    ),
    id: (
      <StyledExternalLink href={`${domain}/TopUp/USDk/${item.id}`} target="_blank" rel="noreferrer">
        {item.id}
      </StyledExternalLink>
    ),
    detailsBtn: (
      <Link to={`${item.extId}/details`}>
        <Button size="small" variant="contained">
          Details
        </Button>
      </Link>
    ),
  }))

  const modifiedFilterList = useMemo(() => {
    let newList = []

    for (const prop in filterList) {
      if (!filterList[prop]) {
        continue
      }

      if (typeof filterList[prop] === 'string') {
        newList.push({
          nameState: prop,
          nameField: filterTagConfig[prop],
          valueField: filterList[prop],
        })
      }

      if (prop === 'riskLevelMarker') {
        newList.push({
          nameState: prop,
          nameField: filterTagConfig[prop],
          valueField: RiskLevelMarker[filterList[prop]],
        })
      }
    }
    return newList
  }, [filterList])

  const onDeleteTag = useCallback(
    async (deletedFilterItem: FilterTag) => {
      let modifiedFilterList = { ...filterList }

      if (deletedFilterItem.nameState === 'reference') {
        modifiedFilterList.reference = ''
      }

      if (deletedFilterItem.nameState === 'riskLevelMarker') {
        modifiedFilterList.riskLevelMarker = ''
      }


      const queryString = { ...modifiedFilterList, ...pagination, offset: 0 }

      const currentParams = getQueryString(concatenateObjProps(queryString))


      await dispatch(fetchTopUpKWLList(currentParams))

      setFilterList(prevState => ({ ...prevState, ...modifiedFilterList }))
    },
    [dispatch, filterList, pagination]
  )

  return (
    <>
      {!listFetchFinished ? (
        <Loader />
      ) : (
        <Container className="pt-25 pb-10 h-100-percent overflow-hidden" maxWidth="xl">
          <h3 className="mb-25">Top Up Kuva White Label</h3>
          <div className="flex mb-20 j-content-end">
            <Button className="w-130 mr-15" variant="outlined" size="small" onClick={onOpenFilter}>
              Show Filters
            </Button>

            <Button
              className="w-130"
              variant="outlined"
              size="small"
              onClick={clearFilters}
              // disabled={isDisabled}
            >
              Clear Filters
            </Button>
          </div>
          <TagsForFilter data={modifiedFilterList} onDeleteTag={onDeleteTag} />
          {/* <form className="search mb-20" onSubmit={clickSearch}>
            <TextField
              label="Search by Reference..."
              size="small"
              value={query.reference}
              onChange={({ target: { value } }) => changeSearch(value)}
            />
            <Button size="small" variant="contained" type="submit">
              Apply
            </Button>
          </form> */}
          <Table
            bodyData={mappedData}
            headData={headData}
            dataKeys={dataKeys}
            containerStyle="pb-100"
            fetchData={fetchData}
            dataCount={count}
            isResetPagination={isResetPagination}
          />
          <RemittanceTopUpFilter
            isShowFilter={isShowFilter}
            filterState={filterList}
            setIsShowFilter={setIsShowFilter}
            getRemittanceTopUpsWithFilter={getRemittanceTopUpsWithFilter}
          />
        </Container>
      )}
    </>
  )
}

export default KWL
