import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { agentGroupsActions } from '../../store/actions/agentGroupsActions'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { Button, styled } from '@mui/material'
import { useForm } from 'react-hook-form'
import { GenerateAgentGroupApiKeyForm } from '../../types/agentGroupTypes'
import TextFieldForm from '../../components/reusable/form/TextFieldForm'
import { openGlobalModal } from '../../store/reducers/globalModalReducer'
import { trimObjectFields } from '../../utils/commonUtils'
import { removeAgentGroupKeys } from '../../store/reducers/agentGroupsReducer'
// import { useParams } from 'react-router-dom'

type AgentGroupApiKeysTabProps = {
  agentGroupId: string | undefined
}

const StyledAgentGroupApiKeysTab = styled('div')({
  '.table': {
    '&-body': {
      display: 'flex',
      width: '100%',
      '&-left': {
        width: '70%',
      },
      '.generate': {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      },
    },

    // '&-cell': {
    //   // color: 'red',
    //   border: '1px solid red',
    // },
    '&-header': {
      display: 'flex',
      width: '100%',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      fontWeight: 'bold',
      '.name': {
        width: '25%',
        padding: '10px',
      },
      '.value': {
        width: '45%',
        padding: '10px',
      },
    },
    '&-body-row': {
      display: 'flex',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      // width: '100%',
      '.name': {
        width: '35.6%',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '10px',
      },
      '.value': {
        width: '65.4%',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '10px',
        wordBreak: 'break-all',
      },
    },

    '.generate': {
      width: '30%',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '#generate-key-form': {
      '.MuiFormControl-root': {
        margin: 0,
      },
    },
  },
  // },
})

const AgentGroupApiKeysTab = ({ agentGroupId }: AgentGroupApiKeysTabProps) => {
  const dispatch = useAppDispatch()

  const { agentGroupKeys } = useAppSelector(state => state.agentGroups)
  const { handleSubmit, setValue, control, watch } = useForm<GenerateAgentGroupApiKeyForm>({})

  useEffect(() => {
    setValue('clientId', agentGroupKeys?.clientId || '')
  }, [agentGroupKeys?.clientId, setValue])

  useEffect(() => {
    if (agentGroupId) {
      dispatch(agentGroupsActions.getAgentGroupKeys(agentGroupId))
    }

    return () => {
      dispatch(removeAgentGroupKeys())
    }
  }, [dispatch, agentGroupId])

  const onSubmit = (data: GenerateAgentGroupApiKeyForm) => {
    const trimmedData = trimObjectFields(data)
    const arg = { agentGroupId, body: trimmedData }

    // const generateKey = () => {
    //   dispatch(agentGroupsActions.generateAgentGroupApiKey(arg))
    // }

    dispatch(openGlobalModal({ variant: 'generate-dialog-modal', actionData: arg }))
  }

  return (
    <>
      {agentGroupKeys ? (
        <>
          <StyledAgentGroupApiKeysTab>
            <div className="table">
              <div className="table-header">
                <div className="table-cell name">Name</div>
                <div className="table-cell value">Value</div>
                <div className="table-cell generate"></div>
              </div>

              <div className="table-body">
                <div className="table-body-left">
                  <div className="table-body-row">
                    <div className="table-cell name">Client Id</div>
                    <div className="table-cell value">
                      <form id="generate-key-form" onSubmit={handleSubmit(onSubmit)}>
                        <TextFieldForm<GenerateAgentGroupApiKeyForm>
                          name="clientId"
                          control={control}
                          label="Client ID"
                          type="text"
                          inputMode="text"
                          validMask={/[^a-zA-Z0-9_]/}
                          multiline
                          maxLength={30}
                        />
                      </form>
                    </div>
                    {/* <div className="table-cell"></div> */}
                  </div>
                  <div className="table-body-row">
                    <div className="table-cell name">Api Key</div>
                    <div className="table-cell value">{agentGroupKeys.apiKey || ''}</div>
                    {/* <div className="table-cell"></div> */}
                  </div>
                </div>
                <div className="generate">
                  <Button
                    form="generate-key-form"
                    size="medium"
                    variant="contained"
                    type="submit"
                    disabled={!watch('clientId')}
                  >
                    Generate Api Key
                  </Button>
                </div>
              </div>
            </div>
          </StyledAgentGroupApiKeysTab>
        </>
      ) : null}
    </>
  )
}

export default AgentGroupApiKeysTab
