import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AuthType } from '../../types/authTypes'
import {
  fetchTokens,
  fetchAuthenticator,
  confirmAuthenticator,
  disableAuthenticator,
  resetError,
  refreshTokens,
  confirmTwoFa,
} from '../actions/authActions'

type TokensType = { accessToken: string; refreshToken: string }
type FetchTokensType = { tokens: TokensType; twoFactorEnabled: boolean; isAuth: boolean }

const initialState: AuthType = {
  tokens: {
    accessToken: '',
    refreshToken: '',
  },
  tokensFetchStart: false,
  tokensFetchFinished: false,
  tokensRefreshStart: false,
  tokensRefreshFinished: false,
  authenticator: {
    sharedKey: '',
    authenticatorUri: '',
  },
  authenticatorFetchStart: false,
  authenticatorFetchFinished: false,
  authenticatorConfirmStart: false,
  authenticatorConfirmFinished: false,
  authenticatorDisableStart: false,
  authenticatorDisableFinished: false,
  confirmTwoFaStart: false,
  confirmTwoFaFinished: false,
  twoFactorEnabled: false,
  isAuth: false,
  error: '',
}

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      localStorage.removeItem('tokens')
      window.location.reload()
    },
  },
  extraReducers: {
    [fetchTokens.pending.type]: state => {
      state.tokensFetchStart = true
      state.error = ''
    },
    [fetchTokens.fulfilled.type]: (state, action: PayloadAction<FetchTokensType>) => {
      state.tokensFetchFinished = true
      state.tokensFetchStart = false
      state.tokens = { ...action.payload.tokens }
      state.twoFactorEnabled = action.payload.twoFactorEnabled
      state.isAuth = action.payload.isAuth
    },
    [fetchTokens.rejected.type]: (state, action: PayloadAction<string>) => {
      state.tokensFetchStart = false
      state.error = action.payload
    },

    [refreshTokens.pending.type]: state => {
      state.tokensRefreshStart = true
      state.error = ''
    },
    [refreshTokens.fulfilled.type]: (state, action: PayloadAction<TokensType>) => {
      state.tokensRefreshFinished = true
      state.tokensRefreshStart = false
      state.tokens = action.payload
    },
    [refreshTokens.rejected.type]: (state, action: PayloadAction<string>) => {
      state.tokensRefreshStart = false
      state.error = action.payload
    },

    [fetchAuthenticator.pending.type]: state => {
      state.authenticatorFetchStart = true
      state.error = ''
    },
    [fetchAuthenticator.fulfilled.type]: (
      state,
      action: PayloadAction<{ sharedKey: string; authenticatorUri: string }>
    ) => {
      state.authenticatorFetchFinished = true
      state.authenticatorFetchStart = false
      state.authenticator = action.payload
    },
    [fetchAuthenticator.rejected.type]: (state, action: PayloadAction<string>) => {
      state.authenticatorFetchStart = true
      state.error = action.payload
    },

    [confirmAuthenticator.pending.type]: state => {
      state.authenticatorConfirmStart = true
      state.error = ''
    },
    [confirmAuthenticator.fulfilled.type]: state => {
      state.authenticatorConfirmFinished = true
      state.authenticatorConfirmStart = false
    },
    [confirmAuthenticator.rejected.type]: (state, action: PayloadAction<string>) => {
      state.authenticatorConfirmStart = false
      state.error = action.payload
    },

    [disableAuthenticator.pending.type]: state => {
      state.authenticatorDisableStart = true
      state.error = ''
    },
    [disableAuthenticator.fulfilled.type]: state => {
      state.authenticatorDisableFinished = true
      state.authenticatorDisableStart = false
    },
    [disableAuthenticator.rejected.type]: (state, action: PayloadAction<string>) => {
      state.authenticatorDisableStart = false
      state.error = action.payload
    },

    [confirmTwoFa.pending.type]: state => {
      state.confirmTwoFaStart = true
      state.error = ''
    },
    [confirmTwoFa.fulfilled.type]: (state, action: PayloadAction<FetchTokensType>) => {
      state.confirmTwoFaFinished = true
      state.confirmTwoFaStart = false
      state.tokens = action.payload.tokens
      state.twoFactorEnabled = action.payload.twoFactorEnabled
      state.isAuth = action.payload.isAuth
    },
    [confirmTwoFa.rejected.type]: (state, action: PayloadAction<string>) => {
      state.authenticatorDisableStart = false
      state.error = action.payload
    },

    [resetError.type]: state => {
      state.error = ''
    },
  },
})

export const { logout } = auth.actions

export default auth.reducer
