import { Button, Container, Grid, Typography } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import CustomTable from '../../../components/CustomTable/CustomTable'
import { useAppDispatch, useAppSelector } from '../../../store'
import { worldPayActions } from '../../../store/actions/wpActions'
import { WpCardPaymentAmountItem, WpCardPaymentStatusItem } from '../../../types/wpTypes'
import wpTableConfigs from '../../../utils/config/tableConfig/wp'
import { getDateWithTime } from '../../../utils/dateFormating'
import {
  getCardPaymentStatus,
  getPaymentService,
  getWorldPayPaymentStatus,
} from '../../../utils/statusUtils/wpStatusUtils'

const domain = process.env.REACT_APP_BACK_OFFICE_DOMAIN

const WpKlCardPaymentDetail = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams()

  const cardPaymentDetails = useAppSelector(state => state.worldPay.klCardPaymentDetail)

  const amountTableConfig = useMemo(() => {
    return wpTableConfigs.wpCardPaymentAmountTable()
  }, [])

  const statusTableConfig = useMemo(() => {
    return wpTableConfigs.wpCardPaymentStatusConfig()
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(worldPayActions.getKlCardPaymentDetail(id))
    }
  }, [dispatch, id])
  return (
    <Container className="pt-25 pb-10 h-100-percent" maxWidth="xl">
      {cardPaymentDetails ? (
        <Grid container spacing={2} sx={{ margin: 0, width: '100%' }}>
          <Grid sx={{ paddingLeft: 0, paddingTop: 0 }} item xs={12} sm={5} md={4}>
            <Typography fontWeight="bold">Order Id:</Typography>
            <Typography mb={1}>{cardPaymentDetails?.order.id}</Typography>

            <Button
              sx={{ width: '160px', marginBottom: '8px' }}
              size="small"
              variant="contained"
              href={`${domain}/KuvaLocal/Order/${cardPaymentDetails.order.id}`}
              target="_blank"
            >
              Order Details
            </Button>

            <Typography fontWeight="bold">Order Reference:</Typography>
            <Typography mb={1}>{cardPaymentDetails?.order.reference}</Typography>

            <Typography fontWeight="bold">Created Date(UTC):</Typography>
            <Typography mb={1}>{getDateWithTime(cardPaymentDetails?.createdDate)}</Typography>

            <Typography fontWeight="bold">Updated Date(UTC):</Typography>
            <Typography mb={1}>{getDateWithTime(cardPaymentDetails?.updatedDate)}</Typography>

            <Typography fontWeight="bold">Card Payment Status:</Typography>
            <Typography mb={1}>{getCardPaymentStatus(cardPaymentDetails?.status)}</Typography>

            {cardPaymentDetails?.paymentType && (
              <>
                <Typography fontWeight="bold">Payment Type:</Typography>
                <Typography mb={1}>
                  {getWorldPayPaymentStatus(cardPaymentDetails?.paymentType)}
                </Typography>
              </>
            )}

            <Typography fontWeight="bold">Payment Service:</Typography>
            <Typography mb={1}>{getPaymentService(cardPaymentDetails?.paymentService)}</Typography>

            <Typography fontWeight="bold">User:</Typography>
            <Typography>
              {cardPaymentDetails?.user.firstName + ' ' + cardPaymentDetails?.user.lastName}
            </Typography>

            <Typography>{cardPaymentDetails?.user.phoneNumber}</Typography>
            <Typography mb={1}>{cardPaymentDetails?.user.email}</Typography>

            <Button
              sx={{ width: '160px', marginBottom: '8px' }}
              size="small"
              variant="contained"
              href={`${domain}/MobileUsers/${cardPaymentDetails.user.extId}`}
              target="_blank"
            >
              User Details
            </Button>
          </Grid>
          <Grid
            sx={{
              paddingLeft: 0,
              paddingTop: 0,
              overflowX: { md: 'auto' },
              height: { md: '85dvh' },
            }}
            item
            xs={12}
            sm={7}
            md={8}
          >
            <h3>Card Payment Amount Info</h3>
            <CustomTable<WpCardPaymentAmountItem>
              tableConfig={amountTableConfig}
              tableData={cardPaymentDetails?.amounts ? cardPaymentDetails?.amounts : []}
            />
            <h3>Card Payment Status Info</h3>
            <CustomTable<WpCardPaymentStatusItem>
              tableConfig={statusTableConfig}
              tableData={cardPaymentDetails?.statuses ? cardPaymentDetails?.statuses : []}
            />
          </Grid>
        </Grid>
      ) : null}
    </Container>
  )
}

export default WpKlCardPaymentDetail
