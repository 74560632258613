import { ButtonBase, ListItemButton, ListItemText, styled } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'

const StyledSideBarNavLink = styled('div')({
  minWidth: '200px',
  a: {
    display: 'block',
    color: 'inherit',
    marginRight: '25px',
    // marginRight: '20px',
    '&.active': {
      background: '#29EDFC',
      borderTopRightRadius: '30px',
      borderBottomRightRadius: '30px',
    },

    '.MuiTouchRipple-root': {
      borderTopRightRadius: '30px',
      borderBottomRightRadius: '30px',
    },
    '.MuiButtonBase-root': {
      display: 'block',
      borderTopRightRadius: '30px',
      borderBottomRightRadius: '30px',
      height: '48px',
      width: '100%',
      textAlign: 'start',
      padding: '0px 16px',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
})

type SideBarNavLinkProps = {
  to: string
  label: string
}

const SideBarNavLink = ({ to, label }: SideBarNavLinkProps) => {
  return (
    <StyledSideBarNavLink>
      <NavLink to={to}>
        {/* {label} */}
        <ButtonBase>{label}</ButtonBase>
        {/* <ListItemButton>
          <ListItemText primary={label} />
        </ListItemButton> */}
      </NavLink>
    </StyledSideBarNavLink>
  )
}

export default SideBarNavLink
