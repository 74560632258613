import { styled } from '@mui/material'
import { TableConfig } from '../../../../../types/tableTyles'
import TableHeader from '../../../../../components/CustomTable/TableHeader'
import TableRowWithCollapsing from './TableRowWithCollapsing'

type SimpleTableWithCollapsingRowProps<T> = {
  tableConfig: TableConfig<T>
  tableData: T[]
  CollapsibleComponent: (props: { itemRow: T }) => JSX.Element
}

const StyledCustomTable = styled('div')({
  marginBottom: '15px',
  '.table-wrap': {
    overflowX: 'auto',
    overflowY: 'auto',
  },
})

const SimpleTableWithCollapsingRow = <T extends { extId?: string | number; id?: string | number }>({
  tableConfig,
  tableData,
  CollapsibleComponent,
}: SimpleTableWithCollapsingRowProps<T>) => {
  return (
    <StyledCustomTable>
      <div className="table-wrap">
        <TableHeader tableConfig={tableConfig} />
        <div className="table-body">
          {tableData.map((rowData, rowIndex) => (
            <TableRowWithCollapsing<T>
              key={rowData.extId || rowData.id}
              rowData={rowData}
              rowIndex={rowIndex}
              tableConfig={tableConfig}
              CollapsibleComponent={CollapsibleComponent}
            />
          ))}
        </div>
      </div>
    </StyledCustomTable>
  )
}

export default SimpleTableWithCollapsingRow
