import { createAsyncThunk } from '@reduxjs/toolkit'
import apiRequestServiceWithRefresh from '../../services/apiRequestServiceWithRefresh'
import { RequestErrType } from '../../types/generalTypes'
import { GetWPKuvaLocalListRequest, GetWpKwlListRequest } from '../../types/wpTypes'
import {
  getWpKlCardPaymentDetailByIdPath,
  getWpKlCardPaymentListPath,
  getWpKwlCardPaymentDetailByIdPath,
  getWpKwlCardPaymentListPath,
} from '../../utils/apiPaths'

const getKwlCardPayments = createAsyncThunk(
  'worldPay/getKwlCardPayments',
  async (arg: GetWpKwlListRequest, { rejectWithValue, dispatch }) => {
    const { queryParams } = arg
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        `${getWpKwlCardPaymentListPath}?${queryParams}`
      )

      return result
    } catch (err) {
      return rejectWithValue(err as RequestErrType)
    }
  }
)

const getKLCardPayments = createAsyncThunk(
  'worldPay/getKLCardPayments',
  async (arg: GetWPKuvaLocalListRequest, { rejectWithValue, dispatch }) => {
    const { queryParams } = arg
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        `${getWpKlCardPaymentListPath}?${queryParams}`
      )

      return result
    } catch (err) {
      return rejectWithValue(err as RequestErrType)
    }
  }
)

const getKlCardPaymentDetail = createAsyncThunk(
  'worldPay/getKlCardPaymentDetail',
  async (id: string | number, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        getWpKlCardPaymentDetailByIdPath(id)
      )

      return result
    } catch (err) {
      return rejectWithValue(err as RequestErrType)
    }
  }
)

const getKwlCardPaymentDetail = createAsyncThunk(
  'worldPay/getKwlCardPaymentDetail',
  async (id: string | number, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        getWpKwlCardPaymentDetailByIdPath(id)
      )

      return result
    } catch (err) {
      return rejectWithValue(err as RequestErrType)
    }
  }
)

export const worldPayActions = {
  getKwlCardPayments,
  getKLCardPayments,
  getKlCardPaymentDetail,
  getKwlCardPaymentDetail,
}
