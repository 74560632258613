import { useCallback, useEffect, useMemo } from 'react'
import { Button, styled } from '@mui/material'
import {
  StyledCloseButtonModal,
  StyledDialog,
  StyledDialogActions,
  StyledDialogBody,
  StyledDialogContent,
  StyledDialogHeader,
  StyledDialogTitle,
} from '../../../../../components/modal/styledModalComponent'
import { useAppDispatch, useAppSelector } from '../../../../../store'
import { closeGlobalModal } from '../../../../../store/reducers/globalModalReducer'

import CancelIcon from '@mui/icons-material/Cancel'
import { remittanceActions } from '../../../../../store/actions/kwlActions'
import InstanceCurrencyForm from './InstanceCurrencyForm'
import { CreateOrUpdateInstanceCurrencyRequestBody } from '../../../../../types/remittanceTypes'
import { removeAfterClosingInstanceCurrencyModal } from '../../../../../store/reducers/kwlReducers'

const StyledDialogModalTitle = styled(StyledDialogTitle)({
  width: '100%',
  paddingLeft: 0,
})

const StyledInstanceCurrencyModal = styled(StyledDialog)({
  '.MuiDialog-paper': {
    width: '600px',
  },
})

type InstanceCurrencyModalProps = {
  isOpenDialog: boolean
  variant: 'edit-instance-currency' | 'add-instance-currency'
}

const InstanceCurrencyModal = ({ isOpenDialog, variant }: InstanceCurrencyModalProps) => {
  const dispatch = useAppDispatch()
  const { id: instanceId, additionalId: currencyId } = useAppSelector(state => state.globalModal)
  const {
    availableInstanceCurrencyList,
    allCurrencyList,
    paymentProviderMethods,
    instanceCurrencyDetails,
  } = useAppSelector(state => state.kwl)

  const {
    getInstanceCurrencyLoadState,
    getPaymentProviderMethodsLoadState,
    getAllCurrenciesLoadState,
  } = useAppSelector(state => state.kwl)

  const isLoading = useMemo(
    () =>
      getInstanceCurrencyLoadState.isLoading ||
      getPaymentProviderMethodsLoadState.isLoading ||
      getAllCurrenciesLoadState.isLoading,
    [
      getAllCurrenciesLoadState.isLoading,
      getInstanceCurrencyLoadState.isLoading,
      getPaymentProviderMethodsLoadState.isLoading,
    ]
  )

  useEffect(() => {
    dispatch(remittanceActions.getAllCurrencies())

    variant === 'add-instance-currency' &&
      dispatch(remittanceActions.getAvailableInstanceCurrencyList(instanceId))
    dispatch(remittanceActions.getPaymentProviderMethods())

    currencyId && dispatch(remittanceActions.getInstanceCurrency({ instanceId, currencyId }))
  }, [currencyId, dispatch, instanceId, variant])

  const closeModal = useCallback(() => {
    dispatch(closeGlobalModal())
    dispatch(removeAfterClosingInstanceCurrencyModal())
  }, [dispatch])

  const onConfirm = useCallback(
    (body: CreateOrUpdateInstanceCurrencyRequestBody) => {
      if (variant === 'add-instance-currency') {
        const requestArg = {
          instanceId: instanceId,
          body,
        }

        dispatch(remittanceActions.createInstanceCurrency(requestArg))
      }
      if (variant === 'edit-instance-currency') {
        const requestArgUpdate = {
          instanceId: instanceId,
          currencyId: currencyId ? currencyId : 0,
          body,
        }

        dispatch(remittanceActions.updateInstanceCurrency(requestArgUpdate))
      }

      closeModal()
    },
    [closeModal, currencyId, dispatch, instanceId, variant]
  )
  const titleText = useMemo(() => {
    if (variant === 'add-instance-currency') {
      return 'Adding New Currency'
    }
    if (variant === 'edit-instance-currency') {
      return 'Editing Currency'
    }
  }, [variant])

  const modifiedAllCurrencyList = useMemo(() => {
    if (variant === 'add-instance-currency') {
      return availableInstanceCurrencyList.map(currencyItem => ({
        label: currencyItem.displayName,
        value: currencyItem.id,
      }))
    } else {
      return allCurrencyList.map(currencyItem => ({
        label: currencyItem.displayName,
        value: currencyItem.id,
      }))
    }
  }, [allCurrencyList, availableInstanceCurrencyList, variant])

  return (
    <>
      {!isLoading && (
        <StyledInstanceCurrencyModal open={isOpenDialog} onClose={closeModal}>
          <StyledDialogHeader>
            <StyledDialogModalTitle>{titleText}</StyledDialogModalTitle>
            <StyledCloseButtonModal onClick={closeModal}>
              Close
              <CancelIcon />
            </StyledCloseButtonModal>
          </StyledDialogHeader>
          <StyledDialogBody>
            <StyledDialogContent>
              <InstanceCurrencyForm
                modifiedAllCurrencyList={modifiedAllCurrencyList}
                instanceCurrencyDetails={instanceCurrencyDetails}
                paymentProviderMethods={paymentProviderMethods}
                onConfirm={onConfirm}
                variant={variant}
              />
            </StyledDialogContent>
            <StyledDialogActions>
              <Button size="large" variant="outlined" onClick={closeModal}>
                Cancel
              </Button>
              <Button form="instance-currency-form" type="submit" size="large" variant="contained">
                Save
              </Button>
            </StyledDialogActions>
          </StyledDialogBody>
        </StyledInstanceCurrencyModal>
      )}
    </>
  )
}

export default InstanceCurrencyModal
