import { RiskLevelMarker } from './commonTypes'
import { EntityType } from './generalTypes'
import { PaymentMethod } from './remittanceTypes'
import { UserDataType } from './userTypes'
import { WpKwlItem } from './wpTypes'

export type TopUpUsdkDataType = {
  requestType: number
  user: UserDataType
  id: number
  extId: string
  riskScore: number
  riskLevelMarker: number
  createdDate: string
  reference: string
  sourceAmount: number
  sourceCurrency: string
  destinationAmount: number
  destinationCurrency: string
  status: number
}

export type TopUpCashrailDataType = {
  agent: UserDataType
  id: number
  extId: string
  createdDate: string
  instanceName?: string
  riskLevelMarker: RiskLevelMarker
  riskScore: number
  reference: string
  sourceAmount: number
  sourceCurrency: string
  destinationAmount: number
  destinationCurrency: string
  status: number
  requestType: number
}

export type TopUpKWLDataType = TopUpCashrailDataType & { agent: null | UserDataType }

export type TopUpType = {
  usdk: EntityType<TopUpUsdkDataType>
  cashrail: EntityType<TopUpCashrailDataType>
  kwl: EntityType<TopUpKWLDataType>
  error: string
  remittanceTopUpDetails: GetRemittanceTopUpDetailsResponse | null
  remittanceCardPaymentTxList: Array<WpKwlItem>

  remittanceTopUpDetailsLoadState: {
    isLoading: boolean
    isLoaded: boolean
  }
}

export enum ConversionStatus {
  Pending = 10,
  Settled = 20,
  Cancelled = 30,
  CancelFailed = 40,
  CreationFailed = 50,
}

export enum TopUpPaymentProvider {
  ClearJunctionBank,
  CurrencyCloudBank,
  WorldPay,
}

export enum TopUpStatus {
  Ordered = 10,
  PaymentInProgress = 15,
  Transferred = 20,
  Completed = 30,
  Cancelled = 40,
  Expired = 50,
  FundsCleared = 60,
  TransferFailed = 70,
  IncorrectPayin = 80,
  ErrorNopCommerceOrder = 90,
  Underpaid = 100,
  Overpaid = 110,
  FundsReceived = 120,
}

export enum AddFundsType {
  BankTransfer = 10,
  Cash = 20,
  KuvaLocalBankTransfer = 30,
  KuvaLocalCash = 40,
  KuvaDirectBankTransfer = 50,
  CashOnDelivery = 60,
  ChangeMerchant = 70,
  CreditVendor = 80,
  Cashrail = 90,
  KuvaWhiteLabel = 100,
  Multicash = 110,
}

export enum SourceOfFunds {
  Other,
  Salary,
  Savings,
  Investments,
  Gift,
  Loan,
  Winnings,
  PropertySale,
  BusinessOwnership,
}

export enum ReasonForTransfer {
  Other,
  Personal,
  Gift,
  Schooling,
  ExpenseReimbursement,
  InvoicePayment,
  MedicalCosts,
  Transportation,
}

export type GetRemittanceTopUpDetailsResponse = {
  conversion: {
    shortReference: string
    settlementDate: string
    status: ConversionStatus
  }
  branchId: string | null
  tellerId: string | null
  kuvaFee: number
  currencyCloudFee: number
  clearJunctionFee: number
  // treasuryTransfer: null
  agentFixedFee: number
  agentPercentFee: number
  settlementCurrency: string
  toAgentFixedCashFee: number
  toAgentPercentCashFee: number
  kuvaFixedCashFee: number
  kuvaPercentCashFee: number
  liquidityFeePersentsAmount: number
  onboardingFeeSrcCurrencyAmount: number
  onboardingFeeDestCurrencyAmount: number
  kdAgentFeeSrcCurrencyAmount: number
  kdAgentFeeDestCurrencyAmount: number
  initialExpectedAmount: number
  change: number
  totalFee: number
  localUser: {
    firstName: string
    lastName: string
    phoneNumber: string
    extId: string
    email: string
  }
  CJFeePayerKuva: boolean
  amountThreshold: number
  paymentProvider: TopUpPaymentProvider
  requesterName: string | null
  requesterPhoneNumber: string | null
  id: string
  createdDate: string
  expirationDate: string
  reference: string
  digitalReference: string
  status: TopUpStatus
  requestType: AddFundsType
  cashOutId: string
  instanceUrl: string | null
  instanceName: string
  rate: number
  totalCashInProviderFeeSrcCurrAmount: number
  totalRewardFeeSrcCurrAmount: number
  totalCashOutProviderFeeSrcCurrencyAmount: number
  totalCashOutProviderFeeDestCurrencyAmount: number
  from: {
    amount: number
    currency: string
    totalFromAmount: number
  }
  to: {
    amount: number
    currency: string
    totalFromAmount: number
  }
  transactionDetails: {
    reasonTransfer: ReasonForTransfer,
    reasonTransferDetails: string,
    sourceOfFunds: SourceOfFunds,
    sourceOfFundsDetails: string,
    // payoutType: null
  }
  withdrawMethod: PaymentMethod,
  withdrawPaymentProviderName: string
  cashInMethod: PaymentMethod
  cashInPaymentProviderName: string
}

export type RemittanceTopUpFilterForm = {
  reference: string
  riskLevelMarker: RiskLevelMarker | ''
}
