import {
  CardPaymentService,
  CardPaymentStatus,
  CreditCardPaymentDetailedStatus,
  CreditCardPaymentEvent,
  WorldPayPaymentType,
} from '../../types/wpTypes'

export const getCardPaymentStatus = (statusCode: CardPaymentStatus | undefined) => {
  const statuses = {
    [CardPaymentStatus.Initiated]: 'Initiated',
    [CardPaymentStatus.PaymentInProgress]: 'Payment In Progress',
    [CardPaymentStatus.Authenticated]: 'Authenticated',
    [CardPaymentStatus.Challenge]: 'Challenge',
    [CardPaymentStatus.Verified]: 'Verified',
    [CardPaymentStatus.Authorizing]: 'Authorizing',
    [CardPaymentStatus.Authorized]: 'Authorized',
    [CardPaymentStatus.Settled]: 'Settled',
    [CardPaymentStatus.Canceled]: 'Canceled',
    [CardPaymentStatus.Aborted]: 'Aborted',
    [CardPaymentStatus.FullyRefunded]: 'Fully Refunded',
    [CardPaymentStatus.PartiallyRefunded]: 'Partially Refunded',
    [CardPaymentStatus.Failed]: 'Failed',
    [CardPaymentStatus.FraudWarningReceived]: 'Fraud Warning Received',
  }
  return statusCode !== undefined ? statuses[statusCode] : ''
}

export const getCardPaymentEventStatus = (statusCode: CreditCardPaymentEvent | undefined) => {
  const statuses = {
    [CreditCardPaymentEvent.Initiation]: 'Initiation',
    [CreditCardPaymentEvent.DeviceDataInitialization]: 'Device Data Initialization',
    [CreditCardPaymentEvent.Authentication]: 'Authentication',
    [CreditCardPaymentEvent.Verification]: 'Verification',
    [CreditCardPaymentEvent.Authorization]: 'Authorization',
    [CreditCardPaymentEvent.Settlement]: 'Settlement',
    [CreditCardPaymentEvent.PartialSettlement]: 'Partial Settlement',
    [CreditCardPaymentEvent.Cancel]: 'Cancel',
    [CreditCardPaymentEvent.FullyRefund]: 'Fully Refund',
    [CreditCardPaymentEvent.PartialRefund]: 'Partial Refund',
    [CreditCardPaymentEvent.Abort]: 'Abort',
    [CreditCardPaymentEvent.FraudAssessment]: 'Fraud Assessment',
    [CreditCardPaymentEvent.PaymentLinkCreated]: 'Payment Link Created',
    [CreditCardPaymentEvent.NewDataFromThirdPartyService]: 'New Data From Third Party Service',
    [CreditCardPaymentEvent.ThunesWebhook]: 'Thunes Webhook',
  }
  return statusCode !== undefined ? statuses[statusCode] : ''
}

export const getCardPaymentDetailedStatus = (
  statusCode: CreditCardPaymentDetailedStatus | undefined
) => {
  const statuses = {
    [CreditCardPaymentDetailedStatus.Started]: 'Started',
    [CreditCardPaymentDetailedStatus.Pending]: 'Pending',
    [CreditCardPaymentDetailedStatus.Succeeded]: 'Succeeded',
    [CreditCardPaymentDetailedStatus.SucceededWithIssue]: 'Succeeded With Issue',
    [CreditCardPaymentDetailedStatus.Failed]: 'Failed',
  }
  return statusCode !== undefined ? statuses[statusCode] : ''
}

export const getWorldPayPaymentStatus = (statusCode: WorldPayPaymentType | undefined) => {
  const statuses = {
    [WorldPayPaymentType.SavedCard]: 'Saved Card',
    [WorldPayPaymentType.CardSession]: 'Card Session',
    [WorldPayPaymentType.GooglePay]: 'GooglePay',
    [WorldPayPaymentType.ApplePay]: 'ApplePay',
    [WorldPayPaymentType.None]: 'None',
  }
  return statusCode !== undefined ? statuses[statusCode] : ''
}

export const getPaymentService = (statusCode: CardPaymentService | undefined) => {
  const statuses = {
    [CardPaymentService.Stripe]: 'Stripe',
    [CardPaymentService.WorldPay]: 'WorldPay',
    [CardPaymentService.Thunes]: 'Thunes',
  }
  return statusCode !== undefined ? statuses[statusCode] : ''
}

export const cardPaymentStatusList = [
  { value: Number([CardPaymentStatus.Initiated]), label: 'Initiated' },
  { value: Number([CardPaymentStatus.Authenticated]), label: 'Authenticated' },
  { value: Number([CardPaymentStatus.Challenge]), label: 'Challenge' },
  { value: Number([CardPaymentStatus.Verified]), label: 'Verified' },
  { value: Number([CardPaymentStatus.Authorized]), label: 'Authorized' },
  { value: Number([CardPaymentStatus.Settled]), label: 'Settled' },
  { value: Number([CardPaymentStatus.Canceled]), label: 'Canceled' },
  { value: Number([CardPaymentStatus.Aborted]), label: 'Aborted' },
  { value: Number([CardPaymentStatus.FullyRefunded]), label: 'Fully Refunded' },
  { value: Number([CardPaymentStatus.PartiallyRefunded]), label: 'Partially Refunded' },
  { value: Number([CardPaymentStatus.Failed]), label: 'Failed' },
  { value: Number([CardPaymentStatus.FraudWarningReceived]), label: 'Fraud Warning Received' },
]

export const worldPayPaymentType = [
  { value: Number([WorldPayPaymentType.SavedCard]), label: 'Saved Card' },
  { value: Number([WorldPayPaymentType.CardSession]), label: 'Card Session' },
  { value: Number([WorldPayPaymentType.GooglePay]), label: 'GooglePay' },
  { value: Number([WorldPayPaymentType.ApplePay]), label: 'ApplePay' },
  { value: Number([WorldPayPaymentType.None]), label: 'None' },
]
