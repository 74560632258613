import { Button, styled } from '@mui/material'
import React, { useCallback } from 'react'
import { useAppDispatch } from '../../../../../store'
import { InstancePaymentOptionType } from '../../../../../types/remittanceTypes'
import { remittanceActions } from '../../../../../store/actions/kwlActions'

const StyledGettingDataByCountryBtn = styled(Button)({
  backgroundColor: '#FD8111',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: '#FD8111',
    filter: 'brightness(80%)',
  },
})

type GettingDataByCountryBtnProps = {
  instanceId?: string
  countryId: number
  type: InstancePaymentOptionType
}

const GettingDataByCountryBtn = ({ instanceId, countryId, type }: GettingDataByCountryBtnProps) => {
  const dispatch = useAppDispatch()

  const onClick = useCallback(() => {
    instanceId &&
      dispatch(remittanceActions.getPaymentOptionsByCountry({ instanceId, countryId, type }))
  }, [countryId, dispatch, instanceId, type])
  
  return (
    <StyledGettingDataByCountryBtn size="small" variant="contained" onClick={onClick}>
      Select
    </StyledGettingDataByCountryBtn>
  )
}

export default GettingDataByCountryBtn
