import { styled } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'

export const StyledDialog = styled(Dialog)(({ theme }) => ({
	// width: '762px',
	// padding: '38px',
	'.MuiDialog-paper': {
		position: 'relative',
		maxWidth: '762px',
		//padding: '22px 30px',
		borderRadius: '10px',

		//backgroundColor: theme.palette.common.white,
		[theme.breakpoints.down('sm')]: {
			maxWidth: 'calc(100% - 30px)',
			minWidth: '200px',
			overflow: 'hidden',
		},
	},
}))

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
	font: '400 24px/28px Roboto,sans-serif',
	display: 'flex',
	justifyContent: 'space-between',
	padding: '0 25px',
	// marginBottom: '10px',
	width: '680px',
	

	[theme.breakpoints.down('sm')]:{
		width: '100%',
		padding: '0 70px 0 0',
		fontSize: '22px',
	}
}))

export const StyledDialogHeader = styled('div')(({ theme }) => ({
	padding: '22px 30px 16px',
	borderTopRightRadius: '10px',
	borderTopLeftRadius: '10px',
	backgroundColor: theme.palette.info.contrastText,
	[theme.breakpoints.down('sm')]:{
		padding: '14px 24px 10px',
	}
}))
export const StyledDialogBody = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	padding: '8px 30px 22px',
	borderBottomRightRadius: '10px',
	borderBottomLeftRadius: '10px',
	overflow: 'auto',

	[theme.breakpoints.down('sm')]:{
		padding: '8px 20px 22px',
		overflow: 'auto',
	}
}))

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
	padding: '0px',
	display: 'flex',
	flexDirection: 'column',
	'.user-info-dialog': {
		marginBottom: '25px',
		padding: '25px',
		backgroundColor: theme.palette.success.main,
		borderRadius: '10px',
		'&__row': {
			marginBottom: '6px',
			
			'& > p:first-of-type': {
				fontWeight: '500',
				minWidth: '130px',
				lineHeight: '32px',
			},
			'& > p:last-child': {
				width: 'calc(100% - 130px)',
				textAlign: 'right',
			},
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
				marginBottom: '8px',
				'& > *': {
					margin: '0',
					fontSize: '16px',
					
				},
				'& > p:first-of-type':{
					lineHeight: '24px',
				},
				'& > p:last-child': {
					width: '100%',
					textAlign: 'left',
				},
				'& > div': {
					justifyContent: 'space-between'
				}
			}
		},
		[theme.breakpoints.down('sm')]:{
			padding: '18px',
		}
	},
	'.user-info-action': {
		marginBottom: '25px',
		padding: '25px',
		backgroundColor: theme.palette.warning.main,
		borderRadius: '10px',
		[theme.breakpoints.down('sm')]:{
			padding: '12px 18px',
		}
	}
}))

export const StyledDialogContentText = styled(DialogContentText)(({ theme }) => ({
	font: '400 18px/21px Roboto,sans-serif',
	color: 'black',
	padding: '0 25px',
	marginBottom: '24px',
	[theme.breakpoints.down('sm')]:{
		padding: '0',
		fontSize: '16px',
		lineHeight: '18px',
	}
}))

export const StyledDialogActions = styled(DialogActions)(({ theme }) =>({
	display: 'flex',
	justifyContent: 'space-between',
	padding: '0 25px',
	[theme.breakpoints.down('sm')]: {
		padding: '0',
		flexWrap: 'wrap',
		justifyContent: 'center',
		'button': {
			margin: '0 8px 8px 8px!important'
		}
	}
}))

export const StyledCloseButtonModal = styled(IconButton)(({ theme }) => ({
	position: 'absolute',
	top: '14px',
	right: '14px',
	font: '400 14px/16px Roboto,sans-serif',
	'&:hover': {
		backgroundColor: 'transparent',
	},
	'&:focus': {
		backgroundColor: 'transparent',
	},
	svg: {
		marginLeft: '8px',
	},
	[theme.breakpoints.down('sm')]:{
		top: '6px',
		right: '6px',
	}
}))
