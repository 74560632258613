import { Link } from 'react-router-dom'
import { AgentGroupItem } from '../../../types/agentGroupTypes'
import { TableConfig } from '../../../types/tableTyles'
import { Button } from '@mui/material'

const agentGroupTableConfig = (): TableConfig<AgentGroupItem> => {
  return {
    columns: [
      {
        id: 'title',
        className: 'title',
        header: 'Title',
        contentColumn: item => item.title,
        // width: '30%',
        width: {
          minWidth: '200px',
          width: '30%',
        },
      },
      {
        id: 'currency',
        header: 'Currency',
        contentColumn: item => item.currency,
        // width: '30%',
        width: {
          minWidth: '100px',
          width: '10%',
        },
      },
      {
        id: 'settlementCurrency',
        header: 'Settlement Currency',
        contentColumn: item => item.settlementCurrency,
        // width: '30%',
        width: {
          minWidth: '200px',
          width: '15%',
        },
      },
      {
        id: 'rate',
        header: 'Rate',
        contentColumn: item => item.rate.toFixed(2),
        // width: '30%',
        width: {
          minWidth: '120px',
          width: '7%',
        },
      },
      {
        id: 'fixedFee',
        header: 'Fixed Fee',
        contentColumn: item => item.agentFixedFee.toFixed(2),
        // width: '30%',
        width: {
          minWidth: '120px',
          width: '8%',
        },
      },
      {
        id: 'percentageFee',
        header: 'Percentage Fee',
        contentColumn: item => item.agentPercentageFee.toFixed(2),
        // width: '30%',
        width: {
          minWidth: '160px',
          width: '15%',
        },
      },
      {
        id: 'details',
        header: '',
        contentColumn: item => (
          <Link to={`/agent-groups/edit/${item.id}`}>
            <Button
              // className="w-130"
              variant='contained'
              size="small"
              // onClick={clearFilters}
              // disabled={isDisabled}
            >
              Details
            </Button>
          </Link>
        ),
        // width: '30%',
        width: {
          minWidth: '130px',
          width: '15%',
        },
      },
    ],
  }
}

const agentGroupTableConfigs = { agentGroupTableConfig }

export default agentGroupTableConfigs
