import { Link } from 'react-router-dom'
import { TableConfig } from '../../../types/tableTyles'
import { WpKwlItem } from '../../../types/wpTypes'
import { getDateWithTime } from '../../dateFormating'
import { getCardPaymentStatus } from '../../statusUtils/wpStatusUtils'
import { Button } from '@mui/material'

const remittanceCardPaymentTxTableConfig = (): TableConfig<WpKwlItem> => {
  return {
    columns: [
      {
        id: 'details',
        header: 'Details',
        contentColumn: item => (
          <Link to={`/world-pay/kwl-payments/${item.extId}`}>
            <Button variant="contained" size="small">
              Details
            </Button>
          </Link>
        ),
        width: {
          minWidth: '120px',
          width: '15%',
        },
      },
      {
        id: 'creditCardNumber',
        header: 'Card Number',
        contentColumn: item => item.creditCardNumber,
        width: {
          minWidth: '180px',
          width: '30%',
        },
      },
      {
        id: 'createdDate',
        header: 'Created Date(UTC)',
        contentColumn: item => getDateWithTime(item.createdDate),
        // width: '30%',
        width: {
          minWidth: '200px',
          width: '35%',
        },
      },
      {
        id: 'status',
        header: 'Status',
        contentColumn: item => getCardPaymentStatus(item.status),
        // width: '20%',
        width: {
          minWidth: '130px',
          width: '20%',
        },
      },
      {
        id: 'amount',
        header: 'Amount',
        contentColumn: item => item.amount,
        // width: '10%',
        width: {
          minWidth: '130px',
          width: '20%',
        },
      },
      {
        id: 'currency',
        header: 'Currency',
        contentColumn: item => item.currency,
        // width: '10%',
        width: {
          minWidth: '100px',
          width: '20%',
        },
      },
    ],
  }
}

export const topUpTableConfigs = {
  remittanceCardPaymentTxTableConfig,
}
