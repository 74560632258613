type ObjType = {
  [key: string]:
    | {
        [prop: string]: string | number | number[] | string[] | []
      }
    | string
    | number
    | boolean
    | number[]
    | string[]
    | []
}

export default (obj: ObjType) => {
  let resObj: ObjType = {}

  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      for (const [prop, val] of Object.entries(obj[key])) {
        resObj[`${key}.${prop}`] = val
      }
    } else resObj[key] = obj[key]
  })

  return resObj
}
