import { ChangeEvent, useCallback, useState } from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

type SearchTextProps = {
  label: string
  onParentChange: (value: string) => void
}

const SearchField = ({ onParentChange, label }: SearchTextProps) => {
  const [fieldValue, setFieldValue] = useState('')

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value

      onParentChange(value)
      setFieldValue(value)
    },
    [onParentChange]
  )

  const clearSearchField = useCallback(() => {
    onParentChange('')
    setFieldValue('')
  }, [onParentChange])

  return (
    <TextField
      label={label}
      size="small"
      fullWidth
      value={fieldValue}
      onChange={onChange}
      sx={{ marginBottom: '16px' }}
      {...(fieldValue && {
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={clearSearchField}>
                <CancelIcon />
              </IconButton>
            </InputAdornment>
          ),
        },
      })}
      inputProps={{
        maxLength: 32,
      }}
    />
  )
}

export default SearchField
