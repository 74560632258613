import { Button, Grid, Typography, styled } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { remittanceActions } from '../../../../store/actions/kwlActions'
import remittanceTableConfig from '../../../../utils/config/tableConfig/remittance'
import CountryListTable from './CurrencyTable.tsx/CountryListTable'
import SearchField from '../../../../components/reusable/SearchField'
import {
  Country,
  InstancePaymentOptionType,
  PaymentMethod,
} from '../../../../types/remittanceTypes'
import { openGlobalModal } from '../../../../store/reducers/globalModalReducer'

const getListBySearchValue = (
  arr: any,
  searchValue: string,
  arrOfFieldsForSearch: Array<string>
) => {
  const value = searchValue.trim().toLowerCase()

  return arr.filter((item: any) =>
    arrOfFieldsForSearch.some(entry => {
      return String(item[entry]).toLowerCase().includes(value)
    })
  )
}

type CurrencyTabProps = {
  instanceId: string | undefined
}

const StyledCurrencyTab = styled(Grid)({
  margin: 0,
  width: '100%',
  position: 'relative',
  height: 'calc(100dvh - 170px)',
  overflowY: 'auto',
})

const CurrencyTab = ({ instanceId }: CurrencyTabProps) => {
  const dispatch = useAppDispatch()

  const {
    cashInCountries,
    cashOutCountries,
    selectedCashInCountry,
    selectedCashOutCountry,
    cashInPaymentOptionsByCountry,
    cashOutPaymentOptionsByCountry,
  } = useAppSelector(state => state.kwl)

  const [cashInCountriesState, setCashInCountriesState] = useState<Array<Country>>([])
  const [cashOutCountriesState, setCashOutCountriesState] = useState<Array<Country>>([])

  useEffect(() => {
    if (cashInCountries.length > 0 && cashOutCountries.length > 0) {
      setCashInCountriesState(cashInCountries)
      setCashOutCountriesState(cashOutCountries)
    }
  }, [cashInCountries, cashOutCountries])

  useEffect(() => {
    instanceId && dispatch(remittanceActions.getCountryList(instanceId))
  }, [dispatch, instanceId])

  const paymentOptionsByCountryTableConfig = useMemo(() => {
    return remittanceTableConfig.paymentOptionsByCountryTableConfig(instanceId)
  }, [instanceId])

  const cashInCountryListTableConfig = useMemo(() => {
    return remittanceTableConfig.countryListTableConfig(
      InstancePaymentOptionType.CashIn,
      instanceId
    )
  }, [instanceId])

  const cashOutCountryListTableConfig = useMemo(() => {
    return remittanceTableConfig.countryListTableConfig(
      InstancePaymentOptionType.CashOut,
      instanceId
    )
  }, [instanceId])

  const onSearchCashInCountryFieldChange = useCallback(
    (value: string) => {
      const list = getListBySearchValue(cashInCountries, value, ['name'])

      setCashInCountriesState(list)
    },
    [cashInCountries]
  )

  const onSearchCashOutCountryFieldChange = useCallback(
    (value: string) => {
      const list = getListBySearchValue(cashOutCountries, value, ['name'])

      setCashOutCountriesState(list)
    },
    [cashOutCountries]
  )

  const onAddPaymentOption = useCallback(
    (countryId: number, paymentOptionType: InstancePaymentOptionType) => {
      const actionData = {
        id: 0,
        currency: '',
        title: '',
        longTitle: '',
        description: '',
        paymentProviderMethod: {
          id: 0,
          paymentProvider: {
            id: 0,
            name: '',
          },
          paymentOptionType: paymentOptionType,
          paymentMethod: PaymentMethod.CreditCard,
        },
        country: { id: 0, name: '' },
        fee: {
          percentValue: 0,
          fixedValue: 0,
        },
      }

      instanceId &&
        dispatch(
          openGlobalModal({
            variant: 'add-payment-option-by-country',
            id: instanceId,
            additionalId: countryId,
            actionData,
          })
        )
    },
    [dispatch, instanceId]
  )

  return (
    <StyledCurrencyTab container spacing={2}>
      <Grid item xs={12} md={4} lg={4} pr={2} sx={{ paddingTop: 0 }}>
        <Typography paragraph sx={{ marginBottom: '20px', fontSize: '22px', fontWeight: 'bold' }}>
          Cash In Countries
        </Typography>
        <SearchField label="Search by Name" onParentChange={onSearchCashInCountryFieldChange} />
        <CountryListTable
          tableConfig={cashInCountryListTableConfig}
          tableData={cashInCountriesState}
        />
      </Grid>
      <Grid item xs={12} md={8} lg={8} pr={2}>
        {selectedCashInCountry && (
          <>
            <Button
              size="medium"
              variant="contained"
              color="success"
              onClick={() =>
                onAddPaymentOption(selectedCashInCountry.id, InstancePaymentOptionType.CashIn)
              }
            >
              Add New Payment Option
            </Button>
            <Typography
              paragraph
              sx={{
                marginBottom: '10px',
                marginTop: '38px',
                paddingLeft: '16px',
                fontSize: '16px',
              }}
            >
              Selected Country: {selectedCashInCountry.name}
            </Typography>
          </>
        )}
        {cashInPaymentOptionsByCountry.length > 0 && (
          <CountryListTable
            tableConfig={paymentOptionsByCountryTableConfig}
            tableData={cashInPaymentOptionsByCountry}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4} lg={4} pr={2}>
        <Typography
          paragraph
          sx={{ marginBottom: '20px', fontSize: '22px', fontWeight: 'bold', paddingTop: 0 }}
        >
          Cash Out Countries
        </Typography>
        <SearchField label="Search by Name" onParentChange={onSearchCashOutCountryFieldChange} />
        <CountryListTable
          tableConfig={cashOutCountryListTableConfig}
          tableData={cashOutCountriesState}
        />
      </Grid>
      <Grid item xs={12} md={8} lg={8} pr={2}>
        {selectedCashOutCountry && (
          <>
            <Button
              size="medium"
              variant="contained"
              color="success"
              onClick={() =>
                onAddPaymentOption(selectedCashOutCountry.id, InstancePaymentOptionType.CashOut)
              }
            >
              Add New Payment Option
            </Button>{' '}
            <Typography
              paragraph
              sx={{
                marginBottom: '10px',
                marginTop: '38px',
                paddingLeft: '16px',
                fontSize: '16px',
              }}
            >
              Selected Country: {selectedCashOutCountry.name}
            </Typography>
          </>
        )}
        {cashOutPaymentOptionsByCountry.length > 0 && (
          <CountryListTable
            tableConfig={paymentOptionsByCountryTableConfig}
            tableData={cashOutPaymentOptionsByCountry}
          />
        )}
      </Grid>
    </StyledCurrencyTab>
  )
}

export default CurrencyTab
