import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../store'
import { closeGlobalModal } from '../../../../../store/reducers/globalModalReducer'
import {
  StyledCloseButtonModal,
  StyledDialog,
  StyledDialogActions,
  StyledDialogBody,
  StyledDialogContent,
  StyledDialogHeader,
  StyledDialogTitle,
} from '../../../../../components/modal/styledModalComponent'
import { styled } from '@mui/system'
import CancelIcon from '@mui/icons-material/Cancel'
import { Button } from '@mui/material'
import BlockingRecordCreationForm from './BlockingRecordCreationForm'
import { mobileUserActions } from '../../../../../store/actions/mobileUserActions'

const StyledDialogModalTitle = styled(StyledDialogTitle)({
  width: '100%',
})

type BlockingRecordCreationModalProps = {
  isOpenDialog: boolean
}

const BlockingRecordCreationModal = ({ isOpenDialog }: BlockingRecordCreationModalProps) => {
  const dispatch = useAppDispatch()
  const { id: userId } = useAppSelector(state => state.globalModal)

  const closeModal = useCallback(() => {
    dispatch(closeGlobalModal())
  }, [dispatch])

  const onConfirm = useCallback(
    (data: { reason: string; projectTypes: number }) => {
      const body = { ...data, userId }
      userId && dispatch(mobileUserActions.createBlockingRecord(body))
      closeModal()
    },
    [closeModal, dispatch, userId]
  )

  return (
    <StyledDialog open={isOpenDialog} onClose={closeModal}>
      <StyledDialogHeader>
        <StyledDialogModalTitle>Profile Blocking</StyledDialogModalTitle>
        <StyledCloseButtonModal onClick={closeModal}>
          Close
          <CancelIcon />
        </StyledCloseButtonModal>
      </StyledDialogHeader>
      <StyledDialogBody>
        <StyledDialogContent>
          <BlockingRecordCreationForm onMainSubmit={onConfirm} />
        </StyledDialogContent>
        <StyledDialogActions>
          <Button size="large" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <Button form="create-blocking-record-from" type="submit" size="large" variant="contained">
            Confirm
          </Button>
        </StyledDialogActions>
      </StyledDialogBody>
    </StyledDialog>
  )
}

export default BlockingRecordCreationModal
