import { createSlice } from '@reduxjs/toolkit'

import { TopUpStatus, TopUpType } from '../../types/topUpTypes'
import {
  fetchTopUpCashrailList,
  fetchTopUpKWLList,
  fetchTopUpUsdkList,
  topUpActions,
} from '../actions/topUpActions'
import { getList } from './initialStates'

const initialState: TopUpType = {
  usdk: { ...getList() },
  cashrail: { ...getList() },
  kwl: { ...getList() },
  error: '',
  remittanceTopUpDetails: null,
  remittanceCardPaymentTxList: [],

  remittanceTopUpDetailsLoadState: {
    isLoading: false,
    isLoaded: false,
  },
}

const topUp = createSlice({
  name: 'topUp',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchTopUpUsdkList.pending, state => {
        state.usdk.listFetchStart = true
      })
      .addCase(fetchTopUpUsdkList.fulfilled, (state, action) => {
        state.usdk.listFetchFinished = true
        state.usdk.listFetchStart = false
        state.usdk.list = action.payload
      })
      .addCase(fetchTopUpUsdkList.rejected, (state, action) => {
        state.error = action.payload!.message
      })

      .addCase(fetchTopUpCashrailList.pending, state => {
        state.cashrail.listFetchStart = true
      })
      .addCase(fetchTopUpCashrailList.fulfilled, (state, action) => {
        state.cashrail.listFetchFinished = true
        state.cashrail.listFetchStart = false
        state.cashrail.list = action.payload
      })
      .addCase(fetchTopUpCashrailList.rejected, (state, action) => {
        state.error = action.payload!.message
      })

      .addCase(fetchTopUpKWLList.pending, state => {
        state.kwl.listFetchStart = true
      })
      .addCase(fetchTopUpKWLList.fulfilled, (state, action) => {
        state.kwl.listFetchFinished = true
        state.kwl.listFetchStart = false
        state.kwl.list = action.payload
      })
      .addCase(fetchTopUpKWLList.rejected, (state, action) => {
        state.error = action.payload!.message
      })
      .addCase(topUpActions.getRemittanceTopUpDetails.pending, state => {
        state.remittanceTopUpDetailsLoadState.isLoading = true
      })
      .addCase(topUpActions.getRemittanceTopUpDetails.fulfilled, (state, action) => {
        state.remittanceTopUpDetailsLoadState.isLoading = false
        state.remittanceTopUpDetails = action.payload
      })
      .addCase(topUpActions.getRemittanceTopUpDetails.rejected, state => {
        state.remittanceTopUpDetailsLoadState.isLoading = false
      })
      .addCase(topUpActions.cancelRemittanceTopUp.fulfilled, state => {
        state.remittanceTopUpDetails!.status = TopUpStatus.Cancelled
      })
      .addCase(topUpActions.getRemittanceCardPaymentTxList.fulfilled, (state, action) => {
        state.remittanceCardPaymentTxList = action.payload.items
      })
  },
})

export default topUp.reducer
