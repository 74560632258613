import { useCallback, useMemo } from 'react'
import { Button, styled } from '@mui/material'
import {
  StyledCloseButtonModal,
  StyledDialog,
  StyledDialogActions,
  StyledDialogBody,
  StyledDialogContent,
  StyledDialogHeader,
  StyledDialogTitle,
} from '../../../../../components/modal/styledModalComponent'
import { useAppDispatch, useAppSelector } from '../../../../../store'
import { closeGlobalModal } from '../../../../../store/reducers/globalModalReducer'
import CancelIcon from '@mui/icons-material/Cancel'
import { remittanceActions } from '../../../../../store/actions/kwlActions'
import { CommonCurrencyFormType, Currency } from '../../../../../types/remittanceTypes'
import { removeAfterClosingInstanceCurrencyModal } from '../../../../../store/reducers/kwlReducers'
import CommonCurrencyForm from './CommonCurrencyForm'

const StyledDialogModalTitle = styled(StyledDialogTitle)({
  width: '100%',
  paddingLeft: 0,
})

const StyledPaymentOptionByCountryModal = styled(StyledDialog)({
  // '.MuiDialog-paper': {
  //   // width: '600px',
  // },
})

type CommonCurrencyModalProps = {
  isOpenDialog: boolean
  variant: 'add-common-currency' | 'edit-common-currency'
}

const CommonCurrencyModal = ({ isOpenDialog, variant }: CommonCurrencyModalProps) => {
  const dispatch = useAppDispatch()
  const actionData: Currency = useAppSelector(state => state.globalModal.actionData)

  const closeModal = useCallback(() => {
    dispatch(closeGlobalModal())
    dispatch(removeAfterClosingInstanceCurrencyModal())
  }, [dispatch])

  const onConfirm = useCallback(
    (dataForm: CommonCurrencyFormType) => {
      if (variant === 'add-common-currency') {
        dispatch(remittanceActions.addCommonCurrency(dataForm))
      }
      if (variant === 'edit-common-currency') {
        dispatch(remittanceActions.editCommonCurrency(dataForm))
      }

      closeModal()
    },
    [closeModal, dispatch, variant]
  )
  const titleText = useMemo(() => {
    if (variant === 'add-common-currency') {
      return 'Adding Currency'
    }
    if (variant === 'edit-common-currency') {
      return 'Editing Currency'
    }
  }, [variant])

  return (
    <>
      <StyledPaymentOptionByCountryModal open={isOpenDialog} onClose={closeModal}>
        <StyledDialogHeader>
          <StyledDialogModalTitle>{titleText}</StyledDialogModalTitle>
          <StyledCloseButtonModal onClick={closeModal}>
            Close
            <CancelIcon />
          </StyledCloseButtonModal>
        </StyledDialogHeader>
        <StyledDialogBody>
          <StyledDialogContent>
            <CommonCurrencyForm onConfirm={onConfirm} variant={variant} data={actionData} />
          </StyledDialogContent>
          <StyledDialogActions>
            <Button size="large" variant="outlined" onClick={closeModal}>
              Cancel
            </Button>
            <Button form="instance-currency-form" type="submit" size="large" variant="contained">
              Save
            </Button>
          </StyledDialogActions>
        </StyledDialogBody>
      </StyledPaymentOptionByCountryModal>
    </>
  )
}

export default CommonCurrencyModal
