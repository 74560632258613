import { RiskLevelMarker } from "../types/commonTypes"

export const trimObjectFields = <T extends object>(object: T) => {
  return Object.keys(object).reduce((acc, key) => {
    acc[key] = typeof object[key] == 'string' ? object[key].trim() : object[key]
    return acc
  }, {} as T)
}

export const riskLevelMarkerList = [
  { value: Number([RiskLevelMarker.Green]), label: 'Green' },
  { value: Number([RiskLevelMarker.Yellow]), label: 'Yellow' },
  { value: Number([RiskLevelMarker.Red]), label: 'Red' },
  { value: Number([RiskLevelMarker.Black]), label: 'Black' },
]
