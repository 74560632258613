import { useEffect, useState, FormEvent, useMemo } from 'react'
import { Button, Container, TextField } from '@mui/material'
import { DeleteRounded, ReceiptLongRounded } from '@mui/icons-material'
import { DatePicker } from 'antd'
import moment from 'moment'

import Loader from '../../components/Loader'
import Table from '../../components/Table'

import getQueryString from '../../helpers/getQueryString'
import { useAppDispatch, useAppSelector } from '../../store'
import { mobileUserActions } from '../../store/actions/mobileUserActions'
import { mobileUserStatuses, riskLevelMarkers } from '../../utils/constants'
import concatenateObjProps from '../../helpers/concatenateObjProps'
import LazyLoadingTable from '../../components/CustomTable/LazyLoadingTable'
import { MobileUserDataType } from '../../types/mobileUserTypes'
import { mobileUserTableConfig } from '../../utils/config/tableConfig/mobileUsers'
import { removeMobileUserList } from '../../store/reducers/mobileUserReducer'
import useInfiniteScroll from 'react-infinite-scroll-hook'

// type PaginationType = { offset: number; pageSize: number }

const { RangePicker } = DatePicker

const MobileUsers = () => {
  const dispatch = useAppDispatch()
  const [queryList, setQueryList] = useState({ query: '', start: '', end: '' })
  // const [isResetPagination, setResetPagination] = useState(false)
  // const [pagination, setPagination] = useState<PaginationType>({ offset: 0, pageSize: 10 })

  // const { list: { items, count }, listFetchFinished } = useAppSelector(state => state.mobileUser)
  const { isMobileView } = useAppSelector(state => state.display)
  const { items: tableData, hasNextPage } = useAppSelector(state => state.mobileUser.mobileUserList)
  const { isLoading, error } = useAppSelector(state => state.mobileUser)

  const pageSize = 15
  const offset = 0

  const tableConfig = useMemo(() => {
    return mobileUserTableConfig()
  }, [])

  useEffect(() => {
    const currentParams = getQueryString(concatenateObjProps({ offset, pageSize }))

    dispatch(
      mobileUserActions.fetchMobileUserList({ queryParams: currentParams, offset, pageSize })
    )

    return () => {
      dispatch(removeMobileUserList())
    }
  }, [dispatch])

  const onLoadMore = async () => {
    const currentOffset = offset + tableData.length
    const currentParams = getQueryString(
      concatenateObjProps({ ...queryList, offset: currentOffset, pageSize })
    )

    await dispatch(
      mobileUserActions.fetchMobileUserList({
        queryParams: currentParams,
        offset: currentOffset,
        pageSize,
      })
    )
  }

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  })

  const clickSearch = async (e: FormEvent) => {
    e.preventDefault()

    const query = encodeURIComponent(e.target[0].value.trim())

    const currentParams = getQueryString(concatenateObjProps({ query, offset, pageSize }))

    await dispatch(
      mobileUserActions.fetchMobileUserList({ queryParams: currentParams, offset, pageSize })
    )

    setQueryList(prevState => ({ ...prevState, query }))
  }

  const onChangeDate = async (val: any) => {
    let queryString = { ...queryList, offset, pageSize }
    let start: string, end: string

    if (val) {
      start = moment(val[0]).format('MM-DD-YYYY') + 'Z'
      end = moment(val[1]).format('MM-DD-YYYY') + 'Z'

      queryString = {
        ...queryString,
        start,
        end,
      }
    } else {
      queryString.start = queryString.end = ''

      start = end = ''
    }

    const currentParams = getQueryString(concatenateObjProps(queryString))

    await dispatch(
      mobileUserActions.fetchMobileUserList({ queryParams: currentParams, offset, pageSize })
    )

    setQueryList(prevState => ({ ...prevState, start, end }))
  }

  return (
    <>
      {/* {!listFetchFinished ? (
        <Loader />
      ) : ( */}
      <Container className="pt-25 pb-10 h-100-percent overflow-hidden" maxWidth="xl">
        <h3 className="mb-25">Mobile Users</h3>
        <div className={`flex j-content-space-between ${isMobileView ? 'flex-dir-column' : ''}`}>
          <RangePicker className="mb-20 mr-10" onChange={onChangeDate} />
          <form className="search mb-20 ml-10" onSubmit={clickSearch}>
            <TextField
              label="Search by Status, Full Name, Email, Phone Number"
              size="small"
              // value={query.query}
              // onChange={({ target: { value } }) => changeSearch(value)}
            />
            <Button size="small" variant="contained" type="submit">
              Apply
            </Button>
          </form>
        </div>
        <LazyLoadingTable<MobileUserDataType>
          tableData={tableData}
          tableConfig={tableConfig}
          sentryRef={sentryRef}
          rootRef={rootRef}
          // onClick={onClickRow}
          minTableWidth="360px"
          loading={isLoading}
          hasNextPage={hasNextPage}
          maxHeight="calc(100vh - 375px)"
        />
        {/* <Table
              bodyData={mappedData}
              headData={headData}
              dataKeys={dataKeys}
              containerStyle='pb-100'
              fetchData={fetchData}
              dataCount={count}
              isResetPagination={isResetPagination}
            /> */}
      </Container>
      {/* )} */}
    </>
  )
}

export default MobileUsers
