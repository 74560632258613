export default [
  {
    title: 'Details',
    key: 'detailsBtn',
  },
  // {
  //   title: 'Edit',
  //   key: 'editingBtn',
  // },
  {
    title: 'ID',
    key: 'id',
  },
  {
    title: 'Type',
    key: 'txType',
  },
  {
    title: 'Status',
    key: 'transferStatus',
  },
  {
    title: 'Created Date',
    key: 'createdDate',
  },
  {
    title: 'Wallet Type',
    key: 'walletType',
  },
  {
    title: 'Amount (USD)',
    key: 'amountUsd',
  },
  {
    title: 'Comission (USD)',
    key: 'comissionUsd',
  },
  {
    title: 'Amount (Coin)',
    key: 'amountCoins',
  },
  {
    title: 'Comission (Coin)',
    key: 'comissionCoins',
  },
  {
    title: 'USD Rate',
    key: 'dashToUsdRate',
  },
  {
    title: 'Full Name',
    key: 'fullName',
  },
  {
    title: 'Phone Number',
    key: 'phoneNumber',
  },
  {
    title: 'From address',
    key: 'senderWalletAddress',
  },
  {
    title: 'To Address',
    key: 'recipientWalletAddress',
  },
  {
    title: 'Bws Id',
    key: 'bwsId',
  },
  {
    title: 'Origin Tx Id',
    key: 'originTxId',
    cssStyle: 'w-100'
  },
]
