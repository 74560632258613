import { styled } from '@mui/material'
import { LegacyRef, memo, useMemo, useRef } from 'react'
import { TableConfig } from '../../types/tableTyles'
// import useScrollBar from '../../../hooks/useScrollBar'
import TableHeader from './TableHeader'
import TableRow from './TableRow'

type LazyLoadingTableProps<T> = {
  tableConfig: TableConfig<T>
  tableData: T[]
  onClick?: (id: string | number) => void
  sentryRef: LegacyRef<HTMLDivElement>
  rootRef: LegacyRef<HTMLDivElement>
  minTableWidth: string
  loading: boolean
  hasNextPage: boolean
  maxHeight: string
}

type StyledLazyLoadingTableProps = {
  minTableWidth: string
  maxHeight: string
}

const StyledLazyLoadingTable = styled('div')<StyledLazyLoadingTableProps>(({ minTableWidth, maxHeight }) => ({
  minWidth: '360px',
  '.table-wrap': {
   
    overflowX: 'auto',
    overflowY: 'auto',
  },
  '.table-header': {
    position: 'sticky',
    top: '0',
    zIndex: 10,
  },
  '.table-body': {
    maxHeight: maxHeight,
  },
}))

const LazyLoadingTable = <T extends { extId?: string; id?: string | number }>({
  tableConfig,
  tableData,
  rootRef,
  sentryRef,
  onClick,
  minTableWidth,
  loading,
  hasNextPage,
  maxHeight,
}: LazyLoadingTableProps<T>) => {
  // const tableBodyRef = useRef<HTMLDivElement | null>(null)
  // const tableRef = useRef<HTMLDivElement | null>(null)

  // const hasScroll = true

  // useScrollBar(tableBodyRef, hasScroll)
  // useScrollBar(tableRef, hasScroll)
  const isExistOnClick = useMemo(() => Boolean(onClick), [onClick])

  return (
    <StyledLazyLoadingTable minTableWidth={minTableWidth} maxHeight={maxHeight}>
      <div className="table-wrap" ref={rootRef}>
        <TableHeader tableConfig={tableConfig} />
        <div
          className="table-body"

          // style={{ height: hasScroll ? '435px' : 'auto' }}
        >
          {tableData.map((rowData, rowIndex) => (
            <TableRow<T>
              // sentryRef={sentryRef}
              key={rowData.extId || rowData.id}
              rowData={rowData}
              rowIndex={rowIndex}
              tableConfig={tableConfig}
              isExistOnClick={isExistOnClick}
              onClick={onClick}
            />
          ))}
          {(loading || hasNextPage) && <div ref={sentryRef}></div>}
        </div>
      </div>
    </StyledLazyLoadingTable>
  )
}

export default LazyLoadingTable
