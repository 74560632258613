import { Box, Button } from '@mui/material'
import { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { mobileUserActions } from '../../../../store/actions/mobileUserActions'
import { mobileUserTableConfigs } from '../../../../utils/config/tableConfig/mobileUsers'
import { BlockingRecord } from '../../../../types/mobileUserTypes'
import SimpleTableWithCollapsingRow from './table/SimpleTableWithCollapsingRow'
import CollapsingBlockingRecordRow from './table/CollapsingBlockingRecordRow'
import { openGlobalModal } from '../../../../store/reducers/globalModalReducer'
import { removeCurrentUserBlockingRecords } from '../../../../store/reducers/mobileUserReducer'

type BlockingRecordsTabProps = {
  userId: string | undefined
}

const BlockingRecordsTab = ({ userId }: BlockingRecordsTabProps) => {
  const dispatch = useAppDispatch()

  const { currentUserBlockingRecords } = useAppSelector(state => state.mobileUser)

  useEffect(() => {
    userId && dispatch(mobileUserActions.getBlockingRecordsByUserId(userId))

    return () => {
      dispatch(removeCurrentUserBlockingRecords())
    }
  }, [dispatch, userId])

  const tableConfig = useMemo(() => {
    return mobileUserTableConfigs.currentUserBlockingRecordsTableConfig()
  }, [])

  const onCreateBlockingRecord = useCallback(() => {
    userId && dispatch(openGlobalModal({ variant: 'create-blocking-record', id: userId }))
  }, [dispatch, userId])

  return (
    <Box
      sx={{
        margin: 0,
        pl: '16px',
        width: '100%',
        height: 'calc(100dvh - 200px)',
        overflowX: 'auto',
      }}
    >
      <Button
        sx={{ width: '160px', marginBottom: '8px' }}
        size="small"
        variant="contained"
        onClick={onCreateBlockingRecord}
      >
        Block Profile
      </Button>
      {currentUserBlockingRecords.length > 0 && (
        <SimpleTableWithCollapsingRow<BlockingRecord>
          tableConfig={tableConfig}
          tableData={currentUserBlockingRecords.length > 0 ? currentUserBlockingRecords : []}
          CollapsibleComponent={CollapsingBlockingRecordRow}
        />
      )}
    </Box>
  )
}

export default BlockingRecordsTab
