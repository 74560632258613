import { useController, UseControllerProps, FieldValues } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { FormHelperText, OutlinedInput } from '@mui/material'

const StyledSelectForm = styled(FormControl)({
  width: '100%',
  marginBottom: '20px',
  '.MuiInput-root': {
    '&.Mui-focused': {
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  '.MuiInputBase-root.Mui-focused > div': {
    background: 'transparent',
    boxShadow: 'none',
  },
})

type SelectFormProps<T extends FieldValues> = UseControllerProps<T> & {
  label: string
  options: { label: string; value: string | number }[]
  multiple?: boolean
  disabled?: boolean
}

const SelectFieldForm = <T extends FieldValues>({
  name,
  control,
  label,
  options,
  disabled = false,
  multiple = true,
}: SelectFormProps<T>) => {
  const {
    field: { onChange, value },
    fieldState: { error: inputError },
  } = useController({
    name,
    control,
  })

  return (
    <StyledSelectForm disabled={disabled} {...(inputError && { error: true, helperText: inputError.message })}>
      <InputLabel id="select-label" size="small">
        {label}
      </InputLabel>
      <Select
        labelId="select-label"
        id="select-label"
        // value={value ? value : []}
        value={value ? value : ''}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        multiple={multiple}
        size="small"
        sx={{ width: '100%' }}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {inputError && <FormHelperText>{inputError.message}</FormHelperText>}
    </StyledSelectForm>
  )
}

export default SelectFieldForm
