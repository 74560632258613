import { Dispatch, MouseEvent, SetStateAction, useRef } from 'react'
import { MenuList, MenuItem, Collapse } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

import { PermissionDataType } from '../../types/permissionTypes'
import { getMapedPermissionList } from '../../services/permissionsService'
import { collapseItems } from '../../utils/constants'

import * as path from '../../utils/appPaths'
import NestedMenu from './NestedMenu'
import SideBarNavLink from './SideBarNavLink'
import useScrollBar from '../../hooks/useScrollBar'

export default ({
  userPermissionList,
  onClick,
  open,
}: {
  userPermissionList: PermissionDataType[]
  onClick: (e: MouseEvent, val: string) => void
  open: { [key: string]: boolean }
}) => {
  const permissions = getMapedPermissionList(userPermissionList)
  let menuList: JSX.Element[] = []

  const getCollapseExpandIcon = (open: boolean) =>
    open ? (
      <div className="item-expand">
        <ExpandLess />
      </div>
    ) : (
      <div className="item-expand">
        <ExpandMore />
      </div>
    )

  userPermissionList.forEach((item, _, thisArr) => {
    if (item.key === permissions.SuperUser) {
      menuList = [
        ...menuList,
        <NestedMenu
          key={collapseItems.KWL}
          open={open[collapseItems.KWL]}
          onClick={onClick}
          config={path.sideBarConfig[collapseItems.KWL]}
          name={collapseItems.KWL}
        />,
        <NestedMenu
          key={collapseItems.WORLD_PAY}
          open={open[collapseItems.WORLD_PAY]}
          onClick={onClick}
          config={path.sideBarConfig[collapseItems.WORLD_PAY]}
          name={collapseItems.WORLD_PAY}
        />,
        <SideBarNavLink
          to={path.agentGroupsPath}
          label="Agent Groups"
          key={path.agentGroupsPath}
        />,
        <SideBarNavLink
          to={path.mobileUsersPath}
          label="Mobile Users"
          key={path.mobileUsersPath}
        />,
        <NestedMenu
          key={collapseItems.TRANSACTIONS}
          open={open[collapseItems.TRANSACTIONS]}
          onClick={onClick}
          config={path.sideBarConfig[collapseItems.TRANSACTIONS]}
          name={collapseItems.TRANSACTIONS}
        />,
        <NestedMenu
          key={collapseItems.TOP_UP}
          open={open[collapseItems.TOP_UP]}
          onClick={onClick}
          config={path.sideBarConfig[collapseItems.TOP_UP]}
          name={collapseItems.TOP_UP}
        />,
        <SideBarNavLink
          to={path.userBlockingRecordsPath}
          label="User Blocking Records"
          key={path.userBlockingRecordsPath}
        />,
        // <NavLink to={path.agentGroupsPath}>
        //   Agent Groups
        //   {/* <ListItemButton>
        //         <ListItemText primary={item.label} />
        //       </ListItemButton> */}
        // </NavLink>,
      ]
      // } else if (item.key === permissions.Users_Access) {
      //   menuList = [
      //     ...menuList,
      //     <MenuItem key={permissions.Users_Access}>
      //       <div className="item">
      //         <h4>
      //           <NavLink className="item-link" to={path.mobileUsersPath}>
      //             Mobile Users
      //           </NavLink>
      //         </h4>
      //       </div>
      //     </MenuItem>,
      //   ]
    }
    // else if (item.key === permissions.Agents_Access) {
    //   menuList = [
    //     ...menuList,
    //     <MenuItem key={permissions.Agents_Access + 1}>
    //       <div className="item">
    //         <h4>
    //           <NavLink className="item-link" to={path.agentsProfilesPath}>
    //             Agents Profiles
    //           </NavLink>
    //         </h4>
    //       </div>
    //     </MenuItem>,
    // <MenuItem key={permissions.Agents_Access + 2}>
    //   <div className="item">
    //     <h4>
    //       <NavLink className="item-link" to={path.agentGroupsPath}>
    //         Agent Groups
    //       </NavLink>
    //     </h4>
    //   </div>
    // </MenuItem>,
    //   ]
    // }
    // else if (item.key === permissions.Top_Up_Access) {
    //   menuList = [
    //     ...menuList,
    //     <MenuItem key={permissions.Top_Up_Access} onClick={e => onClick(e, collapseItems.TOP_UP)}>
    //       <div className="item">
    //         <Collapse component="div" in={open.topUp} collapsedSize="35px" className="collapse">
    //           <h4>
    //             Top Up Requests
    //             {getCollapseExpandIcon(open.topUp)}
    //           </h4>
    //           <NavLink className="item-link" to={path.topUpUsdkPath}>
    //             USDk
    //           </NavLink>
    //           <NavLink className="item-link" to={path.topUpKuvaCoinPath}>
    //             Kuva Coin
    //           </NavLink>
    //           <NavLink className="item-link" to={path.topUpCashrailPath}>
    //             Cashrail
    //           </NavLink>
    //           <NavLink className="item-link" to={path.topUpKuvaWhiteLabelPath}>
    //             Kuva White Label
    //           </NavLink>
    //         </Collapse>
    //       </div>
    //     </MenuItem>,
    //   ]
    // }
    // else if (item.key === permissions.Bug_Reports_Access) {
    //   menuList = [
    //     ...menuList,
    //     <MenuItem key={permissions.Bug_Reports_Access}>
    //       <div className="item">
    //         <h4>
    //           <NavLink className="item-link" to={path.bugReportPath}>
    //             Bug Report
    //           </NavLink>
    //         </h4>
    //       </div>
    //     </MenuItem>,
    //   ]
    // }
    //  else if (item.key === permissions.Contacts_Access) {
    //   menuList = [
    //     ...menuList,
    //     <MenuItem key={permissions.Contacts_Access}>
    //       <div className="item">
    //         <h4>
    //           <NavLink className="item-link" to={path.contactPath}>
    //             Contact
    //           </NavLink>
    //         </h4>
    //       </div>
    //     </MenuItem>,
    //   ]
    // }
    else if (item.key === permissions.KuvaLocalAdmin) {
      menuList = [
        ...menuList,
        <NestedMenu
          key={collapseItems.KUVA_LOCAL}
          open={open[collapseItems.KUVA_LOCAL]}
          onClick={onClick}
          config={path.sideBarConfig[collapseItems.KUVA_LOCAL]}
          name={collapseItems.KUVA_LOCAL}
        />,
        // <MenuItem
        //   key={permissions.KuvaLocalAdmin}
        //   onClick={e => onClick(e, collapseItems.KUVA_LOCAL)}
        // >
        //   <div className="item">
        //     <Collapse component="div" in={open.kuvaLocal} collapsedSize="35px" className="collapse">
        //       <h4>
        //         Kuva Local
        //         {getCollapseExpandIcon(open.kuvaLocal)}
        //       </h4>
        //       <NavLink className="item-link" to={path.kuvaLocalSettingsPath}>
        //         Settings
        //       </NavLink>
        //       <NavLink className="item-link" to={path.kuvaLocalOrdersPath}>
        //         Orders
        //       </NavLink>
        //       <NavLink className="item-link" to={path.kuvaLocalCouriersPath}>
        //         Couries
        //       </NavLink>
        //       <NavLink className="item-link" to={path.kuvaLocalCourierDispatchOrderPath}>
        //         Dispatch Orders
        //       </NavLink>
        //       <NavLink className="item-link" to={path.kuvaLocalUsersPath}>
        //         Users
        //       </NavLink>
        //       <NavLink className="item-link" to={path.kuvaLocalContactPath}>
        //         Contact
        //       </NavLink>
        //       <NavLink className="item-link" to={path.kuvaLocalFeedbackPath}>
        //         Feedback
        //       </NavLink>
        //       <NavLink className="item-link" to={path.kuvaLocalStoresPath}>
        //         Stores
        //       </NavLink>
        //       <NavLink className="item-link" to={path.kuvaLocalCountriesPath}>
        //         Countries
        //       </NavLink>
        //       <NavLink className="item-link" to={path.kuvaLocalRefundsPath}>
        //         Payment Refunds
        //       </NavLink>
        //       <NavLink className="item-link" to={path.kuvaLocalSubmissionsPath}>
        //         Submissions
        //       </NavLink>
        //       {thisArr.find(perm => perm.key === permissions.Promo_Code_Manager) && (
        //         <NavLink className="item-link" to={path.kuvaLocalPromocodePath}>
        //           Promocode
        //         </NavLink>
        //       )}
        //       {thisArr.find(perm => perm.key === permissions.Charity_Manager) && (
        //         <NavLink className="item-link" to={path.kuvaLocalCompaniesPath}>
        //           Charity Companies
        //         </NavLink>
        //       )}
        //       <NavLink className="item-link" to={path.kuvaLocalReportPath}>
        //         Report
        //       </NavLink>
        //       {thisArr.find(perm => perm.key === permissions.Credit_Vendor) && (
        //         <NavLink className="item-link" to={path.kuvaLocalCreditVendorPath}>
        //           Credit Vendor
        //         </NavLink>
        //       )}
        //       <NavLink className="item-link" to={path.kuvaLocalMobileHomePageWidgetsPath}>
        //         Mobile Home Page Widgets
        //       </NavLink>
        //       <NavLink className="item-link" to={path.kuvaLocalFacebookPath}>
        //         Facebook
        //       </NavLink>
        //     </Collapse>
        //   </div>
        // </MenuItem>,
      ]
    }
    // else if (item.key === permissions.Credit_Wallet_Reserve_Treasury_Reconciliation) {
    //   menuList = [
    //     ...menuList,
    //     <MenuItem key={permissions.Credit_Wallet_Reserve_Treasury_Reconciliation}>
    //       <div className="item">
    //         <h4>
    //           <NavLink className="item-link" to={path.creditWalletTreasurePath}>
    //             Credit Wallet Treasure
    //           </NavLink>
    //         </h4>
    //       </div>
    //     </MenuItem>,
    //   ]
    // }
    //  else if (item.key === permissions.Currency_Cloud_Rate_Access) {
    //   menuList = [
    //     ...menuList,
    //     <MenuItem
    //       key={permissions.Currency_Cloud_Rate_Access}
    //       onClick={e => onClick(e, collapseItems.CURRENCY_CLOUD)}
    //     >
    //       <div className="item">
    //         <Collapse
    //           component="div"
    //           in={open.currencyCloud}
    //           collapsedSize="35px"
    //           className="collapse"
    //         >
    //           <h4>
    //             Currency Cloud
    //             {getCollapseExpandIcon(open.currencyCloud)}
    //           </h4>
    //           <NavLink className="item-link" to={path.currencyCloudKLRatesPath}>
    //             Rates for KL
    //           </NavLink>
    //           <NavLink className="item-link" to={path.currencyCloudOTRatesPath}>
    //             Rates for OT
    //           </NavLink>
    //           <NavLink className="item-link" to={path.currencyCloudTopUpRatesPath}>
    //             Rates for Top Up
    //           </NavLink>
    //           <NavLink className="item-link" to={path.currencyCloudAllRatesPath}>
    //             All Rates
    //           </NavLink>
    //         </Collapse>
    //       </div>
    //     </MenuItem>,
    //   ]
    // }
    // else if (item.key === permissions.Feedbacks_Access) {
    //   menuList = [
    //     ...menuList,
    //     <MenuItem key={permissions.Feedbacks_Access}>
    //       <div className="item">
    //         <h4>
    //           <NavLink className="item-link" to={path.feedbacksPath}>
    //             Feedbacks
    //           </NavLink>
    //         </h4>
    //       </div>
    //     </MenuItem>,
    //   ]
    // }
    // else if (item.key === permissions.Masternodes_Access) {
    //   menuList = [
    //     ...menuList,
    //     <MenuItem
    //       key={permissions.Masternodes_Access}
    //       onClick={e => onClick(e, collapseItems.MASTER_NODE)}
    //     >
    //       <div className="item">
    //         <Collapse
    //           component="div"
    //           in={open.masterNode}
    //           collapsedSize="35px"
    //           className="collapse"
    //         >
    //           <h4>
    //             Master Node
    //             {getCollapseExpandIcon(open.masterNode)}
    //           </h4>
    //           <NavLink className="item-link" to={path.masterNodeAllMasterNodesPath}>
    //             All Master Nodes
    //           </NavLink>
    //           <NavLink className="item-link" to={path.masterNodeRewardsPath}>
    //             Rewards
    //           </NavLink>
    //         </Collapse>
    //       </div>
    //     </MenuItem>,
    //   ]
    // }
    // else if (item.key === permissions.Outbound_Transfers_Access) {
    //   menuList = [
    //     ...menuList,
    //     <MenuItem
    //       key={permissions.Outbound_Transfers_Access + 1}
    //       onClick={e => onClick(e, collapseItems.OUTBOUND_TRANSFERS)}
    //     >
    //       <div className="item">
    //         <Collapse
    //           component="div"
    //           in={open.outboundTransfers}
    //           collapsedSize="35px"
    //           className="collapse"
    //         >
    //           <h4>
    //             Outbound Transfers
    //             {getCollapseExpandIcon(open.outboundTransfers)}
    //           </h4>
    //           <NavLink className="item-link" to={path.outboundTransfersTransfersPath}>
    //             Transfers
    //           </NavLink>
    //           <NavLink className="item-link" to={path.outboundTransfersCountriesPath}>
    //             Countries
    //           </NavLink>
    //           <NavLink className="item-link" to={path.outboundTransfersFeesPath}>
    //             Fees
    //           </NavLink>
    //           <NavLink className="item-link" to={path.outboundTransfersRiskScoresPath}>
    //             Risk scores
    //           </NavLink>
    //         </Collapse>
    //       </div>
    //     </MenuItem>,
    //     <MenuItem
    //       key={permissions.Outbound_Transfers_Access + 2}
    //       onClick={e => onClick(e, collapseItems.CASHOUT)}
    //     >
    //       <div className="item">
    //         <Collapse component="div" in={open.cashout} collapsedSize="35px" className="collapse">
    //           <h4>
    //             Cash Out
    //             {getCollapseExpandIcon(open.cashout)}
    //           </h4>
    //           <NavLink className="item-link" to={path.cashOutPendingRequestsPath}>
    //             Pending Requests
    //           </NavLink>
    //           <NavLink className="item-link" to={path.cashOutCompletedRequestsPath}>
    //             Completed Request
    //           </NavLink>
    //           <NavLink className="item-link" to={path.cashOutPendingKWLRequestsPath}>
    //             Pending KWL Requests
    //           </NavLink>
    //           <NavLink className="item-link" to={path.cashOutCompletedKWLRequestsPath}>
    //             Completed KWL Requests
    //           </NavLink>
    //           <NavLink className="item-link" to={path.cashOutPendingMulticashRequestsPath}>
    //             Pending Multicash Requests
    //           </NavLink>
    //           <NavLink className="item-link" to={path.cashOutCompletedMulticashRequestsPath}>
    //             Completed Multicash Requests
    //           </NavLink>
    //         </Collapse>
    //       </div>
    //     </MenuItem>,
    //   ]
    // }
    // else if (item.key === permissions.Push_Notifications_Access) {
    //   menuList = [
    //     ...menuList,
    //     <MenuItem key={permissions.Push_Notifications_Access}>
    //       <div className="item">
    //         <h4>
    //           <NavLink className="item-link" to={path.pushNotificationsPath}>
    //             Push Notifications
    //           </NavLink>
    //         </h4>
    //       </div>
    //     </MenuItem>,
    //   ]
    // } else if (item.key === permissions.Referral_Links_Access) {
    //   menuList = [
    //     ...menuList,
    //     <MenuItem key={permissions.Referral_Links_Access}>
    //       <div className="item">
    //         <h4>
    //           <NavLink className="item-link" to={path.referralLinkPath}>
    //             Referral Link
    //           </NavLink>
    //         </h4>
    //       </div>
    //     </MenuItem>,
    //   ]
    // }
    // else if (item.key === permissions.Payments_Access) {
    //   menuList = [
    //     ...menuList,
    //     <MenuItem
    //       key={permissions.Payments_Access + 1}
    //       onClick={e => onClick(e, collapseItems.PAYMENTS)}
    //     >
    //       <div className="item">
    //         <Collapse component="div" in={open.payments} collapsedSize="35px" className="collapse">
    //           <h4>
    //             Payments
    //             {getCollapseExpandIcon(open.payments)}
    //           </h4>
    //           <NavLink className="item-link" to={path.paymentsTreasuryTransfersPath}>
    //             Treasury Transfers
    //           </NavLink>
    //           <NavLink className="item-link" to={path.paymentsPayinsPath}>
    //             Payins
    //           </NavLink>
    //           <NavLink className="item-link" to={path.paymentsPayoutsPath}>
    //             Payouts
    //           </NavLink>
    //           <NavLink className="item-link" to={path.paymentsUpholdTransactionsPath}>
    //             Uphold Transactions
    //           </NavLink>
    //         </Collapse>
    //       </div>
    //     </MenuItem>,
    // <MenuItem
    //   key={permissions.Payments_Access + 2}
    //   onClick={e => onClick(e, collapseItems.TRANSACTIONS)}
    // >
    //   <div className="item">
    //     <Collapse
    //       component="div"
    //       in={open.transactions}
    //       collapsedSize="35px"
    //       className="collapse"
    //     >
    //       <h4>
    //         Transactions
    //         {getCollapseExpandIcon(open.transactions)}
    //       </h4>
    //       <NavLink className="item-link" to={path.transactionsAllPath}>
    //         All Transactions
    //       </NavLink>
    //       {(thisArr.find(perm => perm.key === permissions.SuperUser) ||
    //         thisArr.find(perm => perm.key === permissions.Duplicate_Transactions_Access)) && (
    //         <NavLink className="item-link" to={path.transactionsDuplicatePath}>
    //           Duplicate Transactions
    //         </NavLink>
    //       )}
    //       {thisArr.find(perm => perm.key === permissions.SuperUser) && (
    //         <NavLink className="item-link" to={path.transactionsIncorectRatePath}>
    //           Incorrect Rate Transactions
    //         </NavLink>
    //       )}
    //       {thisArr.find(perm => perm.key === permissions.SuperUser) && (
    //         <NavLink className="item-link" to={path.transactionsUnpaidPath}>
    //           Unpaid Transactions
    //         </NavLink>
    //       )}
    //     </Collapse>
    //   </div>
    // </MenuItem>,
    // <MenuItem
    //   key={permissions.Payments_Access + 3}
    //   onClick={e => onClick(e, collapseItems.MOBILE_TRANSFERS)}
    // >
    //   <div className="item">
    //     <Collapse
    //       component="div"
    //       in={open.mobileTransfers}
    //       collapsedSize="35px"
    //       className="collapse"
    //     >
    //       <h4>
    //         Mobile Transfers
    //         {getCollapseExpandIcon(open.mobileTransfers)}
    //       </h4>
    //       <NavLink className="item-link" to={path.mobileTransfersTransferTablePath}>
    //         Mobile Transfer Table
    //       </NavLink>
    //       <NavLink className="item-link" to={path.mobileTransfersOrderTablePath}>
    //         Mobile Order Table
    //       </NavLink>
    //     </Collapse>
    //   </div>
    // </MenuItem>,
    //   ]
    // }
  })

  return <MenuList>{menuList}</MenuList>
}
