import { OverlayScrollbars } from 'overlayscrollbars'
import React, { Ref, useEffect } from 'react'

const config = {
  // scrollbars: {
  //   visibility: "auto",
  //   autoHide: "never",
  // }
}

const useScrollBar = (elemRef: any) => {
  useEffect(() => {
    let scrollbars: OverlayScrollbars

    if (elemRef.current) {
      scrollbars = OverlayScrollbars(elemRef.current, config)
    }

    return () => {
      if (scrollbars) {
        scrollbars.destroy()
      }
    }
  }, [elemRef])
}

export default useScrollBar
