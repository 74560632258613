import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { mobileUserActions } from '../../store/actions/mobileUserActions'
import getQueryString from '../../helpers/getQueryString'
import concatenateObjProps from '../../helpers/concatenateObjProps'
import { Button, Container, TextField } from '@mui/material'
import LazyLoadingTable from '../../components/CustomTable/LazyLoadingTable'
import { mobileUserTableConfigs } from '../../utils/config/tableConfig/mobileUsers'
import { UserBlockingRecord } from '../../types/mobileUserTypes'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useNavigate } from 'react-router-dom'
import { removeUserBlockingRecords } from '../../store/reducers/mobileUserReducer'

const UserBlockingRecords = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { items: tableData, hasNextPage } = useAppSelector(
    state => state.mobileUser.userBlockingRecords
  )
  const { userBlockingRecordsLoadState, error } = useAppSelector(state => state.mobileUser)
  const { isMobileView } = useAppSelector(state => state.display)

  const pageSize = 15
  const offset = 0

  const [queryList, setQueryList] = useState({ query: '' })

  const tableConfig = useMemo(() => {
    return mobileUserTableConfigs.userBlockingRecordsTableConfig()
  }, [])

  const modifiedTableData = useMemo(
    () => tableData.map(tableItem => ({ ...tableItem, id: tableItem.userId })),
    [tableData]
  )

  useEffect(() => {
    const currentParams = getQueryString(concatenateObjProps({ offset, pageSize }))
    dispatch(mobileUserActions.getUserBlockingRecords({ queryParams: currentParams, offset }))

    return () => {
      dispatch(removeUserBlockingRecords())
    }
  }, [dispatch])

  const onLoadMore = async () => {
    const currentOffset = offset + tableData.length
    const currentParams = getQueryString(
      concatenateObjProps({ ...queryList, offset: currentOffset, pageSize })
    )
    await dispatch(
      mobileUserActions.getUserBlockingRecords({
        queryParams: currentParams,
        offset: currentOffset,
      })
    )
  }

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: userBlockingRecordsLoadState.isLoading,
    hasNextPage,
    onLoadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  })

  const clickSearch = async (e: FormEvent) => {
    e.preventDefault()

    const query = e.target[0].value.trim()
    const currentParams = getQueryString(concatenateObjProps({ query, offset, pageSize }))

    await dispatch(
      mobileUserActions.getUserBlockingRecords({
        queryParams: currentParams,
        offset,
      })
    )

    setQueryList(prevState => ({ ...prevState, query }))
  }

  const onClickRow = useCallback((id: string | number) => {
    window.open(`/mobile-users/${id}?activeTab=blocking-records`)
  }, [])

  return (
    <Container className="pt-25 pb-10 h-100-percent overflow-hidden" maxWidth="xl">
      <h3 className="mb-25">User Blocking Records</h3>
      <div
        className={`flex j-content-space-between ${isMobileView ? 'flex-dir-column' : ''}`}
        style={{ justifyContent: 'end' }}
      >
        <form className="search mb-20 ml-10" onSubmit={clickSearch}>
          <TextField
            label="Search by First Name, Last Name, Phone Number, Id "
            size="small"
            name="search"
          />
          <Button size="small" variant="contained" type="submit">
            Apply
          </Button>
        </form>
      </div>
      <LazyLoadingTable<UserBlockingRecord & { id: string }>
        tableData={modifiedTableData}
        tableConfig={tableConfig}
        sentryRef={sentryRef}
        rootRef={rootRef}
        onClick={onClickRow}
        minTableWidth="360px"
        loading={userBlockingRecordsLoadState.isLoading}
        hasNextPage={hasNextPage}
        maxHeight="calc(100vh - 330px)"
      />
    </Container>
  )
}

export default UserBlockingRecords
