import { createSlice } from '@reduxjs/toolkit'
import { GlobalModalVariant } from '../../types/globalModalTypes'

type GlobalModalState = {
  isModalOpen: boolean
  variant?: GlobalModalVariant
  actionData?: any
  id: number | string
  additionalId?: number | string
  // confirmFunction?: () => void
  // headerModal: string
}

const initialState: GlobalModalState = {
  isModalOpen: false,
  id: 0,
  // headerModal: ''
  // variant: '',
}

export const globalModalReducer = createSlice({
  name: 'globalModal',
  initialState,
  reducers: {
    openGlobalModal: (state, action) => {
      state.isModalOpen = true
      state.variant = action.payload.variant
      state.actionData = action.payload.actionData
      state.id = action.payload.id
      state.additionalId = action.payload.additionalId
      // state.headerModal = action.payload.headerModal
      // state.confirmFunction = action.payload.confirmFunction
    },
    closeGlobalModal: state => {
      state.isModalOpen = false
      state.variant = undefined
      state.actionData = undefined
      state.id = 0
      state.additionalId = undefined
    },
  },
})

export const { openGlobalModal, closeGlobalModal } = globalModalReducer.actions

export default globalModalReducer.reducer
