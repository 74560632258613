import { RiskLevelMarker } from "./commonTypes"

export type RemittanceStateType = {
  site: {
    list: KWLSiteDataType[]
    listFetchStart: boolean
    listFetchFinished: boolean
  }
  user: {
    list: KWLUserDataType[]
    listFetchStart: boolean
    listFetchFinished: boolean
  }
  flowList: GetFlowListResponse[] | []
  data: KWLSiteDataType
  dataFetchStart: boolean
  dataFetchFinished: boolean
  setCountryStart: boolean
  setCountryFinished: boolean
  deleteCountryStart: boolean
  deleteCountryFinished: boolean
  error: string
  isLoading: boolean
  isLoaded: boolean
  beneficiaryConfig: GetBeneficiaryConfig | null

  instanceCurrencyList: Array<CurrencyDetails>
  getInstanceCurrencyListLoadState: {
    isLoading: boolean
    isLoaded: boolean
  }

  allCurrencyList: Array<Currency>
  getAllCurrenciesLoadState: {
    isLoading: boolean
    isLoaded: boolean
  }

  availableInstanceCurrencyList: Array<Currency>
  getAvailableInstanceCurrencyListLoadState: {
    isLoading: boolean
    isLoaded: boolean
  }

  paymentProviderMethods: Array<PaymentProviderMethod>
  getPaymentProviderMethodsLoadState: {
    isLoading: boolean
    isLoaded: boolean
  }

  instanceCurrencyDetails: CurrencyDetails | null
  getInstanceCurrencyLoadState: {
    isLoading: boolean,
    isLoaded: boolean,
  },

  allAgentGroups: Array<AgentGroup>
  getAllAgentGroupsLoadState: {
    isLoading: boolean
    isLoaded: boolean
  }
  searchFieldForAllAgentGroups: string

  agentGroupsForInstance: Array<AgentGroup>
  getAgentGroupListForInstanceLoadState: {
    isLoading: boolean
    isLoaded: boolean
  }

  //remittance-payment-provider
  defaultPaymentProviderFeesByCurrency: Array<DefaultPaymentProviderFees>
  // getAllPaymentProviderFeesLoadState: {
  //   isLoading: boolean
  //   isLoaded: boolean
  // }
  paymentProviderFeesByCurrency: PaymentProviderFeeDetail | null

  getPaymentProviderListLoadState: {
    isLoading: boolean
    isLoaded: boolean
  }

  cashInCountries: Array<Country>
  cashOutCountries: Array<Country>
  selectedCashInCountry: Country | null
  selectedCashOutCountry: Country | null

  cashInPaymentOptionsByCountry: Array<PaymentOptionByCountry>
  cashOutPaymentOptionsByCountry: Array<PaymentOptionByCountry>

  currencyListByCountry: Array<Currency>
  paymentProviderList: Array<PaymentProvider>
  paymentMethodList: Array<PaymentProviderMethod>

  commonCurrencyList: Array<Currency>
  getCommonCurrencyListLoadState: {
    isLoading: boolean
    isLoaded: boolean
  }

  // currentPaymentOptionByCountry: PaymentOptionByCountry | null
}

export type KWLSiteDataType = {
  id: number
  extId: string
  hostUrl: string
  logoUrl: string
  isDefault: boolean
  confirmationEmailText: string
  paymentCompleteSmsText: string
  redeemCodeText: string
  senderName: string
  senderEmail: string
  agentGroupId: number
  sourceCountryIds: number[]
  receiveCountryIds: number[]
}

export type KWLUserDataType = {
  id: number
  createdDate: string
  name: string
  email: string
  phoneNumber: string
  kuvaWhiteLabelId: number
  kwlHostUrl: string
}

export enum FlowType {
  TopUp = 0,
  Cashout = 1,
  KuvaLocalOrder = 2,
  KuvaWhileLabel = 3,
  MaintenanceMode = 4,
  EarlyAccess = 5,
  CreditCardPayment = 6,
  CurrencyCloudBankPayment = 7,
  ClearJunctionBankPayment = 8,
  KuvaPayerFee = 9,
  CBZSanction = 17,
}

export enum FlowState {
  Off = 0,
  On = 1,
}

export type GetFlowListResponse = {
  instanceId: number
  type: FlowType
  state: FlowState
  details: { password: string } | null
}

export type PostFlowStateRequest = {
  id: string | undefined
  flowType: FlowType
  flowState: FlowState
}

export type EarlyAccessForm = {
  password: string
}

export type PostEarlyAccessPassword = {
  id: string | undefined
  body: EarlyAccessForm
}

export type PutBeneficiaryConfig = {
  id: string | undefined
  body: {
    Company: Record<string, { visible: boolean }>
    Individual: Record<string, { visible: boolean }>
  }
}

type BeneficiaryFieldConfig = {
  modifiable: boolean
  // group: number | null
  displayName: string
  visible: boolean
}

export type GetBeneficiaryConfig = {
  Company: Record<string, BeneficiaryFieldConfig>
  Individual: Record<string, BeneficiaryFieldConfig>

}

export type BeneficiaryFieldSettingsForm = {
  Company: Record<string, { visible: boolean }>,
  Individual: Record<string, { visible: boolean }>,
}


export enum CurrencyState {
  Inactive,
  Active,
}

export type Fee = {
  percentValue: number
  fixedValue: number
}

export type Currency = {
  id: string
  displayName: string
  sign: string
}

export enum CurrencyType {
  CashIn,
  CashOut,
  Both
}


export type CurrencyDetails = {
  id: number | string
  type: CurrencyType
  currency: Currency
  state: CurrencyState
  cashInFee: Fee
  kuvaCashInFee: Fee
  paymentProviders: Array<PaymentProviderWithFeeForInstanceDetails>
  cashOutProviders: Array<PaymentProviderWithFeeForInstanceDetails>
}

export type PaymentProviderFormType = Record<
  string,
  {
    paymentProviderId: number | string
    paymentMethod: number
    checked: boolean
    fee: Fee
    // paymentMethods: Record<string, { checked: boolean }>
  }
>

export type InstanceCurrencyFormType = {
  selectedCurrencyType: CurrencyType | string
  selectedCurrency: string | number
  cashInFee: Fee
  kuvaCashInFee: Fee
  cashInPaymentOptions: PaymentProviderFormType
  cashOutPaymentOptions: PaymentProviderFormType
}

export type PaymentProviderForForm = {
  paymentProviderId: string | number
  paymentProviderName: string
  paymentMethods: Array<{
    checked: boolean
    type: PaymentMethod
    name: string
  }>
}

export type CurrencyDetailForForm = {
  id: number | string
  type: CurrencyType
  currency: Currency
  state: CurrencyState
  cashInFee: Fee
  kuvaCashInFee: Fee
  // paymentOptions: Array<PaymentProviderForForm>
  paymentProviders: Array<PaymentProviderWithFeeForInstanceDetails>
  cashOutProviders: Array<PaymentProviderWithFeeForInstanceDetails>
  // paymentOptions: Array<{
  //   paymentProviderId: number | string
  //   paymentMethods: Array<PaymentMethod>
  //   fee: Fee
  // }>
}

export type PutInstanceCurrencyRequest = {
  instanceId: string | number
  currencyId: string | number
}

export enum PaymentMethod {
  KuvaCash = 1,
  CreditCard = 2, //KWL
  BankAccount = 3,
  Cash = 4,
  InstantCredit = 5,
  ApplePay = 6, //KWL
  GooglePay = 7, //KWL
  CashOnDelivery = 8,
  CreditWalletOnly = 9,
  CreditVendor = 10,
  MPesa = 11,
  BankTransfer = 100,
  OpenBanking = 105
}

export enum InstancePaymentOptionType {
  CashIn,
  CashOut
}

export type PaymentProviderMethod = {
  id: number
  paymentProvider: {
    id: number
    name: string
  }
  paymentOptionType: InstancePaymentOptionType
  paymentMethod: PaymentMethod
}

export type PaymentProviderWithFeeForInstanceDetails = {
  id: number | string
  paymentMethods: Array<PaymentMethod>
  fee: Fee
}

// type PaymentOption = {
//   // id: number
//   paymentMethod: PaymentMethod
//   paymentProvider: {
//     id: number | string
//   }
// }

export type CreateOrUpdateInstanceCurrencyRequestBody = {
  currency: {
    id: string | number
  }
  cashInFee: Fee
  kuvaCashInFee: Fee
  paymentProviders: Array<PaymentProviderWithFeeForInstanceDetails>
}

export type CreateInstanceCurrencyRequest = {
  instanceId: string | number
  body: CreateOrUpdateInstanceCurrencyRequestBody
}

export type UpdateInstanceCurrencyRequest = {
  instanceId: string | number
  currencyId: string | number
  body: CreateOrUpdateInstanceCurrencyRequestBody
}

export type GetInstanceCurrencyRequest = {
  instanceId: string | number
  currencyId: string | number
}

//remittance-agent-groups
export type AssignAgentGroupRequest = {
  instanceId: string | number
  agentGroupId: string | number
}

export type UnassignAgentGroupRequest = {
  instanceId: string | number
  agentGroupId: string | number
}

export type AgentGroup = {
  id: number
  title: string
}

export type Country = {
  id: number
  name: string
}

// export enum PaymentOptionType {
//   CashIn,
//   CashOut
// }

//remittance-payment-provider
export enum FeeUnitType {
  FixedAmount = 0,
  GradualAmount = 1,
  Percentage = 2,
  PercentAndFixed = 3,
  PercentOrMinFixed = 4,
}

export enum FeePayerType {
  Kuva = 0,
  User = 1,
  Instance = 2,
}

export type PaymentProvider = {
  id: number
  name: string
}

export type PaymentProviderFee = {
  id: number
  paymentProvider: {
    id: number
    name: string
  }
  // instance: {
  //   id: number
  //   hostUrl: string
  //   name: string
  // }
  // paymentMethod: PaymentMethod
  feeUnitType: FeeUnitType
  feeValue: {
    percentValue: number
    fixedValue: number
    feePayerType: FeePayerType
  }
  defaultValue: {
    percentValue: number
    fixedValue: number
    feePayerType: FeePayerType
  }
  status: string
}

export type PaymentProviderFeeDetail = {
  currency: Currency
  paymentProviderFees: Array<PaymentProviderFee>
}

export type DefaultPaymentProviderFees = {
  currency: string
  feeValue: {
    percentValue: number
    fixedValue: number
  }
  paymentProvider: {
    id: number
    name: string
  }
}

export type PaymentProviderFeeFormType = Record<
  string,
  { percentValue: number | string; fixedValue: number | string }
>
// {
//   selectedCurrency: string | number
//   selectedPaymentProvider: string | number
//   fixedValue: number
//   percentValue: number
// }

export type GetAllPaymentProviderFeesRequest = {
  paymentProviderId: string | number
  queryParams?: string
}

export type CreatePaymentProviderFeeRequest = {
  paymentProviderId: string | number
  body: {
    instanceId: number | string
    currency: string
    feeUnitType: FeeUnitType
    // countryId: null
    // paymentMethod: PaymentMethod | null
    feeValue: {
      percentValue: number
      fixedValue: number
      feePayerType: FeePayerType
    }
  }
}

//remittance-currency
export type GetPaymentOptionsByCountryRequestArg = {
  instanceId: string
  countryId: number | string
  type: InstancePaymentOptionType | string
}

export type PaymentOptionByCountry = {
  id: number,
  currency: string,
  title: string | null,
  longTitle: string | null,
  description: string | null,
  order: number

  paymentProviderMethod: PaymentProviderMethod
  instanceId: {
    id: number
  }
  country: Country
  fee: {
    percentValue: number,
    fixedValue: number
  }
}

export type PaymentOptionByCountryFormType = {
  currency: string,
  paymentMethod: PaymentMethod,
  paymentProviderId: number,
  countryId: number | string,
  instanceId: number,
  title: string,
  longTitle: string,
  description: string,
  order: number,
  fee: {
    percentValue: number,
    fixedValue: number
  }
  type: InstancePaymentOptionType | string

}

export type GetCurrencyListByCountryRequestArg = {
  instanceId: string
  countryId: number | string
  type: InstancePaymentOptionType
}

export type AddPaymentOptionByCountryRequestBody = {
  instanceId: string
  countryId: number | string
  type: InstancePaymentOptionType | string
  body: {
    currency: string,
    paymentMethod: PaymentMethod,
    paymentProviderId: number,
    countryId: number | string,
    instanceId: number,
    title: string,
    longTitle: string,
    description: string,
    fee: {
      percentValue: number,
      fixedValue: number
    }
  }

}

export type UpdatePaymentOptionByCountryRequestArg = {
  paymentOptionId: string
  instanceId: string
  countryId: number | string
  type: InstancePaymentOptionType | string
  body: {
    title: string
    longTitle: string
    description: string
    fee: {
      percentValue: number
      fixedValue: number
    }
  }
}

export type DeletePaymentOptionByCountryRequestArg = {
  paymentOptionId: string
  instanceId: string
  countryId: number | string
  type: InstancePaymentOptionType | string
}

export type SetExpirationEntryForUsersByRiskLevelRequestBody = {
  userRiskLevelMarker: RiskLevelMarker,
  months: number
}

export type CommonCurrencyFormType = {
  id: string,
  displayName: string,
  sign: string
}

