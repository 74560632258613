import { Alert, SnackbarCloseReason, AlertTitle, styled } from '@mui/material'

import Snackbar from '@mui/material/Snackbar'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../store'
import { clearNotification } from '../store/reducers/notificationReducer'

export const Notification = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const notification = useAppSelector(state => state.notification)

  // const [isOpenNotification, setIsOpenNotification] = useState(false)
  // const { clearNotification } = useNotification();

  const handleClose = (_: unknown, reason?: SnackbarCloseReason) => {
    reason !== 'clickaway' && dispatch(clearNotification())
  }

  const StyledAlert = styled(Alert)({
    width: '100%',
  })

  return (
    <Snackbar
      open={notification.open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <StyledAlert
        variant="filled"
        onClose={handleClose}
				severity={notification.type}
        // type={notification.type}
      >
        <AlertTitle>
          {notification.type === 'error' && `Error ${notification.errorCode}`}
          {notification.type === 'success' && `Success`}
        </AlertTitle>
        {notification.message}
      </StyledAlert>
    </Snackbar>
  )
}
