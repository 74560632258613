import { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'
import ProfileDetailsTab from './ProfileDetailsTab'
import { useAppDispatch } from '../../../store'
import { removeMobileUserDetails } from '../../../store/reducers/mobileUserReducer'
import AmlInspectionTab from './amlInspection/AmlInspectionTab'
import BlockingRecordsTab from './blockingRecords/BlockingRecordsTab'
import { Container, Tab, Tabs, styled } from '@mui/material'
import RiscScoresTab from './riskScores/RiscScoresTab'

const StyledTabs = styled(Tabs)({
  padding: '0 10px',
  '.MuiTabs-scrollButtons': {
    width: '25px',
    '&.Mui-disabled': {
      opacity: 0.3,
    },
  },
})

const MobileUserDetailsPage = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()

  const [tabValue, setTabValue] = useState<'details' | 'aml' | 'blocking-records' | 'risk-scores'>(
    'details'
  )
  const [queryParams, setQueryParams] = useSearchParams()

  useEffect(() => {
    if (queryParams.get('activeTab') === 'aml') {
      setTabValue('aml')
      return
    }
    if (queryParams.get('activeTab') === 'blocking-records') {
      setTabValue('blocking-records')
      return
    }

    if (queryParams.get('activeTab') === 'risk-scores') {
      setTabValue('risk-scores')
      return
    }

    setTabValue('details')
    setQueryParams({ activeTab: 'details' })
  }, [queryParams, setQueryParams])

  useEffect(() => {
    return () => {
      dispatch(removeMobileUserDetails())
    }
  }, [dispatch])

  const onChangeTab = useCallback(
    (event: SyntheticEvent, newTabValue: 'details') => {
      setTabValue(newTabValue)
      setQueryParams({ activeTab: newTabValue })
    },
    [setQueryParams]
  )
  return (
    <Container
      className="pt-25 pb-10"
      maxWidth="xl"
      sx={{ height: 'calc(100dvh - 90px)', overflowY: 'auto' }}
    >
      <StyledTabs
        value={tabValue}
        onChange={onChangeTab}
        sx={{ marginBottom: '16px' }}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        <Tab value="details" label="Profile Details" />
        <Tab value="aml" label="Aml Inspections" />
        <Tab value="blocking-records" label="Blocking Records" />
        <Tab value="risk-scores" label="Risk Scores" />
      </StyledTabs>
      {tabValue === 'details' && <ProfileDetailsTab userId={id} />}
      {tabValue === 'aml' && <AmlInspectionTab userId={id} />}
      {tabValue === 'blocking-records' && <BlockingRecordsTab userId={id} />}
      {tabValue === 'risk-scores' && <RiscScoresTab userId={id} />}
    </Container>
  )
}

export default MobileUserDetailsPage
