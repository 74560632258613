import { Box, Button, Grid, Stack, styled } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import React, { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../store'
import { remittanceActions } from '../../../store/actions/kwlActions'
import { removeBeneficiaryConfig } from '../../../store/reducers/kwlReducers'
import { BeneficiaryFieldSettingsForm } from '../../../types/remittanceTypes'
import omit from 'lodash/omit'

import PersonIcon from '@mui/icons-material/Person'
import ApartmentIcon from '@mui/icons-material/Apartment'

const StyledBeneficiarySettings = styled('form')({
  '.MuiFormControlLabel-root': {
    marginRight: 0,
  },
})

type BeneficiarySettingsProps = {
  instanceId: string | undefined
}

const BeneficiarySettings = ({ instanceId }: BeneficiarySettingsProps) => {
  const dispatch = useAppDispatch()
  const beneficiaryConfig = useAppSelector(state => state.kwl.beneficiaryConfig)

  const { handleSubmit, setValue, control, watch } = useForm<BeneficiaryFieldSettingsForm>({})

  const watchedIndividualEnabledVisible = watch('Individual.Enabled.visible')
  const watchedCompanyEnabledVisible = watch('Company.Enabled.visible')

  const watchedNationalIdVisible = watch('Individual.NationalId.visible')
  const watchedDriversLicenseVisible = watch('Individual.DriversLicense.visible')
  const watchedPassportVisible = watch('Individual.Passport.visible')
  const watchedDocumentIssuingCountryVisible = watch('Individual.DocumentIssuingCountry.visible')

  const watchedAddressCountryVisible = watch('Individual.AddressCountry.visible')
  const watchedAddressCityVisible = watch('Individual.AddressCity.visible')
  const watchedAddressStreetVisible = watch('Individual.AddressStreet.visible')
  const watchedAddressPostCodeVisible = watch('Individual.AddressPostCode.visible')

  const setFormValue = useCallback(() => {
    if (beneficiaryConfig) {
      //For Individual Type
      setValue('Individual.Enabled.visible', beneficiaryConfig.Individual['Enabled'].visible)
      setValue('Individual.FirstName.visible', beneficiaryConfig.Individual['FirstName'].visible)
      setValue('Individual.LastName.visible', beneficiaryConfig.Individual['LastName'].visible)
      setValue(
        'Individual.PhoneNumber.visible',
        beneficiaryConfig.Individual['PhoneNumber'].visible
      )
      setValue(
        'Individual.DateOfBirth.visible',
        beneficiaryConfig.Individual['DateOfBirth'].visible
      )
      setValue('Individual.NationalId.visible', beneficiaryConfig.Individual['NationalId'].visible)
      setValue(
        'Individual.DriversLicense.visible',
        beneficiaryConfig.Individual['DriversLicense'].visible
      )
      setValue('Individual.Passport.visible', beneficiaryConfig.Individual['Passport'].visible)
      setValue(
        'Individual.DocumentNumber.visible',
        beneficiaryConfig.Individual['DocumentNumber'].visible
      )
      setValue(
        'Individual.DocumentIssuingCountry.visible',
        beneficiaryConfig.Individual['DocumentIssuingCountry'].visible
      )
      setValue('Individual.Gender.visible', beneficiaryConfig.Individual['Gender'].visible)
      setValue(
        'Individual.AddressCountry.visible',
        beneficiaryConfig.Individual['AddressCountry'].visible
      )
      setValue(
        'Individual.AddressCity.visible',
        beneficiaryConfig.Individual['AddressCity'].visible
      )
      setValue(
        'Individual.AddressStreet.visible',
        beneficiaryConfig.Individual['AddressStreet'].visible
      )

      setValue(
        'Individual.AddressPostCode.visible',
        beneficiaryConfig.Individual['AddressPostCode'].visible
      )

      setValue(
        'Individual.ForeignCurrencyAccount.visible',
        beneficiaryConfig.Individual['AccountNumber'].visible &&
          beneficiaryConfig.Individual['BankName'].visible &&
          beneficiaryConfig.Individual['NotificationEmail'].visible &&
          beneficiaryConfig.Individual['Reference'].visible
      )

      //For Company Type
      setValue('Company.Enabled.visible', beneficiaryConfig.Company['Enabled'].visible)
      setValue(
        'Company.CompanyName.visible',
        beneficiaryConfig.Company['CompanyName'].visible || true
      )
      setValue(
        'Company.ForeignCurrencyAccount.visible',
        beneficiaryConfig.Company['AccountNumber'].visible &&
          beneficiaryConfig.Company['BankName'].visible &&
          beneficiaryConfig.Company['NotificationEmail'].visible &&
          beneficiaryConfig.Company['Reference'].visible
      )
    }
  }, [beneficiaryConfig, setValue])

  useEffect(() => {
    if (beneficiaryConfig) {
      setFormValue()
    }
  }, [beneficiaryConfig, setFormValue])

  useEffect(() => {
    if (instanceId) {
      dispatch(remittanceActions.getBeneficiaryConfig(instanceId))
    }

    return () => {
      dispatch(removeBeneficiaryConfig())
    }
  }, [dispatch, instanceId])

  const onChangeDocumentsCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked

    setValue('Individual.Passport.visible', checked)
    setValue('Individual.NationalId.visible', checked)
    setValue('Individual.DriversLicense.visible', checked)
    setValue('Individual.DocumentNumber.visible', checked)
    setValue('Individual.DocumentIssuingCountry.visible', checked)
  }

  const onChangeDocumentTypeCheckBox = useCallback(
    (nameField: string, event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked
      const passportVisible =
        nameField === 'Individual.DocumentNumber.visible' ? checked : watchedPassportVisible
      const NationalIdVisible =
        nameField === 'Individual.NationalId.visible' ? checked : watchedNationalIdVisible
      const driversLicenseVisible =
        nameField === 'Individual.DriversLicense.visible' ? checked : watchedDriversLicenseVisible

      if (!passportVisible && !NationalIdVisible && !driversLicenseVisible) {
        setValue('Individual.DocumentIssuingCountry.visible', false)
        setValue('Individual.DocumentNumber.visible', false)
      }

      if (passportVisible || NationalIdVisible || driversLicenseVisible) {
        setValue('Individual.DocumentNumber.visible', true)
      }
    },
    [setValue, watchedDriversLicenseVisible, watchedNationalIdVisible, watchedPassportVisible]
  )

  const onChangeAddressCheckBox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked

      setValue('Individual.AddressCountry.visible', checked)
      setValue('Individual.AddressCity.visible', checked)
      setValue('Individual.AddressStreet.visible', checked)
      setValue('Individual.AddressPostCode.visible', checked)
    },
    [setValue]
  )

  const onChangeAddressFieldsCheckBox = useCallback(
    (nameField: string, event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked
      const isAddressCountryCheckBox = nameField === 'Individual.AddressCountry.visible'
      const isAddressCityCheckBox = nameField === 'Individual.AddressCity.visible'
      const isAddressStreetCheckBox = nameField === 'Individual.AddressStreet.visible'

      const addressCountryVisible = isAddressCountryCheckBox
        ? checked
        : watchedAddressCountryVisible
      const addressCityVisible = isAddressCityCheckBox ? checked : watchedAddressCityVisible
      const addressStreetVisible = isAddressStreetCheckBox ? checked : watchedAddressStreetVisible

      if (!addressCountryVisible && !addressCityVisible && !addressStreetVisible) {
        setValue('Individual.AddressPostCode.visible', false)
        return
      }

      if (checked && isAddressCityCheckBox) {
        setValue('Individual.AddressCountry.visible', true)
      }

      if (!checked && isAddressCityCheckBox) {
        setValue('Individual.AddressStreet.visible', false)
      }

      if (checked && isAddressStreetCheckBox) {
        setValue('Individual.AddressCountry.visible', true)
        setValue('Individual.AddressCity.visible', true)
      }

      if (!checked && isAddressCountryCheckBox) {
        setValue('Individual.AddressCountry.visible', false)
        setValue('Individual.AddressCity.visible', false)
        setValue('Individual.AddressStreet.visible', false)
        setValue('Individual.AddressPostCode.visible', false)
      }
    },
    [setValue, watchedAddressCityVisible, watchedAddressCountryVisible, watchedAddressStreetVisible]
  )

  const onSubmit = (data: BeneficiaryFieldSettingsForm) => {
    const omittedCompanyData = omit(data.Company, 'ForeignCurrencyAccount')
    const omittedIndividualData = omit(data.Individual, 'ForeignCurrencyAccount')
    const companyForeignCurrencyAccountFields = {
      AccountNumber: {
        visible: data.Company.ForeignCurrencyAccount.visible,
      },
      BankName: { visible: data.Company.ForeignCurrencyAccount.visible },
      Reference: { visible: data.Company.ForeignCurrencyAccount.visible },
      NotificationEmail: { visible: data.Company.ForeignCurrencyAccount.visible },
    }

    const individualForeignCurrencyAccountFields = {
      AccountNumber: {
        visible: data.Individual.ForeignCurrencyAccount.visible,
      },
      BankName: { visible: data.Individual.ForeignCurrencyAccount.visible },
      Reference: { visible: data.Individual.ForeignCurrencyAccount.visible },
      NotificationEmail: { visible: data.Individual.ForeignCurrencyAccount.visible },
    }

    const requestData = {
      id: instanceId,
      body: {
        Company: {
          ...omittedCompanyData,
          ...companyForeignCurrencyAccountFields,
        },
        Individual: {
          ...omittedIndividualData,
          ...individualForeignCurrencyAccountFields,
        },
      },
    }
    dispatch(remittanceActions.updateBeneficiaryConfig(requestData))
  }

  return (
    <>
      {beneficiaryConfig !== null && (
        <StyledBeneficiarySettings onSubmit={handleSubmit(onSubmit)}>
          <FormGroup
            sx={{
              display: 'flex',
              flexDirection: 'row',
              height: 'calc(100dvh - 170px)',
              overflow: 'auto',
            }}
          >
            <Stack flexDirection="row" height={50}>
              <Controller
                name="Individual.Enabled.visible"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    sx={{ width: '100%' }}
                    control={
                      <Checkbox
                        onChange={onChange}
                        checked={typeof value === 'undefined' ? false : value}
                        // disabled={!beneficiaryConfig.Individual['Enabled'].modifiable}
                        disabled
                      />
                    }
                    label="Individual Type"
                  />
                )}
              />
              <PersonIcon sx={{ height: '50px', padding: '5px 0' }} />
            </Stack>
            <Grid container sx={{ margin: '0', width: '100%' }}>
              <Grid item xs={6} sx={{ padding: '0 16px 16px 16px !important' }}>
                <Controller
                  name="Individual.FirstName.visible"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      sx={{ width: '100%' }}
                      control={
                        <Checkbox
                          onChange={onChange}
                          checked={typeof value === 'undefined' ? false : value}
                          disabled={
                            !beneficiaryConfig.Individual['FirstName'].modifiable ||
                            !watchedIndividualEnabledVisible
                          }
                        />
                      }
                      label="First name"
                    />
                  )}
                />
                <Controller
                  name="Individual.LastName.visible"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      sx={{ width: '100%' }}
                      control={
                        <Checkbox
                          onChange={onChange}
                          checked={typeof value === 'undefined' ? false : value}
                          disabled={
                            !beneficiaryConfig.Individual['LastName'].modifiable ||
                            !watchedIndividualEnabledVisible
                          }
                        />
                      }
                      label="Last name"
                    />
                  )}
                />
                <Controller
                  name="Individual.PhoneNumber.visible"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      sx={{ width: '100%' }}
                      control={
                        <Checkbox
                          onChange={onChange}
                          checked={typeof value === 'undefined' ? false : value}
                          disabled={
                            !beneficiaryConfig.Individual['LastName'].modifiable ||
                            !watchedIndividualEnabledVisible
                          }
                        />
                      }
                      label="Phone number"
                    />
                  )}
                />
                <Controller
                  name="Individual.DateOfBirth.visible"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      sx={{ width: '100%' }}
                      control={
                        <Checkbox
                          onChange={onChange}
                          checked={typeof value === 'undefined' ? false : value}
                          disabled={
                            !beneficiaryConfig.Individual['DateOfBirth'].modifiable ||
                            !watchedIndividualEnabledVisible
                          }
                        />
                      }
                      label="Date of birth"
                    />
                  )}
                />
                <FormControlLabel
                  label="Documents"
                  control={
                    <Checkbox
                      checked={Boolean(
                        watchedPassportVisible &&
                          watchedNationalIdVisible &&
                          watchedDriversLicenseVisible &&
                          watchedDocumentIssuingCountryVisible
                      )}
                      indeterminate={Boolean(
                        !(
                          watchedPassportVisible &&
                          watchedNationalIdVisible &&
                          watchedDriversLicenseVisible &&
                          watchedDocumentIssuingCountryVisible
                        ) &&
                          (watchedPassportVisible ||
                            watchedNationalIdVisible ||
                            watchedDriversLicenseVisible ||
                            watchedDocumentIssuingCountryVisible)
                      )}
                      disabled={!watchedIndividualEnabledVisible}
                      onChange={onChangeDocumentsCheckBox}
                    />
                  }
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                  <Controller
                    name="Individual.Passport.visible"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={event => {
                              onChange(event.target.checked)
                              onChangeDocumentTypeCheckBox(name, event)
                            }}
                            checked={typeof value === 'undefined' ? false : value}
                            disabled={
                              !beneficiaryConfig.Individual['Passport'].modifiable ||
                              !watchedIndividualEnabledVisible
                            }
                          />
                        }
                        label="Passport"
                      />
                    )}
                  />
                  <Controller
                    name="Individual.NationalId.visible"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={event => {
                              onChange(event.target.checked)
                              onChangeDocumentTypeCheckBox(name, event)
                            }}
                            checked={typeof value === 'undefined' ? false : value}
                            disabled={
                              !beneficiaryConfig.Individual['NationalId'].modifiable ||
                              !watchedIndividualEnabledVisible
                            }
                          />
                        }
                        label="National ID"
                      />
                    )}
                  />
                  <Controller
                    name="Individual.DriversLicense.visible"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={event => {
                              onChange(event.target.checked)
                              onChangeDocumentTypeCheckBox(name, event)
                            }}
                            checked={typeof value === 'undefined' ? false : value}
                            disabled={
                              !beneficiaryConfig.Individual['DriversLicense'].modifiable ||
                              !watchedIndividualEnabledVisible
                            }
                          />
                        }
                        label="Drivers license"
                      />
                    )}
                  />
                  <Controller
                    name="Individual.DocumentNumber.visible"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox onChange={e => onChange()} checked={Boolean(value)} disabled />
                        }
                        label="Document number"
                      />
                    )}
                  />
                  <Controller
                    name="Individual.DocumentIssuingCountry.visible"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={onChange}
                            checked={Boolean(value)}
                            disabled={
                              !beneficiaryConfig.Individual['DriversLicense'].modifiable ||
                              !Boolean(
                                watchedPassportVisible ||
                                  watchedNationalIdVisible ||
                                  watchedDriversLicenseVisible
                              ) ||
                              !watchedIndividualEnabledVisible
                            }
                          />
                        }
                        label="Document issuing country"
                      />
                    )}
                  />
                </Box>
                <Controller
                  name="Individual.Gender.visible"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      sx={{ width: '100%' }}
                      control={
                        <Checkbox
                          onChange={onChange}
                          checked={typeof value === 'undefined' ? false : value}
                          disabled={
                            !beneficiaryConfig.Individual['Gender'].modifiable ||
                            !watchedIndividualEnabledVisible
                          }
                        />
                      }
                      label="Gender"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} pb={2}>
                <FormControlLabel
                  label="Address"
                  control={
                    <Checkbox
                      checked={
                        Boolean(watchedAddressCountryVisible && watchedAddressCityVisible) &&
                        watchedAddressStreetVisible &&
                        watchedAddressPostCodeVisible
                      }
                      indeterminate={Boolean(
                        !(
                          watchedAddressCountryVisible &&
                          watchedAddressCityVisible &&
                          watchedAddressStreetVisible &&
                          watchedAddressPostCodeVisible
                        ) &&
                          (watchedAddressCountryVisible ||
                            watchedAddressCityVisible ||
                            watchedAddressStreetVisible ||
                            watchedAddressPostCodeVisible)
                      )}
                      onChange={onChangeAddressCheckBox}
                      disabled={!watchedIndividualEnabledVisible}
                    />
                  }
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                  <Controller
                    name="Individual.AddressCountry.visible"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={event => {
                              onChange(event.target.checked)
                              onChangeAddressFieldsCheckBox(name, event)
                            }}
                            checked={typeof value === 'undefined' ? false : value}
                            disabled={
                              !beneficiaryConfig.Individual['AddressCountry'].modifiable ||
                              !watchedIndividualEnabledVisible
                            }
                          />
                        }
                        label="Country"
                      />
                    )}
                  />
                  <Controller
                    name="Individual.AddressCity.visible"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={event => {
                              onChange(event.target.checked)
                              onChangeAddressFieldsCheckBox(name, event)
                            }}
                            checked={typeof value === 'undefined' ? false : value}
                            disabled={
                              !beneficiaryConfig.Individual['AddressCity'].modifiable ||
                              !watchedIndividualEnabledVisible
                            }
                          />
                        }
                        label="City"
                      />
                    )}
                  />
                  <Controller
                    name="Individual.AddressStreet.visible"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={event => {
                              onChange(event.target.checked)
                              onChangeAddressFieldsCheckBox(name, event)
                            }}
                            checked={typeof value === 'undefined' ? false : value}
                            disabled={
                              !beneficiaryConfig.Individual['AddressStreet'].modifiable ||
                              !watchedIndividualEnabledVisible
                            }
                          />
                        }
                        label="Street"
                      />
                    )}
                  />
                  <Controller
                    name="Individual.AddressPostCode.visible"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={onChange}
                            checked={Boolean(value)}
                            disabled={
                              !beneficiaryConfig.Individual['AddressPostCode'].modifiable ||
                              !Boolean(
                                watchedAddressCountryVisible ||
                                  watchedAddressCityVisible ||
                                  watchedAddressStreetVisible
                              ) ||
                              !watchedIndividualEnabledVisible
                            }
                          />
                        }
                        label="Post code"
                      />
                    )}
                  />
                </Box>
                <Controller
                  name="Individual.ForeignCurrencyAccount.visible"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      sx={{ width: '100%' }}
                      control={
                        <Checkbox
                          onChange={onChange}
                          checked={typeof value === 'undefined' ? false : value}
                          disabled={
                            (!beneficiaryConfig.Individual['AccountNumber'].modifiable &&
                              !beneficiaryConfig.Individual['BankName'].modifiable &&
                              !beneficiaryConfig.Individual['NotificationEmail'].modifiable &&
                              !beneficiaryConfig.Individual['Reference'].modifiable) ||
                            !watchedIndividualEnabledVisible
                          }
                        />
                      }
                      label="Foreign Currency Account"
                    />
                  )}
                />
              </Grid>

              <Stack flexDirection="row" height={50}>
                <Controller
                  name="Company.Enabled.visible"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      sx={{ width: '100%' }}
                      control={
                        <Checkbox
                          onChange={onChange}
                          checked={typeof value === 'undefined' ? false : value}
                          disabled={!beneficiaryConfig.Company['Enabled'].modifiable}
                        />
                      }
                      label="Company Type"
                    />
                  )}
                />
                <ApartmentIcon sx={{ height: '50px', padding: '5px 0' }} />
              </Stack>
              <Grid container spacing={2} sx={{ margin: 0, width: '100%' }}>
                <Grid item xs={6} sx={{ padding: '0 16px 16px 16px !important' }}>
                  <Controller
                    name="Company.CompanyName.visible"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        sx={{ width: '100%' }}
                        control={
                          <Checkbox
                            onChange={onChange}
                            checked={typeof value === 'undefined' ? false : value}
                            disabled
                            // disabled={!beneficiaryConfig.Company['Enabled'].modifiable}
                          />
                        }
                        label="Company Name"
                      />
                    )}
                  />
                  <Controller
                    name="Company.ForeignCurrencyAccount.visible"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        sx={{ width: '100%' }}
                        control={
                          <Checkbox
                            onChange={onChange}
                            checked={typeof value === 'undefined' ? false : value}
                            disabled={
                              (!beneficiaryConfig.Company['AccountNumber'].modifiable &&
                                !beneficiaryConfig.Company['BankName'].modifiable &&
                                !beneficiaryConfig.Company['NotificationEmail'].modifiable &&
                                !beneficiaryConfig.Company['Reference'].modifiable) ||
                              !watchedCompanyEnabledVisible
                            }
                          />
                        }
                        label="Foreign Currency Account"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <Button size="small" variant="contained" type="submit" sx={{ width: '160px' }}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </FormGroup>
        </StyledBeneficiarySettings>
      )}
    </>
  )
}

export default BeneficiarySettings
