import { FormEvent, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { agentGroupsActions } from '../../store/actions/agentGroupsActions'
import { Button, Container, TextField } from '@mui/material'
import getQueryString from '../../helpers/getQueryString'
import concatenateObjProps from '../../helpers/concatenateObjProps'
import { removeAgentGroupsList } from '../../store/reducers/agentGroupsReducer'
import LazyLoadingTable from '../../components/CustomTable/LazyLoadingTable'
import { AgentGroupItem } from '../../types/agentGroupTypes'
import agentGroupTableConfigs from '../../utils/config/tableConfig/agentGroups'
import useInfiniteScroll from 'react-infinite-scroll-hook'

const AgentGroupsPage = () => {
  const dispatch = useAppDispatch()

  const { items: tableData, hasNextPage } = useAppSelector(
    state => state.agentGroups.agentGroupsList
  )
  const { isLoading, error } = useAppSelector(state => state.agentGroups)
  const { isMobileView } = useAppSelector(state => state.display)

  const [query, setQuery] = useState({ query: '' })

  const pageSize = 15
  const offset = 0

  useEffect(() => {
    const currentParams = getQueryString(concatenateObjProps({ offset, pageSize }))
    dispatch(agentGroupsActions.getAgentGroupList({ queryParams: currentParams, offset }))

    return () => {
      dispatch(removeAgentGroupsList())
    }
  }, [dispatch])

  const onLoadMore = async () => {
    const currentOffset = offset + tableData.length
    const currentParams = getQueryString(
      concatenateObjProps({ ...query, offset: currentOffset, pageSize })
    )

    await dispatch(
      agentGroupsActions.getAgentGroupList({ queryParams: currentParams, offset: currentOffset })
    )
  }

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  })

  const tableConfig = useMemo(() => {
    return agentGroupTableConfigs.agentGroupTableConfig()
  }, [])

  const clickSearch = async (e: FormEvent) => {
    e.preventDefault()

    const query = e.target[0].value.trim()
    const currentParams = getQueryString(concatenateObjProps({ query, offset, pageSize }))

    await dispatch(agentGroupsActions.getAgentGroupList({ queryParams: currentParams, offset }))

    setQuery(prevState => ({ ...prevState, query }))
  }

  return (
    <Container className="pt-25 pb-10 h-100-percent overflow-hidden" maxWidth="xl">
      <h3 className="mb-25">Agent Groups</h3>
      <div
        className={`flex j-content-space-between ${isMobileView ? 'flex-dir-column' : ''}`}
        style={{ justifyContent: 'end' }}
      >
        <form className="search mb-20 ml-10" onSubmit={clickSearch}>
          <TextField
            label="Search by Title"
            size="small"
            name="search"
          />
          <Button size="small" variant="contained" type="submit">
            Apply
          </Button>
        </form>
      </div>
      <LazyLoadingTable<AgentGroupItem>
        tableData={tableData}
        tableConfig={tableConfig}
        sentryRef={sentryRef}
        rootRef={rootRef}
        // onClick={onClickRow}
        minTableWidth="360px"
        loading={isLoading}
        hasNextPage={hasNextPage}
        maxHeight='calc(100vh - 330px)'
      />
    </Container>
  )
}

export default AgentGroupsPage
