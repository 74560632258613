export const RESET_ERROR = 'RESET_ERROR'

// auth
export const TOKENS_FETCHING = 'TOKENS_FETCHING'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const AUTHENTICATOR_FETCHING = 'AUTHENTICATOR_FETCHING'
export const AUTHENTICATOR_CONFIRM = 'AUTHENTICATOR_CONFIRM'
export const AUTHENTICATOR_DISABLE = 'AUTHENTICATOR_DISABLE'
export const CONFIRM_TWO_FA = 'CONFIRM_TWO_FA'

// user
export const USER_FETCHING = 'USER_FETCHING'

// display
export const SET_DISPLAY_DATA = 'SET_DISPLAY_DATA'

// permissions
export const PERMISSION_LIST_FETCHING = 'PERMISSION_LIST_FETCHING'
export const USER_PERMISSION_LIST_FETCHING = 'USER_PERMISSION_LIST_FETCHING'

// loader
export const SET_START_LOADER = 'SET_START_LOADER'
export const SET_STOP_LOADER = 'SET_STOP_LOADER'

// kwl
export const FETCH_KWL_SITE_LIST = 'FETCH_KWL_SITE_LIST'
export const FETCH_KWL_USER_LIST = 'FETCH_KWL_USER_LIST'
export const FETCH_KWL_DATA_BY_ID = 'FETCH_KWL_DATA_BY_ID'
export const SET_KWL_SOURCE_COUNTRY = 'SET_KWL_SOURCE_COUNTRY'
export const SET_KWL_RECEIVE_COUNTRY = 'SET_KWL_RECEIVE_COUNTRY'
export const DELETE_KWL_SOURCE_COUNTRY = 'DELETE_KWL_SOURCE_COUNTRY'
export const DELETE_KWL_RECEIVE_COUNTRY = 'DELETE_KWL_RECEIVE_COUNTRY'

// cash out
export const FETCH_CASH_OUT_PENDING_LIST = 'FETCH_CASH_OUT_PENDING_LIST'

// top up
export const FETCH_TOP_UP_USDK_LIST = 'FETCH_TOP_UP_USDK_LIST'
export const FETCH_TOP_UP_CASHRAIL_LIST = 'FETCH_TOP_UP_CASHRAIL_LIST'
export const FETCH_TOP_UP_KWL_LIST = 'FETCH_TOP_UP_KWL_LIST'

// country
export const FETCH_COUNTRY_LIST = 'FETCH_COUNTRY_LIST'

export const FETCH_MOBILE_USER_LIST = 'FETCH_MOBILE_USER_LIST'

// transactions
export const FETCH_TRANSACTION_ALL_LIST = 'FETCH_TRANSACTION_ALL_LIST'
