import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import {
  PaymentOptionByCountry,
  PaymentOptionByCountryFormType,
} from '../../../../../types/remittanceTypes'
import SelectFieldForm from '../../../../../components/reusable/form/SelectFieldForm'
import { Stack, Typography, styled } from '@mui/material'
import TextFieldForm from '../../../../../components/reusable/form/TextFieldForm'

const getPaymentMethodOptionsByPaymentProvider = (
  list: Array<{ label: string; value: number; paymentProviderId: number }>,
  paymentProviderId: number
) => list.filter(item => item.paymentProviderId === paymentProviderId)

const StyledPaymentOptionByCountryForm = styled('form')({
  padding: '12px 0',

  '.MuiFormControl-root': {
    width: '50%',
  },
  '.MuiGrid-item': {
    display: 'flex',
    '&.fixed-value-block': {
      padding: '8px 16px 0 16px',
      '.MuiTextField-root': {},
    },
    '&.percent-value-block': {
      padding: '8px 0 0 32px',
    },
    '.MuiCheckbox-root': {
      marginLeft: '12px',
      paddingTop: 0,
    },

    '.MuiFormControl-root': {
      width: 'initial',
      '.MuiInput-input': {
        padding: '1px 0 0 5px',
      },
    },
    '.MuiFormControlLabel-root': {
      alignItems: 'start',
    },
  },
})

type PaymentOptionByCountryFormProps = {
  currencyOptions: Array<{ label: string; value: string }>
  countryOptions: Array<{ label: string; value: string | number }>
  currencyTypeOptions: Array<{ label: string; value: string | number }>
  paymentProviderOptions: Array<{ label: string; value: number }>
  paymentMethodOptions: Array<{ label: string; value: number; paymentProviderId: number }>
  instanceId: string | number
  countryId?: string | number
  data: PaymentOptionByCountry
  onConfirm: (dataForm: PaymentOptionByCountryFormType) => void
  variant: 'add-payment-option-by-country' | 'edit-payment-option-by-country'
}

const PaymentOptionByCountryForm = ({
  data,
  currencyOptions,
  countryOptions,
  currencyTypeOptions,
  paymentProviderOptions,
  paymentMethodOptions,
  instanceId,
  countryId,
  onConfirm,
  variant,
}: PaymentOptionByCountryFormProps) => {
  const { handleSubmit, setValue, control, watch } = useForm<PaymentOptionByCountryFormType>({})

  const setFormValue = useCallback(() => {
    setValue('countryId', Number(countryOptions[0].value))
    setValue('type', String(data.paymentProviderMethod.paymentOptionType))
    setValue('instanceId', Number(instanceId))

    if (variant === 'add-payment-option-by-country') {
      const currentPaymentMethodValue = getPaymentMethodOptionsByPaymentProvider(
        paymentMethodOptions,
        paymentProviderOptions[0]?.value
      )[0]?.value

      setValue('paymentProviderId', paymentProviderOptions[0].value)
      setValue('paymentMethod', currentPaymentMethodValue)
      setValue('currency', currencyOptions[0].value)
      setValue('fee.fixedValue', 0)
      setValue('fee.percentValue', 0)
      setValue('order', 0)
      setValue('title', '')
      setValue('longTitle', '')
      setValue('description', '')
    } else {
      setValue('currency', data.currency)
      setValue('paymentProviderId', data.paymentProviderMethod.paymentProvider.id)
      setValue('paymentMethod', data.paymentProviderMethod.paymentMethod)
      setValue('fee.fixedValue', data.fee.fixedValue)
      setValue('fee.percentValue', data.fee.percentValue)
      setValue('order', data.order)
      setValue('title', data.title || '')
      setValue('longTitle', data.longTitle || '')
      setValue('description', data.description || '')
    }
  }, [
    countryOptions,
    currencyOptions,
    data.currency,
    data.description,
    data.fee.fixedValue,
    data.fee.percentValue,
    data.longTitle,
    data.order,
    data.paymentProviderMethod.paymentMethod,
    data.paymentProviderMethod.paymentOptionType,
    data.paymentProviderMethod.paymentProvider.id,
    data.title,
    instanceId,
    paymentMethodOptions,
    paymentProviderOptions,
    setValue,
    variant,
  ])

  useEffect(() => {
    if (data) {
      setFormValue()
    }
  }, [data, setFormValue])

  const watchedPaymentProviderId = watch('paymentProviderId')
  const watchedCurrency = watch('currency')

  const currentPaymentMethodOptions = useMemo(
    () => getPaymentMethodOptionsByPaymentProvider(paymentMethodOptions, watchedPaymentProviderId),
    [paymentMethodOptions, watchedPaymentProviderId]
  )

  const onSubmit = (data: PaymentOptionByCountryFormType) => {
    onConfirm(data)
  }

  return (
    <StyledPaymentOptionByCountryForm id="instance-currency-form" onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <SelectFieldForm<PaymentOptionByCountryFormType>
          name="countryId"
          control={control}
          label="Select Country"
          multiple={false}
          options={countryOptions}
          disabled
        />
        <SelectFieldForm<PaymentOptionByCountryFormType>
          name="type"
          control={control}
          label="Select Currency Type"
          multiple={false}
          options={currencyTypeOptions}
          disabled
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <SelectFieldForm<PaymentOptionByCountryFormType>
          name="currency"
          control={control}
          label="Select Currency"
          multiple={false}
          options={currencyOptions}
          disabled={variant === 'edit-payment-option-by-country'}
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <SelectFieldForm<PaymentOptionByCountryFormType>
          name="paymentProviderId"
          control={control}
          label="Select Payment Provider"
          multiple={false}
          options={paymentProviderOptions}
          disabled={variant === 'edit-payment-option-by-country'}
        />
        <SelectFieldForm<PaymentOptionByCountryFormType>
          name="paymentMethod"
          control={control}
          label="Select Payment Method"
          multiple={false}
          options={currentPaymentMethodOptions}
          disabled={variant === 'edit-payment-option-by-country'}
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing={2} mt={1} mb={1}>
        <>
          <Typography fontSize="16px">{watchedCurrency}</Typography>
          <TextFieldForm<PaymentOptionByCountryFormType>
            name="fee.fixedValue"
            control={control}
            label=""
            type="tel"
            inputMode="numeric"
            variant="standard"
            autoComplete="off"
            validMask={/[^0-9.]/}
            maxLength={7}
            disabled={!watchedCurrency}
          />
          <Typography fontSize="16px">(Fixed)</Typography>
        </>
        <>
          <Typography fontSize="16px">%</Typography>
          <TextFieldForm<PaymentOptionByCountryFormType>
            name="fee.percentValue"
            control={control}
            label=""
            type="tel"
            inputMode="numeric"
            variant="standard"
            autoComplete="off"
            validMask={/[^0-9.]/}
            maxLength={7}
            disabled={!watchedCurrency}
          />
          <Typography fontSize="16px">(Percent)</Typography>
        </>
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <TextFieldForm<PaymentOptionByCountryFormType>
          name="order"
          control={control}
          label="Order"
          type="tel"
          inputMode="numeric"
          variant="standard"
          autoComplete="off"
          validMask={/[^0-9]/}
          maxLength={2}
        />
        <TextFieldForm<PaymentOptionByCountryFormType>
          name="title"
          control={control}
          label="Title"
          type="text"
          inputMode="text"
          variant="standard"
          autoComplete="off"
          // validMask={/[^0-9.]/}
          maxLength={1024}
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <TextFieldForm<PaymentOptionByCountryFormType>
          name="longTitle"
          control={control}
          label="Long Title"
          type="text"
          inputMode="text"
          variant="standard"
          autoComplete="off"
          // validMask={/[^0-9.]/}
          maxLength={1024}
        />
        <TextFieldForm<PaymentOptionByCountryFormType>
          name="description"
          control={control}
          label="Description"
          type="text"
          inputMode="text"
          variant="standard"
          autoComplete="off"
          // validMask={/[^0-9.]/}
          maxLength={1024}
        />
      </Stack>
    </StyledPaymentOptionByCountryForm>
  )
}

export default PaymentOptionByCountryForm
