import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import CustomTable from '../../../components/CustomTable/CustomTable'
import { useAppDispatch, useAppSelector } from '../../../store'
import { worldPayActions } from '../../../store/actions/wpActions'
import { WpCardPaymentAmountItem, WpCardPaymentStatusItem } from '../../../types/wpTypes'
import wpTableConfigs from '../../../utils/config/tableConfig/wp'
import { getDateWithTime } from '../../../utils/dateFormating'
import {
  getCardPaymentStatus,
  getPaymentService,
  getWorldPayPaymentStatus,
} from '../../../utils/statusUtils/wpStatusUtils'
import { openGlobalModal } from '../../../store/reducers/globalModalReducer'

const domain = process.env.REACT_APP_BACK_OFFICE_DOMAIN

const WpKwlCardPaymentDetail = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams()

  const cardPaymentDetails = useAppSelector(state => state.worldPay.kwlCardPaymentDetail)

  const amountTableConfig = useMemo(() => {
    return wpTableConfigs.wpCardPaymentAmountTable()
  }, [])

  const statusTableConfig = useMemo(() => {
    return wpTableConfigs.wpCardPaymentStatusConfig()
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(worldPayActions.getKwlCardPaymentDetail(id))
    }
  }, [dispatch, id])

  const onRefundTopUp = useCallback(() => {
    dispatch(
      openGlobalModal({
        variant: 'refund-remittance-top-up',
        actionData: { remittanceId: cardPaymentDetails?.topUp.extId },
      })
    )
  }, [cardPaymentDetails?.topUp.extId, dispatch])

  return (
    <Container className="pt-25 pb-10 h-100-percent" maxWidth="xl">
      {cardPaymentDetails ? (
        <>
          <Grid container spacing={2} sx={{ margin: 0, width: '100%' }}>
            <Grid
              sx={{
                paddingLeft: 0,
                paddingTop: 0,
                overflowX: { md: 'auto' },
              }}
              item
              xs={12}
              sm={4}
            >
              <Typography fontWeight="bold">Instance Id:</Typography>
              <Typography mb={1}>{cardPaymentDetails?.instance.id}</Typography>

              <Typography fontWeight="bold">Instance Name:</Typography>
              <Typography mb={1}>{cardPaymentDetails?.instance.name}</Typography>

              <Typography fontWeight="bold" sx={{ wordWrap: 'break-word' }}>
                Instance URL:
              </Typography>
              <Typography mb={1}>{cardPaymentDetails?.instance.hostUrl}</Typography>

              <Typography fontWeight="bold">TopUp Id:</Typography>
              <Typography mb={1}>{cardPaymentDetails?.topUp.id}</Typography>
              <Box>
                <Link to={`/top-up/kuva-white-label/${cardPaymentDetails?.topUp.extId}/details`}>
                  <Button
                    sx={{ width: '160px', marginBottom: '8px' }}
                    size="small"
                    variant="contained"
                  >
                    Top Up Details
                  </Button>
                </Link>
              </Box>
              {cardPaymentDetails?.refundIsAvailable && (
                <Button
                  sx={{ width: '160px', marginBottom: '8px' }}
                  size="small"
                  variant="contained"
                  onClick={onRefundTopUp}
                >
                  Refund
                </Button>
              )}
            </Grid>
            <Grid
              sx={{
                paddingLeft: 0,
                paddingTop: 0,
                overflowX: { md: 'auto' },
              }}
              item
              xs={12}
              sm={4}
            >
              <Typography fontWeight="bold">Created Date(UTC):</Typography>
              <Typography mb={1}>{getDateWithTime(cardPaymentDetails?.createdDate)}</Typography>

              <Typography fontWeight="bold">Updated Date(UTC):</Typography>
              <Typography mb={1}>{getDateWithTime(cardPaymentDetails?.updatedDate)}</Typography>

              <Typography fontWeight="bold">Card Payment Status:</Typography>
              <Typography mb={1}>{getCardPaymentStatus(cardPaymentDetails?.status)}</Typography>

              {cardPaymentDetails?.paymentType && (
                <>
                  <Typography fontWeight="bold">Payment Type:</Typography>
                  <Typography mb={1}>
                    {getWorldPayPaymentStatus(cardPaymentDetails?.paymentType)}
                  </Typography>
                </>
              )}

              <Typography fontWeight="bold">Payment Service:</Typography>
              <Typography mb={1}>
                {getPaymentService(cardPaymentDetails?.paymentService)}
              </Typography>
            </Grid>
            <Grid
              sx={{
                paddingLeft: 0,
                paddingTop: 0,
                overflowX: { md: 'auto' },
              }}
              item
              xs={12}
              sm={4}
            >
              <Typography fontWeight="bold">User:</Typography>
              <Typography>
                {cardPaymentDetails?.user.firstName + ' ' + cardPaymentDetails?.user.lastName}
              </Typography>

              <Typography>{cardPaymentDetails?.user.phoneNumber}</Typography>
              <Typography mb={1} sx={{ wordWrap: 'break-word' }}>
                {cardPaymentDetails?.user.email}
              </Typography>

              <Button
                sx={{ width: '160px', marginBottom: '8px' }}
                size="small"
                variant="contained"
                href={`${domain}/MobileUsers/${cardPaymentDetails.user.extId}`}
                target="_blank"
              >
                User Details
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ margin: 0, width: '100%' }}>
            <Grid
              sx={{
                paddingLeft: 0,
                paddingTop: 0,
                overflowX: { md: 'auto' },
                height: { md: `${cardPaymentDetails?.refundIsAvailable ? '40dvh' : '45dvh'}` },
              }}
              item
              xs={12}
            >
              <h3 style={{ marginBottom: '8px' }}>Card Payment Info</h3>
              {cardPaymentDetails.amounts[0].creditCardNumber && (
                <Stack direction="row" spacing={1} pl={3} mb={1}>
                  <Typography fontWeight="bold">{`Card Number: `}</Typography>
                  <Typography>{cardPaymentDetails.amounts[0].creditCardNumber}</Typography>
                </Stack>
              )}

              <CustomTable<WpCardPaymentAmountItem>
                tableConfig={amountTableConfig}
                tableData={cardPaymentDetails?.amounts ? cardPaymentDetails?.amounts : []}
              />
              <h3 style={{ marginBottom: '8px' }}>Card Payment Status Info</h3>
              <CustomTable<WpCardPaymentStatusItem>
                tableConfig={statusTableConfig}
                tableData={cardPaymentDetails?.statuses ? cardPaymentDetails?.statuses : []}
              />
            </Grid>
          </Grid>
        </>
      ) : null}
    </Container>
  )
}

export default WpKwlCardPaymentDetail
