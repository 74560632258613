import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import SettingRiskScoreByLevelBox from './SettingRiskScoreByLevelBox'

const RiskScoresTab = () => {
  return (
    <div>
      <Typography sx={{fontWeight: '700', color: 'red', fontSize: '24px'}}>DRAFT</Typography>
      <Grid
        container
        sx={{
          margin: 0,
          padding: 0,
          width: '100%',
        }}
      >
        <Grid sx={{ padding: '10px 10px 10px 0' }} item xs={12} md={9}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gridGap: '10px',
            }}
          >
            {/* {userVisibleInspectionList.length > 0 &&
              userVisibleInspectionList.map((userVisibleInspection, index) => (
                <VisibleAmlInspectionBox
                  userVisibleInspection={userVisibleInspection}
                  key={index}
                />
              ))} */}
            <SettingRiskScoreByLevelBox levelItem={{ level: 'green', name: 'Green' }} />
            <SettingRiskScoreByLevelBox levelItem={{ level: 'yellow', name: 'Yellow' }} />
            <SettingRiskScoreByLevelBox levelItem={{ level: 'red', name: 'Red' }} />
            <SettingRiskScoreByLevelBox levelItem={{ level: 'black', name: 'Black' }} />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default RiskScoresTab
