import { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { remittanceActions } from '../../../../store/actions/kwlActions'
import { Grid, Typography } from '@mui/material'
import remittanceTableConfig from '../../../../utils/config/tableConfig/remittance'
import AgentGroupsTable from './AgentGroupsTable/AgentGroupsTable'
import { AgentGroup } from '../../../../types/remittanceTypes'
import CustomLoader from '../../../../components/reusable/CustomLoader'
import SearchField from '../../../../components/reusable/SearchField'
import debounce from 'lodash/debounce'
import {
  clearSearchFieldForAllAgentGroups,
  setSearchFieldForAllAgentGroups,
} from '../../../../store/reducers/kwlReducers'

type AgentGroupsTabProps = {
  instanceId: string | undefined
}

const AgentGroupsTab = ({ instanceId }: AgentGroupsTabProps) => {
  const dispatch = useAppDispatch()

  const { agentGroupsForInstance, allAgentGroups } = useAppSelector(state => state.kwl)
  const { getAgentGroupListForInstanceLoadState, getAllAgentGroupsLoadState } = useAppSelector(
    state => state.kwl
  )

  const isLoading = useMemo(
    () => getAgentGroupListForInstanceLoadState.isLoading || getAllAgentGroupsLoadState.isLoading,
    [getAgentGroupListForInstanceLoadState.isLoading, getAllAgentGroupsLoadState.isLoading]
  )

  const getAllAgentGroups = useCallback(
    (searchValue: string) => {
      const encodedValue = encodeURIComponent(searchValue.trim())

      const currentParam = searchValue ? `query=${encodedValue}` : ''

      dispatch(setSearchFieldForAllAgentGroups(encodedValue))

      dispatch(remittanceActions.getAllAgentGroups(currentParam))
    },
    [dispatch]
  )

  const getAgentGroupListForInstance = useCallback(async () => {
    const currentParam = ''

    instanceId && (await dispatch(remittanceActions.getAgentGroupListForInstance(instanceId)))

    dispatch(remittanceActions.getAllAgentGroups(currentParam))
  }, [dispatch, instanceId])

  const searchDebounceFn = useMemo(() => debounce(getAllAgentGroups, 500), [getAllAgentGroups])

  useEffect(() => {
    getAgentGroupListForInstance()

    return () => {
      dispatch(clearSearchFieldForAllAgentGroups())
    }
  }, [dispatch, getAgentGroupListForInstance])

  const allAgentGroupsTableConfig = useMemo(() => {
    return remittanceTableConfig.allAgentGroupsTableConfig(instanceId)
  }, [instanceId])

  const agentGroupsForInstanceTableConfig = useMemo(() => {
    return remittanceTableConfig.agentGroupsForInstanceTableConfig(instanceId)
  }, [instanceId])

  const onSearchFieldChange = useCallback(
    (value: string) => {
      searchDebounceFn(value)
    },
    [searchDebounceFn]
  )

  return (
    <Grid container spacing={2} sx={{ margin: 0, width: '100%', position: 'relative' }}>
      {isLoading && <CustomLoader />}
      <Grid item xs={6} pr={2}>
        <Typography paragraph sx={{ marginBottom: '72px', fontSize: '20px', fontWeight: 'bold' }}>
          Cashout Agent Groups
        </Typography>
        {agentGroupsForInstance.length > 0 && (
          <AgentGroupsTable<AgentGroup>
            tableConfig={agentGroupsForInstanceTableConfig}
            tableData={agentGroupsForInstance}
          ></AgentGroupsTable>
        )}
        {agentGroupsForInstance.length === 0 && getAgentGroupListForInstanceLoadState.isLoaded && (
          <Typography paragraph sx={{ fontSize: '18px' }}>
            No agent groups added yet
          </Typography>
        )}
      </Grid>
      <Grid item xs={6} pr={2}>
        <Typography paragraph sx={{ fontSize: '20px', fontWeight: 'bold' }}>
          Agent Group List
        </Typography>

        <SearchField label="Search by Name" onParentChange={onSearchFieldChange} />
        {allAgentGroups.length > 0 && (
          <AgentGroupsTable<AgentGroup>
            tableConfig={allAgentGroupsTableConfig}
            tableData={allAgentGroups}
          ></AgentGroupsTable>
        )}
        {allAgentGroups.length === 0 && getAllAgentGroupsLoadState.isLoaded && (
          <Typography paragraph sx={{ fontSize: '18px' }}>
            No agent group found
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default AgentGroupsTab
