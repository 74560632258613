import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { CreateBlockingRecordForm } from '../../../../../types/mobileUserTypes'
import { Checkbox, FormControlLabel, Typography, styled } from '@mui/material'
import TextFieldForm from '../../../../../components/reusable/form/TextFieldForm'
import { blockingRecordYupSchemes } from '../../../../../utils/yupScheme/blockingRecordYupSchemes'
import { useCallback, useEffect, useState } from 'react'
import { trimObjectFields } from '../../../../../utils/commonUtils'

const projectsConfig = {
  kuvaCashProject: {
    lable: 'Kuva Cash',
    value: 1,
  },
  kuvaLocalProject: {
    lable: 'Kuva Local',
    value: 2,
  },
  kuvaWhiteLabelProject: {
    lable: 'Kuva White Label',
    value: 4,
  },
}

const StyledBlockingRecordCreationForm = styled('form')({
  padding: '0 25px',
  marginBottom: '20px',
  '.MuiFormControl-root': {
    marginBottom: '8px',
  },
  '.checkboxes-error': {
    color: '#d32f2f',
    fontSize: '12px',
    margin: '4px 14px 0',
  },
})

type BlockingRecordCreationFormProps = {
  onMainSubmit: (data: { reason: string; projectTypes: number }) => void
}

const BlockingRecordCreationForm = ({ onMainSubmit }: BlockingRecordCreationFormProps) => {
  const [checkBoxError, setCheckBoxError] = useState('')
  const { handleSubmit, control, formState, watch, setValue } = useForm<CreateBlockingRecordForm>({
    resolver: yupResolver(blockingRecordYupSchemes.createBlockingRecordStatus),
  })

  useEffect(() => {
    setValue('kuvaCashProject', false)
    setValue('kuvaLocalProject', false)
  }, [setValue])

  useEffect(() => {
    if (formState.errors.checkboxSection) {
      setCheckBoxError('Please, select projects')
    }
  }, [formState.errors])

  const getProjectTypes = useCallback((data: CreateBlockingRecordForm) => {
    let projectTypes = 0

    if (data.kuvaCashProject) {
      projectTypes += projectsConfig.kuvaCashProject.value
    }

    if (data.kuvaLocalProject) {
      projectTypes += projectsConfig.kuvaLocalProject.value
    }

    if (data.kuvaWhiteLabelProject) {
      projectTypes += projectsConfig.kuvaWhiteLabelProject.value
    }

    return projectTypes
  }, [])

  const onSubmit = (data: CreateBlockingRecordForm) => {
    const trimmedData = trimObjectFields(data)
    const isOneOfProjects =
      data.kuvaCashProject || data.kuvaLocalProject || data.kuvaWhiteLabelProject

    if (isOneOfProjects) {
      const projectTypes = getProjectTypes(data)
      const body = { reason: trimmedData.reason, projectTypes }

      onMainSubmit(body)
    } else {
      setCheckBoxError('Please, select projects')
    }
  }

  const onValidateCheckBoxSection = useCallback(() => {
    if (
      !(watch('kuvaLocalProject') || watch('kuvaCashProject') || watch('kuvaWhiteLabelProject'))
    ) {
      setCheckBoxError(prev => 'Please, select projects')
      return
    }

    setCheckBoxError('')
  }, [watch])

  return (
    <StyledBlockingRecordCreationForm
      id="create-blocking-record-from"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography fontSize="16px" mb={1}>
        Please, type the reason of status changing into this field.
      </Typography>
      <TextFieldForm<CreateBlockingRecordForm>
        name="reason"
        control={control}
        label="Reason"
        type="text"
        inputMode="text"
        validMask={/[^a-zA-Z0-9-\s,.`'"+]/}
        multiline
        rows={3}
        maxLength={500}
      />
      <Typography fontSize="16px">
        Please, select the projects on which the user will be blocked
      </Typography>
      <Controller
        name="kuvaCashProject"
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            sx={{ width: '100%' }}
            control={
              <Checkbox
                onChange={event => {
                  onChange(event.target.checked)
                  onValidateCheckBoxSection()
                }}
                checked={typeof value === 'undefined' ? false : value}
              />
            }
            label={projectsConfig.kuvaCashProject.lable}
          />
        )}
      />
      <Controller
        name="kuvaLocalProject"
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            sx={{ width: '100%' }}
            control={
              <Checkbox
                onChange={event => {
                  onChange(event.target.checked)
                  onValidateCheckBoxSection()
                }}
                checked={typeof value === 'undefined' ? false : value}
              />
            }
            label={projectsConfig.kuvaLocalProject.lable}
          />
        )}
      />
      <Controller
        name="kuvaWhiteLabelProject"
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            sx={{ width: '100%' }}
            control={
              <Checkbox
                onChange={event => {
                  onChange(event.target.checked)
                  onValidateCheckBoxSection()
                }}
                checked={typeof value === 'undefined' ? false : value}
              />
            }
            label={projectsConfig.kuvaWhiteLabelProject.lable}
          />
        )}
      />
      <p className="checkboxes-error">{checkBoxError}</p>
    </StyledBlockingRecordCreationForm>
  )
}

export default BlockingRecordCreationForm
