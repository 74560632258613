import { useEffect, useState, FormEvent } from 'react'
import { Button, Container, SelectChangeEvent } from '@mui/material'
import { DatePicker } from 'antd'
import moment, { MomentInput } from 'moment'

import Filter from './Filter'
import Loader from '../../../components/Loader'
import Table from '../../../components/Table'

import getQueryString from '../../../helpers/getQueryString'
import { useAppDispatch, useAppSelector } from '../../../store'
import { fetchTransactionAllList } from '../../../store/actions/transactionActions'
import { coinTypes, transactionTypes, transferStatuses } from '../../../utils/constants'
import concatenateObjProps from '../../../helpers/concatenateObjProps'
import columnsSource from './columnsSource'
import { Link } from 'react-router-dom'

type PaginationType = { offset: number; pageSize: number }
type QueryStateType = {
  dates: {
    startDate: string
    endDate: string
  }
  fromAddress: string
  toAddress: string
  bwsId: string
  originTransactionId: string
  usdAmounts: { min: number | string; max: number | string }
  coinAmounts: { min: number | string; max: number | string }
  rates: { min: number | string; max: number | string }
  types: []
  statuses: []
  walletTypes: []
}

const { RangePicker } = DatePicker

const AllTransactions = () => {
  const [pagination, setPagination] = useState<PaginationType>({ offset: 0, pageSize: 10 })
  const [query, setQuery] = useState<QueryStateType>({
    fromAddress: '',
    toAddress: '',
    dates: { startDate: '', endDate: '' },
    bwsId: '',
    originTransactionId: '',
    usdAmounts: { min: '', max: '' },
    coinAmounts: { min: '', max: '' },
    rates: { min: '', max: '' },
    types: [],
    statuses: [],
    walletTypes: [],
  })
  const [isResetPagination, setResetPagination] = useState(false)
  const [isShowFilters, setShowFilters] = useState(false)

  const dispatch = useAppDispatch()
  const {
    list: { items, count },
    listFetchFinished,
  } = useAppSelector(state => state.transaction.all)
  const { isMobileView } = useAppSelector(state => state.display)

  useEffect(() => {
    ;(async () => {
      await dispatch(fetchTransactionAllList(getQueryString(pagination)))
    })()
  }, [])

  useEffect(() => {
    setResetPagination(false)
  }, [isResetPagination])

  const changeSearch = (value: string | [], name: string, parentProp: string = '') => {
    if (!parentProp) setQuery({ ...query, [name]: value })
    else {
      setQuery({
        ...query,
        [parentProp]: {
          ...query[parentProp as 'usdAmounts' | 'coinAmounts' | 'rates'],
          [name]: value,
        },
      })
    }

    setResetPagination(true)
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    await fetchData({ ...pagination, offset: 0 }, true)

    setResetPagination(true)
    setShowFilters(false)
  }

  const fetchData = async (params: PaginationType = pagination, isApply: boolean = false) => {
    let queryString

    if (!isResetPagination || isApply)
      queryString = getQueryString(concatenateObjProps({ ...query, ...params }))
    else queryString = getQueryString(params)

    await dispatch(fetchTransactionAllList(queryString))

    setPagination({ ...params } as PaginationType)
    setResetPagination(false)
  }

  const onChangeDate = async (val: [MomentInput, MomentInput] | null) => {
    let queryString = { ...query, ...pagination, offset: 0 }
    let startDate, endDate

    if (val) {
      startDate = moment(val[0]).format('MM/DD/YYYY') + 'Z'
      endDate = moment(val[1]).format('MM/DD/YYYY') + 'Z'

      queryString = {
        ...queryString,
        dates: { startDate, endDate },
      }
    } else {
      queryString.dates.startDate = queryString.dates.endDate = ''

      startDate = endDate = ''
    }

    await dispatch(fetchTransactionAllList(getQueryString(concatenateObjProps(queryString))))

    setQuery({ ...query, dates: { startDate, endDate } })
  }

  const onSelect = (e: SelectChangeEvent) => {
    const { name, value } = e.target

    setQuery({ ...query, [name]: value })
  }

  const clearFilters = async () => {
    const queryString = { ...pagination, dates: { ...query.dates } }

    await dispatch(fetchTransactionAllList(getQueryString(concatenateObjProps(queryString))))

    setQuery({
      ...query,
      toAddress: '',
      fromAddress: '',
      bwsId: '',
      originTransactionId: '',
      usdAmounts: { min: '', max: '' },
      coinAmounts: { min: '', max: '' },
      rates: { min: '', max: '' },
      types: [],
      statuses: [],
      walletTypes: [],
    })
    setResetPagination(true)
  }

  const domain = process.env.REACT_APP_BACK_OFFICE_DOMAIN

  const mappedData = items.map(item => ({
    ...item,
    txType: transactionTypes[item.txType],
    transferStatus: (
      <span className={`color-${transferStatuses[item.transferStatus].color}`}>
        {transferStatuses[item.transferStatus].value}
      </span>
    ),
    fullName: `${item.sender && item.sender.firstName} ${item.sender && item.sender.lastName}`,
    phoneNumber: item.sender && item.sender.phoneNumber,
    walletType: coinTypes[item.walletType],
    comissionUsd: item.commissionUsd,
    comissionCoins: item.commissionCoins,
    originTxId: item.originTxId,
    detailsBtn: (
      <Button
        size="small"
        variant="contained"
        href={`${domain}/MobileUsers/TransactionDetails/${item.id}`}
        target="_blank"
      >
        Details
      </Button>
    ),
    // editingBtn: (
    //   <Link to={`transactions/all/444/details`}>
    //     <Button size="small" variant="contained">
    //       Edit
    //     </Button>
    //   </Link>
    // ),
  }))

  const {
    fromAddress,
    toAddress,
    bwsId,
    originTransactionId,
    usdAmounts,
    coinAmounts,
    rates,
    types,
    statuses,
    walletTypes,
  } = query

  const isDisabled =
    !fromAddress &&
    !toAddress &&
    !bwsId &&
    !originTransactionId &&
    !usdAmounts.min &&
    !usdAmounts.max &&
    !coinAmounts.min &&
    !coinAmounts.max &&
    !rates.min &&
    !rates.max &&
    !types.length &&
    !statuses.length &&
    !walletTypes.length

  return (
    <>
      {!listFetchFinished ? (
        <Loader />
      ) : (
        <Container className="pt-25 pb-10" maxWidth="xl" sx={{ height: 'calc(100dvh - 250px)' }}>
          <h3 className="mb-25">All Transactions</h3>
          <div className={`flex j-content-space-between ${isMobileView ? 'flex-dir-column' : ''}`}>
            <RangePicker className="mb-20" onChange={onChangeDate} showTime />
            <div className="mb-20 align-self-end">
              <Button
                className="w-130 mr-15"
                variant="outlined"
                size="small"
                onClick={() => setShowFilters(true)}
              >
                Show Filters
              </Button>

              <Button
                className="w-130"
                variant="outlined"
                size="small"
                onClick={clearFilters}
                disabled={isDisabled}
              >
                Clear Filters
              </Button>
            </div>
          </div>
          <Filter
            query={query}
            onChange={async (value, name, parentProp) => changeSearch(value, name, parentProp)}
            onSubmit={onSubmit}
            isDisabled={isDisabled}
            isShow={isShowFilters}
            setShow={async isShow => setShowFilters(isShow)}
            onSelect={onSelect}
          />
          <Table
            bodyData={mappedData}
            columnsSource={columnsSource}
            containerStyle={`${!isMobileView ? 'pb-100' : 'pb-160'}`}
            fetchData={fetchData}
            dataCount={count}
            isResetPagination={isResetPagination}
          />
        </Container>
      )}
    </>
  )
}

export default AllTransactions
