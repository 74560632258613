import { Button } from "@mui/material"
import { Container } from '@mui/material'
import { useNavigate } from "react-router-dom"

const PageNotFound = () => {
  const navigate = useNavigate()

  return (
    <>
      <Container maxWidth='sm'>
        <div className="flex align-items-center j-content-center flex-dir-column text-center h-100-vh">
          <h2 className="mb-30">Page not found :(</h2>
          <Button className="w-100" variant="outlined" onClick={() => navigate(-1)}>Go back</Button>
        </div>
      </Container>
    </>
  )
}

export default PageNotFound
