import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  DELETE_KWL_RECEIVE_COUNTRY,
  DELETE_KWL_SOURCE_COUNTRY,
  FETCH_KWL_DATA_BY_ID,
  FETCH_KWL_SITE_LIST,
  FETCH_KWL_USER_LIST,
  SET_KWL_RECEIVE_COUNTRY,
  SET_KWL_SOURCE_COUNTRY,
} from './actionTypes'

import {
  activateInstanceCurrencyApiPath,
  apiKWLSitesPath,
  apiKWLUsersPath,
  deactivateInstanceCurrencyApiPath,
  getApiKWLByIdPath,
  getBeneficiaryConfigPropApiPath,
  getBeneficiaryConfigValueApiPath,
  getFlowListApiPath,
  postFlowStateApiPath,
  postPasswordForEarlyAccess,
  updateBeneficiaryConfigApiPath,
  getPaymentProviderMethodsApiPath,
  getInstanceCurrencyListApiPath,
  getAllCurrenciesApiPath,
  createInstanceCurrencyApiPath,
  getInstanceCurrencyApiPath,
  updateInstanceCurrencyApiPath,
  getAvailableInstanceCurrencyListApiPath,
  getAgentGroupListForInstanceApiPath,
  getAllAgentGroupsApiPath,
  assignAgentGroupApiPath,
  unassignAgentGroupApiPath,
  getAllPaymentProviderFeesApiPath,
  deletePaymentProviderFeeApiPath,
  createPaymentProviderFeeApiPath,
  getPaymentProviderListApiPath,
  getCountryListApiPath,
  getPaymentOptionByCountry,
  getCurrencyListByCountryApiPath,
  getPaymentProviderListApiPathV2,
  getPaymentMethodListApiPath,
  addPaymentOptionByCountryApiPath,
  updatePaymentOptionsByCountryApiPath,
  deletePaymentOptionsByCountryApiPath,
  setExpirationEntryForUsersByRiskLevelApiPath,
  commonCurrencyApiPath,
  removeCommonCurrencyApiPath,
} from '../../utils/apiPaths'

import apiRequestServiceWithRefresh from '../../services/apiRequestServiceWithRefresh'
import {
  AssignAgentGroupRequest,
  CreateInstanceCurrencyRequest,
  FlowType,
  GetAllPaymentProviderFeesRequest,
  GetInstanceCurrencyRequest,
  KWLSiteDataType,
  RemittanceStateType,
  KWLUserDataType,
  PostEarlyAccessPassword,
  PostFlowStateRequest,
  PutBeneficiaryConfig,
  PutInstanceCurrencyRequest,
  UnassignAgentGroupRequest,
  UpdateInstanceCurrencyRequest,
  CreatePaymentProviderFeeRequest,
  FeeUnitType,
  FeePayerType,
  GetPaymentOptionsByCountryRequestArg,
  GetCurrencyListByCountryRequestArg,
  AddPaymentOptionByCountryRequestBody,
  UpdatePaymentOptionByCountryRequestArg,
  DeletePaymentOptionByCountryRequestArg,
  SetExpirationEntryForUsersByRiskLevelRequestBody,
  CommonCurrencyFormType,
} from '../../types/remittanceTypes'

import merge from 'lodash/merge'
import { AlertColor } from '@mui/material'
import { addNotification } from '../reducers/notificationReducer'
import { AxiosError } from 'axios'
import { CustomErrorResponse } from '../../types/apiTypes'

type ErrType = { message: string }

const fetchKWLSiteList = createAsyncThunk<KWLSiteDataType[], undefined, { rejectValue: ErrType }>(
  FETCH_KWL_SITE_LIST,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(dispatch, 'get', apiKWLSitesPath)

      if (result) return result
    } catch (err) {
      return rejectWithValue(err as ErrType)
    }
  }
)

const fetchKWLUserList = createAsyncThunk<KWLUserDataType[], undefined, { rejectValue: ErrType }>(
  FETCH_KWL_USER_LIST,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(dispatch, 'get', apiKWLUsersPath)

      if (result) return result
    } catch (err) {
      return rejectWithValue(err as ErrType)
    }
  }
)

const fetchKWLDataById = createAsyncThunk<KWLSiteDataType, number, { rejectValue: ErrType }>(
  FETCH_KWL_DATA_BY_ID,
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(dispatch, 'get', getApiKWLByIdPath(id))

      if (result) return result
    } catch (err) {
      return rejectWithValue(err as ErrType)
    }
  }
)

const setKWLCountry = (action: string = SET_KWL_RECEIVE_COUNTRY || SET_KWL_SOURCE_COUNTRY) =>
  createAsyncThunk<boolean, string, { rejectValue: ErrType }>(
    action,
    async (apiPath, { rejectWithValue, dispatch }) => {
      try {
        await apiRequestServiceWithRefresh(dispatch, 'post', apiPath)

        return true
      } catch (err) {
        return rejectWithValue(err as ErrType)
      }
    }
  )

const setExpirationEntryForUsersByRiskLevel = createAsyncThunk(
  'kwl/setExpirationEntryForUsersByRiskLevel',
  async (body: SetExpirationEntryForUsersByRiskLevelRequestBody, { rejectWithValue, dispatch }) => {
    try {
      const result: {
        userCount: number,
        amlInspectionCount: number
      } = await apiRequestServiceWithRefresh(
        dispatch,
        'put',
        setExpirationEntryForUsersByRiskLevelApiPath,
        body
      )

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: `Completed Successfully. ${result.userCount} of users we effected, ${result.amlInspectionCount} of verifications were expired`,
      }

      dispatch(addNotification(notificationData))

      return result
    } catch (err) {
      return rejectWithValue(err as ErrType)
    }
  })

const deleteKWLCountry = (
  action: string = DELETE_KWL_RECEIVE_COUNTRY || DELETE_KWL_SOURCE_COUNTRY
) =>
  createAsyncThunk<boolean, string, { rejectValue: ErrType }>(
    action,
    async (apiPath, { rejectWithValue, dispatch }) => {
      try {
        await apiRequestServiceWithRefresh(dispatch, 'delete', apiPath)

        return true
      } catch (err) {
        return rejectWithValue(err as ErrType)
      }
    }
  )

const getBeneficiaryConfig = createAsyncThunk(
  'kwl/getBeneficiaryConfig',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const beneficiaryConfigValue = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        getBeneficiaryConfigValueApiPath(id)
      )
      const beneficiaryConfigProp = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        getBeneficiaryConfigPropApiPath(id)
      )

      const mergedBeneficiaryConfig = merge(beneficiaryConfigValue, beneficiaryConfigProp)

      return mergedBeneficiaryConfig
    } catch (err) {
      return rejectWithValue(err as ErrType)
    }
  }
)

const updateBeneficiaryConfig = createAsyncThunk(
  'kwl/updateBeneficiaryConfig',
  async (arg: PutBeneficiaryConfig, { rejectWithValue, dispatch }) => {
    const { id, body } = arg

    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'put',
        updateBeneficiaryConfigApiPath(id),
        body
      )

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'Beneficiary settings saved successfully',
      }

      dispatch(addNotification(notificationData))

      return result
    } catch (err) {
      return rejectWithValue(err as ErrType)
    }
  }
)

//remittance-flow

const getFlowList = createAsyncThunk(
  'kwl/getFlowList',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(dispatch, 'get', getFlowListApiPath(id))

      return result
    } catch (err) {
      return rejectWithValue(err as ErrType)
    }
  }
)

const postFlowState = createAsyncThunk(
  'kwl/postFlowState',
  async (arg: PostFlowStateRequest, { rejectWithValue, dispatch, getState }) => {
    const { id, flowType, flowState } = arg
    const {
      kwl: { flowList },
    } = getState() as { kwl: RemittanceStateType }
    try {
      await apiRequestServiceWithRefresh(
        dispatch,
        'post',
        postFlowStateApiPath(id, flowType, flowState)
      )
      const updatedFlowList = flowList.map(flowItem => {
        if (flowItem.type === flowType) {
          return {
            ...flowItem,
            state: flowState,
          }
        }

        return flowItem
      })

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: `${FlowType[flowType]} flow was updated successfully`,
      }

      dispatch(addNotification(notificationData))

      return updatedFlowList
    } catch (err) {
      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse

      if (errorData.code === 400) {
        const notificationData = {
          open: true,
          type: 'error' as AlertColor,
          message: errorData.message,
          errorCode: errorData.errorCode,
        }

        dispatch(addNotification(notificationData))
      }
      return rejectWithValue('Error')
    }
  }
)

const postEarlyAccessPassword = createAsyncThunk(
  'kwl/postEarlyAccessPassword',
  async (arg: PostEarlyAccessPassword, { rejectWithValue, dispatch }) => {
    const { body, id } = arg

    try {
      await apiRequestServiceWithRefresh(dispatch, 'post', postPasswordForEarlyAccess(id), body)

      alert('Password was changed.')
    } catch (err) {
      return rejectWithValue(err as ErrType)
    }
  }
)

const getInstanceCurrencyList = createAsyncThunk(
  'kwl/getInstanceCurrencyList',
  async (instanceId: string | number, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        getInstanceCurrencyListApiPath(instanceId)
      )

      return result
    } catch (err) {
      return rejectWithValue('Error')
    }
  }
)

const createInstanceCurrency = createAsyncThunk(
  'kwl/createInstanceCurrency',
  async (arg: CreateInstanceCurrencyRequest, { rejectWithValue, dispatch }) => {
    const { instanceId, body } = arg
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'post',
        createInstanceCurrencyApiPath(instanceId),
        body
      )

      dispatch(getInstanceCurrencyList(instanceId))
      dispatch(getAvailableInstanceCurrencyList(instanceId))

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'Instance Currency has been created',
      }

      dispatch(addNotification(notificationData))

      return result
    } catch (err) {
      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse

      if (errorData.code === 400) {
        const notificationData = {
          open: true,
          type: 'error' as AlertColor,
          message: errorData.message,
          errorCode: errorData.errorCode,
        }

        dispatch(addNotification(notificationData))
      }
      // return rejectWithValue('Error')
    }
  }
)

const updateInstanceCurrency = createAsyncThunk(
  'kwl/updateInstanceCurrency',
  async (arg: UpdateInstanceCurrencyRequest, { rejectWithValue, dispatch }) => {
    const { instanceId, currencyId, body } = arg
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'put',
        updateInstanceCurrencyApiPath(instanceId, currencyId),
        body
      )

      dispatch(getInstanceCurrencyList(instanceId))

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'Instance Currency has been updated',
      }

      dispatch(addNotification(notificationData))

      return result
    } catch (err) {
      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse

      if (errorData.code === 400) {
        const notificationData = {
          open: true,
          type: 'error' as AlertColor,
          message: errorData.message,
          errorCode: errorData.errorCode,
        }

        dispatch(addNotification(notificationData))
      }
      // return rejectWithValue('Error')
    }
  }
)

const getInstanceCurrency = createAsyncThunk(
  'kwl/getInstanceCurrency',
  async (arg: GetInstanceCurrencyRequest, { rejectWithValue, dispatch }) => {
    const { instanceId, currencyId } = arg
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        getInstanceCurrencyApiPath(instanceId, currencyId)
      )

      return result
    } catch (err) {
      return rejectWithValue('Error')
    }
  }
)

const getAllCurrencies = createAsyncThunk(
  'kwl/getAllCurrencies',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(dispatch, 'get', getAllCurrenciesApiPath)

      return result
    } catch (err) {
      return rejectWithValue('Error')
    }
  }
)

const getAvailableInstanceCurrencyList = createAsyncThunk(
  'kwl/getAvailableInstanceCurrencyList',
  async (instanceId: string | number, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        getAvailableInstanceCurrencyListApiPath(instanceId)
      )

      return result
    } catch (err) {
      return rejectWithValue('Error')
    }
  }
)

const activateInstanceCurrency = createAsyncThunk(
  'kwl/activateInstanceCurrency',
  async (arg: PutInstanceCurrencyRequest, { rejectWithValue, dispatch }) => {
    const { instanceId, currencyId } = arg
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'put',
        activateInstanceCurrencyApiPath(instanceId, currencyId)
      )

      dispatch(getInstanceCurrencyList(instanceId))

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'Currency has been activated',
      }

      dispatch(addNotification(notificationData))

      return result
    } catch (err) {
      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse

      if (errorData.code === 400) {
        const notificationData = {
          open: true,
          type: 'error' as AlertColor,
          message: errorData.message,
          errorCode: errorData.errorCode,
        }

        dispatch(addNotification(notificationData))
      }
    }
  }
)

const deactivateInstanceCurrency = createAsyncThunk(
  'kwl/deactivateInstanceCurrency',
  async (arg: PutInstanceCurrencyRequest, { rejectWithValue, dispatch }) => {
    const { instanceId, currencyId } = arg
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'put',
        deactivateInstanceCurrencyApiPath(instanceId, currencyId)
      )

      dispatch(getInstanceCurrencyList(instanceId))

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'Currency has been deactivated',
      }

      dispatch(addNotification(notificationData))

      return result
    } catch (err) {
      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse

      if (errorData.code === 400) {
        const notificationData = {
          open: true,
          type: 'error' as AlertColor,
          message: errorData.message,
          errorCode: errorData.errorCode,
        }

        dispatch(addNotification(notificationData))
      }
    }
  }
)

const getPaymentProviderMethods = createAsyncThunk(
  'kwl/getPaymentProviderMethods',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        getPaymentProviderMethodsApiPath
      )

      return result
    } catch (err) { }
  }
)

//remittance-agent-group
const getAgentGroupListForInstance = createAsyncThunk(
  'kwl/getAgentGroupListForInstance',
  async (instanceId: string | number, { dispatch, rejectWithValue }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        getAgentGroupListForInstanceApiPath(instanceId)
      )

      return result
    } catch (err) { }
  }
)

const getAllAgentGroups = createAsyncThunk(
  'kwl/getAllAgentGroups',
  async (queryParams: string, { dispatch, rejectWithValue }) => {
    const query = queryParams ? `?${queryParams}` : ''
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        `${getAllAgentGroupsApiPath}${query}`
      )

      return result
    } catch (err) { }
  }
)

const assignAgentGroup = createAsyncThunk(
  'kwl/assignAgentGroup',
  async (arg: AssignAgentGroupRequest, { dispatch, rejectWithValue, getState }) => {
    const { instanceId, agentGroupId } = arg
    const {
      kwl: { searchFieldForAllAgentGroups: searchField },
    } = getState() as { kwl: RemittanceStateType }

    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'post',
        assignAgentGroupApiPath(instanceId, agentGroupId)
      )

      await dispatch(getAgentGroupListForInstance(instanceId))

      const searchParam = searchField ? `query=${searchField}` : ''
      dispatch(getAllAgentGroups(searchParam))

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'Agent Group has been assigned',
      }

      dispatch(addNotification(notificationData))

      return result
    } catch (err) {
      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse

      if (errorData.code === 400) {
        const notificationData = {
          open: true,
          type: 'error' as AlertColor,
          message: errorData.message,
          errorCode: errorData.errorCode,
        }

        dispatch(addNotification(notificationData))
      }
    }
  }
)

const unassignAgentGroup = createAsyncThunk(
  'kwl/unassignAgentGroup',
  async (arg: UnassignAgentGroupRequest, { dispatch, rejectWithValue, getState }) => {
    const { instanceId, agentGroupId } = arg
    const {
      kwl: { searchFieldForAllAgentGroups: searchField },
    } = getState() as { kwl: RemittanceStateType }

    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'delete',
        unassignAgentGroupApiPath(instanceId, agentGroupId)
      )

      await dispatch(getAgentGroupListForInstance(instanceId))

      const searchParam = searchField ? `query=${searchField}` : ''
      dispatch(getAllAgentGroups(searchParam))

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'Agent Group has been unassigned',
      }

      dispatch(addNotification(notificationData))

      return result
    } catch (err) {
      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse

      if (errorData.code === 400) {
        const notificationData = {
          open: true,
          type: 'error' as AlertColor,
          message: errorData.message,
          errorCode: errorData.errorCode,
        }

        dispatch(addNotification(notificationData))
      }
    }
  }
)

//remittance-payment-provider
const getPaymentProviderList = createAsyncThunk(
  'kwl/getPaymentProviderList',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        `${getPaymentProviderListApiPath}`
      )

      return result
    } catch (err) { }
  }
)

const getAllPaymentProviderFeesMocksData = [
  {
    currency: { id: 'GBP', displayName: 'British pound', sign: '£' },
    paymentProviderFees: [
      {
        id: 1,
        paymentProvider: {
          id: 1,
          name: 'WorldPay',
        },

        feeUnitType: FeeUnitType.PercentAndFixed,
        feeValue: {
          percentValue: 1,
          fixedValue: 1,
          feePayerType: FeePayerType.User,
        },
        defaultValue: {
          percentValue: 0.5,
          fixedValue: 0.5,
          feePayerType: FeePayerType.User,
        },
        status: 'Active',
      },
      {
        id: 2,
        paymentProvider: {
          id: 2,
          name: 'CurrencyCloud',
        },

        feeUnitType: FeeUnitType.PercentAndFixed,
        feeValue: {
          percentValue: 1.5,
          fixedValue: 1.5,
          feePayerType: FeePayerType.User,
        },
        defaultValue: {
          percentValue: 0.5,
          fixedValue: 0.5,
          feePayerType: FeePayerType.User,
        },
        status: 'Active',
      },
      {
        id: 3,
        paymentProvider: {
          id: 3,
          name: 'ClearJunction',
        },

        feeUnitType: FeeUnitType.PercentAndFixed,
        feeValue: {
          percentValue: 2,
          fixedValue: 2,
          feePayerType: FeePayerType.User,
        },
        defaultValue: {
          percentValue: 0.5,
          fixedValue: 0.5,
          feePayerType: FeePayerType.User,
        },
        status: 'Inactive',
      },
    ],
  },
  {
    currency: { id: 'USD', displayName: 'US dollar', sign: '$' },
    paymentProviderFees: [
      {
        id: 1,
        paymentProvider: {
          id: 1,
          name: 'WorldPay',
        },

        feeUnitType: FeeUnitType.PercentAndFixed,
        feeValue: {
          percentValue: 1,
          fixedValue: 1,
          feePayerType: FeePayerType.User,
        },
        defaultValue: {
          percentValue: 0.5,
          fixedValue: 0.5,
          feePayerType: FeePayerType.User,
        },
        status: 'Active',
      },
      {
        id: 2,
        paymentProvider: {
          id: 2,
          name: 'CurrencyCloud',
        },

        feeUnitType: FeeUnitType.PercentAndFixed,
        feeValue: {
          percentValue: 1.5,
          fixedValue: 1.5,
          feePayerType: FeePayerType.User,
        },
        defaultValue: {
          percentValue: 0.5,
          fixedValue: 0.5,
          feePayerType: FeePayerType.User,
        },
        status: 'Active',
      },
      {
        id: 3,
        paymentProvider: {
          id: 3,
          name: 'ClearJunction',
        },

        feeUnitType: FeeUnitType.PercentAndFixed,
        feeValue: {
          percentValue: 2,
          fixedValue: 2,
          feePayerType: FeePayerType.User,
        },
        defaultValue: {
          percentValue: 0.5,
          fixedValue: 0.5,
          feePayerType: FeePayerType.User,
        },
        status: 'Inactive',
      },
    ],
  },
  {
    currency: { id: 'EUR', displayName: 'Euro', sign: '€' },
    paymentProviderFees: [
      {
        id: 1,
        paymentProvider: {
          id: 1,
          name: 'WorldPay',
        },

        feeUnitType: FeeUnitType.PercentAndFixed,
        feeValue: {
          percentValue: 1,
          fixedValue: 1,
          feePayerType: FeePayerType.User,
        },
        defaultValue: {
          percentValue: 0.5,
          fixedValue: 0.5,
          feePayerType: FeePayerType.User,
        },
        status: 'Active',
      },
      {
        id: 2,
        paymentProvider: {
          id: 2,
          name: 'CurrencyCloud',
        },

        feeUnitType: FeeUnitType.PercentAndFixed,
        feeValue: {
          percentValue: 1.5,
          fixedValue: 1.5,
          feePayerType: FeePayerType.User,
        },
        defaultValue: {
          percentValue: 0.5,
          fixedValue: 0.5,
          feePayerType: FeePayerType.User,
        },
        status: 'Active',
      },
      {
        id: 3,
        paymentProvider: {
          id: 3,
          name: 'ClearJunction',
        },

        feeUnitType: FeeUnitType.PercentAndFixed,
        feeValue: {
          percentValue: 2,
          fixedValue: 2,
          feePayerType: FeePayerType.User,
        },
        defaultValue: {
          percentValue: 0.5,
          fixedValue: 0.5,
          feePayerType: FeePayerType.User,
        },
        status: 'Inactive',
      },
    ],
  },
]

const getPaymentProviderFeesByInstance = createAsyncThunk(
  'kwl/getPaymentProviderFeesByInstance',
  async (arg: GetAllPaymentProviderFeesRequest, { dispatch, rejectWithValue }) => {
    const { paymentProviderId, queryParams } = arg
    const query = arg.queryParams ? `?${queryParams}` : ''
    // const query = '?currency=USD'
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        `${getAllPaymentProviderFeesApiPath(paymentProviderId)}${query}`
      )

      return result
    } catch (err) {
      rejectWithValue('Error')
    }

    return getAllPaymentProviderFeesMocksData
  }
)

const getPaymentProviderFeesByCurrencyMockData = {
  currency: { id: 'USD', displayName: 'US dollar', sign: '$' },
  paymentProviderFees: [
    {
      id: 1,
      paymentProvider: {
        id: 1,
        name: 'WorldPay',
      },

      feeUnitType: FeeUnitType.PercentAndFixed,
      feeValue: {
        percentValue: 1,
        fixedValue: 1,
        feePayerType: FeePayerType.User,
      },
      defaultValue: {
        percentValue: 0.5,
        fixedValue: 0.5,
        feePayerType: FeePayerType.User,
      },
      status: 'Active',
    },
    {
      id: 2,
      paymentProvider: {
        id: 2,
        name: 'CurrencyCloud',
      },

      feeUnitType: FeeUnitType.PercentAndFixed,
      feeValue: {
        percentValue: 1.5,
        fixedValue: 1.5,
        feePayerType: FeePayerType.User,
      },
      defaultValue: {
        percentValue: 0.5,
        fixedValue: 0.5,
        feePayerType: FeePayerType.User,
      },
      status: 'Active',
    },
    {
      id: 3,
      paymentProvider: {
        id: 3,
        name: 'ClearJunction',
      },

      feeUnitType: FeeUnitType.PercentAndFixed,
      feeValue: {
        percentValue: 2,
        fixedValue: 2,
        feePayerType: FeePayerType.User,
      },
      defaultValue: {
        percentValue: 0.5,
        fixedValue: 0.5,
        feePayerType: FeePayerType.User,
      },
      status: 'Inactive',
    },
  ],
}

const getPaymentProviderFeesByCurrency = createAsyncThunk(
  'kwl/getPaymentProviderFeesByCurrency',
  async (_, { dispatch, rejectWithValue }) => {
    // const query = arg.queryParams ? `?${arg.queryParams}` : ''
    // try {
    //   //   const result = await apiRequestServiceWithRefresh(
    //   //     dispatch,
    //   //     'get',
    //   //     `${getAllPaymentProviderFeesApiPath(arg.paymentProviderId)}${query}`
    //   //   )
    //   const result = getAllPaymentProviderFeesMocksData

    // } catch (err) {
    //   rejectWithValue('Error')
    // }

    return getPaymentProviderFeesByCurrencyMockData
  }
)

const getCountryList = createAsyncThunk(
  'kwl/getCountryList',
  async (countryId: string, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        getCountryListApiPath(countryId)
      )



      return result
    } catch (err) {
      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse

      if (errorData.code === 400) {
        const notificationData = {
          open: true,
          type: 'error' as AlertColor,
          message: errorData.message,
          errorCode: errorData.errorCode,
        }

        dispatch(addNotification(notificationData))
      }
    }
  }
)

const getPaymentOptionsByCountry = createAsyncThunk(
  'kwl/getPaymentOptionsByCountry',
  async (arg: GetPaymentOptionsByCountryRequestArg, { rejectWithValue, dispatch }) => {
    const { instanceId, countryId, type } = arg
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        getPaymentOptionByCountry(instanceId, countryId, type)
      )



      return result
    } catch (err) {
      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse

      if (errorData.code === 400) {
        const notificationData = {
          open: true,
          type: 'error' as AlertColor,
          message: errorData.message,
          errorCode: errorData.errorCode,
        }

        dispatch(addNotification(notificationData))
      }
    }
  }
)

const getCurrencyListByCountry = createAsyncThunk(
  'kwl/getCurrencyListByCountry',
  async (arg: GetCurrencyListByCountryRequestArg, { rejectWithValue, dispatch }) => {
    const { instanceId, countryId, type } = arg
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        getCurrencyListByCountryApiPath(instanceId, countryId, type)
      )



      return result
    } catch (err) {
      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse

      if (errorData.code === 400) {
        const notificationData = {
          open: true,
          type: 'error' as AlertColor,
          message: errorData.message,
          errorCode: errorData.errorCode,
        }

        dispatch(addNotification(notificationData))
      }
    }
  }
)

const getPaymentProviderListV2 = createAsyncThunk(
  'kwl/getPaymentProviderListV2',
  async (_, { rejectWithValue, dispatch }) => {

    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        getPaymentProviderListApiPathV2
      )



      return result
    } catch (err) {
      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse

      if (errorData.code === 400) {
        const notificationData = {
          open: true,
          type: 'error' as AlertColor,
          message: errorData.message,
          errorCode: errorData.errorCode,
        }

        dispatch(addNotification(notificationData))
      }
    }
  }
)

const getPaymentMethodList = createAsyncThunk(
  'kwl/getPaymentMethodList',
  async (_, { rejectWithValue, dispatch }) => {

    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        getPaymentMethodListApiPath
      )



      return result
    } catch (err) {
      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse

      if (errorData.code === 400) {
        const notificationData = {
          open: true,
          type: 'error' as AlertColor,
          message: errorData.message,
          errorCode: errorData.errorCode,
        }

        dispatch(addNotification(notificationData))
      }
    }
  }
)

const addPaymentOptionByCountry = createAsyncThunk(
  'kwl/addPaymentOptionByCountry',
  async (arg: AddPaymentOptionByCountryRequestBody, { rejectWithValue, dispatch }) => {
    const { instanceId, countryId, type, body } = arg
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'post',
        addPaymentOptionByCountryApiPath,
        body,

      )

      const nextArg = {
        instanceId,
        countryId,
        type,
      }
      dispatch(getPaymentOptionsByCountry(nextArg))

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'Payment Option has been added',
      }

      dispatch(addNotification(notificationData))


      return result
    } catch (err) {
      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse

      if (errorData.code === 400) {
        const notificationData = {
          open: true,
          type: 'error' as AlertColor,
          message: errorData.message,
          errorCode: errorData.errorCode,
        }

        dispatch(addNotification(notificationData))
      }
    }
  }
)

const updatePaymentOptionByCountry = createAsyncThunk(
  'kwl/updatePaymentOptionByCountry',
  async (arg: UpdatePaymentOptionByCountryRequestArg, { rejectWithValue, dispatch }) => {
    const { paymentOptionId, instanceId, countryId, type, body } = arg

    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'put',
        updatePaymentOptionsByCountryApiPath(paymentOptionId),
        body,

      )

      const nextArg = {
        instanceId,
        countryId,
        type,
      }
      dispatch(remittanceActions.getPaymentOptionsByCountry(nextArg))

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'Payment Option has been updated',
      }

      dispatch(addNotification(notificationData))


      return result
    } catch (err) {
      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse

      if (errorData.code === 400) {
        const notificationData = {
          open: true,
          type: 'error' as AlertColor,
          message: errorData.message,
          errorCode: errorData.errorCode,
        }

        dispatch(addNotification(notificationData))
      }
    }
  }
)

const deletePaymentOptionByCountry = createAsyncThunk(
  'kwl/deletePaymentOptionByCountry',
  async (arg: DeletePaymentOptionByCountryRequestArg, { rejectWithValue, dispatch }) => {
    const { paymentOptionId, instanceId, countryId, type, } = arg

    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'delete',
        deletePaymentOptionsByCountryApiPath(paymentOptionId),
      )

      const nextArg = {
        instanceId,
        countryId,
        type,
      }
      dispatch(remittanceActions.getPaymentOptionsByCountry(nextArg))

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'Payment Option has been updated',
      }

      dispatch(addNotification(notificationData))


      return result
    } catch (err) {
      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse

      if (errorData.code === 400) {
        const notificationData = {
          open: true,
          type: 'error' as AlertColor,
          message: errorData.message,
          errorCode: errorData.errorCode,
        }

        dispatch(addNotification(notificationData))
      }
    }
  }
)


const getCommonCurrencyList = createAsyncThunk(
  'kwl/getCommonCurrencyList',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'get',
        commonCurrencyApiPath,
      )




      return result
    } catch (err) {
      const error = err as AxiosError
      // const errorData = error.response?.data as CustomErrorResponse


    }
  })

const addCommonCurrency = createAsyncThunk(
  'kwl/addCommonCurrency',
  async (body: CommonCurrencyFormType, { rejectWithValue, dispatch }) => {

    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'post',
        commonCurrencyApiPath,
        body,

      )

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'Currency has been added',
      }

      dispatch(addNotification(notificationData))

      dispatch(getCommonCurrencyList())


      return result
    } catch (err) {
      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse

      if (errorData.code === 400) {
        const notificationData = {
          open: true,
          type: 'error' as AlertColor,
          message: errorData.message,
          errorCode: errorData.errorCode,
        }

        dispatch(addNotification(notificationData))
      }
    }
  }
)

const editCommonCurrency = createAsyncThunk(
  'kwl/editCommonCurrency',
  async (body: CommonCurrencyFormType, { rejectWithValue, dispatch }) => {

    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'put',
        commonCurrencyApiPath,
        body,

      )

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'Currency has been changed',
      }

      dispatch(addNotification(notificationData))

      dispatch(getCommonCurrencyList())


      return result
    } catch (err) {
      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse

      if (errorData.code === 400) {
        const notificationData = {
          open: true,
          type: 'error' as AlertColor,
          message: errorData.message,
          errorCode: errorData.errorCode,
        }

        dispatch(addNotification(notificationData))
      }
    }
  }
)

const removeCommonCurrency = createAsyncThunk(
  'kwl/removeCommonCurrency',
  async (id: string | number, { rejectWithValue, dispatch }) => {

    try {
      const result = await apiRequestServiceWithRefresh(
        dispatch,
        'delete',
        removeCommonCurrencyApiPath(id),
      )

      const notificationData = {
        open: true,
        type: 'success' as AlertColor,
        message: 'Currency has been deleted',
      }

      dispatch(addNotification(notificationData))

      dispatch(getCommonCurrencyList())


      return result
    } catch (err) {
      const error = err as AxiosError
      const errorData = error.response?.data as CustomErrorResponse

      if (errorData.code === 400) {
        const notificationData = {
          open: true,
          type: 'error' as AlertColor,
          message: errorData.message,
          errorCode: errorData.errorCode,
        }

        dispatch(addNotification(notificationData))
      }
    }
  }
)


export const remittanceActions = {
  fetchKWLSiteList,
  fetchKWLUserList,
  fetchKWLDataById,
  setKWLCountry,
  deleteKWLCountry,
  getFlowList,
  getBeneficiaryConfig,
  updateBeneficiaryConfig,
  postFlowState,
  postEarlyAccessPassword,
  createInstanceCurrency,
  updateInstanceCurrency,
  getInstanceCurrencyList,
  getInstanceCurrency,
  getAllCurrencies,
  getAvailableInstanceCurrencyList,
  activateInstanceCurrency,
  deactivateInstanceCurrency,
  getPaymentProviderMethods,
  setExpirationEntryForUsersByRiskLevel,

  //remittance-agent-group
  getAgentGroupListForInstance,
  getAllAgentGroups,
  assignAgentGroup,
  unassignAgentGroup,

  //remittance-payment-provider
  getPaymentProviderList,
  getPaymentProviderFeesByInstance,
  getPaymentProviderFeesByCurrency,
  // createPaymentProviderFee,
  // deletePaymentProviderFee,

  //remittance-currency
  getCountryList,
  getPaymentOptionsByCountry,
  getCurrencyListByCountry,
  getPaymentProviderListV2,
  getPaymentMethodList,
  addPaymentOptionByCountry,
  updatePaymentOptionByCountry,
  deletePaymentOptionByCountry,
  getCommonCurrencyList,
  addCommonCurrency,
  editCommonCurrency,
  removeCommonCurrency,
}
