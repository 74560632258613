import { Container, Button } from '@mui/material'
import { useEffect } from 'react'
import Table from '../../../components/Table'
import { useAppDispatch, useAppSelector } from '../../../store'
import { remittanceActions } from '../../../store/actions/kwlActions'
import { getKwlEditCountriesPath, getKwlInstancePath } from '../../../utils/appPaths'

const KuvaWhiteLabelSites = () => {
  const dispatch = useAppDispatch()
  const { list } = useAppSelector(state => state.kwl.site)

  useEffect(() => {
    dispatch(remittanceActions.fetchKWLSiteList())
  }, [dispatch])

  const domain = process.env.REACT_APP_BACK_OFFICE_DOMAIN

  const headData = ['id', 'Host Url', 'Is Default', 'Details', 'Edit', 'Edit Src', 'Edit Dest']
  const dataKeys = [
    'id',
    'hostUrl',
    'isDefault',
    'detailsBtn',
    'editingBtn',
    'editingSrcCountry',
    'editingDestCountry',
  ]
  const mappedData = list.map(item => ({
    ...item,
    detailsBtn: (
      <Button
        size="small"
        variant="contained"
        href={`${domain}/KuvaWhiteLabel/${item.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Details
      </Button>
    ),
    editingBtn: (
      <Button
        size="small"
        variant="contained"
        href={getKwlInstancePath(item.id)}
        target="_blank"
        rel="noopener noreferrer"
      >
        Edit
      </Button>
    ),
    editingSrcCountry: (
      <Button
        size="small"
        variant="contained"
        href={`${getKwlEditCountriesPath(item.id)}?countryTypeIds=sourceCountryIds`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Edit Src
      </Button>
    ),
    editingDestCountry: (
      <Button
        size="small"
        variant="contained"
        href={`${getKwlEditCountriesPath(item.id)}?countryTypeIds=receiveCountryIds`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Edit Dest
      </Button>
    ),
  }))

  return (
    <>
      <Container className="pt-25 pb-10 h-100-percent overflow-hidden" maxWidth="xl">
        <h3 className="mb-25">Kuva White Label Sites</h3>
        <Button
          className="mb-20"
          variant="contained"
          href={`${domain}/KuvaWhiteLabel/Create`}
          target="_blank"
        >
          Create new
        </Button>
        <Table
          bodyData={mappedData}
          headData={headData}
          dataKeys={dataKeys}
          containerStyle="pb-100"
          isPagination={false}
        />
      </Container>
    </>
  )
}

export default KuvaWhiteLabelSites
