import { Button, Dialog, styled } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../store'
import { useCallback, useEffect } from 'react'
import { closeGlobalModal } from '../../store/reducers/globalModalReducer'
import {
  StyledCloseButtonModal,
  StyledDialog,
  StyledDialogActions,
  StyledDialogBody,
  StyledDialogContent,
  StyledDialogHeader,
  StyledDialogTitle,
} from './styledModalComponent'
import CancelIcon from '@mui/icons-material/Cancel'
import { removeCurrentDocImgList } from '../../store/reducers/mobileUserReducer'

const StyledDialogModalTitle = styled(StyledDialogTitle)({
  width: '300px',
})

export const StyledDocDisplayDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-paper': {
    position: 'relative',
    maxWidth: '762px',
    borderRadius: '10px',
    overflow: 'hidden',

    img: {
      marginBottom: '20px',
      borderRadius: '10px',
    },

    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 30px)',
      minWidth: '200px',
      overflow: 'hidden',
    },
  },
}))

type DocDisplayModalProps = {
  isOpenDialog: boolean
  // onCloseModalClick: ActionCreatorWithoutPayload<'globalModal/closeGlobalModal'>
}

const DocDisplayModal = ({ isOpenDialog }: DocDisplayModalProps) => {
  const dispatch = useAppDispatch()
  const { currentDocImgList } = useAppSelector(state => state.mobileUser)

  useEffect(() => {
    return () => {
      dispatch(removeCurrentDocImgList())
    }
  }, [dispatch])

  const closeModal = useCallback(() => {
    dispatch(closeGlobalModal())
  }, [dispatch])

  return (
    <StyledDocDisplayDialog open={isOpenDialog} onClose={closeModal} scroll="paper">
      <StyledDialogHeader>
        <StyledDialogModalTitle>Documents</StyledDialogModalTitle>
        <StyledCloseButtonModal onClick={closeModal}>
          Close
          <CancelIcon />
        </StyledCloseButtonModal>
      </StyledDialogHeader>
      <StyledDialogBody>
        <StyledDialogContent>
          {currentDocImgList.map((docImgSrc, index) => (
            <img src={docImgSrc} alt="" key={index} />
          ))}
        </StyledDialogContent>
        <StyledDialogActions>
          <Button size="large" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
        </StyledDialogActions>
      </StyledDialogBody>
    </StyledDocDisplayDialog>
  )
}

export default DocDisplayModal
