import { Box, Paper, Typography, styled } from '@mui/material'
import React, { useMemo } from 'react'
import { AmlInspectionStatus, UserVisibleAmlInspection } from '../../../../types/mobileUserTypes'
import { getAmlInspectionStatus } from '../../../../utils/statusUtils/mobileUserStatusUtils'
import { getDateWithTime } from '../../../../utils/dateFormating'

type StyledVisibleAmlInspectionBoxProps = {
  bgColor: string
}

export const StyledVisibleAmlInspectionBox = styled(Paper, {
  shouldForwardProp: prop => prop !== 'bgColor',
})<StyledVisibleAmlInspectionBoxProps>(
  ({ bgColor }) => ({
    width: 'calc(50% - 5px)',
    padding: '10px',
    backgroundColor: bgColor,
    '.MuiBox-root': {
      display: 'flex',
      // justifyContent: 'space-between',
      gridGap: '8px',
      marginBottom: '5px',
      p: {
        flex: 1,
        fontSize: '14px',
        '&:last-child': {
          textAlign: 'right',
        },
      },
    },
  })
)

type VisibleAmlInspectionBoxProps = {
  userVisibleInspection: UserVisibleAmlInspection
}

const VisibleAmlInspectionBox = ({ userVisibleInspection }: VisibleAmlInspectionBoxProps) => {
  const bgColor = useMemo(() => {
    if (userVisibleInspection.status === AmlInspectionStatus.Verified) {
      return '#e8f5e9'
    }

    if (userVisibleInspection.status === AmlInspectionStatus.Pending) {
      return '#e3f2fd'
    }

    if (userVisibleInspection.status === AmlInspectionStatus.Rejected) {
      return '#ffebee'
    }

    if (userVisibleInspection.status === AmlInspectionStatus.RejectedWithRetry) {
      return '#ffebee'
    }

    return '#ffffff'
  }, [userVisibleInspection.status])
  return (
    <StyledVisibleAmlInspectionBox elevation={3} bgColor={bgColor}>
      {/* <Box>
        <Typography fontWeight="bold">Id:</Typography>
        <Typography>545345</Typography>
      </Box> */}
      {/* <Box>
        <Typography fontWeight="bold">Created Date:</Typography>
        <Typography>2023-32-32 10:54:56</Typography>
      </Box> */}

      <Box>
        <Typography fontWeight="bold">Flow Name:</Typography>
        <Typography>{userVisibleInspection.level.name}</Typography>
      </Box>

      <Box>
        <Typography fontWeight="bold">Flow Display Name:</Typography>
        <Typography>{userVisibleInspection.level.displayName} </Typography>
      </Box>

      <Box>
        <Typography fontWeight="bold">Flow Weight:</Typography>
        <Typography>{userVisibleInspection.level.weight}</Typography>
      </Box>

      <Box>
        <Typography fontWeight="bold">Status:</Typography>
        <Typography>{getAmlInspectionStatus(userVisibleInspection.status)}</Typography>
      </Box>

      <Box>
        <Typography fontWeight="bold">Expiration Date:</Typography>
        <Typography>{getDateWithTime(userVisibleInspection.expirationDate)}</Typography>
      </Box>
    </StyledVisibleAmlInspectionBox>
  )
}

export default VisibleAmlInspectionBox
