import { Button, styled } from '@mui/material'
import { useCallback } from 'react'
import { PaymentOptionByCountry } from '../../../../../types/remittanceTypes'
import { useAppDispatch } from '../../../../../store'
import { openGlobalModal } from '../../../../../store/reducers/globalModalReducer'

const StyledControlButtonCell = styled('div')({
  display: 'flex',
  gridGap: '5px',
})

type ControlButtonCellProps = {
  paymentOptionData: PaymentOptionByCountry
  instanceId?: string
}

const ControlButtonCell = ({ paymentOptionData, instanceId }: ControlButtonCellProps) => {
  const dispatch = useAppDispatch()

  const onEditBtn = useCallback(() => {
    instanceId &&
      dispatch(
        openGlobalModal({
          variant: 'edit-payment-option-by-country',
          actionData: paymentOptionData,
          id: instanceId,
        })
      )
  }, [dispatch, instanceId, paymentOptionData])

  const onDeleteBtn = useCallback(() => {
    dispatch(
      openGlobalModal({
        variant: 'delete-payment-option-by-country',
        id: instanceId,
        actionData: paymentOptionData,
      })
    )
  }, [dispatch, instanceId, paymentOptionData])

  return (
    <StyledControlButtonCell>
      <Button size="small" variant="contained" onClick={onEditBtn}>
        Edit
      </Button>

      <Button size="small" variant="contained" color="error" onClick={onDeleteBtn}>
        Delete
      </Button>
    </StyledControlButtonCell>
  )
}

export default ControlButtonCell
