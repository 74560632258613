import { styled } from '@mui/material/styles'
import CancelIcon from '@mui/icons-material/Cancel'
import {
  StyledCloseButtonModal,
  StyledDialog,
  StyledDialogActions,
  StyledDialogBody,
  StyledDialogContent,
  StyledDialogContentText,
  StyledDialogHeader,
  StyledDialogTitle,
} from './styledModalComponent'
import { useAppDispatch } from '../../store'
import { closeGlobalModal } from '../../store/reducers/globalModalReducer'
import Button from '@mui/material/Button'
import { useCallback } from 'react'

type StyledConfirmDialogModalBtnProps = {
  bgColor: string
}

const StyledConfirmDialogModalBtn = styled(Button, {
  shouldForwardProp: prop => prop !== 'bgColor',
})<StyledConfirmDialogModalBtnProps>(({ bgColor }) => ({
  backgroundColor: bgColor,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: bgColor,
    filter: 'brightness(80%)',
  },
}))

const StyledDialogModalTitle = styled(StyledDialogTitle)({
  width: '390px',
})

type DialogModalProps = {
  isOpenDialog: boolean
  headerText: string
  mainText: string
  onParentConfirm: () => void
  confirmBtnText: string
  // confirmBtnColor: 'primary' | 'success' | 'error'
  confirmBtnColor: string
}

const DialogModal = ({
  isOpenDialog,
  headerText,
  mainText,
  onParentConfirm,
  confirmBtnText,
  confirmBtnColor = '#1976d2',
}: DialogModalProps) => {
  const dispatch = useAppDispatch()

  const closeModal = useCallback(() => {
    dispatch(closeGlobalModal())
  }, [dispatch])

  const onConfirm = useCallback(() => {
    onParentConfirm()
    closeModal()
  }, [closeModal, onParentConfirm])

  return (
    <StyledDialog open={isOpenDialog} onClose={closeModal}>
      <StyledDialogHeader>
        <StyledDialogModalTitle>{headerText}</StyledDialogModalTitle>
        <StyledCloseButtonModal onClick={closeModal}>
          Close
          <CancelIcon />
        </StyledCloseButtonModal>
      </StyledDialogHeader>
      <StyledDialogBody>
        <StyledDialogContent>
          <StyledDialogContentText>{mainText}</StyledDialogContentText>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button size="large" variant="outlined" onClick={closeModal}>
            No
          </Button>
          <StyledConfirmDialogModalBtn
            size="large"
            variant="contained"
            onClick={onConfirm}
            bgColor={confirmBtnColor}
          >
            {confirmBtnText}
          </StyledConfirmDialogModalBtn>
        </StyledDialogActions>
      </StyledDialogBody>
    </StyledDialog>
  )
}

export default DialogModal
