import {
  AmlInspectionState,
  BlockingRecord,
  BlockingRecordStatus,
  BlockingType,
  InspectionDoc,
  MobileUserDataType,
  ProjectType,
  UserAmlInspection,
  UserBlockingRecord,
} from '../../../types/mobileUserTypes'
import { TableConfig } from '../../../types/tableTyles'
import { mobileUserStatuses, riskScoreMarkers } from '../../constants'
import { getDateWithTime } from '../../dateFormating'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import { getMobileUserDetails, getMobileUserTransactions } from '../../appPaths'
import { ReceiptLongRounded } from '@mui/icons-material'
import { getAmlInspectionStatus } from '../../statusUtils/mobileUserStatusUtils'
import { GettingImgDocButton } from '../../../pages/MobileUsers/MobileUserDetails/amlInspection/GettingDocsButton'
import ModalActivationButton from '../../../components/modal/ModalActivationButton'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

export const mobileUserTableConfig = (): TableConfig<MobileUserDataType> => {
  return {
    columns: [
      {
        id: 'status',
        header: 'Status',
        contentColumn: item => mobileUserStatuses[item.status].value,
        width: {
          minWidth: '200px',
          width: '200px',
        },
      },
      {
        id: 'roles',
        header: 'Roles',
        contentColumn: item => item.roles.join(', '),
        width: {
          minWidth: '230px',
          width: '230px',
        },
      },
      {
        id: 'registrationDate',
        header: 'Registration Date',
        contentColumn: item => getDateWithTime(item.registrationDate),
        width: {
          minWidth: '200px',
          width: '200px',
        },
      },
      {
        id: 'kwlRiskScore',
        header: 'KWL Risk',
        contentColumn: item => (
          <span className={`color-${riskScoreMarkers[item.kwlRiskLevelMarker]}`}>
            {item.kwlRiskScore}
          </span>
        ),
        width: {
          minWidth: '120px',
          width: '120px',
        },
      },
      {
        id: 'userRiskScore',
        header: 'User Risk',
        contentColumn: item => item.riskScore,
        width: {
          minWidth: '120px',
          width: '120px',
        },
      },

      {
        id: 'fullName',
        header: 'Full Name',
        contentColumn: item => `${item.firstName} ${item.lastName}`,
        width: {
          minWidth: '250px',
          width: '250px',
        },
      },
      {
        id: 'email',
        header: 'Email',
        contentColumn: item => item.email,
        width: {
          minWidth: '300px',
          width: '300px',
        },
      },
      {
        id: 'phoneNumber',
        header: 'Phone Number',
        contentColumn: item => item.phoneNumber,
        width: {
          minWidth: '170px',
          width: '170px',
        },
      },
      {
        id: 'walletAddressUsd',
        header: 'Wallet Address Usd',
        contentColumn: item => item.walletAddressUsd,
        width: {
          minWidth: '250px',
          width: '250px',
        },
      },
      {
        id: 'transactions',
        header: 'Transactions',
        contentColumn: item => (
          <a href={getMobileUserTransactions(item.id)} target="_blank" rel="noreferrer">
            <ReceiptLongRounded />
          </a>
        ),
        width: {
          minWidth: '150px',
          width: '150px',
        },
      },
      {
        id: 'old-bo-details',
        header: 'Details',
        contentColumn: item => (
          <Button
            size="small"
            variant="contained"
            href={getMobileUserDetails(item.id)}
            target="_blank"
          >
            Details
          </Button>
        ),
        width: {
          minWidth: '150px',
          width: '150px',
        },
      },
      {
        id: 'details',
        header: '',
        contentColumn: item => (
          <Link to={`/mobile-users/${item.id}`}>
            <Button variant="contained" size="small">
              Edit
            </Button>
          </Link>
        ),
        width: {
          minWidth: '150px',
          width: '150px',
        },
      },
    ],
  }
}
//UserAmlInspection

const userAmlInspectionsTableConfig = (): TableConfig<UserAmlInspection> => {
  return {
    columns: [
      {
        id: 'inspectionId',
        header: 'Inspection Id',
        contentColumn: item => item.id,
        width: {
          minWidth: '150px',
          width: '10%',
        },
      },
      {
        id: 'createdDate',
        header: 'Created Date (UTC)',
        contentColumn: item => getDateWithTime(item.createdDate),
        width: {
          minWidth: '200px',
          width: '20%',
        },
      },
      {
        id: 'expirationDate',
        header: 'Expiration Date (UTC)',
        contentColumn: item => getDateWithTime(item.expirationDate),
        width: {
          minWidth: '200px',
          width: '20%',
        },
      },
      {
        id: 'status',
        header: 'Status',
        contentColumn: item => getAmlInspectionStatus(item.status),
        width: {
          minWidth: '100px',
          width: '20%',
        },
      },
      {
        id: 'state',
        header: 'State',
        contentColumn: item => AmlInspectionState[item.state],
        width: {
          minWidth: '100px',
          width: '10%',
        },
      },
      {
        id: 'flow',
        header: 'Flow',
        contentColumn: item => {
          return (
            <>
              {item.flow.displayName}
              <br />
              {item.flow.name}/ {item.flow.weight}
            </>
          )
        },
        width: {
          minWidth: '200px',
          width: '20%',
        },
      },
    ],
  }
}

const amlInspectionDocTableConfig = (): TableConfig<InspectionDoc> => {
  return {
    columns: [
      {
        id: 'docType',
        header: 'Document Type',
        contentColumn: item => item.docType,
        width: {
          minWidth: '180px',
          width: '40%',
        },
      },
      {
        id: 'reviewAnswer',
        header: 'Review Answer',
        contentColumn: item => item.reviewAnswer,
        width: {
          minWidth: '160px',
          width: '40%',
        },
      },
      {
        id: '',
        header: '',
        contentColumn: item => <GettingImgDocButton docImgList={item.imagesReviewResults} />,
        width: {
          minWidth: '80px',
          width: '20%',
        },
      },
    ],
  }
}

const userBlockingRecordsTableConfig = (): TableConfig<UserBlockingRecord> => {
  return {
    columns: [
      {
        id: 'blocking-date',
        header: 'Last Blocking Date (UTC)',
        contentColumn: item => getDateWithTime(item.lastBlockingDate),
        width: {
          minWidth: '240px',
          width: '20%',
        },
      },
      {
        id: 'user-name',
        header: 'User Name',
        contentColumn: item => `${item.firstName} ${item.lastName}`,
        width: {
          minWidth: '250px',
          width: '30%',
        },
      },
      {
        id: 'phone-number',
        header: 'Phone Number',
        contentColumn: item => item.phoneNumber,
        width: {
          minWidth: '170px',
          width: '20%',
        },
      },
      {
        id: 'unconfirmed-count',
        header: 'Unconfirmed',
        contentColumn: item => item.unconfirmedCount,
        width: {
          minWidth: '140px',
          width: '10%',
        },
      },
      {
        id: 'confirmed-count',
        header: 'Confirmed',
        contentColumn: item => item.confirmedCount,
        width: {
          minWidth: '140px',
          width: '10%',
        },
      },
      {
        id: 'resolved-count',
        header: 'Resolved',
        contentColumn: item => item.resolvedCount,
        width: {
          minWidth: '140px',
          width: '10%',
        },
      },
    ],
  }
}

const getBlockedProjects = (projectTypes: number) => {
  const projectArray = []

  if ((projectTypes & ProjectType.KuvaCash) !== 0) {
    projectArray.push('Kuva Cash')
  }

  if ((projectTypes & ProjectType.KuvaLocal) !== 0) {
    projectArray.push('Kuva Local')
  }

  if ((projectTypes & ProjectType.KuvaWhiteLabel) !== 0) {
    projectArray.push('Kuva White Label')
  }

  return projectArray.join(', ')
}

const currentUserBlockingRecordsTableConfig = (): TableConfig<BlockingRecord> => {
  return {
    columns: [
      {
        id: 'collapsing-icon',
        header: '',
        contentColumn: item => null,
        contentColumnWithChangingIcon: isOpen =>
          isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />,
        width: {
          minWidth: '55px',
          width: '5%',
        },
      },
      {
        id: 'id',
        header: 'Id',
        contentColumn: item => item.id,
        width: {
          minWidth: '100px',
          width: '10%',
        },
      },
      {
        id: 'type',
        header: 'Type',
        contentColumn: item => BlockingType[item.blockingType],
        width: {
          minWidth: '250px',
          width: '20%',
        },
      },
      {
        id: 'status',
        header: 'Status',
        contentColumn: item => BlockingRecordStatus[item.status],
        width: {
          minWidth: '150px',
          width: '10%',
        },
      },
      {
        id: 'blocked-project',
        header: 'Blocked Projects',
        contentColumn: item => getBlockedProjects(item.projectTypes),
        width: {
          minWidth: '180px',
          width: '15%',
        },
      },
      {
        id: 'blocking-date',
        header: 'Blocking Date (UTC)',
        contentColumn: item => getDateWithTime(item.blockingDate),
        width: {
          minWidth: '200px',
          width: '20%',
        },
      },
      {
        id: 'confirmation-btn',
        header: '',
        contentColumn: item =>
          item.status !== BlockingRecordStatus.Confirmed &&
          item.status !== BlockingRecordStatus.Resolved ? (
            <ModalActivationButton
              textBtn="Confirm"
              variant="confirm-blocking-record"
              id={item.id}
            />
          ) : null,
        width: {
          minWidth: '120px',
          width: '10%',
        },
      },
      {
        id: 'resolving-btn',
        header: '',
        contentColumn: item =>
          item.status !== BlockingRecordStatus.Resolved ? (
            <ModalActivationButton
              textBtn="Resolve"
              variant="resolve-blocking-record"
              id={item.id}
            />
          ) : null,
        width: {
          minWidth: '120px',
          width: '10%',
        },
      },
    ],
  }
}

export const mobileUserTableConfigs = {
  mobileUserTableConfig,
  userAmlInspectionsTableConfig,
  amlInspectionDocTableConfig,
  userBlockingRecordsTableConfig,
  currentUserBlockingRecordsTableConfig,
}
