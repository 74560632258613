import { Grid, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../store'
import { useCallback, useEffect, useMemo } from 'react'
import { mobileUserActions } from '../../../store/actions/mobileUserActions'
import { CustomerKlProfile, MobileUserStatus } from '../../../types/mobileUserTypes'
import { getDateWithTime } from '../../../utils/dateFormating'

type ProfileDetailsTabProps = {
  userId: string | undefined
}

const ProfileDetailsTab = ({ userId }: ProfileDetailsTabProps) => {
  const dispatch = useAppDispatch()

  const { mobileUserDetails, klProfileDetails, kwlProfileDetails, isLoading } = useAppSelector(
    state => state.mobileUser
  )

  useEffect(() => {
    if (userId) {
      dispatch(mobileUserActions.getMobileUserDetails(userId))
      dispatch(mobileUserActions.getKlProfileDetails(userId))
      dispatch(mobileUserActions.getKwlProfileDetails(userId))
    }
  }, [dispatch, userId])

  const getUserStatus = useCallback((status: MobileUserStatus | undefined) => {
    if (status) {
      return MobileUserStatus[status]
    } else {
      return ''
    }
  }, [])

  const getNopCommerceCustomerString = useCallback(
    (nopCommerceCustomerEntry: CustomerKlProfile) => {
      const newString = `${nopCommerceCustomerEntry.countryCode}: ${nopCommerceCustomerEntry.nopCommerceCustomerId}`

      return `${newString} ${nopCommerceCustomerEntry.isDeleted ? '(deleted)' : ''}`
    },
    []
  )

  return (
    <>
      {!isLoading ? (
        <Grid
          container
          spacing={2}
          sx={{ margin: 0, width: '100%', height: 'calc(100dvh - 200px)', overflowX: 'auto' }}
        >
          <Grid item xs={12} md={4}>
            <Typography fontWeight="bold">Id:</Typography>
            <Typography mb={1}>{userId}</Typography>

            <Typography fontWeight="bold">Status:</Typography>
            <Typography mb={1}>{getUserStatus(mobileUserDetails?.status)}</Typography>

            <Typography fontWeight="bold">Full Name:</Typography>
            <Typography mb={1}>{mobileUserDetails?.fullName || ''}</Typography>

            <Typography fontWeight="bold">Phone Number:</Typography>
            <Typography mb={1}>{mobileUserDetails?.phoneNumber || ''}</Typography>

            <Typography fontWeight="bold">Email:</Typography>
            <Typography mb={1}>{mobileUserDetails?.email || ''}</Typography>

            {mobileUserDetails?.email && (
              <>
                <Typography fontWeight="bold">Email Verified:</Typography>
                <Typography mb={1}>{String(mobileUserDetails?.isEmailConfirmed)}</Typography>
              </>
            )}
          </Grid>
          {klProfileDetails && (
            <Grid item xs={12} md={4}>
              <Typography fontSize={18} fontWeight="bold" mb={1.5}>
                Kuva Local Profile:
              </Typography>

              <Typography fontWeight="bold">Status:</Typography>
              <Typography mb={1}>{getUserStatus(klProfileDetails.status)}</Typography>

              {klProfileDetails.customerProfiles.length > 0 && (
                <>
                  <Typography fontWeight="bold">NopCommerce Customer Ids:</Typography>
                  {klProfileDetails.customerProfiles.map(customerProfiles => (
                    <Typography key={customerProfiles.nopCommerceCustomerId}>
                      {getNopCommerceCustomerString(customerProfiles)}
                    </Typography>
                  ))}
                </>
              )}

              <Typography fontWeight="bold">Created Date (UTC):</Typography>
              <Typography mb={1}>{getDateWithTime(klProfileDetails.createdDate)}</Typography>

              <Typography fontWeight="bold">Previous Shop Location:</Typography>
              <Typography mb={1}>{klProfileDetails.previousShopLocation}</Typography>
            </Grid>
          )}
          {kwlProfileDetails && (
            <Grid item xs={12} sm={4}>
              <Typography fontSize={18} fontWeight="bold" mb={1.5}>
                Kuva White Label Profile:
              </Typography>

              <Typography fontWeight="bold">Status:</Typography>
              <Typography mb={1}>{getUserStatus(kwlProfileDetails.status)}</Typography>

              <Typography fontWeight="bold">Country Id:</Typography>
              <Typography mb={1}>{kwlProfileDetails.countryId || ''}</Typography>

              <Typography fontWeight="bold">City:</Typography>
              <Typography mb={1}>{kwlProfileDetails.city || ''}</Typography>

              <Typography fontWeight="bold">Street:</Typography>
              <Typography mb={1}>{kwlProfileDetails.street || ''}</Typography>

              <Typography fontWeight="bold">Postcode:</Typography>
              <Typography mb={1}>{kwlProfileDetails.postcode || ''}</Typography>

              <Typography fontWeight="bold">Subscribe to news and updates:</Typography>
              <Typography mb={1}>
                {String(kwlProfileDetails.isSubscribedToNewsAndUpdates)}
              </Typography>
            </Grid>
          )}
        </Grid>
      ) : null}
    </>
  )
}

export default ProfileDetailsTab
